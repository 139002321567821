import React, { useContext } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';

const OptionsTooltipContent = () => {
  return (
    <>
      <h3>How to tell if I have a gas or electric appliance.</h3>
      <br />
      <p>
        {`The easiest way to tell is to look at the back of your appliance.
        If you see a large power cord, then you have an electric appliance.
        Alternatively, if you see a pipe or a metal hose, then it’s a gas appliance.`}
      </p>
    </>
  );
};

export const AlternateOptions = ({
  bundleItemTitle,
  currentSelectedItemId,
  alternateItems,
  updateCurrentSelection
}) => {
  const { channel } = useContext(ExperienceContext);
  const INFO_ICON = 'https://assets.thdstatic.com/images/v1/info.svg';

  const handleAlternateProducts = (evt) => {
    updateCurrentSelection(evt.currentTarget.id);
  };

  const getAlternateOptionsTitle = () => {
    return (
      <div className="appliance-bundle-item__tooltip">
        {alternateItems.length === 0 ? (`This ${bundleItemTitle}`) : (
          <>
            <span>Choose a matching&nbsp;</span>
            <span className="appliance-bundle-item__tooltip--title-container">
              {bundleItemTitle}
              <Tooltip
                channel={channel}
                closeButton
                content={<OptionsTooltipContent />}
                maxWidth={400}
                placement="top"
              >
                <img
                  src={INFO_ICON}
                  alt="Information icon for choose your option tooltip."
                  className="appliance-bundle-item__tooltip__icon"
                  width="1"
                  height="1"
                />
              </Tooltip>
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        {getAlternateOptionsTitle()}
        <div className="appliance-bundle-item__alternate-products">
          {alternateItems && alternateItems.map((alternateProduct, index) => {
            const isSelected = currentSelectedItemId === alternateProduct.itemId;
            const currentlySelectedClasses = classNames({
              'appliance-bundle-item__alternate-products__radio-button--checked': isSelected,
              'appliance-bundle-item__alternate-products__radio-button': !isSelected
            });
            return (
              <div
                onClick={handleAlternateProducts}
                key={index}
                id={alternateProduct.itemId}
                className="appliance-bundle-item__alternate-products__option"
                role="button"
                tabIndex={index}
              >
                <span className={currentlySelectedClasses} />
                <span className="appliance-bundle-item__alternate-products__label">
                  {alternateProduct.label}&nbsp;<b>{alternateProduct.price}</b>
                </span>
              </div>
            );
          }) }

        </div>
      </div>
    </>
  );
};

AlternateOptions.propTypes = {
  bundleItemTitle: PropTypes.string.isRequired,
  currentSelectedItemId: PropTypes.string,
  updateCurrentSelection: PropTypes.func.isRequired,
  alternateItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

AlternateOptions.defaultProps = {
  currentSelectedItemId: ''
};
