import React from 'react';
import {
  shape, bool
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const CollectionsUplevelTitle = (props) => {
  const {
    metadata,
    productIdentifier,
    isBatteryCollection,
    showColUpLevel
  } = props;

  const productBrandName = productIdentifier?.brandName;

  return (
    <>
      <div>
        {metadata?.collectionTitle1 && !isBatteryCollection && (
          <div className="sui-pb-2 sm:sui-pb-0">
            <Typography variant="h2">{metadata?.collectionTitle1}</Typography>
          </div>
        )}
        {!metadata?.collectionTitle1 && (
          <div className="sui-pb-2 sm:sui-pb-0">
            <Typography variant="h2">{productBrandName}</Typography>
          </div>
        )}

        {metadata?.collectionTitle2 && (
          <div className="sui-pb-2 sm:sui-pb-0">
            <Typography variant="body-base" weight="bold" component="h3">{metadata?.collectionTitle2}</Typography>
          </div>
        )}
        {showColUpLevel ? <div className="sui-pt-4"> Find items that go well together </div> : ''}
      </div>
    </>
  );
};

CollectionsUplevelTitle.propTypes = {
  metadata: shape({
  }),
  productIdentifier: shape({
  }),
  isBatteryCollection: bool,
  showColUpLevel: bool
};

CollectionsUplevelTitle.defaultProps = {
  metadata: {},
  productIdentifier: {},
  isBatteryCollection: false,
  showColUpLevel: false
};

export default CollectionsUplevelTitle;