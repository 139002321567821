import {
  number,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types';
import { ENLARGED_IMAGE_POSITION } from '../constants';

export const EnlargedImagePosition = oneOf([
  ENLARGED_IMAGE_POSITION.beside,
  ENLARGED_IMAGE_POSITION.over
]);

export const EnlargedImageContainerDimensions = shape({
  width: oneOfType([number, string]),
  height: oneOfType([number, string])
});

export const ContainerDimensions = shape({
  width: number,
  height: number
});