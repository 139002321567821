/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { bool, shape, string } from 'prop-types';

import { Image, Rating } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';

import * as analytics from '../../analytics/frequentlyboughttogether-analytics';
import './BundleItem.style.scss';

const BundleItem = ({
  anchor,
  anchorProduct,
  itemInCart,
  openInNewTab,
  product,
  storeId
}) => {

  if (!product) return null;

  const {
    identifiers, media, reviews
  } = product;
  const { brandName, canonicalUrl, productLabel, itemId } = identifiers || {};
  const { url } = media?.image || {};
  const imageUrl = url.replace('<SIZE>', 300);
  const { ratingsReviews: { averageRating, totalReviews } } = reviews || {};

  return (
    <div className="bundle-item">
      <a
        className="bundle-item--generic"
        href={canonicalUrl}
        onClick={() => analytics.track({
          itemId: anchorProduct.itemId,
          bundle: true
        })}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        target={openInNewTab ? '_blank' : '_self'}
      >
        <div className="bundle-item__image">
          <Image
            src={imageUrl}
            alt={brandName}
            stretchy
            width={1}
            height={1}
          />
        </div>
        <div className="bundle-item__wrapper">
          <div className="bundle-item__product-details">
            {anchor && <div className="bundle-item__item-state">(Current Item)</div>}
            {itemInCart && (
              <div className="bundle-item__item-in-cart">
                <Image
                  src="https://assets.thdstatic.com/images/v1/alert-check-green.svg"
                  alt="Check"
                  lazy
                />
                <span>In Your Cart</span>
              </div>
            )}
            <div className="bundle-item__label">{productLabel}</div>
            <div className="bundle-item__brand-name">by <span className="u__bold">{brandName}</span></div>
            <div className="bundle-item__ratings-reviews">
              <Rating value={parseFloat(averageRating)} />
              <span>({Number(totalReviews)})</span>
            </div>
            <div className="bundle-item__price">
              <Price
                itemId={itemId}
                large={false}
                product={product}
                disableRangePricing
                hideBadge
                storeId={storeId}
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

BundleItem.propTypes = {
  anchor: bool,
  anchorProduct: shape({}).isRequired,
  itemInCart: bool,
  openInNewTab: bool.isRequired,
  product: shape({}).isRequired,
  storeId: string.isRequired,
};

BundleItem.defaultProps = {
  anchor: false,
  itemInCart: false
};

BundleItem.displayName = 'FBTBundleItem';

export default BundleItem;
