import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

export const ThreeSixtyIcon = ({ isThumbnail }) => {
  const classes = classNames('sui-absolute sui-h-24 sui-w-24', {
    'sui-m-auto sui-inset-0 sui-cursor-default sui-scale-75': !isThumbnail,
    'sui-scale-50': isThumbnail
  });
  return (
    <span
      className={classes}
      data-testid="threesixty-icon"
      style={{ background: 'url(https://assets.thdstatic.com/images/v1/360-transparent.svg) no-repeat center' }}
    />
  );
};

ThreeSixtyIcon.propTypes = {
  isThumbnail: bool
};

ThreeSixtyIcon.defaultProps = {
  isThumbnail: false
};