import React, { useContext } from 'react';
import * as pt from 'prop-types';
import { Badge as SuiBadge } from '@one-thd/sui-atomic-components';
import {
  params, shape, client, string, alias, useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import classNames from 'classnames';
import { BADGE_MODELS, INSPIRATIONAL_BADGE_MODELS } from '../../constants';

const millisecondsPerDay = 1000 * 60 * 60 * 24;

function getBadge({ badges, inspirational }) {
  const displayableBadges = inspirational ? INSPIRATIONAL_BADGE_MODELS : BADGE_MODELS;
  const itemBadges = badges.filter((badge) => displayableBadges.indexOf(badge?.name) > -1);
  return itemBadges.length ? itemBadges[0] : null;
}

function getEventBadge(badges) {
  const itemBadges = badges.filter((badge) => {
    return badge?.name === 'Event Badge';
  });
  return itemBadges.length ? itemBadges[0] : null;
}

export function getTimerThreshold(timerDuration) {
  if (!timerDuration || timerDuration <= millisecondsPerDay) {
    return '0';
  }
  return millisecondsPerDay;
}

export function getDaysLeftThreshold(timerDuration) {
  if (!timerDuration || timerDuration <= millisecondsPerDay) {
    return '0';
  }
  return timerDuration;
}

function getEventTimerConfig(eventBadge) {
  const dateDisplay = eventBadge.timerDuration === null || eventBadge.timerDuration === '0';
  return {
    endDate: eventBadge.endDate,

    timerThreshold:
      eventBadge.timer?.timeBombThreshold !== null
        ? eventBadge.timer?.timeBombThreshold
        : getTimerThreshold(eventBadge.timerDuration),

    daysLeftThreshold:
      eventBadge.timer?.daysLeftThreshold !== null
        ? eventBadge.timer?.daysLeftThreshold
        : getDaysLeftThreshold(eventBadge.timerDuration),

    dateDisplayThreshold: eventBadge.timer
      ? eventBadge.timer?.dateDisplayThreshold
      : dateDisplay && eventBadge.endDate && +Date.parse(eventBadge.endDate) - Date.now() + 1,

    message: eventBadge?.timer?.message ? eventBadge.timer.message + ' ' : null,
  };
}

export function shouldDisplayTimer(eventBadge, config) {
  if (
    eventBadge?.timer?.timeBombThreshold
    || eventBadge?.timer?.daysLeftThreshold
    || eventBadge?.timer?.dateDisplayThreshold
  ) {
    return true;
  }
  const offset = eventBadge.timerDuration ? +eventBadge.timerDuration : 0;
  const endDateMilliseconds = eventBadge.endDate && Date.parse(eventBadge.endDate);
  const isBeforeEndDate = Date.now() <= endDateMilliseconds;
  const isAfterThreshold = Date.now() + offset >= endDateMilliseconds;
  const isEndDateDisplay = config && config.dateDisplay;
  return !!(isBeforeEndDate && (isAfterThreshold || isEndDateDisplay));
}

export const showColorDisplayBadge = (colorDisplayBadge) => {
  switch (colorDisplayBadge) {
  case 'AdjustedColorMatchColor':
  case 'AdjustedCompetitiveColor':
  case 'AdjustedEfbColor':
    return 'Adjusted';
  case 'ColorMatchColor':
    return 'Color Match';
  case 'CustomColor':
    return 'Manual';
  default:
    return '';
  }
};

export const ProductDetailsBadge = ({
  storeId: propsStoreId,
  itemId,
  inspirational,
  configId,
  noPlaceholderClass,
}) => {
  const { channel } = useContext(ExperienceContext);
  const placeholderClass = classNames({ 'product-details__placeholder': !noPlaceholderClass });

  const storeIdCall = useStoreId();
  const storeId = propsStoreId || storeIdCall;

  const opts = {
    ssr: false,
    variables: { itemId, storeId, configId, skipPaintDetails: !configId },
  };
  const { data, loading, error } = useDataModel('clientOnlyProduct', opts);

  if ((error && !data) || loading || !data) {
    return <div className={placeholderClass} />;
  }

  const defaultBadge = getBadge({ badges: data?.product?.badges || [], inspirational });
  const eventBadge = getEventBadge(data?.product?.badges || []);
  const colorDisplayBadge = showColorDisplayBadge(data?.product?.paintDetails?.colorType);
  const timerConfig = eventBadge && getEventTimerConfig(eventBadge);
  const isMobileDisplayCrowded = channel === 'mobile'
    && defaultBadge
    && eventBadge
    && timerConfig
    && shouldDisplayTimer(eventBadge, timerConfig);
  if (!(defaultBadge || eventBadge || colorDisplayBadge)) {
    return <div className={placeholderClass} />;
  }

  return (
    <span
      className={`sui-flex sui-flex-wrap sui-items-center 
      ${isMobileDisplayCrowded ? 'sui-gap-2 sui-mt-12 sui-mb-3' : 'sui-gap-4 sui-my-3 sui-mr-20'}`}
      data-component="ProductDetailsBadge"
    >
      {defaultBadge && !colorDisplayBadge && (
        <span id="product-details__default-badge">
          <SuiBadge variant="regular" color="info" size={isMobileDisplayCrowded ? 'small' : 'base'}>
            {defaultBadge.label}
          </SuiBadge>
        </span>
      )}
      {colorDisplayBadge && (
        <span id="product-details__color-display-badge">
          <SuiBadge variant="regular" color="info" size={isMobileDisplayCrowded ? 'small' : 'base'}>
            {colorDisplayBadge}
          </SuiBadge>
        </span>
      )}
      <div className={`sui-flex sui-flex-wrap sui-items-center ${isMobileDisplayCrowded ? 'sui-gap-2' : 'sui-gap-4'}`}>
        {eventBadge && !colorDisplayBadge && (
          <span id="product-details__event-badge">
            <SuiBadge
              variant="strong"
              style={{ backgroundColor: eventBadge?.color }}
              color="brand"
              size={isMobileDisplayCrowded ? 'small' : 'base'}
            >
              {eventBadge.label}
            </SuiBadge>
          </span>
        )}
        {eventBadge
          && !colorDisplayBadge
          && timerConfig
          && shouldDisplayTimer(eventBadge, timerConfig) && (
          <span className="product-details__badge-container--timer">
            <CountdownTimer
              endDate={timerConfig.endDate}
              timebombThreshold={+timerConfig.timerThreshold}
              shouldUseDaysLeftDisplay
              shouldUseClockDisplay
              shouldUseDateDisplay
              shouldUseMessagingString
              shouldIncludeSvg
              daysLeftThreshold={+timerConfig.daysLeftThreshold}
              dateDisplayThreshold={+timerConfig.dateDisplayThreshold}
              customMessagingString={timerConfig.message}
            />
          </span>
        )}
      </div>
    </span>
  );
};

ProductDetailsBadge.propTypes = {
  itemId: pt.string.isRequired,
  inspirational: pt.bool,
  storeId: pt.oneOfType([pt.string, pt.number]),
  noPlaceholderClass: pt.bool,
  configId: pt.string,
};

ProductDetailsBadge.defaultProps = {
  inspirational: false,
  storeId: null,
  noPlaceholderClass: false,
  configId: undefined,
};

const paintDetails = params({ storeId: string(), configId: string() }).shape({
  colorType: string(),
});
if (paintDetails.skip) {
  paintDetails.skip('skipPaintDetails', true);
}

ProductDetailsBadge.dataModel = {
  clientOnlyProduct: alias('product')
    .params({ itemId: string().isRequired() })
    .shape({
      itemId: string(),
      dataSources: string(),
      paintDetails,
      badges: client(
        params({ storeId: string() }).arrayOf(
          shape({
            color: string(),
            creativeImageUrl: string(),
            endDate: string(),
            label: string(),
            message: string(),
            name: string(),
            timerDuration: string(),
            timer: shape({
              timeBombThreshold: string(),
              daysLeftThreshold: string(),
              dateDisplayThreshold: string(),
              message: string(),
            }),
          })
        )
      ),
    }),
};

export default ProductDetailsBadge;
