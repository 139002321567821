import React from 'react';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';

export const SpecificationsAccordionTitlePlaceholder = () => (
  <div data-component="SpecificationsAccordionTitlePlaceholder">
    <SkeletonBlock aspect="wide" height={10} width="100%" />
  </div>
);

SpecificationsAccordionTitlePlaceholder.displayName = 'SpecificationsAccordionTitlePlaceholder';
