import {
  extend,
  bool as boolType,
  params,
  shape,
  string as stringType,
  arrayOf,
} from '@thd-nucleus/data-sources';

export const productConfiguratorDataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape({
      itemId: stringType(),
      dataSource: stringType(),
      identifiers: shape({
        productLabel: stringType(),
      }),
    }),
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      itemId: stringType(),
      dataSources: stringType(),
      info: shape({
        gccExperienceOmsId: stringType(),
        recommendationFlags: shape({
          visualNavigation: boolType(),
          pipCollections: boolType(),
          packages: boolType(),
          ACC: boolType(),
          collections: boolType(),
          frequentlyBoughtTogether: boolType(),
          bundles: boolType(),
          batItems: boolType(),
        }),
        dotComColorEligible: boolType(),
        globalCustomConfigurator: shape({
          customPosition: stringType(),
        }),
      }),
      identifiers: shape({
        roomVOEnabled: boolType(),
        productType: stringType(),
        skuClassification: stringType(),
      }),
      availabilityType: shape({
        discontinued: boolType(),
        type: stringType(),
      }),
      fulfillment: params({ storeId: stringType() }).shape({
        backordered: boolType(),
        fulfillmentOptions: arrayOf(
          shape({
            type: stringType(),
            fulfillable: boolType(),
            services: arrayOf(
              shape({
                type: stringType(),
                locations: arrayOf(
                  shape({
                    isAnchor: boolType(),
                    inventory: shape({
                      isLimitedQuantity: boolType(),
                      isOutOfStock: boolType(),
                    }),
                  })
                ),
              })
            ),
          })
        ),
      }),
    }),
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      seoDescription: stringType(),
    }),
  },
  {
    products: params({ itemIds: arrayOf(stringType().isRequired()).isRequired() }).arrayOf({
      itemId: stringType(),
      dataSources: stringType(),
      identifiers: shape({
        itemId: stringType(),
        isSuperSku: boolType(),
        parentId: stringType(),
        canonicalUrl: stringType(),
        brandName: stringType(),
        modelNumber: stringType(),
        productLabel: stringType(),
        productType: stringType(),
      }),
      badges: shape({
        name: stringType(),
      }),
      media: shape({
        images: arrayOf(
          shape({
            sizes: stringType(),
            subType: stringType(),
            type: stringType(),
            url: stringType(),
          })
        ),
        video: arrayOf(
          shape({
            dashURL: stringType(),
            link: shape({
              text: stringType(),
              url: stringType(),
            }),
            longDescription: stringType(),
            shortDescription: stringType(),
            thumbnail: stringType(),
            title: stringType(),
            type: stringType(),
            url: stringType(),
            videoStill: stringType(),
            videoId: stringType(),
            xMpegURL: stringType(),
          })
        ),
      }),
      details: shape({
        description: stringType(),
      }),
      info: shape({
        classNumber: stringType(),
        subClassNumber: stringType(),
        productDepartment: stringType(),
        quantityLimit: stringType(),
        hasServiceAddOns: stringType(),
      }),
      availabilityType: shape({
        discontinued: stringType(),
        status: stringType(),
        type: stringType(),
        buyable: stringType(),
      }),
      reviews: shape({
        ratingsReviews: shape({
          averageRating: stringType(),
          totalReviews: stringType(),
        }),
      }),
      pricing: params({ storeId: stringType() }).shape({
        promotion: shape({
          dates: shape({
            end: stringType(),
            start: stringType(),
          }),
          type: stringType(),
          description: shape({
            shortDesc: stringType(),
            longDesc: stringType(),
          }),
          dollarOff: stringType(),
          percentageOff: stringType(),
          savingsCenter: stringType(),
          savingsCenterPromos: stringType(),
          specialBuySavings: stringType(),
          specialBuyDollarOff: stringType(),
          specialBuyPercentageOff: stringType(),
          experienceTag: stringType(),
          subExperienceTag: stringType(),
          anchorItemList: stringType(),
          itemList: stringType(),
        }),
        value: stringType(),
        alternatePriceDisplay: stringType(),
        alternate: shape({
          bulk: shape({
            pricePerUnit: stringType(),
            thresholdQuantity: stringType(),
            value: stringType(),
            __typename: stringType(),
          }),
          unit: shape({
            caseUnitOfMeasure: stringType(),
            unitsOriginalPrice: stringType(),
            unitsPerCase: stringType(),
            value: stringType(),
            __typename: stringType(),
          }),
          __typename: stringType(),
        }),
        original: stringType(),
        mapAboveOriginalPrice: stringType(),
        message: stringType(),
        preferredPriceFlag: stringType(),
        specialBuy: stringType(),
        unitOfMeasure: stringType(),
        __typename: stringType(),
      }),
      fulfillment: params({ storeId: stringType(), zipCode: stringType() }).shape({
        fulfillmentOptions: shape({
          type: stringType(),
          services: shape({
            type: stringType(),
            locations: shape({
              isAnchor: stringType(),
              inventory: shape({
                isLimitedQuantity: stringType(),
                isOutOfStock: stringType(),
                isInStock: stringType(),
                quantity: stringType(),
                isUnavailable: stringType(),
                maxAllowedBopisQty: stringType(),
                minAllowedBopisQty: stringType(),
                __typename: stringType(),
              }),
              type: stringType(),
              storeName: stringType(),
              locationId: stringType(),
              curbsidePickupFlag: stringType(),
              isBuyInStoreCheckNearBy: stringType(),
              distance: stringType(),
              state: stringType(),
              storePhone: stringType(),
              __typename: stringType(),
            }),
            deliveryTimeline: stringType(),
            deliveryDates: shape({
              startDate: stringType(),
              endDate: stringType(),
              __typename: stringType(),
            }),
            deliveryCharge: stringType(),
            dynamicEta: shape({
              hours: stringType(),
              minutes: stringType(),
              __typename: stringType(),
            }),
            hasFreeShipping: stringType(),
            freeDeliveryThreshold: stringType(),
            totalCharge: stringType(),
            __typename: stringType(),
          }),
          fulfillable: stringType(),
          __typename: stringType(),
        }),
        anchorStoreStatus: stringType(),
        anchorStoreStatusType: stringType(),
        backorderedShipDate: stringType(),
        backordered: stringType(),
        bossExcludedShipStates: stringType(),
        sthExcludedShipState: stringType(),
        bossExcludedShipState: stringType(),
        excludedShipStates: stringType(),
        seasonStatusEligible: stringType(),
        onlineStoreStatus: stringType(),
        onlineStoreStatusType: stringType(),
        inStoreAssemblyEligible: stringType(),
        bodfsAssemblyEligible: stringType(),
      }),
    }),
  }
);
