/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// import { useDataModel } from '@thd-nucleus/data-sources';
import { useEffect, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { shouldUseMcc, useMccGetCart } from './useMccCart';
import { patchResult } from '../transformers/patchDataModel';
import { shouldUseAutomation, useAutomationGetCart } from './useAutomationModel';
import { getCartQueryAndResponse } from './cartResponseDataModel';
import { useHandleFailures } from './useHandleFailures';
import { useCaptureRequestEvent } from '../automation-lib/AutomationUtil';
import { optimalOverride } from '../util/utils';

if (typeof window !== 'undefined') {
  window.injectMockData = (mockData) => {
    window.__cart_item_mockData = mockData;
  };
}

let RAW_CART_RESP = null;
let CURRENT_CART = null;
let CURRENT_ERRORS = null;
let PATCHED = false;

// eslint-disable-next-line import/no-mutable-exports
const useCartInfoGetCart = (props = {}) => {
  let response = useDataModel('getCart', {
    ssr: false,
    dataModel: getCartQueryAndResponse,
    ...props,
    variables: { ...optimalOverride('getCart') }
  });

  // response = useHandleFailures(response);

  let { data, loading, error } = response;

  error = error || CURRENT_ERRORS;

  data = patchRetrievedData(data, error);
  return { ...response, data, loading, error };
};

const patchRetrievedData = (data, error) => {
  try {
    RAW_CART_RESP = data ? JSON.parse(JSON.stringify(data)) : data;
    if (data) {
      CURRENT_ERRORS = error;
      data = patchResult(data, error);
      if (!CURRENT_CART) {
        setCartDataAndErrorsForAutomatedTests();
      }
    }
    CURRENT_CART = data;
  } catch (err) {
    console.error(err);
  }
  return data;
};

export const getCart = () => (CURRENT_CART);
export const getErrors = () => (CURRENT_ERRORS);
export const getRawCartResp = () => (RAW_CART_RESP);
export const updateErrors = (errors) => {
  CURRENT_ERRORS = errors;
  PATCHED = false;
  setCartDataAndErrorsForAutomatedTests();
};
export const resetPatched = () => {
  PATCHED = false;
};

export const getItemById = (id) => (CURRENT_CART.items.find((item) => (item.id === id)));

const getItem = (data, id) => {
  if (!data) {
    return data;
  }
  return { item: data.cartInfo.items.find((item) => (item.id === id)) };
};

export const useItemDataModel = (id) => {
  const { data, loading, error } = useCartDataModel();
  return {
    data: getItem(data, id),
    loading,
    error
  };
};

export const useCartProductDataModel = (id) => {
  const { data, loading, error } = useItemDataModel(id);
  return {
    data: ((data && data.item) ? { product: data.item.product } : data),
    loading,
    error
  };
};

const useCartDataModel = (props) => {
  let useGetCart = useCartInfoGetCart;
  if (shouldUseMcc()) {
    useGetCart = (_props) => {
      const { data, loading, error } = useMccGetCart(_props);
      return { data: patchRetrievedData(data, error), loading, error };
    };
  }
  if (shouldUseAutomation()) {
    useGetCart = (_props) => {
      const { data, loading, error } = useAutomationGetCart(_props);
      return { data: patchRetrievedData(data, error), loading, error };
    };
  }
  return useGetCart(props);
};

const setCartDataAndErrorsForAutomatedTests = () => {
  try {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.cartResponse = {
          data: (CURRENT_CART ? JSON.parse(JSON.stringify(CURRENT_CART)) : null),
          errors: (CURRENT_ERRORS ? JSON.parse(JSON.stringify(CURRENT_ERRORS)) : null),
        };
      }, 10);
    }
  } catch (err) {
    console.error(err);
  }
};
export { useCartDataModel };