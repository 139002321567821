/* eslint-disable max-len */
import { MetaTag } from '@thd-nucleus/thd-helmet';
import { IMAGE_SIZE_REGEX, IMAGE_SIZE_URL_PARAM_REGEX } from '../constants';

export const formatDescription = (description) => {
  if (!description) {
    return '';
  }
  const MAX_LEN = 199;
  if (description.length < MAX_LEN || description[MAX_LEN] === ' ') {
    return description;
  }
  let nextSpace = description.indexOf(' ', MAX_LEN);
  return description.substring(0, nextSpace);
};

export const formatProductLabel = ({ identifiers }) => {
  return `${identifiers?.brandName || ''} ${identifiers?.productLabel || ''} ${identifiers?.modelNumber || ''}`.trim();
};

export const getPrimaryImage = ({ media = {} }) => {
  const { image } = media;
  const primaryImage = image?.url;
  return primaryImage;
};

export const getHoistedImgPath = ({ primaryImage }) => {
  const hoistedImgPath = primaryImage?.replace(IMAGE_SIZE_REGEX, '_600.');
  return hoistedImgPath;
};

export const getSecondaryImgPath = ({ media }) => {
  const images = media?.images || [];
  const secondaryImage = images.length > 1 ? images[1].url : '';
  const secondaryImgPath = secondaryImage?.replace(IMAGE_SIZE_URL_PARAM_REGEX, '_600.');
  return secondaryImgPath;
};

export const getDynamicOGTags = ({
  props, brandName, identifiers, title, description
}) => {
  if (props?.ppq) {
    let ogTitle = `Check The Home Depot's Q&A Before Buying: ${identifiers?.productLabel || ''}`;
    let ogDescription = `Ask a question or read what others have asked for ${identifiers?.productLabel || ''}.`;
    if (brandName) {
      ogTitle = `Check The Home Depot's Q&A Before Buying: ${brandName} ${identifiers?.productLabel || ''}`;
      ogDescription = `Ask a question or read what others have asked for ${brandName} ${identifiers?.productLabel || ''}.`;
    }
    return [
      new MetaTag({
        property: 'og:title',
        content: ogTitle?.trim(),
        id: 'ogTitle'
      }),
      new MetaTag({
        property: 'og:description',
        content: ogDescription?.trim(),
        id: 'ogDescription'
      }),
      new MetaTag({ property: 'og:type', content: 'productQuestions', id: 'ogType' }),
    ];
  }

  return [
    new MetaTag({ property: 'og:title', content: title, id: 'ogTitle' }),
    new MetaTag({ property: 'og:description', content: description?.trim(), id: 'ogDescription' }),
    new MetaTag({ property: 'og:type', content: 'product', id: 'ogType' }),
  ];
};