/* eslint-disable no-tabs */
export const isLLc = () => {
  if (typeof window !== 'undefined') {
    const isLlcEnvironment = window?.location?.host?.toLowerCase().includes('hd-qa71.homedepotdev')
			|| window?.location?.host?.toLowerCase().includes('hd-qa74.homedepotdev');
    return isLlcEnvironment;
  }
  return true;
};

export const getUrlParam = (param) => {
  const searchParams = typeof window !== 'undefined' ? window?.top.location?.search : '';
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get(param);
};

export const getTmxId = () => {
  let nativeTMXID;
  if (typeof window !== 'undefined' && window.__EXPERIENCE_CONTEXT__?.isConsumerApp) {
    nativeTMXID = getUrlParam('guid');
  }
  return nativeTMXID || ((typeof window !== 'undefined') && window.top.THD_ACCOUNT_LIB?.TMX?.getTMXsessionId());
};