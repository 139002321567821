/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { string } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { useConfigService } from '@thd-nucleus/experience-context';
import { dynamicRecsDataModel } from '../dataModel';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import DynamicRecs from './DynamicRecs';
import { TntWrapper } from '../tnt-recs/TntWrapper';

const DynamicRecsWrapperComponent = (props) => {
  const { anchor, anchorId, schemaName } = props;
  const [mounted, setMounted] = useState(false);
  const [tntData, setTntData] = useState(null);
  const abcRecsTestEnable = useConfigService('fs:isABCRecsTestEnable');

  useEffect(() => {
    setMounted(true);
    if (abcRecsTestEnable) {
      if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
        LIFE_CYCLE_EVENT_BUS.lifeCycle.on('recs.tnt', (data) => {
          let tntObj = {};
          const output = data?.output;
          if (output?.currentAPI === schemaName) {
            if (output?.isDts) {
              tntObj.isDts = output?.isDts;
              tntObj.isTNT = data?.eventName === 'tnt';
              tntObj.schemaName = output?.dtsModel?.model;
              tntObj.dtsModelVariables = output?.dtsModel;
              tntObj.tntTitle = output?.dtsModel?.tntTitle;
              setTntData(tntObj);
            } else {
              tntObj.isDts = output?.isDts;
              tntObj.isTNT = data?.eventName === 'tnt';
              tntObj.schemaName = output?.recsModel?.testAPI;
              tntObj.recsModelVariables = output?.recsModel;
              tntObj.tntTitle = output?.recsModel?.tntTitle;
              setTntData(tntObj);
            }
          }
        });
      }
    }
  }, []);

  if (mounted) {
    if (abcRecsTestEnable && tntData?.isTNT) {
      return (
        <TntWrapper recsprops={props} tntData={tntData} />
      );
    }
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <DynamicRecs {...props} anchor={anchor || anchorId} />
    );
  }

  return null;
};

DynamicRecsWrapperComponent.displayName = 'DynamicRecsWrapper';

const propTypes = {
  anchorId: string.isRequired,
  schemaName: string.isRequired,
  anchor: string
};
const defaultProps = {
  anchor: null
};
const dataModel = dynamicRecsDataModel;

DynamicRecsWrapperComponent.propTypes = propTypes;
DynamicRecsWrapperComponent.defaultProps = defaultProps;
DynamicRecsWrapperComponent.dataModel = dataModel;

const HydratedDynamicRecs = withHydrator({
  delay: 1500,
  placeholder: (<LoadingPlaceholder />),
  preserveCtxVal: 'clientStore'
}, DynamicRecsWrapperComponent);

export const DynamicRecsWrapper = withErrorBoundary(HydratedDynamicRecs);

DynamicRecsWrapper.propTypes = propTypes;
DynamicRecsWrapper.defaultProps = defaultProps;
DynamicRecsWrapper.dataModel = dataModel;
DynamicRecsWrapper.displayName = DynamicRecsWrapperComponent.displayName;
