import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ProductMobile } from './ProductMobile';

export const ProductDataModel = {
  prefetchPip: ProductMobile.dataModel.product,
  prefetchPipClient: ProductMobile.dataModel.clientOnlyProduct
};

export const ProductMobileContainer = ({ pipTimeout, ...props }) => {
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const store = useStore();
  const { storeId, storeZip, isLocalized } = store;

  const pipQueryOptions = ({ options, queryName }) => {
    if (queryName === 'product') {
      let to = 3000;
      if (pipTimeout) {
        if (typeof pipTimeout === 'string') {
          to = parseInt(pipTimeout, 10);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(to)) {
            to = 3000;
          }
        } else if (typeof pipTimeout === 'number') {
          to = pipTimeout;
        }
      }
      return {
        ...options,
        context: {
          ...(options.context || {}),
          timeout: to
        }
      };
    }
    if (queryName === 'productClientOnlyProduct') {
      return {
        ...options,
        context: {
          ...(options.context || {}),
          withAuth: true
        }
      };
    }
    return options;
  };

  const pipDefaultVariables = {
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null
    },
    searchTerm: null,
    sortBy: 'photoreview',
    startIndex: 1,
    maxItems: 6,
    storeId,
    zipCode: storeZip,
    isBrandPricingPolicyCompliant: isLocalized ? isCustomerIdentified : false
  };

  const merge = {
    productClientOnlyProduct: {
      queries: ['product', 'clientOnlyProduct'],
      fn: ({ query, mounted }) => {

        if (!mounted) return query;

        if (query === 'product' || query === 'clientOnlyProduct') {
          return 'productClientOnlyProduct';
        }
        return query;
      }
    }
  };

  return (
    <QueryProvider
      defaultVariables={pipDefaultVariables}
      queryOptions={pipQueryOptions}
      merge={merge}
      cacheKey="product-mobile-page"
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ProductMobile {...props} />
    </QueryProvider>
  );
};

ProductMobileContainer.propTypes = {
  pipTimeout: oneOfType([string, number])
};

ProductMobileContainer.defaultProps = {
  pipTimeout: '3000'
};
