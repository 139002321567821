import {
  bool, client, number, params, string, shape, arrayOf
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      value: number({ float: true }),
    }),
    info: shape({
      productSubType: shape({
        name: string()
      })
    }),
    fulfillment: client(params({ storeId: string() }).shape({
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        services: arrayOf(shape({
          type: string()
        }))
      }))
    })),
    subscription: shape({
      defaultfrequency: string(),
      discountPercentage: string(),
      subscriptionEnabled: bool()
    }).skip('skipSubscribeAndSave', false)
  })
};