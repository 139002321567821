/* eslint-disable max-len */
import React, { useContext } from 'react';
import {
  bool as boolType,
  number as numberType,
  any,
  string as stringType,
  array,
  object,
  oneOfType
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  QueryProvider, QueryContext
} from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import { SelectionControlLabel, Checkbox } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { Add } from '@one-thd/sui-icons';
import { FBTCustomProductPod } from './FBTCustomProductPod';
import { FBTContext } from '../contexts/FBTContext';
import { FBTPlaceholder } from '../FBTPlaceholder.component';
import {
  getTotalPrice,
  getCart,
  getAddToCartMessage
} from '../helpers/helpers';
import PriceMessage from '../Partials/PriceMessage.component';
import * as analytics from '../analytics/frequentlyboughttogether-analytics';
import styles from '../thd-recs-fbt.module.scss';

// components and runs into issues when they are in multiple places
// eslint-disable-next-line react/prop-types
const cx = classNames.bind(styles);
const Wrapper = ({ children }) => children;

const RecsFBTProductPodCarousel = (props) => {
  const { channel } = useContext(ExperienceContext);
  const {
    checkedItems,
    setCheckedItems,
    apiName
  } = useContext(FBTContext);

  const { defaultVariables } = useContext(QueryContext);

  const isMobile = channel === 'mobile';

  const {
    data,
    storeId,
    itemId: anchorItemId,
    zipCode,
    hideTitle,
    slidesPer,
    membershipInformation
  } = props;

  const cart = getCart(data, channel);
  const message = getAddToCartMessage(checkedItems?.length);
  let totalPrice = {};
  if (checkedItems?.length && data) {
    totalPrice = getTotalPrice(data);
  }

  const onAddToCartClick = () => {
    const { itemId } = props;
    let analyticsItems = [];
    // Uncheck all items on ATC callback.
    setCheckedItems([]);
    data.forEach((product) => {
      if (product.checked) {
        if (product.itemId !== itemId) {
          analyticsItems.push(product);
        }
      }
    });
    setTimeout(() => {
      analytics.fbtAddToCartAnalytics({ analyticsItems, apiName });
    }, 500);
  };

  const onChangeCheckbox = (id) => {
    const index = checkedItems.indexOf(id);
    if (index > -1) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  let breakpoints = ({});
  if (!slidesPer) {
    breakpoints = ({
      sm: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      md: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      lg: {
        slidesPerView: 4,
        slidesPerGroup: 4
      },
      xl: {
        slidesPerView: 6,
        slidesPerGroup: 6
      }
    });
  }

  const defaultVariablesFBT = {
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    skipInstallServices: true,
    zipCode,
    storeId,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <>
      {data?.length > 1 && <FBTPlaceholder hideTitle={hideTitle} />
      && (
        <>
          <Carousel
            breakpoints={breakpoints}
            disableShadow
            disableMargin
            spaceBetween={10}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
          >
            {data?.length > 1 && data.map((dynamicProduct, idx) => {
              const { product } = dynamicProduct;
              const { itemId } = product;
              const key = `fbt-product-line-${dynamicProduct.itemId}-${idx}`;
              const dataSourceName = dynamicProduct?.product?.dataSource;
              return (
                <Wrapper key={idx}>
                  <QueryProvider
                    cacheKey={dataSourceName}
                    dataSource={dataSourceName}
                    defaultVariables={defaultVariablesFBT}
                  >
                    <div className="sui-flex">
                      <div className={cx({ 'frequently-bought-together__notCurrentItem': idx > 0 })}>
                        {idx === 0 && <span className={cx('frequently-bought-together__currentItem')}>Current Item</span>}
                        <div className={cx('frequently-bought-together__ChkBoxCont', { 'frequently-bought-together__fbtOpacitizer': !dynamicProduct.checked })}>
                          <SelectionControlLabel label={cart.options.channel !== 'mobile' && 'Select'}>
                            <Checkbox
                              key={key}
                              name={key}
                              id={dynamicProduct.itemId}
                              onChange={() => onChangeCheckbox(dynamicProduct.itemId)}
                              checked={dynamicProduct.checked}
                              value={`${dynamicProduct.itemId}`}
                            />
                          </SelectionControlLabel>
                        </div>
                        <FBTCustomProductPod
                          key={key}
                          itemId={itemId}
                          storeId={storeId}
                          itemIndex={idx}
                          data={dynamicProduct?.product}
                          channel={cart.options.channel}
                          strategy={dynamicProduct.strategy}
                          scheme={apiName}
                          anchorProduct={anchorItemId}
                        />
                      </div>
                      <span data-id={idx < (data.length - 1) ? 'plus' : 'noPlus'} className={cx('frequently-bought-together__fbtPlus')}>
                        <Add size={channel === 'mobile' ? 'small' : 'regular'} />
                      </span>
                    </div>
                  </QueryProvider>
                </Wrapper>
              );
            }
            )}
          </Carousel>
          <div>
            <PriceMessage
              cartOptions={cart.options}
              cartReqParams={cart.itemDetails}
              channel={cart.options.channel}
              itemId={anchorItemId}
              storeId={storeId}
              message={message}
              onAddToCartClick={onAddToCartClick}
              pricing={totalPrice}
              hideBadge
            />
          </div>
        </>
      )}
    </>
  );
};

RecsFBTProductPodCarousel.displayName = 'frequentlyboughttogether';

RecsFBTProductPodCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  data: any,
  storeId: stringType.isRequired,
  // eslint-disable-next-line react/require-default-props
  itemId: stringType,
  // eslint-disable-next-line react/require-default-props
  zipCode: stringType,
  // eslint-disable-next-line react/require-default-props
  slidesPer: numberType,
  // eslint-disable-next-line react/require-default-props
  hideTitle: boolType,
  membershipInformation: oneOfType([array, object])
};

RecsFBTProductPodCarousel.defaultProps = {
  data: null,
  zipCode: null,
  hideTitle: false,
  membershipInformation: null
};

export { RecsFBTProductPodCarousel };
