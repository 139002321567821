/* eslint-disable no-console */
// tell the mini-cart in the header to update its quantity
// Add a payload with source details so internal event handler
// can ignore this if necessary
export const updateMiniCartQuantity = () => {
  try {
    if (typeof document !== 'undefined' && typeof jQuery !== 'undefined') {
    /* eslint-disable no-undef */
      $(document).trigger({ type: 'cartUpdated' }, { source: 'cartMutation' });
    }

  } catch (err) {
    console.error(err);
  }
};

// optimalFulfillment supported by updateCart, deleteItem, getCart/viewCart
export const OPTIMAL_OVERRIDE = 'optimal_override';
export const OPTIMAL_FULFILLMENT = 'optimal_fulfillment';

export const optimalOverride = (mutationName) => {
  try {
    // optimal fulfillment logic override
    if (typeof window !== 'undefined' && localStorage.getItem(OPTIMAL_OVERRIDE)) {

      // only getCart is optimal override
      // eslint-disable-next-line
      if (mutationName === 'getCart') {
        return { optimalFulfillment: false };
      }
      localStorage.removeItem(OPTIMAL_OVERRIDE);
    }
    return { optimalFulfillment: true };
  } catch (err) {
    console.log(' Error on setting optimalHeaders:', err);
  }
  return { optimalFulfillment: true };
};