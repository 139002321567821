import React from 'react';
import PropTypes from 'prop-types';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Price } from '@thd-olt-component-react/price';

const PriceMessage = ({
  cartOptions,
  cartReqParams,
  channel,
  hideBadge,
  itemId,
  message,
  onAddToCartClick,
  pricing,
  storeId
}) => (
  <>
    <div className="sui-m-0 sui-w-full sui-min-w-64">
      <div>
        <div className="sui-flex sui-flex-row sui-leading-none sui-items-center sui-gap-2 sui-mb-2">
          <div className="sui-text-lg sui-font-w-bold sui-leading-7">
            {message.priceMessage}
          </div>
          <Price
            channel={channel}
            hideBadge={hideBadge}
            itemId={itemId}
            large
            product={pricing}
            storeId={storeId}
          />
        </div>
      </div>
      <div className="sui-w-64">
        <AddToCart
          disabled={message.isDisable}
          cartOptions={cartOptions}
          cartReqParams={cartReqParams}
          onClick={onAddToCartClick}
          variant="secondary"
        >{message.atcMessage}
        </AddToCart>
      </div>
    </div>
  </>
);

PriceMessage.propTypes = {
  cartOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  cartReqParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  channel: PropTypes.oneOf([
    'desktop',
    'tablet',
    'mobile'
  ]),
  itemId: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  pricing: PropTypes.shape({}).isRequired,
  hideBadge: PropTypes.bool,
  storeId: PropTypes.string
};

PriceMessage.defaultProps = {
  channel: 'desktop',
  hideBadge: false,
  storeId: null
};

export default PriceMessage;
