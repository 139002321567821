import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Heading } from '@thd-olt-component-react/core-ui';
import './Bundle.style.scss';
import BundleItem from '../BundleItem/BundleItem';
import { BundledItems } from '../BundledItems/BundledItems';
import Buybox from '../Buybox/Buybox';

const Bundle = ({
  cartOptions,
  cartReqParams,
  itemId,
  itemInCart,
  message,
  onAddToCartClick,
  openInNewTab,
  pricing,
  product,
  products,
  channel,
  storeId
}) => {

  const nonPrimary = products && products.slice(1);
  const productCount = products.length;
  const promoMessage = nonPrimary && productCount ? nonPrimary[0].idPromoDetails : '';

  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && products) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('frequently-bought-together.ready', { products });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const bundleItems = nonPrimary && (
    products.map((item, index) => {
      return (
        <BundleItem
          anchor={index === 0}
          anchorProduct={product}
          itemInCart={itemInCart}
          key={`bundle-item-${item.itemId}-${index}`}
          openInNewTab={openInNewTab}
          product={item.product}
          storeId={storeId}
        />
      );
    })
  );

  const BuyBoxComponent = () => (
    <Buybox
      cartOptions={cartOptions}
      cartReqParams={cartReqParams}
      finalPriceMessage
      itemId={itemId}
      message={message}
      onAddToCartClick={onAddToCartClick}
      pricing={pricing}
      strikethrough
      storeId={storeId}
    />
  );

  const SingleItem = () => (
    <>
      <div className="fbt-bundle__single-item-wrapper">
        {productCount <= 3
        && <div className="fbt-bundle__items-no-carousel">{bundleItems}</div>}
        <Col className="fbt-bundle__single-item-buybox-wrapper">
          {productCount === 1 && channel !== 'mobile'
          && <BuyBoxComponent />}
        </Col>
      </div>
    </>
  );

  const mobileView = (
    <>
      <div className="fbt-bundle-mobile__items-no-carousel">{bundleItems}</div>
      {promoMessage && (
        <div className="fbt-bundle-mobile__promo-message">
          <p>{promoMessage}</p>
        </div>
      )}
      <Col>
        <BuyBoxComponent />
      </Col>
    </>
  );

  const headerCount = productCount >= 3 ? `(${productCount})` : '';
  const buyboxEligible = productCount >= 2;

  return (
    <div data-component="FrequentlyBoughtTogether">
      <Col>
        <Heading title={`Buy Together & Save ${headerCount}`} underline />
      </Col>
      {channel === 'mobile'
      && (
        <Col nopadding className="fbt-bundle-mobile">
          {mobileView}
        </Col>
      )}
      {channel !== 'mobile'
      && (
        <Col className="fbt-bundle">
          <Col nopadding sm="8" className="fbt-bundle__products-container">
            <Col className="fbt-bundle__bundle-items-wrapper" xs="10" sm="8">
              {productCount === 1
                ? (
                  <SingleItem />
                )
                : (
                  <BundledItems
                    bundleItems={bundleItems}
                    productCount={productCount}
                  />
                )}
            </Col>
          </Col>
          <Col nopadding className="fbt-bundle__purchase-wrapper" xs="10" sm="4">
            {promoMessage && (
              <div className="u__bold fbt-bundle__promo-message">
                <p>{promoMessage}</p>
              </div>
            )}
            {buyboxEligible && (
              <BuyBoxComponent />
            )}
          </Col>
        </Col>
      )}
    </div>
  );
};

Bundle.propTypes = {
  cartOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cartReqParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  itemInCart: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  openInNewTab: PropTypes.bool.isRequired,
  pricing: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string
  })),
  channel: PropTypes.string
};

Bundle.defaultProps = {
  products: [],
  channel: 'desktop'
};

Bundle.displayName = 'FBTBundle';
export default Bundle;
