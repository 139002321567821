import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { params, shape, string as stringType, useDataModel } from '@thd-nucleus/data-sources';
import './hdpp-learnmore.scss';
import {
  generalMerchandiseInfo,
  majorApplianceInfo,
} from '../constants';

const HdppLearnmore = ({ majorAppliance }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('hdpp-learnmore.ready'); }, []);

  // const { data, loading, error } = useDataModel('product', {
  //   variables: { itemId }
  // });
  const {
    bullets,
    planDetailsLink,
  } = majorAppliance ? majorApplianceInfo : generalMerchandiseInfo;

  // eslint-disable-next-line max-len
  // const coverageSentence = `Covers your purchase for ${lengthString} years after the manufacturer's labor warranty ends`;

  function createListItem(key, bullet) {
    return (
      <li key={key}>
        <span className="u__bold">{bullet.boldText}</span>&nbsp;<span>{bullet.normalText}</span>
      </li>
    );
  }

  return (
    <div className="pp-learnmore" data-component="HdppLearnmore">
      <div
        className="pp-learnmore__header pp-learnmore__p__horizontal--small
        pp-learnmore__p__top--standard pp-learnmore__p__bottom--small"
      >
        <div className="pp-learnmore__hdpp-badge">
          <span>HDPP Badge</span>
        </div>
        <div className="pp-learnmore__header__text">
          <div>
            <p className="u__bold">
              The Home Depot Protection Plan by Allstate
            </p>
          </div>
        </div>
      </div>
      <div className="pp-learnmore__p__horizontal--small">
        <ul className="list pp-learnmore__p__vertical--standard pp-learnmore__bullets-list">
          {
            bullets.map((bullet, idx) => createListItem(idx, bullet))
          }
        </ul>
        {/* <p className="p__bottom--standard">
          {coverageSentence}
        </p> */}
        <p className="u__bold pp-learnmore__p__top--small pp-learnmore__p__bottom--standard">
          See Everything Your Plan Can Offer
        </p>
        <p className="pp-learnmore__p__bottom--standard">
          Your protection plan details will be emailed to you after purchase
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href={planDetailsLink}
          className="u__default-link"
        >
          <span>Plan Details</span>
        </a>
      </div>
    </div>
  );
};

HdppLearnmore.propTypes = {
  majorAppliance: bool,
};

HdppLearnmore.defaultProps = {
  majorAppliance: false,
};

HdppLearnmore.displayName = 'HdppLearnmore';

HdppLearnmore.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType()
    })
  })
};

export { HdppLearnmore };
