/* eslint-disable no-param-reassign, no-useless-escape */
export default {
  validateEmail: (text) => {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
    return regex.test(String(text).toLowerCase());
  },
  getCollectionIdFromURL: (path) => {
    return path.includes('id-')
      ? path.substring(path.lastIndexOf('-') + 1)
      : '';
  },
  getFiscalYear: (corporateInformation) => {
    return !corporateInformation.fiscalYear
      ? (new Date()).getFullYear().toString().substr(2, 4)
      : corporateInformation.fiscalYear.toString().substr(2, 4);
  }
};
