import React, {
  useRef, useEffect, useState, useContext, Suspense
} from 'react';
import { Button, Popover, Typography } from '@one-thd/sui-atomic-components';
import { Histogram } from '@thd-olt-component-react/histogram';
import { func, shape, bool, instanceOf } from 'prop-types';
import { useLazyDataModel } from '@thd-nucleus/data-sources';
import { getProductObject, getRatingDistribution, getRecommendationsPercentage } from '../../../productDetailsUtils';
import { CustomerImageCarousel } from './CustomerImageCarousel.component';
import { AttributeRatings } from './AttributeRatings.component';
import * as analytics from '../../../analytics';
import { ProductDetailsContext } from '../../ProductDetailsContext';
import { dataModel } from '../ratings-and-reviews-data-model';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/core-ui')
  .then((module) => ({ default: module.Overlay })));
// eslint-disable-next-line max-len
const CustomerImageOverlay = React.lazy(() => import(/* webpackChunkName: "customer-image-overlay" */ '@thd-olt-component-react/customer-image-overlay')
  .then((module) => ({ default: module.CustomerImageOverlay })));

export const RatingsPopover = ({
  open,
  anchorEl,
  reviewStats,
  onClick,
  onClose
}) => {
  const {
    itemId, channel
  } = useContext(ProductDetailsContext);

  const { Results: reviews } = reviewStats;
  const popoverRef = useRef(null);
  const [carouselPhotos, setCarouselPhotos] = useState([]);

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [imageId, setImageId] = useState(null);

  const totalResults = reviewStats?.TotalResults;
  const {
    FilteredReviewStatistics: filteredReviewStatistics = null
  } = getProductObject(reviewStats, totalResults) ?? {};

  const [exec, { data, loading, error }, cachedVariables] = useLazyDataModel('reviewPhotos', {
    variables: {
      itemId
    }
  });

  useEffect(() => {
    if (open && !data) {
      exec(cachedVariables);
    }
  }, [open]);

  useEffect(() => {
    const mouseListener = (event) => {
      const popoverSizeAndPosition = popoverRef.current?.parentElement?.parentElement?.getBoundingClientRect();
      if (!popoverSizeAndPosition) {
        return;
      }
      if (event.clientX < popoverSizeAndPosition.left
        || event.clientX > popoverSizeAndPosition.right
        || event.clientY < (popoverSizeAndPosition.top - 64)
        || event.clientY > popoverSizeAndPosition.bottom) {
        onClose();
      }
    };
    document.addEventListener('mousemove', mouseListener);

    return () => {
      document.removeEventListener('mousemove', mouseListener);
    };
  }, []);

  useEffect(() => {
    const { reviewPhotos } = data || {};
    const { Results = [] } = reviewPhotos || {};
    const photos = Results.reduce((images, review) => {
      return images.concat(review.Photos);
    }, []);

    setCarouselPhotos(photos);
  }, [data]);

  if (!reviews) return null;

  const secondaryRatingsAverages = filteredReviewStatistics?.SecondaryRatingsAverages || null;
  const { RatingDistribution: ratingDistribution = null } = filteredReviewStatistics ?? {};

  const onCarouselClick = (id) => {
    setImageId(id.toString());
    analytics.track('click', {
      element: 'media'
    });
    setOverlayOpen(true);
  };

  return (
    <>
      <Popover
        disablePortal
        style={{ zIndex: 50 }}
        open={open}
        anchorEl={anchorEl}
        title="Customer Reviews"
        onClose={onClose}
      >
        <div className="sui-flex sui-flex-col sui-gap-3 sui-mt-2 sui-w-72" ref={popoverRef}>
          <Typography variant="body-xs">
            <span className="sui-font-bold">{getRecommendationsPercentage(filteredReviewStatistics)}%  </span>
            of Customers Recommend
          </Typography>
          <div className="sui-flex sui-flex-col sui-gap-2">
            <Typography component="h3" variant="body-xs" weight="bold">Overall Ratings</Typography>
            <Histogram ratings={getRatingDistribution(totalResults, ratingDistribution)} />
          </div>
          <div data-testid="carousel">
            <CustomerImageCarousel
              itemId={itemId}
              onCarouselClick={onCarouselClick}
              carouselPhotos={carouselPhotos}
            />
          </div>
          {(carouselPhotos.length > 0 && secondaryRatingsAverages)
          && <AttributeRatings secondaryRatingsAverages={secondaryRatingsAverages} />}
          <Button
            fullWidth
            variant="secondary"
            onClick={onClick}
          >
            See All Reviews
          </Button>
        </div>
      </Popover>
      {overlayOpen && (
        <Suspense fallback={(<div />)}>
          <Overlay
            modalClass="customer-image-carousel__overlay"
            scrollable={false}
            medium
            open={overlayOpen}
            onClose={() => {
              setOverlayOpen(false);
            }}
          >
            {imageId ? (
              <CustomerImageOverlay
                channel={channel}
                photos={data.reviewPhotos}
                selectedPhotoID={imageId}
                onClose={() => {
                  setOverlayOpen(false);
                  setImageId(null);
                }}
                loopPhotos={false}
              />
            ) : null}
          </Overlay>
        </Suspense>
      )}
    </>
  );
};

RatingsPopover.propTypes = {
  open: bool.isRequired,
  anchorEl: shape({ current: shape({}) }).isRequired,
  onClick: func.isRequired,
  onClose: func.isRequired,
  reviewStats: shape({}).isRequired
};

RatingsPopover.dataModel = dataModel;