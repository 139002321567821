import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from '@thd-olt-component-react/core-ui';
import './quantity.style.scss';

class Quantity extends Component {

  constructor(props) {
    super(props);
    const { value, quantityLimit } = this.props;
    this.state = { quantity: value, maximumQuantity: quantityLimit > 0 ? quantityLimit : 9999 };
  }

  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ quantity: nextProps.value });
    }

    return true;
  }

  increment = () => {
    const { onChange, onBlurValue } = this.props;
    this.setState((currentState) => ({
      quantity: currentState.quantity < currentState.maximumQuantity ? currentState.quantity + 1 : currentState.quantity
    }), () => {
      const { quantity } = this.state;
      onChange({ quantity });
      onBlurValue({ quantity });
    });
  };

  decrement = () => {
    const { onChange, minimumQuantity, onBlurValue } = this.props;
    this.setState((currentState) => ({
      quantity: currentState.quantity > minimumQuantity ? currentState.quantity - 1 : currentState.quantity
    }), () => {
      const { quantity } = this.state;
      onChange({ quantity });
      onBlurValue({ quantity });
    });
  };

  checkMaximumQuantityLimit = (newValue) => {
    const { maximumQuantity } = this.state;
    return (newValue && newValue < maximumQuantity) ? parseInt(newValue, 10) : maximumQuantity;
  }

  formatOnBlurQuantityValue = ({ value }) => {
    const { minimumQuantity } = this.props;
    const newValue = value.toString().replace(/[^0-9]/g, '').substr(0, 4);
    return (newValue && newValue > minimumQuantity) ? this.checkMaximumQuantityLimit(newValue) : minimumQuantity;
  };

  formatOnChangeQuantityValue = (value) => {
    const { minimumQuantity } = this.props;
    const newValue = String(value);
    let qty;
    if (newValue === '0' && minimumQuantity > 0) {
      qty = '';
    } else if (newValue === '') {
      qty = value;
    } else if (value > 9999) {
      qty = 9999;
    } else {
      qty = parseInt(newValue.replace(/[^0-9]/g, '').substr(0, 4), 10);
      qty = Number.isNaN(qty) ? '' : qty;
    }
    return qty;
  }

  handleOnChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    const qty = this.formatOnChangeQuantityValue(value);
    this.setState(() => ({ quantity: qty }), () => {
      const { quantity } = this.state;
      onChange({ quantity });
    });
  };

  handleOnBlur = (event) => {
    const { onChange, onBlurValue } = this.props;
    const { value } = event.target;
    this.setState(() => ({
      quantity: this.formatOnBlurQuantityValue({ value })
    }), () => {
      const { quantity } = this.state;
      onChange({ quantity });
      onBlurValue({ quantity });
    });
  };

  keyPressed = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  render() {
    const {
      textOnly,
      className,
      wideView,
      minimumQuantity,
      disabled,
      incrementDisabled,
      shortHeight,
      smallText,
      noRightMargin,
      showWarningState,
      decrementDisabled,
    } = this.props;
    const { quantity, maximumQuantity } = this.state;

    const isQuantityLimitMetExceeded = quantity === maximumQuantity || quantity > maximumQuantity;
    const containerClasses = classNames({
      'quantity--no-right-margin': noRightMargin,
    });

    const inputClasses = classNames(className, {
      'quantity-input': true,
      'quantity-input__textonly': textOnly,
      'quantity-input--wide': wideView,
      'quantity-input--disabled': disabled,
      'quantity-input--small-text': smallText,
      'quantity-input--short-height': shortHeight,
      'quantity-input--warning': showWarningState
    });

    const buttonClasses = classNames({
      'quantity-btn': true,
      'quantity-btn--disabled': disabled,
      'quantity-btn--short-height': shortHeight,
    });

    const incrementClasses = classNames('increment', buttonClasses, {
      'increment--disabled': showWarningState || incrementDisabled || isQuantityLimitMetExceeded,
    });

    const decrementClasses = classNames('decrement', buttonClasses, {
      'decrement--disabled': disabled || decrementDisabled
    });

    return (
      <div className={'buybox__quantity ' + containerClasses} data-component="Quantity">
        <div className="quantity-field-wrapper">
          {!textOnly && (
            <button
              className={decrementClasses}
              onClick={this.decrement}
              type="button"
              disabled={disabled || decrementDisabled}
              data-testid="quantity-decrement-button"
            >
              {(minimumQuantity === 0 && (quantity <= 1))
                ? (
                  <Image
                    alt="trash icon"
                    width="15px"
                    height="20px"
                    src="https://assets.thdstatic.com/p/static/images/icons/trash.svg"
                  />
                )
                : '-'}
            </button>
          )}
          <input
            className={inputClasses}
            min="0"
            max={maximumQuantity}
            maxLength="4"
            inputMode="numeric"
            value={this.formatOnChangeQuantityValue(quantity)}
            pattern="[0-9]*"
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            onKeyPress={this.keyPressed}
            disabled={disabled}
          />
          {!textOnly && (
            <button
              className={incrementClasses}
              onClick={this.increment}
              type="button"
              disabled={disabled || incrementDisabled || isQuantityLimitMetExceeded}
              data-testid="quantity-increment-button"
            >
              +
            </button>
          )}
        </div>
      </div>
    );
  }
}

Quantity.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  textOnly: PropTypes.bool,
  className: PropTypes.string,
  wideView: PropTypes.bool,
  minimumQuantity: PropTypes.number,
  onBlurValue: PropTypes.func,
  disabled: PropTypes.bool,
  incrementDisabled: PropTypes.bool,
  noRightMargin: PropTypes.bool,
  shortHeight: PropTypes.bool,
  smallText: PropTypes.bool,
  showWarningState: PropTypes.bool,
  quantityLimit: PropTypes.number,
  decrementDisabled: PropTypes.bool,
};

Quantity.defaultProps = {
  onChange: () => {},
  value: 1,
  textOnly: false,
  className: null,
  wideView: false,
  minimumQuantity: 1,
  onBlurValue: () => {},
  disabled: false,
  incrementDisabled: false,
  noRightMargin: false,
  shortHeight: false,
  smallText: false,
  showWarningState: false,
  quantityLimit: 9999,
  decrementDisabled: false
};

export default Quantity;
