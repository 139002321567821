import {
  bool, func, oneOf, oneOfType, shape, string
} from 'prop-types';

export const mediaGalleryDesktopPropTypes = {
  collectionId: string,
  mainImageFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ]),
  hideProductImagesLabel: bool,
  highlight: bool,
  highRes: bool,
  hover: bool,
  itemId: string,
  configId: string,
  marqueeRef: oneOfType([
    func,
    shape({})
  ]),
  overrideData: shape({
    media: shape({}),
    galleryLabel: string,
    brandNameLabel: string
  }),
  showLoader: bool
};

export const mediaGalleryDesktopDefaultProps = {
  collectionId: null,
  mainImageFetchPriority: 'auto',
  hideProductImagesLabel: false,
  highlight: false,
  highRes: false,
  hover: false,
  itemId: null,
  configId: undefined,
  marqueeRef: null,
  overrideData: null,
  showLoader: false
};

export const mediaGalleryMobilePropTypes = {
  collectionId: string,
  highlight: bool,
  itemId: string,
  configId: string,
  onMediaClick: func,
  overrideData: shape({
    media: shape({}),
    galleryLabel: string,
    brandNameLabel: string
  }),
  showLoader: bool,
  firstItemFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ])
};

export const mediaGalleryMobileDefaultPropTypes = {
  collectionId: null,
  highlight: false,
  itemId: null,
  configId: undefined,
  onMediaClick: null,
  overrideData: null,
  showLoader: false,
  firstItemFetchPriority: 'auto'
};
