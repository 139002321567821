/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  Alert
} from '@one-thd/sui-atomic-components';

const SignInDrawer = ({ onClose }) => {
  return (
    <>
      <DrawerBody>
        <Alert status="info">
          Please Sign In to Add to Quote
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            href={`/auth/view/signin?redirect=/&ref=${window?.location?.href}`}
          >
            Sign In
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

SignInDrawer.propTypes = {};

SignInDrawer.displayName = 'SignInDrawer';

export { SignInDrawer };