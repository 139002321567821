import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useConfigService, useStore } from '@thd-nucleus/experience-context';
import {
  bool,
  params,
  shape,
  string,
  number,
  useDataModel
} from '@thd-nucleus/data-sources';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { Clock } from '@one-thd/sui-icons';

import {
  getPromoToDisplay,
  getPercentageOff,
  isDateLessThan24Hours,
  getExperienceName,
  daysLeftLessThanMessagingLimit,
  getEndDateWithHourString,
} from './util';

import './promotion.style.scss';

const PromotionUrgencyMessaging = ({ itemId }) => {
  const { storeId } = useStore();
  const [mounted, setMounted] = useState(false);

  /**
   * determines whether or not to show
   * messaging for urgency A/B test
   */
  const urgencyMessagingConfigValue = useConfigService('fs-prop:isUrgencyMessageEnabled');

  let isB2BExperience;
  if (typeof window !== 'undefined') {
    const expName = getExperienceName();
    isB2BExperience = expName === 'b2b';
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  const { data } = useDataModel('product', { variables: { itemId, storeId } });

  const hiddenPromos = {};
  const hideConditionalPromoDescription = false;

  const promotion = getPromoToDisplay({ data,
    hiddenPromos,
    hideConditionalPromoDescription,
    urgencyMessagingConfigValue });

  const percentageOff = getPercentageOff(promotion);

  const end = getEndDateWithHourString(promotion);

  const daysLeftEqualThreeOrLess = daysLeftLessThanMessagingLimit(end);

  const millisecondsPerDay = (1000 * 60 * 60 * 24);

  const isLessThan24Hours = isDateLessThan24Hours(end);

  const showUrgencyMessaging = urgencyMessagingConfigValue && percentageOff > 0 && daysLeftEqualThreeOrLess;

  const isB2BAndUrgencyMessaging = urgencyMessagingConfigValue && isB2BExperience;

  let styles;
  if (urgencyMessagingConfigValue) {
    styles = 'sui-h-7 sui-mb-2';
  }
  if (isB2BAndUrgencyMessaging) {
    styles = 'sui-h-7 sui-mb-1 sui-pl-[5px]';
  }

  const EndsTodayMessageJSX = () => {
    return (
      <div className="sui-flex sui-font-semibold">
        <div className="sui-mr-2">
          <Clock size="regular" color="primary" />
        </div>
        <p className="sui-text-base">Ends Today</p>
      </div>

    );
  };
  const UrgencyMessagingJSX = () => {
    return isLessThan24Hours ? <EndsTodayMessageJSX /> : (
      <CountdownTimer
        shouldUseMessagingString
        customMessagingString="Ends In"
        shouldIncludeSvg
        shouldUseDateDisplay
        shouldUseDaysLeftDisplay
        daysLeftThreshold={millisecondsPerDay * 8}
        endDate={end}
      />
    );
  };

  if (!promotion || !showUrgencyMessaging) return null;

  return (
    <div data-testid="promotion" data-component="PromotionUrgencyMessaging" className={styles}>
      {showUrgencyMessaging && (
        <div className="promotion__urgency-messaging-timer sui-flex">
          {mounted && <UrgencyMessagingJSX />}
        </div>
      )}

    </div>

  );
};

PromotionUrgencyMessaging.displayName = 'PromotionUrgencyMessaging';

PromotionUrgencyMessaging.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      preferredPriceFlag: bool(),
      promotion: shape({
        dates: shape({
          start: string(),
          end: string()
        }),
        percentageOff: number(),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        experienceTag: string(),
        subExperienceTag: string(),
      }),
      // this block must remain commented until searchModel supports conditionalPromotions
      // we use conditionalPromotions on PIP where it is included in the dataModel by the
      // promotion-products component. When search model supports the block, then we will have
      // conditionalPromotions in PLPs too
      // conditionalPromotions: arrayOf(shape({
      //   dates: shape({
      //     start: string(),
      //     end: string()
      //   }),
      //   description: shape({
      //     shortDesc: string(),
      //     longDesc: string()
      //   }),
      // }))
    })
  })
};

PromotionUrgencyMessaging.propTypes = {
  /** The item */
  itemId: PropTypes.string.isRequired,

};

export default PromotionUrgencyMessaging;
