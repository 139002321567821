import React from 'react';
import PropTypes from 'prop-types';
import { Pill } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { track } from './related-search-analytics';

const RelatedSearchPill = ({ relatedSearchResult, index, copyText }) => {
  const url = relatedSearchResult.url || (`/s/${encodeURIComponent(relatedSearchResult.keyword)}`);

  const getCategory = (text) => {
    const words = text.split(' ');
    const wordsUpperCase = words.map(
      (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    );
    return wordsUpperCase.join('');
  };

  const { ref, clickID } = useImpression({
    data: {
      id: '00DEFVALRS00', // generic ID for impressions when componentID is not available
      name: 'RelatedSearch',
      component: 'RelatedSearch',
      position: index + 1,
      type: 'content',
      category: getCategory(copyText)
    }
  });

  const handleRelatedSearchClick = () => {
    if (window?.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('related-search.UPDATE', { updatedSearchTerm: copyText });
    }
    track(copyText);
  };

  return (
    <div
      className="related-search__elements sui-bg-primary sui-rounded-full"
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
    >
      <Pill
        label={copyText}
        component="a"
        href={url}
        onClick={() => handleRelatedSearchClick()}
        clickable
      />
    </div>
  );
};

export { RelatedSearchPill };

RelatedSearchPill.propTypes = {
  relatedSearchResult: PropTypes.shape({
    anchor: PropTypes.string,
    url: PropTypes.string,
    keyword: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  copyText: PropTypes.string
};

RelatedSearchPill.defaultProps = {
  copyText: ''
};
