const getCustomBlindsUrl = ({
  canonicalUrl,
  isBuildAndBuyProduct,
  blindsHost
}) => {
  if (!isBuildAndBuyProduct) {
    return canonicalUrl;
  }
  const regex = /\/p\//gi;
  const customBlindsPath = '/p/custom-blinds/';
  const customProductUrl = canonicalUrl && canonicalUrl.replace(regex, customBlindsPath);
  return `${blindsHost}${customProductUrl}`;
};

export const getCustomUrlWithAnalytics = ({
  canonicalUrl,
  isBuildAndBuyProduct,
  blindsHost,
}) => {
  let productURL = getCustomBlindsUrl({ canonicalUrl,
    isBuildAndBuyProduct,
    blindsHost });
  return productURL;
};
