import React, { useState } from 'react';
import { oneOf, func, string, bool } from 'prop-types';
import { PRODUCT_ADD_ON_IDENTIFIER, DESKTOP, MOBILE } from '@thd-olt-functional/utils';
import { ServicePro, Paint } from '@one-thd/sui-icons';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod';
import { ProReferralTooltip } from './ProReferral-tooltip';

const {
  PRO_ASSEMBLY,
  PRO_REFERRAL,
} = PRODUCT_ADD_ON_IDENTIFIER;

const ProReferral = ({
  channel,
  onChange,
  category,
  isAssembly
}) => {
  const [checked, setChecked] = useState(false);
  const heading = category === 'Painter' ? 'Request A Painter'
    : (
      <div>
        Need a Pro? We Can Help!
      </div>
    );
  const userOptions = [{
    /** Props for checkbox component */
    value: isAssembly ? PRO_ASSEMBLY : PRO_REFERRAL,
    checked,
    label: 'Add Pro Referral'
  }];

  const handleChange = (evt) => {
    if (!isAssembly) { /* pro referral and pro painter */
      LIFE_CYCLE_EVENT_BUS.trigger('pro-referral.change', { isOptedIn: !checked, category });
      onChange({
        checked: evt.target.checked,
        category,
        addOnType: evt.target.value
      });

    } else {
      LIFE_CYCLE_EVENT_BUS.trigger('assembly.change', { isOptedIn: !checked });
      onChange(evt, { data: { } });
    }
    setChecked(!checked);
  };

  return (
    <ProductAddOnsPodStencil
      channel={channel}
      heading={heading}
      subHeading={category !== 'Painter' ? 'Get Referred to a Local Pro' : ''}
      icon={category === 'Painter' ? <Paint size="large" /> : <ServicePro size="large" />}
      onChange={handleChange}
      tooltip={<ProReferralTooltip channel={channel} checked={checked} />}
      userOptions={userOptions}
    />
  );
};

ProReferral.displayName = 'ProductAddOns-ProReferral';

ProReferral.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf([DESKTOP, MOBILE]),
  /** Callback exec when user selects pro-referral option  */
  onChange: func.isRequired,
  /** Category .ie. PRInstall, Painter for paint   */
  category: string,
  /** proAssembly */
  isAssembly: bool,
};

ProReferral.defaultProps = {
  channel: DESKTOP,
  category: '',
  isAssembly: false
};

export { ProReferral };