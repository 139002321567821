import { parse } from 'query-string';
import { ERROR_HANDLING_EXPERIENCE, HTTP_CODE } from '../../utils/redirector-utils';

export function isNotFound({ data, itemId }) {
  if (data && !data.product) {
    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: `Item ${itemId} not found`,
      errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.PRODUCT
    };
  }

  return null;
}

export function isInvalidItemId({ itemId }) {
  if (!/^\d{8,10}$/.test(itemId)) {
    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: `Invalid item id ${itemId}`,
      errorHandlingExperience: ERROR_HANDLING_EXPERIENCE.PRODUCT
    };
  }

  return null;
}

export function isInvalidCanonical({ data, disableCanonicalRedirect, path }) {

  let normalizedPath = path;
  let isReviewsPage;
  if ((path || '').startsWith('/p/reviews/')) {
    isReviewsPage = true;
    normalizedPath = path.replace('/reviews', '');
  }
  const { canonicalUrl } = data?.product?.identifiers || {};

  if (!canonicalUrl) {
    return null;
  }

  if (!disableCanonicalRedirect && normalizedPath !== canonicalUrl) {

    return {
      shouldRedirect: true,
      httpCode: HTTP_CODE.PERM_REDIRECT,
      redirectPath: isReviewsPage ? canonicalUrl.replace('/p/', '/p/reviews/') : canonicalUrl,
      description: 'redirected for canonical'
    };
  }

  return null;
}

export function isPEPToPIPRedirect({ data, path, enablePepToPipRedirectFS }) {
  if (!data) {
    return null;
  }
  const { canonicalUrl } = data.product?.identifiers || {};
  if (/^\/pep\//.test(path) && enablePepToPipRedirectFS) {
    return {
      shouldRedirect: true,
      httpCode: HTTP_CODE.TEMP_REDIRECT,
      redirectPath: canonicalUrl,
      description: 'Redirected pep to pip when FS is true'
    };
  }
  return null;
}

export function isPEPRedirect({ data, path, queryParams, disablePEPRedirect, disableCMMMCRedirect }) {
  const params = parse(queryParams);
  if (!data) {
    return null;
  }
  const { canonicalUrl } = data.product?.identifiers || {};
  if (!disablePEPRedirect && params.cm_mmc && /^\/p\//.test(path)) {
    let cmmmc = '';
    if (typeof params.cm_mmc === 'string') {
      cmmmc = params.cm_mmc;
    } else if (Array.isArray(params.cm_mmc) && params.cm_mmc.length) {
      cmmmc = params.cm_mmc[0];
    }
    if (!disableCMMMCRedirect && /^Shopping/i.test(cmmmc) && canonicalUrl) {
      return {
        shouldRedirect: true,
        httpCode: HTTP_CODE.TEMP_REDIRECT,
        redirectPath: canonicalUrl.replace('/p/', '/pep/'),
        description: 'Redirected for pep rules with Shopping param'
      };
    }
  }

  return null;
}
