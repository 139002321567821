import React from 'react';
import { Button, Typography, Link } from '@one-thd/sui-atomic-components';
import { InstantCheckout } from '@one-thd/sui-icons';
import { useConfigService } from '@thd-nucleus/experience-context';
import { bool, number, shape, string } from 'prop-types';
import { getInstantCheckoutPath } from '../../utils';
import { ENABLE_NEW_INSTANT_CHECKOUT } from '../../constants/features';

const FULFILLMENT_METHOD_STH = 'ShipToHome';

const InstantCheckoutEnabled = ({
  atcRequest,
  fulfillmentMethod,
  instantCheckoutModel,
  channel,
  disabled,
}) => {
  const enableNewInstantCheckoutDrawer = useConfigService('enableNewInstantCheckoutDrawer');
  const enableNewInstantCheckoutUrl = useConfigService(ENABLE_NEW_INSTANT_CHECKOUT);
  const instantCheckoutUrl = getInstantCheckoutPath(enableNewInstantCheckoutUrl);

  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }

  const handleInstantCheckout = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('cart.instant-checkout', {
      cartReqParams: atcRequest,
      cartOptions: {
        host,
        channel,
        enableNewInstantCheckoutDrawer,
      },
    });
  };

  return (
    <div className="sui-grid sui-gap-4 sui-py-4" data-component="InstantCheckoutEnabled">
      <Button
        variant="secondary"
        startIcon={InstantCheckout}
        fullWidth
        onClick={handleInstantCheckout}
        disabled={disabled}
        data-testid="instantCheckoutButton"
      >
        Instant Checkout
      </Button>
      {fulfillmentMethod === FULFILLMENT_METHOD_STH && (
        <div className="sui-flex sui-flex-wrap">
          <Typography variant="body-sm">
            Deliver to&nbsp;
            <Link href={instantCheckoutUrl} underline="always" variant="body-sm">
              {instantCheckoutModel?.addressZipCode}
            </Link>
          </Typography>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <Link href={instantCheckoutUrl} underline="always" variant="body-sm">
            {instantCheckoutModel?.displayCardBrand} ending in&nbsp;
            {instantCheckoutModel?.xref}
          </Link>
        </div>
      )}
    </div>
  );
};

InstantCheckoutEnabled.propTypes = {
  channel: string,
  fulfillmentMethod: string,
  atcRequest: shape({
    itemId: string,
    quantity: number,
    fulfillmentMethod: string,
    fulfillmentLocation: string,
  }),
  instantCheckoutModel: shape({
    addressZipCode: string,
    displayCardBrand: string,
    xref: string,
  }),
  disabled: bool,
};

InstantCheckoutEnabled.defaultProps = {
  channel: 'desktop',
  fulfillmentMethod: null,
  instantCheckoutModel: null,
  atcRequest: null,
  disabled: false,
};
export { InstantCheckoutEnabled };
