import React, { useState } from 'react';
import { bool } from 'prop-types';
import { TextAnExpertCard } from '../markup/TextAnExpertCard';

export const DynamicSMS = ({ isiPhone, proUX }) => {
  const [dynamicSmsCode, setDynamicSmsCode] = useState();

  if (window?.THDCustomer?.default?.loadCustomerChatConfiguration) {
    window.THDCustomer.default.loadCustomerChatConfiguration()
      .then((res) => {
        if (res.customerConfiguration.eliteCallCenterSMSCode !== dynamicSmsCode) {
          setDynamicSmsCode(res.customerConfiguration.eliteCallCenterSMSCode);
        }
      });
  }

  return (
    <TextAnExpertCard sms={dynamicSmsCode} isiPhone={isiPhone} proUX={proUX} />
  );
};

const propTypes = {
  isiPhone: bool,
  proUX: bool
};
DynamicSMS.propTypes = propTypes;

const defaultProps = {
  isiPhone: false,
  proUX: false
};
DynamicSMS.defaultProps = defaultProps;
