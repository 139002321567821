import React from 'react';
import { bool, string } from 'prop-types';
import { getDualPathCategoryModel, getDualPathCheckbox } from './dualPathCategories';
import { ProductAddonsTooltip } from '../../common/ProductAddOnsTooltip';

const DualPathTooltip = ({ category, checked }) => {
  const tooltipContent = (
    <div className="sui-p-2 sui-w-72" data-component="DualPathTooltip">
      <ul className="sui-list-disc sui-ml-4">
        {getDualPathCategoryModel(category).map((list, index) => <li key={index}> {list} </li>)}
      </ul>

      <div className="sui-text-xs sui-mt-2">
        <p>*Some brands and services may vary by market.</p>
        <p className="sui-mt-2">
          {`Please note by selecting the “${getDualPathCheckbox(category)}” box,
          you consent to being contacted by local professionals after submitting your request.`}
        </p>
      </div>
    </div>
  );

  return (
    <ProductAddonsTooltip title="How it Works" checked={checked} tooltipContent={tooltipContent} />
  );
};

DualPathTooltip.displayName = 'ProductAddOns-dualPath-tooltip';

DualPathTooltip.propTypes = {
  category: string,
  /** Whether or not the checkbox is checked  */
  checked: bool,
};

DualPathTooltip.defaultProps = {
  category: 'brio_inaisle_sfi_generalfs',
  checked: false
};

export { DualPathTooltip };