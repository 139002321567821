import React from 'react';

import { Typography } from '@one-thd/sui-atomic-components';
import { Calculator as CalculatorIcon } from '@one-thd/sui-icons';

export const CalculatorHeader = () => (
  <div className="sui-flex">
    <CalculatorIcon color="brand" />
    <span className="sui-ml-2">
      <Typography variant="body-lg" weight="bold">How much will you need?</Typography>
    </span>
  </div>
);

CalculatorHeader.displayName = 'CalculatorHeader';