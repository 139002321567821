/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';
import { string, bool, func } from 'prop-types';
import {
  ATC,
  update
} from '../order-groove/order-groove';

class Events extends Component {

  constructor(props) {
    super(props);

    this.state = {
      quantity: 1
    };

    this.subscribeAndSaveATC = this.subscribeAndSaveATC.bind(this);
    this.quantityChangeHandler = this.quantityChangeHandler.bind(this);
  }

  componentDidMount() {
    LIFE_CYCLE_EVENT_BUS.on('subscriptions.cartAdd', this.subscribeAndSaveATC);
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('quantity.change', this.quantityChangeHandler);
  }

  componentWillUnmount() {
    LIFE_CYCLE_EVENT_BUS.off('subscriptions.cartAdd', this.subscribeAndSaveATC);
    LIFE_CYCLE_EVENT_BUS.lifeCycle.off('quantity.change', this.quantityChangeHandler);
  }

  quantityChangeHandler(evt) {
    const { itemId, frequency, onQuantityChange } = this.props;
    const qty = evt.output.quantity;
    this.setState({ quantity: qty });
    update({ itemId, quantity: qty, frequency });
    onQuantityChange(qty);
  }

  subscribeAndSaveATC() {
    const { itemId, subscribed } = this.props;
    const { quantity } = this.state;
    if (subscribed) ATC({ itemId, quantity });
  }

  render() {
    return null;
  }
}

Events.displayName = 'SubscribeAndSave-Events';

Events.propTypes = {
  itemId: string.isRequired,
  subscribed: bool.isRequired,
  frequency: string.isRequired,
  onQuantityChange: func.isRequired
};

export { Events };