/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
const cartAssertTemplate = {
  cartHeader:
  {
    locator_legacy: '.cart-main-container > div:nth-of-type(1)',
    locator: '.cart-header',
    paymentEstimator:
      {
        id: 'paymentEstimator',
        locator_legacy: '[data-automation-id="payment-estimator-pod"]',
        locator: '[data-automation-id="payment-estimator-pod"]',
        valueField: 'innerText',
      },
    shareCartLink:
      {
        id: 'shareCartLink',
        locator_legacy: '[data-automation-id="shareCartModal__header-text"]',
        locator: '[data-automation-id="shareCartLink"]',
        valueField: 'innerText',
      },
    yourCartText:
      {
        id: 'yourCartText',
        locator_legacy: '.row-header__title .row-header__text',
        locator: '[data-automation-id="yourCartText"] h1',
        valueField: 'innerText',
        validate: (orig, newb) => (orig.replace('Your', '').toUpperCase() === newb)
      },
    removeAllItemsLink:
      {
        id: 'removeAllItemsLink',
        locator_legacy: '[data-automation-id="removeAllItemsLink"]',
        locator: '[data-automation-id="removeAllItemsLink"]',
        valueField: 'innerText',
      },
    headerMessages:
      {
        id: 'headerMessages',
        locator_legacy: '.message p span',
        locator: '[data-automation-id="cartAlertsAndMessages"]',
        valueField: 'innerText',
        validate: (orig, newb) => (newb.includes(orig))
      },
    headerWarning:
      {
        id: 'headerWarning',
        locator_legacy: '.alert.custom_warning',
        locator: '',
        valueField: 'innerText',
      },
    headerAlert:
      {
        id: 'headerAlert',
        locator_legacy: '.alert.cart-error-message',
        locator: '',
        valueField: 'innerText',
      },
  },
  cartItemList:
  {
    locator_legacy: '.cart-main-container > div:nth-of-type(3)',
    locator: '.cart-item-list',
    itemGroupTitle:
      {
        id: 'itemGroupTitle',
        locator_legacy: '.title-section',
        locator: '[data-automation-id="itemGroupTitle"]',
        valueField: 'innerText',
        allowMultiple: true,
      },
    // itemGroupSubtitle:
    //   {
    //     id: 'itemGroupSubtitle',
    //     locator_legacy: '.ffm-subtitle-text',
    //     valueField: 'innerText',
    //     allowMultiple: true,
    //   },
    cartItems:
      {
        locator_legacy: '.cartItemDesktop',
        locator: '[data-automation-id="cart-item"]',
        itemElements:
          [
            {
              id: 'itemInfoMessage',
              locator_legacy: '.alert-inline--info',
              locator: '[data-automation-id="itemInfoMessage"]',
              valueField: 'innerText',
              allowMultiple: true,
              optional: true,
            },
            {
              id: 'itemAlertMessage',
              locator_legacy: '[data-automation-id="inlineMessageerrorText"]',
              locator: '[data-automation-id="itemAlertMessage"]',
              valueField: 'innerText',
              allowMultiple: true,
              optional: true,
            },
            {
              id: 'itemQuantity',
              locator_legacy: '[data-automation-id="itemQuantityBoxQuantityInput"]',
              locator: '[data-automation-id="itemQuantity"] input',
              valueField: 'value',
              props:
                  [
                    'disabled',
                  ],
            },
            {
              id: 'itemImage',
              locator_legacy: 'img',
              locator: '[data-automation-id="productImage"]',
              valueField: 'src',
            },
            {
              id: 'itemBrand',
              locator_legacy: '.cartItem__brandName span.u__bold',
              locator: '[data-automation-id="productBrand"]',
              valueField: 'innerText',
            },
            {
              id: 'itemDescription',
              locator_legacy: '[data-automation-id="productDesktopItemBrandLink"]',
              locator: '[data-automation-id="productDescription"]',
              valueField: 'innerText',
            },
            {
              id: 'itemOptions',
              locator_legacy: '.super-sku-options',
              locator: '[data-automation-id="itemSpecs"]',
              valueField: 'innerText',
              optional: true,
            },
            {
              id: 'itemFulfillment',
              locator_legacy: '.fulfillment__tile-container',
              locator: '[data-automation-id="fulfillment-container"]',
              valueField: 'innerText',
              validate: (orig, newb) => {
                const normalizeValue = (value) => {
                  if (value && value.replace) {
                    value = value.replace(/Tomorrow/g, 'TODAY|TOMORROW');
                    value = value.replace(/Today/g, 'TODAY|TOMORROW');
                  }
                  return value;
                };
                orig = normalizeValue(orig);
                newb = normalizeValue(newb);
                return (orig === newb);
              }
            },
            {
              id: 'itemPartsAndServices',
              locator_legacy: '[data-automation-id="cartPartsAndServicesGrid"]',
              locator: '',
              valueField: 'innerText',
              optional: true,
            },
            {
              id: 'itemPrice',
              locator_legacy: '[data-automation-id="pricingScenariosText"]',
              locator: 'itemPrice',
              valueField: 'innerText',
              validate: (orig, newb) => {
                if (typeof orig !== 'undefined' && orig.replaceAll) {
                  orig = orig.replaceAll(',', '').replaceAll('.', '');
                }
                if (typeof newb !== 'undefined' && newb.replaceAll) {
                  newb = newb.replaceAll(',', '').replaceAll('.', '');
                }
                return orig === newb;
              }
            },
            // {
            //   id: 'itemAddOnInStoreAssemblyInput',
            //   locator_legacy: '.product-add-ons-cell .checkbox-btn input',
            //   locator: '.product-add-ons__card #assembleItemAtStore__checkbox',
            //   valueField: 'checked',
            //   optional: true,
            // },
            //
            // addon_Assembly (in-home assembly)
            //
            {
              id: 'itemAddOnInStoreAssemblyLabel',
              locator_legacy: '.product-add-ons-cell .checkbox-btn label',
              locator: '[data-automation-id="addon_protectionPlan"]',
              valueField: 'innerText',
              optional: true,
            },
            {
              id: 'itemAddOnProtectionPlanInput',
              locator_legacy: '.product-add-ons-cell .checkbox-btn input',
              // locator: '.product-add-ons__card #312375_checkbox',
              locator: '[data-automation-id="addon_protectionPlan"] input',
              valueField: 'checked',
              optional: true,
            },
            {
              id: 'itemAddOnProtectionPlanLabel',
              locator_legacy: '.product-add-ons-cell .checkbox-btn label',
              locator: '[data-automation-id="addon_protectionPlan"]',
              valueField: 'innerText',
              optional: true,
            },
            {
              id: 'itemActions',
              locator_legacy: '.product-action-links-cell',
              locator: '[data-automation-id="itemActions"]',
              valueField: 'innerText',
              // allowMultiple: true,
            },
          ],
      },
  },
  orderSummary:
  {
    locator_legacy: '.summary-totals-pod',
    locator: '[data-automation-id="orderSummary"]',
    totalsSubTotal:
      {
        id: 'totalsSubTotal',
        locator_legacy: '[data-automation-id="totalsSubTotalAmount"]',
        locator: '[data-automation-id="totalsSubTotal"]',
        valueField: 'innerText',
      },
    totalsSavingsText:
      {
        id: 'totalsSavingsText',
        locator_legacy: '[data-automation-id="totalsSavingsText"]',
        locator: '[data-automation-id="totalsSavingsText"]',
        valueField: 'innerText',
      },
    totalsSavings:
      {
        id: 'totalsSavings',
        locator_legacy: '[data-automation-id="totalsSavingsText"]',
        locator: '[data-automation-id="totalsSavings"]',
        valueField: 'innerText',
      },
    preferredPricing:
      {
        id: 'preferredPricing',
        locator_legacy: null,
        locator: '[data-automation-id="preferredPricing"]',
        valueField: 'innerText',
      },
    totalsPickup:
      {
        id: 'totalsPickup',
        locator_legacy: '[data-automation-id="totalsPickupInStoreFreeText"]',
        locator: '[data-automation-id="pickupTotal"]',
        valueField: 'innerText',
      },
    totalsDelivery:
      {
        id: 'totalsDelivery',
        locator_legacy: '.totals__list--sth',
        locator: '[data-automation-id="deliveryTotal"]',
        valueField: 'innerText',
      },
    totalsApplianceDelivery:
      {
        id: 'totalsApplianceDelivery',
        locator_legacy: '.totals__list--appl',
        locator: '[data-automation-id="applianceDeliveryTotal"]',
        valueField: 'innerText',
      },
    totalsSalesTax:
      {
        id: 'totalsSalesTax',
        locator_legacy: '.totals__list--appl',
        locator: '[data-automation-id="salesTaxTotal"]',
        valueField: 'innerText',
      },
    totalsTotalText:
      {
        id: 'totalsTotalText',
        locator_legacy: '[data-automation-id="totalsTotalText"]',
        locator: '[data-automation-id="totalsTotalText"]',
        valueField: 'innerText',
      },
    totalsTotal:
      {
        id: 'totalsTotal',
        locator_legacy: '[data-automation-id="totalsTotal"]',
        locator: '[data-automation-id="totalsTotal"]',
        valueField: 'innerText',
      },
  },
  rightRail: {
    locator_legacy: '.rightRailWrapper',
    locator: '.cart-right-rail',
    poJobCode:
    {
      id: 'poJobCode',
      locator_legacy: '.purchase-order',
      locator: '[data-automation-id="purchaseOrder"]',
      valueField: 'innerText',
    }
  },
  cartPromo:
  {
    cartPromoLink:
      {
        id: 'cartPromoLink',
        locator_legacy: '[data-automation-id="reCaptchaPromoCodeLink"]',
        locator: '',
        valueField: 'innerText',
      },
    cartPromoInput:
      {
        id: 'cartPromoInput',
        locator_legacy: '[data-automation-id="promotionPromoCodeTextBox"]',
        locator: '',
        valueField: 'value',
      },
    cartPromoApply:
      {
        id: 'cartPromoApply',
        locator_legacy: '.recaptcha--component button',
        locator: '',
        valueField: 'disabled',
      },
  },
  cartCheckoutButtons:
  {
    checkoutButton:
      {
        id: 'checkoutButton',
        locator_legacy: '[data-automation-id="checkoutNowButton"]',
        locator: '',
        valueField: 'disabled',
      },
    instantCheckoutEnrollLink:
      {
        id: 'instantCheckoutEnrollLink',
        locator_legacy: '[data-automation-id="instantCheckoutEnrollLink"]',
        locator: '',
        valueField: 'innerText',
      },
    paypalOrText:
      {
        id: 'paypalOrText',
        locator_legacy: '#paypal-or',
        locator: '',
        valueField: 'innerText',
      },
    paypalButton:
      {
        id: 'paypalButton',
        locator_legacy: '[data-automation-id="paypal-smart-button"]',
        locator: '',
        valueField: 'className',
      },
    cartPlccDoTheMath:
      {
        id: 'cartPlccDoTheMath',
        locator_legacy: '[data-automation-id="do_the_math"]',
        locator: '',
        valueField: 'innerText',
      },
  },
  cartReturnsMessage:
  {
    id: 'cartReturnsMessage',
    locator_legacy: '.returns-module',
    locator: '',
    valueField: 'innerText',
  },
  cartNeedHelp:
  {
    id: 'cartNeedHelp',
    locator_legacy: '.need-help-padding',
    locator: '',
    valueField: 'innerText',
  },
};
const getTemplate = () => cartAssertTemplate;

module.exports = {
  cartAssertTemplate,
  getTemplate,
};
