import { useEffect, useRef, useCallback } from 'react';

// Users can add items to cart from the fulfillment store selector drawer. If we have a customATC function,
// we can't pass it to the store selector drawer, but we can listen for the event and trigger it from here.
const useCustomAddToCartFromFulfillmentStoreSelectorDrawer = (customATC) => {
  const customATCRef = useRef(customATC);
  useEffect(() => {
    customATCRef.current = customATC;
  }, [customATC]);

  const customATCFromStoreSelectorDrawer = useCallback(({ output }) => {
    if (customATCRef.current && output) {
      customATCRef.current({ ...output, triggeredFromStoreDrawer: true });
    }
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('customATCFromSelectStoreDrawer', customATCFromStoreSelectorDrawer);
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('customATCFromSelectStoreDrawer', customATCFromStoreSelectorDrawer);
    };
  }, []);
};

export default useCustomAddToCartFromFulfillmentStoreSelectorDrawer;
