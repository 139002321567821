/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  DrawerBody,
  DrawerFooter,
  Alert,
  Typography
} from '@one-thd/sui-atomic-components';

import { GENERIC_ERROR } from '../shared/Constants';

const ErrorDrawer = ({ message, onClose }) => {
  return (
    <>
      <DrawerBody>
        <Alert status="error">
          <Typography>Error</Typography>
          {message || GENERIC_ERROR}
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <Button
          fullWidth
          variant="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DrawerFooter>
    </>
  );
};

ErrorDrawer.propTypes = {};

ErrorDrawer.displayName = 'ErrorDrawer';

export { ErrorDrawer };