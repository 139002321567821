/* eslint-disable camelcase */
import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';
import Helper from '../../../component/Helper';

export const renderDEImageFromTNTScriptIfExists = (setImageType, setLoading, threeHrExpiration) => {
  if (typeof window !== 'undefined') {
    const isLocalStorage = !!window.temp_decision_ls;
    const tntPromise = isLocalStorage ? window.temp_decision_ls : window.temp_decision;
    if (tntPromise) {
      tntPromise
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`DE ${isLocalStorage ? 'Local Storage' : 'Network'} response was not ok`);
          }
          return response.json();
        }).then((data) => {
          const {
            campaign_name,
            decision,
            post_de
          } = data?.response_details?.[0] || {};
          window.LIFE_CYCLE_EVENT_BUS.trigger('decision-engine.api-call', {
            post_de,
            loading: false
          });
          setImageType(Helper.armToImageMapping({ decision }));
          window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('media-gallery-rendered', () => {
            setLoading(false);
          });
          if (!isLocalStorage) {
            THDStorageUtils.set(LOCAL_STORAGE, {
              key: `decisionEngine.${campaign_name}`,
              expiration: threeHrExpiration,
              value: JSON.stringify({ data })
            });
          }
        })
        .catch((error) => {
          const TIMEOUT_MESSAGE = 'Async call timeout limit reached';
          if (error.message === TIMEOUT_MESSAGE) {
            window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.timeout');
          } else {
            window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.deError', {
              error: { status: error?.errorResponse?.status, message: error?.errorMessage }
            });
          }
        });
      // delete window variables as to not re-resolve the promises between react renders
      if (isLocalStorage) {
        window.temp_decision_ls = undefined;
      } else {
        window.temp_decision = undefined;
      }
    }
  }
};