import React, { useEffect, useContext, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, IconButton, Popover, RatingMeter } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { dataModel } from '../components/ratings-and-reviews-data-model';
import * as analytics from '../../analytics';

export const BrandRating = ({ parentNode, itemId, brandName }) => {
  const opts = {
    ssr: false,
    variables: {
      itemId
    }
  };
  const { channel } = useContext(ExperienceContext);
  const [openPopover, setOpenPopover] = useState(false);
  const popoverRef = useRef(null);

  const [
    reviewSentimentsRequest,
    reviewSentimentsResponse,
    reviewSentimentsVariables
  ] = useDataModel('reviewSentiments', opts, true);

  const variables = {
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null
    },
    searchTerm: null,
    sortBy: 'photoreview',
    startIndex: 1
  };
  const { data: rData, error: rError, loading: rLoading } = useDataModel('reviews', {
    variables: { ...variables, itemId },
    ssr: false,
    skip: false
  });

  useEffect(() => {
    if (rData?.reviews?.TotalResults <= 10) {
      setTimeout(() => {
        reviewSentimentsRequest(reviewSentimentsVariables);
      }, 1000);
    }
  }, [rData]);

  if ((rError && !rData) || !rData || rLoading || !reviewSentimentsResponse.data) {
    return null;
  }

  /* eslint-enable no-unused-expressions */

  const handleOnShow = () => {
    setOpenPopover(true);
    analytics.track('click', {
      element: 'brandRating'
    });
  };

  const formatBrandTotal = (total) => {
    if (total > 9999) {
      return `${Math.round(total / 100) / 10}k`;
    }
    return total.toString();

  };
  const handleClose = () => {
    setOpenPopover(false);
  };
  const averageBrandRating = reviewSentimentsResponse?.data?.reviewSentiments?.AverageBrandRating;
  const skuReviewCount = rData?.reviews?.TotalResults;
  const totalReviewCount = reviewSentimentsResponse?.data?.reviewSentiments?.TotalReviewCount;
  if (totalReviewCount <= skuReviewCount || !averageBrandRating) {
    return null;
  }
  const averageRatingText = averageBrandRating.toFixed(1);

  return (
    <span className="sui-inline-block">
      <div className="sui-flex sui-items-center">
        <div>(Brand Rating: {averageRatingText}/5)</div>
        <IconButton
          id="popover"
          iconSize="small"
          onClick={handleOnShow}
          ref={popoverRef}
          data-testid="popoverIcon"
          icon={Info}
          buttonSize="tight"
        />
      </div>
      <Popover
        id="popover"
        open={openPopover}
        anchorEl={popoverRef.current}
        onClose={handleClose}
        placement="bottom"
        title="Brand Rating"
        style={{ zIndex: 900 }}
        disablePortal
      >
        <div className="sui-pr-1">
          <div className="sui-flex sui-pb-2">
            <RatingMeter
              className="sui-p-0 sui-gap-2"
              value={averageBrandRating}
              label={formatBrandTotal(totalReviewCount)}
            />
          </div>
          <div>
            <Typography variant="body-base"> On average, customers rated <br />
              <span className="sui-font-bold">{brandName}</span>
              {'\xA0\xA0product '}<span className="sui-font-bold">{averageRatingText}</span>
              {'\xA0out of '}<span className="sui-font-bold">{'\xA05'}</span>
              {'\xA0stars overall.'}
            </Typography>
          </div>
        </div>
      </Popover>
    </span>
  );
};

let HTMLDivElement = null;
try {
  HTMLDivElement = window.HTMLDivElement;
  /* eslint-disable-next-line no-empty */
} catch (error) {}

BrandRating.propTypes = {
  parentNode: PropTypes.instanceOf(HTMLDivElement),
  itemId: PropTypes.string,
  brandName: PropTypes.string
};

BrandRating.defaultProps = {
  parentNode: null,
  itemId: null,
  brandName: '',
};

BrandRating.dataModel = {
  reviewSentiments: dataModel.reviewSentiments,
  reviews: dataModel.reviews
};
