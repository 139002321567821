import React, { useContext, useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import * as analytics from '../../analytics';
import { ProductDetailsContext } from '../ProductDetailsContext';
import { ProductDetailsBadge } from './ProductDetailsBadge.component';

const ProductDetailsTitle = (props) => {

  const { product, itemId, configuredProductLabel, currentProductLabel } = useContext(ProductDetailsContext);

  const publish = useCallback(() => {
    analytics.track('click', {
      element: 'product name',
    });
  }, []);
  const handleClick = useCallback((event) => {
    const { onClick } = props;
    publish();
    if (onClick) {
      onClick(event, { product });
    }
  }, [product]);

  const labelHtml = useCallback(({ product: localProduct,
    configuredProductLabel: localConfigLabel,
    currentProductLabel: localCurrentProductLabel }) => {
    const { link, pageType, badge } = props;
    const { identifiers, paintDetails } = localProduct;
    const titleString = localCurrentProductLabel || localConfigLabel || identifiers.productLabel;
    const ProductTitle = () => (
      <>
        <Typography variant={pageType === 'PEPMobile' ? 'h5' : 'h4'} component="h1">
          {badge && <ProductDetailsBadge itemId={itemId} />}
          {titleString}
        </Typography>
        {paintDetails?.colorDisplayName && (
          <Typography
            variant="body-lg"
            weight="display"
          >
            {`Color: ${paintDetails.colorDisplayName}`}
          </Typography>
        )}
      </>
    );

    if (link) {
      return (
        <Link underline="hover" onClick={handleClick} href={identifiers.canonicalUrl}>
          <ProductTitle />
        </Link>
      );
    }
    return <ProductTitle />;
  }, [product]);

  if (!product) return null;
  return (
    <span data-component="ProductDetailsTitle">
      {labelHtml({ ...props, product, configuredProductLabel, currentProductLabel })}
    </span>
  );
};

ProductDetailsTitle.propTypes = {
  badge: bool,
  link: bool,
  onClick: func,
  pageType: string,
};

ProductDetailsTitle.defaultProps = {
  badge: false,
  link: false,
  onClick: null,
  pageType: '',
};

ProductDetailsTitle.dataModel = extend(ProductDetailsBadge);

export default ProductDetailsTitle;
