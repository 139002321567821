import React, { Component } from 'react';
import {
  arrayOf, bool, func, node, oneOfType, string
} from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import Content from './Content';
import Footer from './Footer';
import './Card.styles.scss';
import { CardContext } from './CardContext';

/** THD Card component. */

class Card extends Component {

  static Content = Content;

  // eslint-disable-next-line react/static-property-placement
  static contextType = ExperienceContext;

  static Footer = Footer;

  state = {
    triggeredOverlay: null,
    scrollToBottom: false
  };

  componentDidMount() {
    const { getOnClick } = this.props;

    window.addEventListener('hashchange', this.checkOverlayHash);
    if (getOnClick) {
      getOnClick(this.handleFooterClick);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.checkOverlayHash);
  }

  checkOverlayHash = (event) => {
    const { oldURL = '' } = event;
    if (oldURL.indexOf('#overlay') > -1) {
      this.setState({ triggeredOverlay: null });
      document.body.classList.toggle('body--noscroll', false);
    }
  };

  desktopItems = (allItems) => {
    return allItems.filter((child) => !!child && [Footer, Content].indexOf(child.type) === -1);
  };

  handleFooterClick = (clickedElement, triggeredOverlay, scrollToBottom) => {
    this.setState({
      triggeredOverlay,
      scrollToBottom
    }, Content.setOverlayHash);
  };

  mobileItems = (allItems) => {
    const { triggeredOverlay, scrollToBottom } = this.state;

    const ctx = {
      scrollToBottom,
      triggered: triggeredOverlay,
      onTriggerClick: this.handleFooterClick
    };
    return (
      <CardContext.Provider value={ctx}>
        { allItems.filter((child) => !!child && [Footer, Content].indexOf(child.type) > -1)}
      </CardContext.Provider>
    );
  };

  render() {
    const {
      children,
      className,
      noHide,
    } = this.props;

    const { channel } = this.context;

    const cardClasses = classNames(
      'content-card',
      className,
      {
        'content-card--responsive': !noHide,
      }
    );

    const wrapperClasses = classNames(
      'content-card__wrapper',
      {
        'content-card__wrapper--mobile': noHide,
        'content-card__wrapper--responsive': !noHide,
      }
    );

    const elements = Array.isArray(children) ? children : [children];

    if (channel === 'desktop') {
      return (
        <div className="desktop-items">
          { this.desktopItems(elements) }
        </div>
      );
    }
    return (
      <div className={wrapperClasses}>
        <div className={cardClasses}>
          { this.mobileItems(elements) }
        </div>
      </div>
    );

  }
}

Card.displayName = 'Card';

Card.propTypes = {
  /** Card content */
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  /** Additional classes */
  className: string,
  /** Ignore card breakpoint */
  noHide: bool,
  /** callback of triggering content onclick */
  getOnClick: func
};

Card.defaultProps = {
  className: null,
  noHide: false,
  getOnClick: null
};

export default Card;
