import React, { useEffect } from 'react';
import { string as stringType, func, object as objectType, number as numberType } from 'prop-types';
import {
  useDataModel
} from '@thd-nucleus/data-sources';

import { SelectionControlLabel } from '@one-thd/sui-atomic-components';

const hasProtectionPlanData = (data) => {
  return data?.protectionPlan?.itemId && data?.protectionPlan?.price && data?.protectionPlan?.termLength;
};

const HdppItemOptionPod = ({
  index,
  itemId,
  optionsForProtectionPlan,
  handleChangeProPlan,
  Input,
  hdppSelectedItem,
  setIsHdppSelected,
  setErrorProtectionPlan,
  getDisplayPrice
}) => {
  let optionsForProtectionPlandata = optionsForProtectionPlan;
  optionsForProtectionPlandata.variables.protectionPlanParentId = itemId;
  const { data: protectionPlanData, loading: ppLoading } = useDataModel('protectionPlan', optionsForProtectionPlandata);

  const isProtectionPlan = !!itemId && hasProtectionPlanData(protectionPlanData);

  useEffect(() => {
    if (!isProtectionPlan && !ppLoading) {
      setErrorProtectionPlan((oldErrorArray) => {
        if (!oldErrorArray.includes(itemId)) {
          return [...oldErrorArray, itemId];
        }

        return oldErrorArray;

      });
    }
  }, [isProtectionPlan]);

  if (ppLoading || !protectionPlanData) {
    return null;
  }

  const {
    price,
    termLength
  } = protectionPlanData?.protectionPlan || {};
  const protItemID = protectionPlanData?.protectionPlan?.itemId;
  const priceReceived = price + '';

  let priceAsNumber = price;

  if (typeof price === 'string') {
    priceAsNumber = Number(price.replace(/[^0-9.]+/g, ''));
  }

  const userSelectedHdpp = `hdpp-accept_${protItemID}`;
  return (
    <>
      <SelectionControlLabel
        label={<div data-id={index}>{`${termLength} Year / `}{getDisplayPrice(priceReceived)}</div>}
        value={userSelectedHdpp}
      >
        <Input
          id={`${userSelectedHdpp}__checkbox`}
          name={protItemID}
          checked={hdppSelectedItem === protItemID}
          onClick={() => {
            setIsHdppSelected(protItemID);
            handleChangeProPlan(protItemID);
            LIFE_CYCLE_EVENT_BUS.trigger(
              'HDPP.change',
              { isHDPP: true, HDPPValue: priceAsNumber });
          }}
        />
      </SelectionControlLabel>
    </>
  );

};

HdppItemOptionPod.propTypes = {
  itemId: stringType,
  index: numberType,
  optionsForProtectionPlan: objectType.isRequired,
  handleChangeProPlan: func.isRequired,
  Input: objectType.isRequired,
  setIsHdppSelected: func.isRequired,
  hdppSelectedItem: stringType,
  setErrorProtectionPlan: func.isRequired,
  getDisplayPrice: func.isRequired,
};

HdppItemOptionPod.defaultProps = {
  itemId: '0',
  index: 0,
  hdppSelectedItem: '0',
};

export { HdppItemOptionPod };
