import React, { useEffect, useContext } from 'react';
import { string, bool, func } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources/react/dataModel/useDataModel';
import { DUAL_PATH_CATEGORIES } from '@thd-olt-functional/utils';
import {
  string as stringType,
  shape as shapeType,
  params
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';

import { CtaSection } from '../common/CtaSection';
import { leadGenFormTitle } from '../../../utils/RequestAQuote-Constants';
import { buildServicesLink, colPalyLink } from '../../utils/home-services-cta-utils';

const getContactNumber = (category) => {
  switch (category) {
  case DUAL_PATH_CATEGORIES.TANKLESS_WATER_HEATER:
  case DUAL_PATH_CATEGORIES.TANK_WATER_HEATER:
    return '1-855-400-2552';
  default:
    return '';
  }
};

const RequestAQuoteCta = ({
  itemId, onClick, hideContactInfo, enableNewTab, buttonText
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('request-a-quote.ready'); }, []);

  const { path = '' } = useContext(ExperienceContext);
  const opts = {
    variables: {
      itemId
    },
    ssr: true
  };

  const { data } = useDataModel('product', opts);

  if (!itemId || !data?.product?.details?.installation?.leadGenUrl) {
    return null;
  }

  const {
    contactNumber: tel,
    programCategory,
    leadGenUrl,
  } = data.product.details.installation;

  const { consultationType = '' } = data?.product?.info;

  const triggerAnalyticsEvent = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('dualPath.request-a-quote-action', { programCategory });
  };

  const headerDescription = leadGenFormTitle[programCategory];
  const contactNumber = tel || getContactNumber(programCategory);
  const onClickHandler = (event) => {
    onClick(event, programCategory);
    triggerAnalyticsEvent();
  };
  const buttonLink = programCategory === 'garage-door-opener-installation'
    ? colPalyLink : buildServicesLink({ itemId, leadGenUrl, path, consultationType });
  const openContentInDrawer = !enableNewTab && programCategory !== 'garage-door-opener-installation';
  return (
    <CtaSection
      buttonText={buttonText}
      contactNumber={contactNumber}
      buttonLink={buttonLink}
      onClick={onClickHandler}
      hideContactInfo={hideContactInfo}
      headerDescription={headerDescription}
      openContentInDrawer={openContentInDrawer}
      dataComponent="RequestAQuoteCta"
    />
  );
};

RequestAQuoteCta.displayName = 'RequestAQuoteCta';

RequestAQuoteCta.dataModel = {
  product: params({ itemId: stringType().isRequired(), dataSource: stringType() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    details: shapeType({
      installation: shapeType({
        programCategory: stringType(),
        contactNumber: stringType(),
        leadGenUrl: stringType()
      }),
    }),
    info: shapeType({
      consultationType: stringType()
    }),
  }),
};

RequestAQuoteCta.propTypes = {
  itemId: string.isRequired,
  onClick: func,
  hideContactInfo: bool,
  enableNewTab: bool,
  buttonText: string
};

RequestAQuoteCta.defaultProps = {
  hideContactInfo: false,
  onClick: () => { },
  enableNewTab: false,
  buttonText: 'Request a Quote'
};

export { RequestAQuoteCta };