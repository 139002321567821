import React from 'react';
import { bool } from 'prop-types';
import { Col, Image } from '@thd-olt-component-react/core-ui';
import { GREEN_DELIVERY_TRUCK_ICON } from './constants';

import './DeliveryMessage.style.scss';

export const DeliveryMessage = React.memo(({
  freeDelivery,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
}) => {

  if (enableApplianceDeliveryCharge && !isExchangeCustomer) {
    return (
      <span className="fbt-appliance-delivery-message fbt-appliance-delivery-message__spacer" />
    );
  }

  const shouldShowFreeDelivery = freeDelivery || (isExchangeCustomer && enableFreeDeliveryForExchange);

  return (
    <>
      <Col className="fbt-appliance-delivery-message">
        {shouldShowFreeDelivery
          ? (
            <>
              <span className="fbt-appliance-delivery-message__text">
                <Image src={GREEN_DELIVERY_TRUCK_ICON} alt="Green Delivery Truck Icon" lazy />
                <span className="u__text--success">FREE Home Delivery</span>
              </span>
            </>
          )
          : (
            <span className="fbt-appliance-delivery-message__title--below-threshold u__text--success">
              Free delivery on appliance purchases of $396 and up
            </span>
          )}
      </Col>
    </>
  );
});

DeliveryMessage.propTypes = {
  freeDelivery: bool.isRequired,
  enableApplianceDeliveryCharge: bool,
  enableFreeDeliveryForExchange: bool,
  isExchangeCustomer: bool
};

DeliveryMessage.defaultProps = {
  enableApplianceDeliveryCharge: false,
  enableFreeDeliveryForExchange: false,
  isExchangeCustomer: false
};
