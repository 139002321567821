import React, { useEffect, useContext } from 'react';
import { bool } from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { loadScript } from '../util';
import { DynamicSMS } from '../sub-components/DynamicSMS';
import { TextAnExpertCard } from '../markup/TextAnExpertCard';
import { TextAnExpertPlaceholder } from './TextAnExpertPlaceholder/TextAnExpertPlaceholder';

const placeholder = (
  <TextAnExpertPlaceholder />
);

const iOSScript = 'https://static.cdn-apple.com/businesschat/start-chat-button/2/index.js';
const isAndroid = () => /Android/i.test(navigator.userAgent);
const isiPhone = () => /iPhone/i.test(navigator.userAgent);

const isIOSSupported = () => {
  if (/iPhone/.test(navigator.platform)) {
    const appVersion = (navigator.appVersion);
    const osFullVersion = appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const version = `
      ${parseInt(osFullVersion[1], 10)}.
      ${parseInt(osFullVersion[2], 10)}.
      ${parseInt(osFullVersion[3] || 0, 10)}`;
    return parseFloat(version) >= 11.3;
  }
  return false;
};

const refreshBusinessChat = () => {
  if (window.appleBusinessChat && window.appleBusinessChat.isSupported()) {
    window.appleBusinessChat.refresh();
  }
};

const TextAnExpertComponent = ({ disableFeature, useDynamicSMS, proUX }) => {

  const { channel, isServer } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('text-an-expert.ready'); }, []);
  useEffect(() => {
    if (isIOSSupported()) {
      loadScript(iOSScript).then(refreshBusinessChat());
    }
  });

  if (disableFeature || !isMobile || isServer || (!isAndroid() && !isiPhone() && !proUX)) return null;

  return (
    <div className="sui-mb-3 sui-p-2 sui-w-full" data-component="TextAnExpert">
      {useDynamicSMS && <DynamicSMS proUX={proUX} isiPhone={isiPhone()} />}
      {!useDynamicSMS && <TextAnExpertCard isiPhone={isiPhone()} proUX={proUX} />}
    </div>
  );
};

TextAnExpertComponent.displayName = 'TextAnExpertComponent';

const propTypes = {
  disableFeature: bool,
  useDynamicSMS: bool,
  proUX: bool
};
TextAnExpertComponent.propTypes = propTypes;

const defaultProps = {
  disableFeature: false,
  useDynamicSMS: false,
  proUX: false
};
TextAnExpertComponent.defaultProps = defaultProps;

const HydratedComponent = withHydrator({
  id: 'text-an-expert',
  scrollBuffer: 750,
  delay: 2000,
  placeholder,
  preserveCtxVal: 'clientStore',
}, TextAnExpertComponent);

const DynamicComponent = withDynamicComponent(
  HydratedComponent,
  { placeholder }
);
const TextAnExpert = withErrorBoundary(DynamicComponent);

TextAnExpert.displayName = 'TextAnExpert';
TextAnExpert.propTypes = propTypes;
TextAnExpert.defaultProps = defaultProps;

export { TextAnExpert };
