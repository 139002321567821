export const MOBILE_CHANNEL = 'mobile';
export const DELIMITER = ':';
export const DRAWERS = {
  LOADING: 'loading',
  CREATE_QUOTE: 'create-quote',
  ADD_TO_QUOTE: 'add-to-quote',
  SIGN_IN_WARNING: 'sign-in-warning',
  PRICE_CHANGE_WARNING: 'price-change-warning',
  SUCCESS: 'success',
  ERROR: 'error'
};
export const GENERIC_ERROR = 'Something went wrong. Please refresh and try again.';
export const DEFAULT_SELECTED_QUOTE = {
  userId: '', // this needs to be the userId of the user that created the quote, not current user
  quoteId: '',
  quoteName: ''
};

export const ERROR = {
  // eslint-disable-next-line max-len
  INVALID_QUOTE_NAME: 'Invalid Quote Name: Only a-z, A-Z, 0-9 are allowed.',
  EMPTY_QUOTE_NAME: 'Quote name cannot be empty'
};

export const QUOTE_ACTIVE_STATUS = 'ACTIVE';
export const QUOTE_PENDING_STATUS = 'PENDING';

export const FULFILLMENT = {
  STH: 'ShipToHome',
  BOPIS: 'BOPIS',
  BOSS: 'ShipToStore',
  BODFS: 'DeliverFromStore',
  APPLIANCE: 'DirectDelivery'
};
export const PICKUP_FULFILLMENT = [FULFILLMENT.BOSS, FULFILLMENT.BOPIS];
export const DELIVERY_FULFILLMENT = [FULFILLMENT.STH, FULFILLMENT.BODFS, FULFILLMENT.APPLIANCE];

// role
export const USER_ADMIN = 'ADMIN';
export const PURCHASER = 'PURCHASER';