/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AccordionContext from './AccordionContext';
import { ButtonBase } from '../private/components/button/ButtonBase';
import { Typography } from '../typography/Typography';
import { ArrowDown } from '../private/icons/ArrowDown';

function createComponent(Component, displayName) {

  const StyledComponent = React.forwardRef((props, ref) => {
    const {
      children,
      className,
      component,
      ...other
    } = props;

    const FinalComponent = Component;

    return (
      <FinalComponent component={component} ref={ref} className={className} {...other}>
        {children}
      </FinalComponent>
    );
  });

  StyledComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    className: PropTypes.string,
    component: PropTypes.elementType
  };

  StyledComponent.displayName = displayName;
  return StyledComponent;
}

const AccordionHeaderRoot = createComponent(ButtonBase, 'AccordionHeaderRoot');
const AccordionHeaderContent = createComponent('div', 'AccordionHeaderContent');
const AccordionHeaderExpandIconWrapper = createComponent('div', 'AccordionHeaderExpandIconWrapper');

/**
 * `AccordionHeader` houses the title and icon in [Accordion](#accordion)
 *
 * Use it along with [AccordionBody](#accordionbody)
 *
 * Usage:
 *
 * ```jsx
 * import { AccordionHeader } from '@one-thd/sui-atomic-components';
 * ```
 */
const AccordionHeader = React.forwardRef((props, ref) => {

  const {
    children: childrenProp,
    expandIcon: expandIconProp,
    onClick,
    variant = 'body-lg',
    ...other
  } = props;

  const {
    disabled = false,
    expanded,
    toggle,
    noBorders
  } = React.useContext(AccordionContext);

  const handleChange = (event) => {
    if (toggle) {
      toggle(event);
    }
    if (onClick) {
      onClick(event);
    }
  };

  let children;
  if (typeof childrenProp === 'string') {
    children = (<Typography color="none" variant={variant} weight="bold" height="tight">{childrenProp}</Typography>);
  } else {
    children = childrenProp;
  }

  const AccordionHeaderExpandClasses = classNames('sui-flex sui-items-center sui-ml-2', {
    'group-hover:sui-fill-primary': !disabled,
    'sui-fill-subtle': disabled,
    'sui-fill-primary': !expanded && !disabled,
    'sui-min-h-[26px]': !noBorders,
    'sui-min-h-8': noBorders
  });

  const AccordionHeaderContentClasses = classNames('sui-flex sui-items-center sui-grow', {
    'sui-min-h-[26px]': !noBorders,
    'sui-min-h-8': noBorders
  });

  const IconContainerClasses = classNames('sui-transition-transform sui-duration-250 sui-h-4 sui-flex sui-items-start', {
    'sui-rotate-180': expanded
  });

  const expandIcon = (
    <div className={IconContainerClasses}>
      { expandIconProp || <ArrowDown size="small" color="inherit" /> }
    </div>
  );

  const AccordionHeaderRootClasses = classNames('sui-flex sui-justify-between sui-items-start sui-group sui-rounded-base sui-flex hover:sui-bg-primary focus-visible:sui-text-primary',
    '[&>div>div>div:first-child]:hover:sui-underline [&>div>div>div:first-child>p]:sui-underline [&>div>div:first-child>p]:hover:sui-underline [&>div:first-child>p]:hover:sui-underline',
    'focus-visible:sui-ring-offset-2 focus-visible:sui-ring-offset-primary focus-visible:sui-ring-1 focus-visible:sui-ring-strongest', {
      'sui-bg-button-inactive-primary': disabled,
      'sui-border-1 sui-border-primary sui-border-solid sui-p-4': !noBorders,
      'sui-py-1': noBorders
    });

  return (
    <AccordionHeaderRoot
      disabled={disabled}
      component="div"
      aria-expanded={expanded}
      className={AccordionHeaderRootClasses}
      onClick={handleChange}
      ref={ref}
      {...other}
    >
      <AccordionHeaderContent className={AccordionHeaderContentClasses}>
        {children}
      </AccordionHeaderContent>
      {expandIcon && (
        <AccordionHeaderExpandIconWrapper className={AccordionHeaderExpandClasses}>
          {expandIcon}
        </AccordionHeaderExpandIconWrapper>
      )}
    </AccordionHeaderRoot>
  );
});

AccordionHeader.displayName = 'AccordionHeader';

AccordionHeader.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The icon to display as the expand indicator.
   */
  expandIcon: PropTypes.node,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * The typography variant to use in header.
   */
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body-lg', 'body-base', 'body-xs'])
};

export { AccordionHeader };
