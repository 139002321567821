/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  alias,
  params,
  arrayOf,
  shape as shapeType,
  string as stringType,
  customType
} from '@thd-nucleus/data-sources';

const String = stringType();

const PayPalInitResponse = shapeType({
  paypalUrl: String,
  cancelUrl: String,
  returnUrl: String,
  cartId: String,
  token: String
});

const Experience = {
  type: String,
  component: String,
};

const ExpTypeFilter = {
  type: String
};

const RequestContext = shapeType({
  cartId: String,
  experience: Experience,
  expTypeFilter: ExpTypeFilter,
  tmxProfileId: String
});
const requestContext = customType('RequestContext').shape(RequestContext);

export const initPaypal = {
  initPaypal: alias('initPaypal').params({ requestContext }).shape(PayPalInitResponse)
};