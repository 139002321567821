import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Row, Col, Placeholder } from '@thd-olt-component-react/core-ui';
import './transactional-placeholder.scss';
import '../packages-mini-visual.scss';
import '../subcomponents/visual-product-images.scss';
import '../subcomponents/visual-package-info.scss';
import '../subcomponents/visual-package-summary.scss';

const VisualProductImages = () => (
  <Row>
    <Col className="product-images__wrapper" flatten>
      <Placeholder className="product-images__image-item-placeholder" />
    </Col>
  </Row>
);

const VisualPackageSummary = () => (
  <>
    <Col className="visual-package-summary__total-price-container">
      <Placeholder height="30px" width="25%" />
      <Placeholder height="35px" width="60%" type="text" />
    </Col>
    <Col className="visual-package-summary__view-bundle-bttn-container">
      <Placeholder height="40px" />
    </Col>
  </>
);

const VisualPackageInfo = () => (
  <>
    <Col className="visual-package-info__title-container">
      {new Array(3).fill('').map((_, key) => (
        <Placeholder
          key={`text-${key}`}
          height="27px"
          type="text"
          className="transactional-placeholder__product-pod-text-block"
        />
      ))}
    </Col>
    <Col flatten>
      <VisualProductImages />
    </Col>
  </>
);

export const VisualPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  return (
    <div className="packages-mini-visual" data-component="PackagesMiniVisualPlaceholder">
      <Row>
        {!isMobile && (
          <Col xs="12" sm="7" className="packages-mini-visual__desktop-images-container">
            <VisualProductImages />
          </Col>
        )}
        <Col sm={isMobile ? '12' : '5'} className="packages-mini-visual__info-summary-container">
          <VisualPackageInfo />
          <VisualPackageSummary />
        </Col>
      </Row>
    </div>
  );
};

VisualPlaceholder.displayName = 'PackagesMiniPlaceholder';
