import React from 'react';
import { string } from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Row, Col } from '@thd-olt-component-react/grid';
import { useConfigService } from '@thd-nucleus/experience-context';
import { InstantCheckoutTooltip } from './InstantCheckoutTooltip';
import { getInstantCheckoutPath } from '../../utils';
import { ENABLE_NEW_INSTANT_CHECKOUT } from '../../constants/features';

const InstantCheckoutEnroll = ({ channel }) => {
  const enableNewInstantCheckoutUrl = useConfigService(ENABLE_NEW_INSTANT_CHECKOUT);
  const instantCheckoutUrl = getInstantCheckoutPath(enableNewInstantCheckoutUrl);

  return (
    <div className="instant-checkout" data-component="InstantCheckoutEnroll">
      <div className="instant-checkout__wrapper">
        <div className="instant-checkout__box">
          <Row>
            <Col>
              <div>
                <span className="instant-checkout__heading">Your Fastest Checkout</span>
                <Tooltip
                  channel={channel}
                  placement="bottom"
                  closeButton
                  content={<InstantCheckoutTooltip />}
                  interactive
                >
                  <span className="instant-checkout__info" />
                </Tooltip>
              </div>
            </Col>
            <Col nopadding>
              <span>
                <a href={instantCheckoutUrl} className="u__default-link">
                  Turn on Instant Checkout
                </a>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
InstantCheckoutEnroll.propTypes = {
  channel: string,
};

InstantCheckoutEnroll.defaultProps = {
  channel: 'desktop',
};

export { InstantCheckoutEnroll };
