import React from 'react';
import PropTypes from 'prop-types';
import AccordionContext from './AccordionContext';
import { Paper } from '../private/components/paper/Paper';
import { Collapse } from '../collapse/Collapse';
import useControlled from '../private/hooks/useControlled';

/**
 * `Accordion` is used to group related information under a container that users can expand or collapse.
 *
 * Use `Accordion` with the following sub-components:
 * [AccordionBody](#accordionbody), [AccordionHeader](#accordionheader), [Collapse](#collapse)
 *
 * Usage:
 *
 * ```jsx
 * import { Accordion } from '@one-thd/sui-atomic-components';
 * ```
 */
const Accordion = React.forwardRef((props, ref) => {

  const {
    children: childrenProp,
    defaultExpanded = false,
    disabled = false,
    expanded: expandedProp,
    noBorders = false,
    onChange,
    TransitionProps,
    ...other
  } = props;

  const [expanded, setExpandedState] = useControlled({
    controlled: expandedProp,
    defaultValue: defaultExpanded
  });

  const handleChange = React.useCallback(
    (event) => {
      setExpandedState(!expanded);

      if (onChange) {
        onChange(event, !expanded);
      }
    },
    [expanded, onChange, setExpandedState],
  );

  const [summary, ...children] = React.Children.toArray(childrenProp);

  const contextValue = React.useMemo(() => ({
    expanded,
    disabled,
    toggle: handleChange,
    noBorders
  }), [expanded, disabled, noBorders, handleChange]);

  return (
    <Paper
      ref={ref}
      className="sui-relative sui-transition-[margin] sui-mb-2"
      {...other}
    >
      <AccordionContext.Provider value={contextValue}>
        {summary}
        <Collapse in={expanded} timeout="auto" {...TransitionProps}>
          <div
            aria-labelledby={summary.props.id}
            id={summary.props['aria-controls']}
            role="region"
          >
            {children}
          </div>
        </Collapse>
      </AccordionContext.Provider>
    </Paper>
  );
});

Accordion.displayName = 'Accordion';

Accordion.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, expands the accordion by default.
   * @default false
   */
  defaultExpanded: PropTypes.bool,
  /**
   * If `true`, the component is disabled.
   * @default false
   * @ignore
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, expands the accordion, otherwise collapse it.
   * Setting this prop enables control over the accordion.
   */
  expanded: PropTypes.bool,
  /**
   * If `true`, then the Accordion will be displayed without borders.
   * @default false
   */
  noBorders: PropTypes.bool,
  /**
   * Callback fired when the expand/collapse state is changed.
   */
  onChange: PropTypes.func,
  /**
   * Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition/) component.
   */
  TransitionProps: PropTypes.object,
};

export { Accordion };
