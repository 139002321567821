import { getLargeToSmallImageRatio } from './imageRatio';

function getLensCursorOffsetDimension(enlargedImageContainerDimension, ratio) {
  return Math.round((enlargedImageContainerDimension * ratio) / 2);
}

export function getLensCursorOffset(smallImage, largeImage, enlargedImageContainerDimensions) {
  const ratio = getLargeToSmallImageRatio(smallImage, largeImage);
  return {
    x: getLensCursorOffsetDimension(enlargedImageContainerDimensions.width, ratio.x),
    y: getLensCursorOffsetDimension(enlargedImageContainerDimensions.height, ratio.y)
  };
}