import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { bool, number } from 'prop-types';
import { Skeleton, SkeletonLine, SkeletonBlock, Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';

const FBTPlaceholder = ({
  hideTitle, itemsCount, itemHeight, txtPlaceholderLines
}) => {

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  let numOfPlaceholders = isMobile ? 2 : 6;
  let placeholderHeight = isMobile ? 48 : 56;
  const totalTextPlaceholderHeight = (txtPlaceholderLines / 3) * 2.4;

  const placeholderGridClass = classNames('sui-grid', { 'sui-grid-cols-2': isMobile,
    'sui-grid-cols-6': !isMobile });

  if (itemsCount) {
    numOfPlaceholders = itemsCount;
  }

  if (itemHeight) {
    placeholderHeight = Math.floor((((itemHeight * 0.4) - totalTextPlaceholderHeight) / (0.4)) / 4) * 4;
  }

  const RenderPlaceholders = () => {
    let placeholder = [];
    for (let i = 0; i < numOfPlaceholders; i += 1) {
      placeholder.push(
        <Skeleton
          orientation="vertical"
          key={i}
        >
          <SkeletonBlock aspect="square" height={placeholderHeight} />
          {txtPlaceholderLines > 0 && <SkeletonLine variant="multi" fullWidth numberOfLines={txtPlaceholderLines} />}
        </Skeleton>);
    }
    return placeholder;
  };

  return (
    <>
      {!hideTitle
      && (
        <div className="sui-p-4">
          <Typography variant="h2">Loading Recommendations</Typography>
        </div>
      )}
      <div className={placeholderGridClass} key="placeholder-row">
        <RenderPlaceholders />
      </div>
    </>
  );
};

FBTPlaceholder.propTypes = {
  hideTitle: bool,
  itemsCount: number,
  itemHeight: number,
  txtPlaceholderLines: number
};

FBTPlaceholder.defaultProps = {
  hideTitle: false,
  itemsCount: null,
  txtPlaceholderLines: 8,
  itemHeight: null
};

export { FBTPlaceholder };