import isEqual from 'fast-deep-equal';
import { FADE_DURATION_IN_MS } from '../constants';

export function getContainerStyle(smallImage) {
  const {
    isFluidWidth: isSmallImageFluidWidth,
    width,
    height
  } = smallImage;

  const fluidWidthContainerStyle = {
    width: 'auto',
    height: 'auto',
    fontSize: '0px',
    position: 'relative'
  };

  const fixedWidthContainerStyle = {
    width: `${width}px`,
    height: `${height}px`,
    position: 'relative'
  };

  const priorityContainerStyle = isSmallImageFluidWidth
    ? fluidWidthContainerStyle
    : fixedWidthContainerStyle;

  const compositeContainerStyle = {
    cursor: 'crosshair',
    ...priorityContainerStyle
  };

  return compositeContainerStyle;
}

export function getSmallImageStyle(smallImage) {
  const {
    isFluidWidth: isSmallImageFluidWidth,
    width,
    height
  } = smallImage;

  const fluidWidthSmallImageStyle = {
    width: '100%',
    height: 'auto',
    display: 'block',
    pointerEvents: 'none'
  };

  const fixedWidthSmallImageStyle = {
    width: `${width}px`,
    height: `${height}px`,
    pointerEvents: 'none'
  };

  const prioritySmallImageStyle = isSmallImageFluidWidth
    ? fluidWidthSmallImageStyle
    : fixedWidthSmallImageStyle;

  return prioritySmallImageStyle;
}

function getPrimaryEnlargedImageContainerStyle(isInPlaceMode) {
  const baseContainerStyle = {
    overflow: 'hidden'
  };

  const sharedPositionStyle = {
    position: 'absolute',
    top: '0px',
  };

  if (isInPlaceMode) {
    return {
      ...baseContainerStyle,
      ...sharedPositionStyle,
      left: '0px'
    };
  }

  return {
    ...baseContainerStyle,
    ...sharedPositionStyle,
    left: '100%',
    marginLeft: '10px',
    border: '1px solid #d6d6d6'
  };
}

function getPriorityEnlargedImageContainerStyle(params) {
  const {
    containerDimensions,
    isTransitionActive
  } = params;

  return {
    width: containerDimensions.width,
    height: containerDimensions.height,
    opacity: isTransitionActive ? 1 : 0,
    transition: `opacity ${FADE_DURATION_IN_MS}ms ease-in`,
    pointerEvents: 'none'
  };
}

const enlargedImageContainerStyleCache = {};

export function getEnlargedImageContainerStyle(params) {
  const cache = enlargedImageContainerStyleCache;
  const {
    params: memoizedParams = {},
    compositeStyle: memoizedStyle
  } = cache;

  if (isEqual(memoizedParams, params)) {
    return memoizedStyle;
  }

  const {
    containerDimensions,
    isTransitionActive,
    isInPlaceMode
  } = params;

  const primaryStyle = getPrimaryEnlargedImageContainerStyle(isInPlaceMode);
  const priorityStyle = getPriorityEnlargedImageContainerStyle({
    containerDimensions,
    isTransitionActive
  });

  cache.compositeStyle = {
    ...primaryStyle,
    ...priorityStyle
  };
  cache.params = params;

  return cache.compositeStyle;
}

export function getEnlargedImageStyle(params) {
  const {
    imageCoordinates,
    largeImage
  } = params;

  const translate = `translate(${imageCoordinates.x}px, ${imageCoordinates.y}px)`;

  const priorityStyle = {
    width: largeImage.width,
    height: largeImage.height,
    transform: translate,
    WebkitTransform: translate,
    msTransform: translate,
    pointerEvents: 'none'
  };

  return priorityStyle;
}