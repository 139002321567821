import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@thd-olt-component-react/core-ui';

export const FbtBadge = ({ badges }) => {

  let badgesColor = '#3e7697';
  const badgeName = badges?.[0]?.label || null;

  if (!badgeName) return null;

  return (
    <Badge type="text" text={badgeName} className="fbt_badge" color={badgesColor} />
  );
};

const badgeShape = PropTypes.shape({
  label: PropTypes.string
});

FbtBadge.propTypes = {
  badges: PropTypes.arrayOf(badgeShape)
};

FbtBadge.defaultProps = {
  badges: []
};

FbtBadge.displayName = 'FbtBadge';
