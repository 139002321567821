import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

export const ToasterContext = createContext({});

export const ToasterProvider = ({ children }) => {
  // KVP = Key Value Pair
  const [toasterKVPs, setToasterKVPs] = useState({});

  const addToaster = (key, toasterProps) => {
    setToasterKVPs((prevState) => {
      return {
        ...prevState,
        [key]: toasterProps
      };
    });
  };

  const removeToaster = ({ key }) => {
    setToasterKVPs((prevState) => {
      const toasterKVPsCopy = { ...prevState };
      delete toasterKVPsCopy[key];
      return toasterKVPsCopy;
    });
  };

  return (
    <ToasterContext.Provider value={{
      addToaster,
      removeToaster,
      toasterKVPs
    }}
    >
      {children}
    </ToasterContext.Provider>
  );
};

ToasterProvider.displayName = 'ToasterProvider';

ToasterProvider.propTypes = {
  children: node.isRequired
};