import { handleUpdateCall, useCartMutation } from './helpers/mutation-helper';

export const useAddToCart = () => {
  const [addToCartMutation, atcResponse] = useCartMutation('addToCart');

  // mutation wrapper
  const addToCart = ({ cartRequest, onComplete }) => {
    const variables = {
      cartRequest
    };

    // returns promise
    return handleUpdateCall({
      mutationName: 'addToCart',
      mutator: addToCartMutation,
      variables,
      onComplete
    });

  };

  return [addToCart, atcResponse];
};
