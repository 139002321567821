import { getAppId } from './helpers';

export const DtsTntVariables = (dtsModel, props, storeInfo, channel, isConsumerApp) => {
  return {
    dtsopts: {
      opts: {
        variables: {
          anchorId: props?.itemId,
          model: dtsModel?.model || '',
          dataSource: dtsModel?.model || '',
          key: dtsModel?.key || props?.requestKey,
          endpointType: dtsModel?.endpointType || 'product',
          appId: dtsModel?.appId || getAppId(channel, isConsumerApp),
          storeId: dtsModel?.storeId || storeInfo?.storeId,
          zipCode: dtsModel?.zipCode || storeInfo?.storeZip || null,
          loyaltyMembershipInput: storeInfo?.membershipInformation?.data?.loyaltyMembership || null,
        },
        skip: !storeInfo?.isLocalized,
        ssr: false,
      },
    },
    qName: 'dts',
    dtsAnchor: props?.itemId
  };
};

export const UseRecsCxtAndProps = (recsModelProps, props, storeInfo) => {
  return {
    recsOpts: {
      opts: {
        variables: {
          anchorId: props?.itemId,
          apiName: recsModelProps?.testAPI || '',
          dataSource: recsModelProps?.testAPI || '',
          skipInstallServices: true,
          loyaltyMembershipInput: storeInfo?.membershipInformation?.data?.loyaltyMembership || null,
          storeId: recsModelProps?.storeId || storeInfo?.storeId,
          zipCode: recsModelProps?.zipCode || storeInfo?.storeZip || null
        },

        ssr: false,
      }
    },
    qName: 'recs',
    recsAnchor: props?.itemId
  };
};