import React from 'react';
import { Skeleton, SkeletonBlock, SkeletonContent, SkeletonLine } from '@one-thd/sui-atomic-components';

export const TextAnExpertPlaceholder = () => {
  return (
    <div
      className="sui-mb-3 sui-p-2 sui-w-full"
      data-testid="text-an-expert-placeholder-component"
      data-component="TextAnExpertPlaceholder"
    >
      <Skeleton grow>
        <SkeletonContent grow initialSize="4/4" disablePadding>
          <div className="sui-flex sui-items-center">
            <div className="sui-basis-1/12 sui-mr-2">
              <SkeletonBlock aspect="square" height={8} width={8} />
            </div>
            <div className="sui-basis-10/12">
              <SkeletonLine variant="single" />
            </div>
            <div className="sui-basis-1/12">
              <SkeletonBlock aspect="square" height={6} width={6} />
            </div>
          </div>
        </SkeletonContent>
      </Skeleton>
    </div>
  );
};