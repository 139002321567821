export const BADGE_MODELS = [
  'new',
  'seasonal',
  'exclusive',
  'trending',
  'bestseller',
  'custom',
  'guaranteed',
  'top rated',
  'popular',
  'installed'
];

export const INSPIRATIONAL_BADGE_MODELS = [
  'new',
  'trending',
  'bestseller',
  'exclusive'
];