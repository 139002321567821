import React from 'react';
import { bool, string, oneOf } from 'prop-types';
import {
  extend,
  params,
  shape as shapeType,
  arrayOf,
  string as stringType
} from '@thd-nucleus/data-sources';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';

import { MediaGalleryCarousel } from './MediaGalleryCarousel';
import { dataModel } from '../../dataModel';

export const MediaGalleryWithHotspotsCarousel = (props) => {
  return (
    <MediaGalleryCarousel {...props} />
  );
};

MediaGalleryWithHotspotsCarousel.displayName = 'MediaGalleryWithHotspotsCarousel';

MediaGalleryWithHotspotsCarousel.propTypes = MediaGalleryCarousel.propTypes;

MediaGalleryWithHotspotsCarousel.defaultProps = MediaGalleryCarousel.defaultProps;

MediaGalleryWithHotspotsCarousel.dataModel = extend({}, {
  product: params({ itemId: stringType().isRequired() }).shape({
    media: shapeType({
      images: arrayOf(shapeType({
        hotspots: arrayOf(shapeType({
          coordinate: shapeType({
            xCoordinate: stringType(),
            yCoordinate: stringType()
          }),
          omsIDs: arrayOf(stringType()),
        })),
      }))
    })
  })
}, dataModel, MediaHotspots);
