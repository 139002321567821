import {
  arrayOf,
  params,
  shape as shapeType,
  string as stringType,
  number as numberType
} from '@thd-nucleus/data-sources';

export const dataModel = {
  collection: params({ collectionId: stringType().isRequired(), storeId: stringType() }).shape({
    media: shapeType({
      augmentedRealityLink: shapeType({
        image: stringType(),
        usdz: arrayOf(stringType())
      }),
      images: arrayOf(shapeType({
        sizes: arrayOf(stringType()),
        type: stringType(),
        url: stringType(),
        altText: stringType()
      })),
      threeSixty: arrayOf(shapeType({
        id: stringType(),
        url: stringType()
      })),
      video: arrayOf(shapeType({
        shortDescription: stringType(),
        title: stringType(),
        thumbnail: stringType(),
        type: stringType(),
        url: stringType(),
        videoId: stringType(),
        videoStill: stringType()
      }))
    }),
    metadata: shapeType({
      brandName: stringType(),
      name: stringType()
    })
  }),
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    itemId: stringType(),
    identifiers: shapeType({
      itemId: stringType(),
      brandName: stringType(),
      productLabel: stringType()
    }),
    paintDetails: params({ configId: stringType(), storeId: stringType() }).shape({
      brandLogo: stringType(),
      colorType: stringType(),
      rgb: shapeType({
        red: stringType(),
        green: stringType(),
        blue: stringType(),
      })
    }).skip('skipPaintDetails', true),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayOf(shapeType({
        url: stringType(),
        sizes: arrayOf(stringType()),
        type: stringType(),
        subType: stringType(),
        altText: stringType()
      })),
      video: arrayOf(shapeType({
        url: stringType(),
        videoStill: stringType(),
        link: shapeType({
          text: stringType(),
          url: stringType()
        }),
        title: stringType(),
        type: stringType(),
        videoId: stringType(),
        thumbnail: stringType(),
        longDescription: stringType(),
        shortDescription: stringType(),
        uploadDate: stringType(),
        dateModified: stringType(),
      })),
      threeSixty: arrayOf(shapeType({
        id: stringType(),
        url: stringType()
      })),
      augmentedRealityLink: shapeType({
        usdz: arrayOf(stringType()),
        image: stringType()
      })
    })
  }),
  reviewPhotos: params({ itemId: stringType().isRequired() })
    .shape({
      Results: arrayOf(shapeType({
        Photos: arrayOf(shapeType({
          Id: stringType(),
          Sizes: shapeType({
            normal: shapeType({
              Id: stringType(),
              Url: stringType(),
            })
          })
        })),
        Rating: numberType()
      }))
    })
};