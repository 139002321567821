import React, { useEffect } from 'react';
import { setErrorEvents } from '../../track';
import '../buybox.scss';

const BuyboxError = () => {

  useEffect(() => {
    setErrorEvents({
      message: 'We\'re sorry, something went wrong on our end. Please try refreshing.'
    });
  }, []);

  return (
    <div className="buybox-error-container" data-component="BuyboxError">
      <div className="alert-inline alert-inline--warning buybox__error">
        <span style={{ color: 'black' }}>
          {'We\'re sorry, something went wrong on our end.'}
          <br />
          {'Please try '}
          {/* eslint-disable-next-line */}
          <a onClick={() => {
            if (typeof window !== 'undefined') {
              window.location.reload(false);
            }
          }}
          >
            <u>refreshing</u>
          </a>
          .
        </span>
      </div>
    </div>
  );
};

BuyboxError.propTypes = {};

BuyboxError.defaultProps = {};

export { BuyboxError };
