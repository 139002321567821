/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { string, bool, func, number } from 'prop-types';
import classNames from 'classnames';

const SubscribeAndSaveDefault = ({
  disabled,
  discount,
  discountPrice,
  itemId,
  onChange,
  subscribed
}) => {

  const handleChange = () => {
    onChange(!subscribed);
  };

  const titleClasses = classNames('subscribe-and-save-options__title',
    'subscribe-and-save-options__title--condensed', {
      'subscribe-and-save--disabled': disabled
    });

  const discountClasses = classNames({
    'subscribe-and-save-options__title-discount': !disabled
  });

  return disabled
    ? (
      <div className="radio-btn__content-wrapper subscribe-and-save-options__opt-in">
        <input
          type="radio"
          className="radio-btn__input"
          name="subscribe-and-save-radio"
          id={`subscribe-and-save-options__opt-in_disabled-${itemId}`}
          disabled
          value="subscribe"
        />
        <label className="radio-btn__label" htmlFor={`subscribe-and-save-options__opt-in_disabled-${itemId}`}>
          <span className="radio-btn" />
          <span className={titleClasses}>
            Subscribe and
            <span className={discountClasses}> Get {discount}% off</span>
          </span>
        </label>
      </div>
    )
    : (
      <form className="subscribe-and-save-options">
        <div className="radio-btn__content-wrapper subscribe-and-save-options__opt-out">
          <input
            type="radio"
            className="radio-btn__input"
            name="subscribe-and-save-radio"
            id={`subscribe-and-save-options__opt-out-${itemId}`}
            onChange={handleChange}
            checked={!subscribed}
            value="one-time-purchase"
          />
          <label className="radio-btn__label" htmlFor={`subscribe-and-save-options__opt-out-${itemId}`}>
            <span className="radio-btn" />
            <span className="subscribe-and-save-options__opt-out-label">One-Time Purchase</span>
          </label>
        </div>
        <div className="radio-btn__content-wrapper subscribe-and-save-options__opt-in">
          <input
            type="radio"
            className="radio-btn__input"
            name="subscribe-and-save-radio"
            id={`subscribe-and-save-options__opt-in-${itemId}`}
            onChange={handleChange}
            checked={subscribed}
            value="subscribe"
          />
          <label className="radio-btn__label" htmlFor={`subscribe-and-save-options__opt-in-${itemId}`}>
            <span className="radio-btn" />
            <span className={titleClasses}>
              ${`${discountPrice} `}&mdash; Subscribe and
              <span className="subscribe-and-save-options__title-discount"> Get {discount}% off</span>
            </span>
          </label>
        </div>
      </form>
    );
};

SubscribeAndSaveDefault.displayName = 'SubscribeAndSaveDefault';

SubscribeAndSaveDefault.propTypes = {
  disabled: bool,
  discount: string,
  discountPrice: string,
  itemId: string.isRequired,
  onChange: func.isRequired,
  subscribed: bool
};

SubscribeAndSaveDefault.defaultProps = {
  disabled: false,
  discount: '5',
  discountPrice: null,
  subscribed: false
};

export { SubscribeAndSaveDefault };
