import React from 'react';
import { SkeletonLine } from '@one-thd/sui-atomic-components';

export const EMTPlaceholder = () => {
  return (
    <div
      className="sui-flex sui-flex-col sui-gap-4"
      data-component="EMTPlaceholder"
    >
      <SkeletonLine variant="heading" fullWidth />
      <div className="sui-grid sui-grid-cols-1 sui-gap-4 lg:sui-grid-cols-3 lg:sui-gap-6">
        <div className="sui-grow">
          <SkeletonLine variant="multi" numberOfLines={8} />
        </div>
        <div className="sui-grow">
          <SkeletonLine variant="multi" numberOfLines={8} />
        </div>
        <div className="sui-grow">
          <SkeletonLine variant="multi" numberOfLines={8} />
        </div>
      </div>
    </div>
  );
};
