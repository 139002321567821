import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { node, object, shape as shapeProp } from 'prop-types';
import {
  Col, Row, Placeholder
} from '@thd-olt-component-react/core-ui';
import {
  Accordion, AccordionHeader, AccordionBody, Skeleton, SkeletonContent, SkeletonLine
} from '@one-thd/sui-atomic-components';
import {
  extend, params, string, useDataModel, QueryContext,
  QueryProvider, useLazyDataModel, shape, bool, arrayOf, number
} from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { useNavigate, useLocation, useParams } from '@thd-olt-component-react/router';
import { RoomVo, BathRenovation } from '@thd-olt-component-react/augmented-reality';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Buybox } from '@thd-olt-component-react/buybox';
import { PIPCalculator, WallAreaCalculator } from '@thd-olt-component-react/calculator';
import { DigitalTicket } from '@thd-olt-component-react/digital-ticket';
import { Discontinued } from '@thd-olt-component-react/discontinued';
import { EducationalSummary } from '@thd-olt-component-react/educational-summary';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { FeatureBasedRecommendationsImporter } from '@thd-olt-component-react/feature-based-recommendations';
import { FrequentlyBoughtTogether } from '@thd-olt-component-react/frequently-bought-together';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import {
  MediaGalleryWithHotspotsCarousel,
  MediaGalleryWithHotspotsMobile
} from '@thd-olt-component-react/media-gallery';
import { Metadata, ProductMetadata } from '@thd-olt-component-react/metadata';
import { CalcLink } from '@thd-olt-component-react/moving-calculator';
import { PackagesMiniVisual } from '@thd-olt-component-react/packages-mini';
import { FlooringDrawer } from '@thd-olt-component-react/flooring-picker';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductConfigurator } from '@thd-olt-component-react/product-configurator';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { ProductOverviewAccordionTitle } from '@thd-olt-component-react/product-overview';
import { ProductPageEventCountdownTimer } from '@thd-olt-component-react/event-countdown-timer';
import { Promotion, PromotionUrgencyMessaging } from '@thd-olt-component-react/promotion';
import { PromotionProductsAnchor } from '@thd-olt-component-react/promotion-products';
import { QuestionsAndAnswersAccordionTitle } from '@thd-olt-component-react/questions-and-answers';
import { RatingsAndReviewsAccordionTitle } from '@thd-olt-component-react/ratings-and-reviews';
import { RelatedSearch, PopularVariations } from '@thd-olt-component-react/related-search';
import { SpecificationsAccordionTitle } from '@thd-olt-component-react/specifications';
import {
  SponsoredPipBanner,
  SponsoredCarousel
} from '@thd-olt-component-react/sponsored-content';
import { TextAnExpert } from '@thd-olt-component-react/text-an-expert';
import {
  DynamicRecsWrapper,
  PipSemWrapper,
  RecentlyViewedWrapper
} from '@thd-olt-component-react/thd-recs-containers';
import { ThdRecsFbt } from '@thd-olt-component-react/thd-recs-fbt';
import { Sharebelt, ShareBeltPrint, ShareBeltShareButton, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import { PIPRedirector, DynamicComponent, AppContext } from '@thd-nucleus/app-render';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import {
  shouldDisplayPipInStock,
  shouldDisplayReferrerRecommendations,
  shouldDisplayVisuallySimilar,
  shouldDisplayBatterySystems,
  shouldDisplayPipCollections,
  shouldDisplayPipAlternativeRecs,
  shouldDisplayConfigurator,
  getAnchorSku,
  validatePipFlip,
  flipPip,
  shouldDisplayIrgaccessories,
  shouldDisplayIrgcollections,
  isBathCategory
} from './product-utils';
import { PMVAndFBT } from './PMVAndFBT';
import { HammerWrenchIcon, LeadingIcon } from '../pipflip';
import { HYDRATION_STANDARD_SCROLL_BUFFER, RECS_REQUEST_KEY } from '../../constants';
import '../../styles/product-page.scss';
import ZoneCard from './zone-card';
import {
  AisleBay, AisleBayLoad, AisleBayModel,
  DisclaimerMessage, DisclaimerMessageLoad, DisclaimerMessageModel,
  ConsumerCardMessaging, ConsumerCardMessagingLoad, ConsumerCardMessagingModel,
  SuperSku, SuperSkuLoad, SuperSkuModel,
  OrderSample, OrderSampleLoad, OrderSampleModel,
  GlobalCustomConfigurator, GlobalCustomConfiguratorLoad, GlobalCustomConfiguratorModel,
  AugmentedReality, AugmentedRealityLoad, AugmentedRealityModel,
  QuestionsAndAnswersAccordionBody, QuestionsAndAnswersAccordionBodyLoad, QuestionsAndAnswersAccordionBodyModel,
  RatingsAndReviewsAccordionBody, RatingsAndReviewsAccordionBodyLoad, RatingsAndReviewsAccordionBodyModel,
  SpecificationsAccordionBody, SpecificationsAccordionBodyLoad, SpecificationsAccordionBodyModel,
  ProductOverviewAccordionBody, ProductOverviewAccordionBodyLoad, ProductOverviewAccordionBodyModel,
  ReturnsMessaging, ReturnsMessagingLoad, ReturnsMessagingModel, MagicApronBotPrompt, MagicApronBotPromptLoad
} from './dynamic-imports';

const ChildMap = ({ children }) => {
  return <Row><Col nopadding>{children}</Col></Row>;
};

ChildMap.propTypes = {
  children: node.isRequired,
};

export const ProductMobile = (props) => {
  declareContexts([QueryContext, ExperienceContext, CustomerContext]);
  const thdCustomer = useThdCustomer();
  const { cookieUtils } = props;
  const [hasPipFlip, setHasPipFlip] = useState(false);
  const {
    configuredProductLabel,
  } = useLifeCycleEventBus('configurator.configurator_sticky_nav_event');
  const productId = useParams()?.itemId;
  const [itemId, setItemId] = useState(productId);
  const ctx = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const qctx = useContext(QueryContext);
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const {
    channel, segment = '', isDebugMode, isServer, isConsumerApp, referer
  } = ctx;
  const viewReferer = shouldDisplayReferrerRecommendations(referer);
  const storeId = useStoreId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const fsRecsPipCollection = useConfigService('fs:isRecsCollectionEnable');
  const fsFilmstrip = useConfigService('fs:isFilmstripEnable');

  const fspipRecsPositionAboveABTest = useConfigService('fs:pipRecsPositionAbove');
  const fspipRecsPositionBelowABTest = useConfigService('fs:pipRecsPositionBelow');
  const fspipRecsPositionDefaultABTest = useConfigService('fs:pipRecsPositionDefault');
  const magicApronQABotEnabled = useConfigService('fs-prop:magic-apron-qa-bot-enabled');

  const RATINGS_AND_REVIEWS_OFFSET = 250;
  const pipRecsABTest = fspipRecsPositionAboveABTest || fspipRecsPositionBelowABTest || fspipRecsPositionDefaultABTest;
  const arRef = useRef(null);

  const theme = useTheme(ProductMobile);
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const {
    hideConsumerCardMessaging,
    hideReturnsMessaging,
    hideSponsoredCarousel,
    hideShareBeltShareButton,
    hideAddToList
  } = theme.props;

  const handleARClick = ({ media }) => {
    arRef.current.click();
  };

  const positionRef = useRef({ lastScrollPosition: 0 });

  // eslint-disable-next-line consistent-return
  const handleScroll = useCallback(({ headerOffset }) => {
    const header = document.querySelector('.Header3');
    if (!header) return null;

    const { lastScrollPosition } = positionRef.current;
    const scrollPosition = window?.pageYOffset || document?.documentElement?.scrollTop;

    if (scrollPosition > lastScrollPosition && scrollPosition > headerOffset) {
      header.classList.add('Header3__header--hide');
    } else {
      header.classList.add('Header3__header--slide');
      header.classList.remove('Header3__header--hide');
    }
    positionRef.current.lastScrollPosition = scrollPosition <= 0 ? 0 : scrollPosition;
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const headerOffset = document.querySelector('.Header3')
        ? document.querySelector('.Header3').offsetHeight
        : 0;
      window.addEventListener('scroll', () => { handleScroll({ headerOffset }); });
      return () => window.removeEventListener('scroll', () => { handleScroll({ headerOffset }); });
    }
  }, []);

  const updateStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (!el) return;
    const hideClasses = ['Header3__header--hide', 'Header3__header--slide'];
    el.classList.add('Header3-sticky');
    el.classList.remove(...hideClasses);
  };

  useEffect(() => {
    updateStickyHeader();
  }, []);

  const onChange = (data = {}) => {
    const { canonicalUrl } = data;
    if (canonicalUrl) {
      navigate(canonicalUrl, { state: { disableScroll: true } });
    }
  };

  const [productRequest, productResponse] = useLazyDataModel('product', {
    fetchPolicy: 'cache-first',
    variables: { storeId, dataSource: 'catalog' }
  });
  const [productRequestClient, productResponseClient] = useLazyDataModel('clientOnlyProduct', {
    fetchPolicy: 'cache-first',
    ssr: false,
    variables: { storeId, dataSource: 'catalog' }
  });

  const { data: productData, loading, error } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
      isBrandPricingPolicyCompliant: isCustomerIdentified
    },
    skip: !itemId,
    ssr: false
  });

  const anchorSku = getAnchorSku(productData, itemId);
  const pipFlipValidation = validatePipFlip(productData, hasPipFlip);
  const hasPipFlipCookie = (typeof document !== 'undefined' && cookieUtils)
    ? cookieUtils.fed_ReadCookie('tnt_pipflip')
    : null;
  const triggerPipFlip = () => flipPip(
    configuredProductLabel,
    productData,
    hasPipFlip,
    productId,
    setHasPipFlip,
    itemId,
    anchorSku,
    setItemId
  );

  useEffect(() => {
    if (productId !== itemId && !hasPipFlip) {
      setItemId(productId);
    }
  }, [productId]);

  const isPackage = !!productData?.product?.info?.recommendationFlags?.packages;

  const hideRatingsIfHDPPSku = true;
  const isHDPPSku = productData?.product?.identifiers?.skuClassification === 'hdpp';

  const displayConfigurator = shouldDisplayConfigurator(productData, isServer);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_omsId_event', itemId);
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.hide_quantity', displayConfigurator);
  }, [productData]);

  const isPaint = productData?.product?.info?.dotComColorEligible;

  const [expanded, setExpanded] = useState({});

  const handleAccordionChange = (accordion, canCloseAccordion = true) => {
    if (!expanded[accordion] || canCloseAccordion) {
      setExpanded((prevState) => ({ ...prevState, [accordion]: !(prevState[accordion]) }));
    }
  };

  const customExperience = productData?.product?.info?.globalCustomConfigurator?.customExperience;
  const customTitle = productData?.product?.info?.globalCustomConfigurator?.customTitle;
  const caretImg = 'https://assets.thdstatic.com/images/v1/caret-orange.svg';
  const caretHoverImg = 'https://assets.thdstatic.com/images/v1/caret-white.svg';
  const [caretImgSrc, setCaretImgSrc] = useState(caretImg);

  return (
    <>
      <ErrorBoundary name="redirector">
        <PIPRedirector disablePEPRedirect disableCanonicalRedirect disableCMMMCRedirect />
      </ErrorBoundary>
      <ErrorBoundary name="product-metadata">
        <Metadata>
          <ProductMetadata itemId={itemId} hideIfHDPPSku={hideRatingsIfHDPPSku} />
        </Metadata>
      </ErrorBoundary>
      <ErrorBoundary name="pip-content">
        <Row className="isBound">
          <Row className="u--paddingTop u--paddingBottom">
            <ErrorBoundary name="breadcrumb-sharebelt-grp">
              <Col sm="10" flatten>
                <ErrorBoundary name="breadcrumbs">
                  <Breadcrumbs
                    itemId={itemId}
                    useStencilDesign
                  />
                </ErrorBoundary>
              </Col>
              <Col sm="2" flatten>
                <ErrorBoundary name="sharebelt">
                  <Sharebelt horizontal itemId={itemId}>
                    { !hideShareBeltShareButton && <ShareBeltShareButton /> }
                    <ShareBeltPrint />
                  </Sharebelt>
                </ErrorBoundary>
              </Col>
            </ErrorBoundary>
          </Row>
          <Row>
            <ErrorBoundary name="breadcrumb-sharebelt-grp">
              <Col sm="12" flatten>
                <ErrorBoundary name="eventbreadcrumbs">
                  <Breadcrumbs
                    itemId={itemId}
                    shouldShowEventOnly
                    useStencilDesign
                  />
                </ErrorBoundary>
              </Col>
            </ErrorBoundary>
          </Row>
          {(fspipRecsPositionAboveABTest || (!pipRecsABTest && viewReferer)) && (
            <ZoneCard>
              <PipSemWrapper
                schemaName="pipsem"
                anchorId={itemId}
                errorBoundary
                hideATC
                withReferrer={viewReferer}
              />
            </ZoneCard>
          )}
          <Row>
            <ErrorBoundary name="zone-a">
              <Col flatten nopadding={channel === 'desktop'}>
                <Row>
                  <Col>
                    <Discontinued itemId={itemId} />
                  </Col>
                  <Col sm="7" nopadding className="product--sticky">
                    <ZoneCard className="zone-card overflow u__clearfix">
                      <Col sm="12">
                        <ErrorBoundary name="product-details">
                          <ProductDetails itemId={itemId}>
                            <ProductDetails.Badge itemId={itemId} />
                            <ProductDetails.BrandCollection
                              showBrandHyperLink
                              hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
                            />
                            <div className="product-details__badge-title--wrapper">
                              <ProductDetails.Title />
                            </div>
                            {!hideAddToList && (
                              <div className="product-details__favorites">
                                <Sharebelt itemId={itemId}>
                                  <ShareFavorite showCircle showFavoritesCount />
                                </Sharebelt>
                              </div>
                            )}
                            {!isHDPPSku
                              && (
                                <ProductDetails.Ratings
                                  target="#product-section-rr"
                                  offsetselector="#sticky-nav"
                                  showBadge
                                  onClick={() => handleAccordionChange('ratings-and-reviews', false)}
                                  qaselector="#product-section-qa"
                                  hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
                                />
                              )}
                          </ProductDetails>
                        </ErrorBoundary>
                      </Col>
                      <Col sm="12">
                        <ErrorBoundary>
                          {
                            !fsFilmstrip
                              ? (
                                <MediaGalleryWithHotspotsMobile
                                  itemId={itemId}
                                  showLoader
                                  onMediaClick={handleARClick}
                                />
                              ) : (
                                <MediaGalleryWithHotspotsCarousel
                                  itemId={itemId}
                                  overlayType="filmstrip"
                                  showLoader
                                  firstItemFetchPriority="high"
                                />
                              )
                          }
                        </ErrorBoundary>
                      </Col>
                    </ZoneCard>
                    <ErrorBoundary name="roomvo">
                      <Col className="zone-card overflow">
                        <RoomVo
                          itemId={itemId}
                          queryVariables={{
                            itemId,
                            storeId
                          }}
                        />
                      </Col>
                    </ErrorBoundary>

                    <ErrorBoundary name="augmented-reality">
                      <DynamicComponent pageType="pip">
                        <Hydrator
                          id="product-section-ar"
                          delay={1500}
                          scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                          preserveCtxVal="clientStore"
                          waitFor={AugmentedRealityLoad}
                        >
                          <Col className="zone-card overflow">
                            <AugmentedReality
                              itemId={itemId}
                              instructions
                              forwardedRef={arRef}
                              qctx={qctx}
                            />
                          </Col>
                        </Hydrator>
                      </DynamicComponent>
                    </ErrorBoundary>

                  </Col>
                  <Col sm="5" flatten>
                    <Row>
                      <ZoneCard nopadding tag={Col}>
                        <ErrorBoundary name="event-countdown-timer">
                          <ProductPageEventCountdownTimer itemId={itemId} />
                        </ErrorBoundary>
                      </ZoneCard>
                      <Col className="zone-card overflow">
                        <PromotionUrgencyMessaging
                          itemId={itemId}
                        />
                        <ErrorBoundary name="price">
                          <Price
                            channel="desktop"
                            displayEachUom={false}
                            itemId={itemId}
                            large
                            disableRangePricing
                            showProjectPrice
                            clsRemediation={{
                              placeholders: true,
                              preservePlaceholders: true
                            }}
                          />
                        </ErrorBoundary>
                      </Col>
                      <Col className="zone-card overflow promotion__card">
                        <ErrorBoundary name="promotion">
                          <Promotion
                            hideConditionalPromoDescription
                            itemId={itemId}
                          />
                        </ErrorBoundary>
                      </Col>
                      <ErrorBoundary
                        name="disclaimer-message"
                      >
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-dm"
                            className="grid"
                            delay={1500}
                            scrollBuffer={10}
                            preserveCtxVal="clientStore"
                            waitFor={DisclaimerMessageLoad}
                          >
                            <Col className="zone-card overflow">
                              <DisclaimerMessage itemId={itemId} qctx={qctx} />
                            </Col>
                          </Hydrator>
                        </DynamicComponent>
                      </ErrorBoundary>

                      {
                        !hideConsumerCardMessaging
                        && (
                          <ErrorBoundary name="consumer-card-messaging">
                            <DynamicComponent pageType="pip">
                              <Hydrator
                                id="product-section-ccm"
                                className="grid"
                                delay={1500}
                                scrollBuffer={10}
                                preserveCtxVal="clientStore"
                                waitFor={ConsumerCardMessagingLoad}
                              >
                                <Col className="zone-card overflow consumer-credit-messaging__card">
                                  <ConsumerCardMessaging
                                    itemId={itemId}
                                    clsRemediation={{
                                      placeholders: true,
                                      preservePlaceholders: true
                                    }}
                                    qctx={qctx}
                                  />
                                </Col>
                              </Hydrator>
                            </DynamicComponent>
                          </ErrorBoundary>
                        )
                      }
                      <Col nopadding className="zone-card overflow">
                        <ErrorBoundary name="flooring-picker">
                          <FlooringDrawer itemId={itemId} />
                        </ErrorBoundary>
                      </Col>

                      <ErrorBoundary name="promotion-products">
                        <Col className="zone-card overflow">
                          <PromotionProductsAnchor itemId={itemId} />
                        </Col>
                      </ErrorBoundary>

                      <ErrorBoundary name="gcc">
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-gcc"
                            className="grid"
                            delay={1500}
                            scrollBuffer={10}
                            preserveCtxVal="clientStore"
                            waitFor={GlobalCustomConfiguratorLoad}
                          >
                            <Col className="zone-card overflow">
                              <GlobalCustomConfigurator itemId={itemId} qctx={qctx} />
                            </Col>
                          </Hydrator>
                        </DynamicComponent>
                      </ErrorBoundary>

                      <ErrorBoundary name="moving-calculator">
                        <Col className="zone-card overflow sui-p-3">
                          <CalcLink itemId={itemId} qctx={qctx} />
                        </Col>
                      </ErrorBoundary>

                      <ErrorBoundary name="aisle-bay">
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-ab"
                            className="grid"
                            delay={1500}
                            scrollBuffer={10}
                            preserveCtxVal="clientStore"
                            waitFor={AisleBayLoad}
                          >
                            <Col className="zone-card overflow hide-empty">
                              <AisleBay
                                itemId={itemId}
                                clsRemediation={{
                                  placeholders: true,
                                  preservePlaceholders: false
                                }}
                                qctx={qctx}
                              />
                            </Col>
                          </Hydrator>
                        </DynamicComponent>
                      </ErrorBoundary>
                      {displayConfigurator
                        && (
                          <Col className="zone-card overflow">
                            <ErrorBoundary name="product-configurator">
                              <ProductConfigurator
                                pipFlip={triggerPipFlip}
                                itemId={itemId}
                                hasPipFlip={hasPipFlip}
                              />
                            </ErrorBoundary>
                          </Col>
                        )}
                      <ErrorBoundary name="super-sku">
                        {!displayConfigurator && (
                          <DynamicComponent pageType="pip">
                            <Hydrator
                              id="product-section-ss"
                              className="grid"
                              delay={1500}
                              scrollBuffer={10}
                              preserveCtxVal="clientStore"
                              waitFor={SuperSkuLoad}
                            >
                              <Col className="zone-card overflow">
                                <SuperSku
                                  itemId={productId}
                                  onChange={onChange}
                                  large={isPaint}
                                  centeredCards={isPaint}
                                  disableMediaAndAvailability={isPaint}
                                  qctx={qctx}
                                  hideCards={!isPaint}
                                  containerSizeHref="wall-area-calculator"
                                />
                                {hasPipFlipCookie && pipFlipValidation
                                  && (
                                    <div>
                                      <div className="attribute__label">
                                        <div className="label">
                                          Customize:
                                        </div>
                                      </div>
                                      <button
                                        type="button"
                                        className="super-sku__inline-tile border-radius--medium"
                                        onClick={triggerPipFlip}
                                        style={{ marginTop: 5, alignItems: 'center' }}
                                      >
                                        {HammerWrenchIcon}
                                        {hasPipFlipCookie === 'A' && (
                                          <span style={{ paddingLeft: 10 }}>
                                            Create Your Own
                                          </span>
                                        )}
                                        {hasPipFlipCookie === 'B' && (
                                          <span style={{ paddingLeft: 10 }}>
                                            See Additional Options
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                {pipFlipValidation
                                  && customExperience === 'stock-blinds-pipflip' && !customTitle && (
                                  <div>
                                    <div className="attribute__label">
                                      <div className="label">
                                        Customize:
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="sui-btn sui-btn-secondary sui-btn-secondary-focus
                                      sui-btn-secondary-active sui-btn-secondary-hover
                                      sui-font-bold sui-text-base sui-leading-normal sui-tracking-normal
                                      sui-normal-case sui-line-clamp-unset"
                                      onClick={triggerPipFlip}
                                      style={{ marginTop: 5, alignItems: 'center' }}
                                      onMouseEnter={() => setCaretImgSrc(caretHoverImg)}
                                      onMouseLeave={() => setCaretImgSrc(caretImg)}
                                    >
                                      {LeadingIcon}
                                      <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        See More Custom Sizes
                                      </span>
                                      <img
                                        src={caretImgSrc}
                                        alt="caret"
                                        height="16"
                                        width="16"
                                        loading="lazy"
                                        style={{ height: '16px', width: '16px' }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </Col>
                            </Hydrator>
                          </DynamicComponent>
                        )}
                      </ErrorBoundary>
                      <ErrorBoundary name="order-sample">
                        <DynamicComponent pageType="pip">
                          <Hydrator
                            id="product-section-os"
                            className="grid"
                            delay={1500}
                            scrollBuffer={10}
                            preserveCtxVal="clientStore"
                            waitFor={OrderSampleLoad}
                          >
                            <Col className="zone-card overflow">
                              <OrderSample itemId={itemId} qctx={qctx}>
                                Need a closer look?
                              </OrderSample>
                            </Col>
                          </Hydrator>
                        </DynamicComponent>
                      </ErrorBoundary>
                      <Col flatten>
                        <ErrorBoundary name="buybox">
                          <Buybox
                            itemId={itemId}
                            hideProReferral={thdCustomer?.isExchangeCustomer}
                            renderCalculator={(buyboxItemId, onCalculate) => {
                              return (
                                <>
                                  <WallAreaCalculator
                                    itemId={buyboxItemId}
                                  />
                                  <PIPCalculator
                                    itemId={buyboxItemId}
                                    onCalculate={onCalculate}
                                  />
                                </>
                              );
                            }}
                            sticky={!isConsumerApp}
                            showCards
                          />
                        </ErrorBoundary>
                      </Col>
                      {
                        !hideReturnsMessaging
                        && (
                          <ErrorBoundary name="returns-messaging">
                            <DynamicComponent pageType="pip">
                              <Hydrator
                                id="product-section-rm"
                                className="grid"
                                delay={1500}
                                scrollBuffer={50}
                                preserveCtxVal="clientStore"
                                waitFor={ReturnsMessagingLoad}
                              >
                                <ReturnsMessaging qctx={qctx} itemId={itemId} displayTimeframe />
                              </Hydrator>
                            </DynamicComponent>
                          </ErrorBoundary>
                        )
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </ErrorBoundary>
          </Row>
          {
            fspipRecsPositionBelowABTest && (
              <ZoneCard>
                <PipSemWrapper
                  schemaName="pipsem"
                  anchorId={itemId}
                  errorBoundary
                  hideATC
                  withReferrer={viewReferer}
                />
              </ZoneCard>
            )
          }
          {!displayConfigurator && (
            <PMVAndFBT
              itemId={itemId}
              productData={productData}
              isPackage={isPackage}
              referer={referer}
            />
          )}
          {isBathCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipcollection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                showColUpLevel
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hideSwatches={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_pipcollection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          <ErrorBoundary name="collapsible-sections">
            <DynamicComponent pageType="pip">
              <Hydrator
                id="collapsible-sections-accordion"
                className="grid"
                placeholder={(
                  <>
                    <div className="sui-flex sui-flex-col sui-w-full sui-gap-2">
                      <div
                        id="product-section-overview"
                        className="accordion-placeholder"
                      >
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-key-feat" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-qa" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                      <div id="product-section-rr" className="accordion-placeholder">
                        <Skeleton grow>
                          <SkeletonContent disablePadding>
                            <SkeletonLine
                              numberOfLines={2}
                              fullWidth
                            />
                          </SkeletonContent>
                        </Skeleton>
                      </div>
                    </div>
                  </>
                )}
              >
                <div className="sui-flex sui-flex-col sui-w-full">
                  <Accordion
                    key="product-details-accordion"
                    expanded={expanded['product-details'] || thdSeoBotDetected}
                    onChange={() => handleAccordionChange('product-details')}
                  >
                    <AccordionHeader>
                      <ProductOverviewAccordionTitle />
                    </AccordionHeader>
                    <AccordionBody>
                      <DynamicComponent pageType="pip">
                        <Hydrator
                          id="product-section-product-overview"
                          className="grid"
                          waitFor={ProductOverviewAccordionBodyLoad}
                          placeholder={(
                            <LoadingPlaceholder
                              color="#f5f5f5"
                              ready={false}
                              showLoadingAnimation
                              style={{ height: 400 }}
                              type="rect"
                            >
                              <div />
                            </LoadingPlaceholder>
                          )}
                        >
                          <ProductOverviewAccordionBody
                            itemId={itemId}
                            showReturnMessage
                            revJet
                            layoutGroup={{
                              tag: Row,
                            }}
                            qctx={qctx}
                          />
                        </Hydrator>
                      </DynamicComponent>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    key="specifications-accordion"
                    expanded={expanded?.specifications || thdSeoBotDetected}
                    onChange={() => handleAccordionChange('specifications')}
                  >
                    <AccordionHeader>
                      <div className="sui-flex sui-flex-col">
                        <SpecificationsAccordionTitle itemId={itemId} />
                      </div>
                    </AccordionHeader>
                    <AccordionBody>
                      <DynamicComponent pageType="pip">
                        <Hydrator
                          id="product-section-specifications"
                          className="grid"
                          waitFor={SpecificationsAccordionBodyLoad}
                          placeholder={(
                            <LoadingPlaceholder
                              color="#f5f5f5"
                              ready={false}
                              showLoadingAnimation
                              style={{ height: 400 }}
                              type="rect"
                            >
                              <div />
                            </LoadingPlaceholder>
                          )}
                        >
                          <SpecificationsAccordionBody
                            itemId={itemId}
                            layoutGroup={{
                              tag: Row,
                            }}
                            qctx={qctx}
                            oneColumn
                            isWhiteSpecTitle
                          />
                        </Hydrator>
                      </DynamicComponent>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    key="questions-answers-accordion"
                    id="product-section-qa"
                    expanded={expanded['questions-and-answers'] || thdSeoBotDetected}
                    onChange={() => handleAccordionChange('questions-and-answers')}
                  >
                    <AccordionHeader>
                      <QuestionsAndAnswersAccordionTitle
                        noSpacing
                        itemId={itemId}
                        layoutGroup={{
                          tag: Row,
                        }}
                        hydrator={{
                          className: 'grid'
                        }}
                        dynamic={{
                          pageType: 'pip'
                        }}
                      />
                    </AccordionHeader>
                    <AccordionBody>
                      <DynamicComponent pageType="pip">
                        <Hydrator
                          id="product-section-questions-answers"
                          className="grid"
                          waitFor={QuestionsAndAnswersAccordionBodyLoad}
                          placeholder={(
                            <LoadingPlaceholder
                              color="#f5f5f5"
                              ready={false}
                              showLoadingAnimation
                              style={{ height: 400 }}
                              type="rect"
                            >
                              <div />
                            </LoadingPlaceholder>
                          )}
                        >
                          <QuestionsAndAnswersAccordionBody
                            itemId={itemId}
                            seoPageNumber={1}
                            layoutGroup={{
                              tag: Row,
                            }}
                            qctx={qctx}
                          />
                        </Hydrator>
                      </DynamicComponent>
                    </AccordionBody>
                  </Accordion>
                  {!isHDPPSku
                    && (
                      <div
                        id="product-section-rr"
                        className="product-section-rr"
                      >
                        <Accordion
                          key="ratings-reviews-accordion"
                          expanded={expanded['ratings-and-reviews'] || thdSeoBotDetected}
                          onChange={() => handleAccordionChange('ratings-and-reviews')}
                        >
                          <AccordionHeader>
                            <RatingsAndReviewsAccordionTitle
                              noSpacing
                              itemId={itemId}
                              layoutGroup={{
                                tag: Row,
                              }}
                              hydrator={{
                                className: 'grid'
                              }}
                              dynamic={{
                                pageType: 'pip'
                              }}
                            />
                          </AccordionHeader>
                          <AccordionBody>
                            <DynamicComponent pageType="pip">
                              <Hydrator
                                id="product-section-ratings-reviews"
                                className="grid"
                                waitFor={RatingsAndReviewsAccordionBodyLoad}
                                placeholder={(
                                  <LoadingPlaceholder
                                    color="#f5f5f5"
                                    ready={false}
                                    showLoadingAnimation
                                    style={{ height: 400 }}
                                    type="rect"
                                  >
                                    <div />
                                  </LoadingPlaceholder>
                                )}
                              >
                                <RatingsAndReviewsAccordionBody
                                  itemId={itemId}
                                  qctx={qctx}
                                  prosAndCons
                                />
                              </Hydrator>
                            </DynamicComponent>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    )}
                </div>
              </Hydrator>
            </DynamicComponent>
          </ErrorBoundary>
          {magicApronQABotEnabled && (
            <ErrorBoundary name="magic-apron-qa-bot">
              <DynamicComponent pageType="pip">
                <Hydrator
                  id="product-section-magic-apron-qa-bot"
                  className="grid"
                  scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                  waitFor={MagicApronBotPromptLoad}
                  placeholder={<Placeholder width="100%" height="276px" color="#F5F5F5" />}
                >
                  <MagicApronBotPrompt qctx={qctx} itemId={itemId} />
                </Hydrator>
              </DynamicComponent>
            </ErrorBoundary>
          )}
          <ErrorBoundary name="bath-renovation-banner">
            <DynamicComponent pageType="pip">
              <Hydrator
                id="product-section-bath-renovation-banner"
                className="grid"
                scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                placeholder={(
                  <LoadingPlaceholder
                    color="#f5f5f5"
                    ready={false}
                    showLoadingAnimation
                    style={{ height: 334 }}
                    type="rect"
                  >
                    <div />
                  </LoadingPlaceholder>
                )}
              >
                <ZoneCard className="zone-card">
                  <BathRenovation itemId={itemId} />
                </ZoneCard>
              </Hydrator>
            </DynamicComponent>
          </ErrorBoundary>
          {shouldDisplayPipInStock(productData, referer) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipinstock"
                anchorId={itemId}
                errorBoundary
                requestKey={RECS_REQUEST_KEY}
                hideATC
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'pipinstock',
                  scrollBuffer: 500
                }}
              />
            </Row>
          )}
          <SponsoredPipBanner
            browserId={thdCustomer?.mcvisID || ''}
            pageContext={{
              label: 'pip',
              data: {
                itemId,
                treatment: 'A'
              },
            }}
            layoutGroup={{
              name: 'pip-sponsored-banner-A'
            }}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              id: 'hydrated-sponsored-pip-banner-A'
            }}
          />
          <Row>
            <ErrorBoundary name="educational-summary">
              <ZoneCard>
                <EducationalSummary
                  itemId={itemId}
                  qctx={qctx}
                  layoutGroup={{
                    tag: Row,
                  }}
                  dynamic={{
                    pageType: 'pip'
                  }}
                  hydrator={{
                    className: 'grid'
                  }}
                />
              </ZoneCard>
            </ErrorBoundary>
          </Row>
          {shouldDisplayBatterySystems(productData) ? (
            <Row>
              <DynamicRecsWrapper
                schemaName="battery_collection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                isBatteryCollection
                hideFavorites={false}
                maxResults="18"
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'battery_collection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          ) : !isBathCategory(productData) && fsRecsPipCollection && shouldDisplayPipCollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pipcollection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isCollection
                isCategoryTab
                showColUpLevel
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hideSwatches={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_pipcollection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {shouldDisplayIrgcollections(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="brand_based_collection"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isIRG
                isCategoryTab
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_brand_based_collection',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {shouldDisplayIrgaccessories(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="irgaccessories"
                anchorId={itemId}
                requestKey={RECS_REQUEST_KEY}
                isIRG
                errorBoundary
                maxResults="18"
                hideFavorites={false}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'recs_irgaccessories',
                  delay: 1500,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
          )}
          {
            !hideSponsoredCarousel
            && (
              <ErrorBoundary name="sponsored-carousel-nucleus">
                <SponsoredCarousel
                  breadCrumbs={productData?.product?.taxonomy?.breadCrumbs}
                  pageContext={{
                    schema: 'pip_sponsored',
                    data: { itemId },
                    isPip: true
                  }}
                  showSponsoredCarousel
                  dynamic={{
                    pageType: 'pip'
                  }}
                  hydrator={{
                    className: 'grid',
                    tag: ZoneCard,
                    scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                  }}
                />
              </ErrorBoundary>
            )
          }
          <Row>
            <FeatureBasedRecommendationsImporter
              hideOnMobile
              itemId={itemId}
              qctx={qctx}
              errorBoundary
              hydrator={{
                tag: ZoneCard,
                className: 'grid'
              }}
              dynamic={{
                pageType: 'pip',
              }}
            />
          </Row>
          {(fspipRecsPositionDefaultABTest || (!pipRecsABTest && shouldDisplayPipAlternativeRecs(productData, referer)))
          && (
            <Row>
              <DynamicRecsWrapper
                schemaName="pip_alternatives"
                anchorId={itemId}
                errorBoundary
                requestKey={RECS_REQUEST_KEY}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: fspipRecsPositionDefaultABTest ? 'RecsDefault' : 'pip_alternatives',
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
                impressionData={{
                  component: 'DynamicRecs',
                  type: 'product'
                }}
              />
            </Row>
          )}
          {shouldDisplayVisuallySimilar(productData) && (
            <Row>
              <DynamicRecsWrapper
                schemaName="visuallysimilar_1_0"
                anchorImage={productData?.product?.media?.image?.url}
                anchorId={itemId}
                errorBoundary
                checkMinimumProducts
                minimumProductCount={4}
                requestKey={RECS_REQUEST_KEY}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'visuallysimilar_1_0',
                  scrollBuffer: 900
                }}
              />
            </Row>
          )}
          {!isConsumerApp && (
            <Row>
              <ErrorBoundary name="text-an-expert">
                <TextAnExpert />
              </ErrorBoundary>
            </Row>
          )}
          <Row>
            <QueryProvider
              defaultVariables={defaultVariables?.current}
              persist
              cacheKey="rv-pip-recs"
            >
              <RecentlyViewedWrapper
                errorBoundary
                schemaName="rv_gm_pip_rr"
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  id: 'rv_gm_pip_rr',
                  delay: 2000,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </QueryProvider>
          </Row>
          <RelatedSearch
            itemId={itemId}
            layoutGroup={{
              tag: ChildMap
            }}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              className: 'col__12-12'
            }}
          />
          <PopularVariations
            productId={itemId}
            dynamic={{
              pageType: 'pip'
            }}
            hydrator={{
              className: 'col__12-12'
            }}
          />
          <EmtLinks
            path={pathname}
            layoutGroup={{
              tag: Row
            }}
            dynamic={{
              pageType: 'pip',
            }}
            hydrator={{
              className: 'col__12-12 sui-mt-4'
            }}
          />
        </Row>
        <DigitalTicket itemId={itemId} />
      </ErrorBoundary>
    </>
  );
};

ProductMobile.displayName = 'ProductPageMobile';

ProductMobile.themeProps = {
  hideConsumerCardMessaging: bool,
  hideReturnsMessaging: bool,
  hideSponsoredCarousel: bool,
  hideShareBeltShareButton: bool,
  hideAddToList: bool
};

ProductMobile.defaultThemeProps = {
  hideConsumerCardMessaging: false,
  hideReturnsMessaging: false,
  hideSponsoredCarousel: false,
  hideShareBeltShareButton: false,
  hideAddToList: false
};

ProductMobile.propTypes = {
  cookieUtils: shapeProp({ object })
};

ProductMobile.defaultProps = {
  cookieUtils: {}
};

ProductMobile.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      fulfillment: params({ storeId: string(), zipCode: string() }).shape({
        backordered: bool()
      }),
      info: shape({
        dotComColorEligible: bool()
      }),
      identifiers: shape({
        skuClassification: string()
      })
    })
  },
  {
    clientOnlyProduct: params({ itemId: string().isRequired }).shape({
      itemId: string(),
      dataSources: string(),
      info: shape({
        gccExperienceOmsId: string(),
        recommendationFlags: shape({
          visualNavigation: bool(),
          pipCollections: bool(),
          packages: bool(),
          ACC: bool(),
          collections: bool(),
          frequentlyBoughtTogether: bool(),
          bundles: bool(),
          batItems: bool()
        }),
        dotComColorEligible: bool()
      }),
      identifiers: shape({
        roomVOEnabled: bool()
      }),
      availabilityType: shape({
        discontinued: bool(),
        type: string()
      }),
      fulfillment: params({ storeId: string() }).shape({
        backordered: bool(),
        fulfillmentOptions: arrayOf(shape({
          type: string(),
          fulfillable: bool(),
          services: arrayOf(shape({
            type: string(),
            locations: arrayOf(shape({
              isAnchor: bool(),
              inventory: shape({
                isLimitedQuantity: bool(),
                isOutOfStock: bool()
              })
            }))
          }))
        })),
      }),
    })
  },
  {
    clientOnlyProduct: params({ itemId: string().isRequired }).shape({
      seoDescription: string()
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string(),
      fulfillment: params({ storeId: string() }).shape({
        anchorStoreStatusType: string(),
        backordered: bool(),
        backorderedShipDate: string(),
        bossExcludedShipStates: string(),
        bodfsAssemblyEligible: bool(),
        seasonStatusEligible: bool(),
        excludedShipStates: string(),
        fulfillmentOptions: shape({
          fulfillable: bool(),
          messages: string(),
          services: shape({
            type: string(),
            isDefault: bool(),
            deliveryCharge: string(),
            deliveryDates: shape({
              endDate: string(),
              startDate: string(),
            }),
            deliveryTimeline: string(),
            freeDeliveryThreshold: string(),
            totalCharge: string(),
            locations: shape({
              type: string(),
              isAnchor: bool(),
              locationId: string(),
              storeName: string(),
              storePhone: string(),
              state: string(),
              distance: string(),
              inventory: shape({
                quantity: string(),
                isInStock: bool(),
                isOutOfStock: bool(),
                isLimitedQuantity: bool(),
                isUnavailable: bool(),
                maxAllowedBopisQty: string(),
                minAllowedBopisQty: string(),
              }),
            })
          }),
          type: string()
        }),
      }),
    })
  },
  BathRenovation,
  Breadcrumbs,
  Buybox,
  CalcLink,
  DigitalTicket,
  Discontinued,
  DynamicRecsWrapper,
  PipSemWrapper,
  EducationalSummary,
  EmtLinks,
  FrequentlyBoughtTogether,
  MediaGalleryWithHotspotsMobile,
  MediaGalleryWithHotspotsCarousel,
  PackagesMiniVisual,
  PIPCalculator,
  WallAreaCalculator,
  PIPRedirector,
  Price,
  ProductPageEventCountdownTimer,
  ProductDetails,
  ProductConfigurator,
  ProductMetadata,
  Promotion,
  PromotionProductsAnchor,
  RecentlyViewedWrapper,
  RelatedSearch,
  ReturnsMessagingModel,
  PopularVariations,
  Sharebelt,
  ThdRecsFbt,
  FeatureBasedRecommendationsImporter,
  AisleBayModel,
  AugmentedRealityModel,
  ConsumerCardMessagingModel,
  DisclaimerMessageModel,
  GlobalCustomConfiguratorModel,
  OrderSampleModel,
  SuperSkuModel,
  SpecificationsAccordionTitle,
  SpecificationsAccordionBodyModel,
  ProductOverviewAccordionTitle,
  ProductOverviewAccordionBodyModel,
  QuestionsAndAnswersAccordionTitle,
  QuestionsAndAnswersAccordionBodyModel,
  RatingsAndReviewsAccordionTitle,
  RatingsAndReviewsAccordionBodyModel,
  PriceClearanceDataModel
);
