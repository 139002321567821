import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SelectionControlBase } from '../private/components/selection/SelectionControlBase';
import { CheckboxChecked } from '../private/icons/CheckboxChecked';
import { CheckboxUnchecked } from '../private/icons/CheckboxUnchecked';
import { CheckboxIndeterminate } from '../private/icons/CheckboxIndeterminate';

const IndeterminateIcon = ({ disabled, spanClasses }) => (
  <span className={`${spanClasses} ${disabled ? 'sui-fill-brand-subtle' : 'sui-fill-brand'}`}>
    <CheckboxIndeterminate size="inherit" backgroundColor="primary" color="inherit" />
  </span>
);

IndeterminateIcon.propTypes = {
  disabled: PropTypes.bool,
  spanClasses: PropTypes.string
};

const CheckedIcon = ({ disabled, spanClasses }) => (
  <span className={`${spanClasses} ${disabled ? 'sui-fill-brand-subtle' : 'sui-fill-brand'}`}>
    <CheckboxChecked size="inherit" backgroundColor="primary" color="inherit" />
  </span>
);

CheckedIcon.propTypes = {
  disabled: PropTypes.bool,
  spanClasses: PropTypes.string
};

const UncheckedIcon = ({ disabled, spanClasses }) => (
  <span className={`${spanClasses} ${disabled ? 'sui-fill-subtle' : 'sui-fill-medium peer-hover:sui-fill-unchecked-strong'}`}>
    <CheckboxUnchecked size="inherit" color="inherit" backgroundColor={disabled ? 'inactive' : 'primary'} />
  </span>
);

UncheckedIcon.propTypes = {
  disabled: PropTypes.bool,
  spanClasses: PropTypes.string
};

/**
 * A `Checkbox` can be used to turn features on/off or can be a list of choices
 * where the user can select multiple options.
 *
 * See related components: [FormGroup](#formgroup), [SelectionControlLabel](#selectioncontrollabel), [Radio](#radio),
 * [Toggle](#toggle), [Tile](#tile)
 *
 * Usage:
 *
 * ```jsx
 * import { Checkbox } from '@one-thd/sui-atomic-components';
 * ```
 */
const Checkbox = React.forwardRef((props, ref) => {

  const { indeterminate, disabled, ...other } = props;

  const spanClasses = classNames('sui-inline-flex sui-align-center sui-justify-center sui-relative sui-box-border sui-bg-transparent sui-align-middle sui-outline-0 sui-border-0 sui-m-0 sui-select-none sui-appearance-none sui-no-underline sui-p-1', {
    'sui-cursor-pointer sui-tap-highlight-transparent': !disabled,
    'sui-cursor-default sui-pointer-events-none': disabled
  });

  const classes = {
    span: spanClasses,
    input: 'sui-peer sui-cursor-inherit sui-absolute sui-opacity-0 sui-w-full sui-h-full sui-top-0 sui-left-0 sui-m-0 sui-p-0 sui-z-10'
  };

  const iconSpanClasses = classNames('sui-relative sui-flex sui-p-1 sui-bg-transparent sui-text-xl',
    {
      'peer-hover:sui-bg-brand-subtle peer-focus-visible:sui-fill-primary peer-focus-visible:sui-bg-warning': !disabled
    });

  let checkedIcon;
  let uncheckedIcon;

  if (indeterminate) {
    checkedIcon = <IndeterminateIcon disabled={disabled} spanClasses={iconSpanClasses} />;
    uncheckedIcon = <IndeterminateIcon disabled={disabled} spanClasses={iconSpanClasses} />;
  } else {
    checkedIcon = <CheckedIcon disabled={disabled} spanClasses={iconSpanClasses} />;
    uncheckedIcon = <UncheckedIcon disabled={disabled} spanClasses={iconSpanClasses} />;
  }

  return (
    <SelectionControlBase
      type="checkbox"
      classes={classes}
      disabled={disabled}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      indeterminate={indeterminate}
      role="checkbox"
      ref={ref}
      {...other}
    />
  );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.bool,
  /**
   * The default checked value for the checkbox element.
   */
  defaultChecked: PropTypes.bool,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The id attribute that is added to the checkbox element
   */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * If `true`, the component is shown is an indeterminate state: neither checked nor unchecked.
   */
  indeterminate: PropTypes.bool,
  /**
   * Attributes that are added to the checkbox element.
   */
  inputAttributes: PropTypes.object,
  /**
   * The onChange event handler that is added to the checkbox element.
   */
  onChange: PropTypes.func,
  /**
   * The value attribute that is added to the checkbox element.
   */
  value: PropTypes.string
};

Checkbox.defaultProps = {
  checked: undefined,
  defaultChecked: false,
  id: null,
  indeterminate: null,
  inputAttributes: null,
  disabled: undefined,
  onChange: null,
  value: undefined
};

export { Checkbox };
