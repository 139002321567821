import React from 'react';
import {
  array,
  bool,
  string,
  oneOfType,
  shape,
  number,
  arrayOf,
  func
} from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import '../buybox.scss';

const Paypal = ({
  cartOptions,
  cartReqParams,
  disabled,
  customATC
}) => {

  return (
    <>
      <div className={`paypal-mobile__wrapper ${disabled ? 'paypal__disable--wrapper' : ''}`}>
        <div>
          <span className="paypal-mobile__dash" /> or <span className="paypal-mobile__dash" />
        </div>
        <div>
          <Button
            link
            onClick={(event) => {
              if (!disabled) {
                if (customATC) {
                  customATC({ ...cartOptions, paypal: true });
                } else {
                  window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
                    cartReqParams,
                    cartOptions: { ...cartOptions, paypal: true }
                  });
                }
              }
            }}
          >
            <div className="sub-buybox-mobile__content">
              <span className="sub-buybox__paypal--text"> Buy now with</span>
              <span className={`sub-buybox__paypal ${disabled ? 'paypal--disabled' : ''}`} />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

Paypal.propTypes = {
  cartOptions: shape({
    directCheckout: bool,
    host: string,
    misship: bool,
    paypal: bool
  }),
  cartReqParams: oneOfType([
    array,
    shape({
      attachedLabor: shape({
        attachedLaborSku: string
      }),
      fulfillmentLocation: string,
      fulfillmentMethod: string,
      itemId: string,
      configurableItems: arrayOf(
        shape({
          itemId: string,
          isAnchor: bool
        })
      ),
      quantity: number,
      service: shape({
        selected: bool,
        type: string
      }),
      subscriptions: shape({
        frequency: string
      }),
      warrantyItem: shape({
        itemId: string
      })
    })
  ]),
  disabled: bool,
  customATC: func
};

Paypal.defaultProps = {
  cartOptions: {
    channel: 'desktop',
    host: 'https://www.homedepot.com',
    misship: false,
    paypal: false
  },
  cartReqParams: {
    itemId: null,
    quantity: 1,
    configurableItems: null
  },
  disabled: false,
  customATC: null
};

export { Paypal };
