import React from 'react';
import {
  arrayOf, node, oneOfType, string, shape
} from 'prop-types';

import { CalculatorProvider } from '../CalculatorProvider';
import { ToasterProvider } from './Toaster/ToasterProvider';

export const FlooringCalculatorWrapper = ({ taxonomy, children }) => {
  return (
    <div data-component="FlooringCalculatorWrapper">
      <ToasterProvider>
        <CalculatorProvider
          taxonomy={taxonomy}
          lineItemName="Area"
        >
          {children}
        </CalculatorProvider>
      </ToasterProvider>
    </div>
  );
};

FlooringCalculatorWrapper.displayName = 'FlooringCalculatorWrapper';

FlooringCalculatorWrapper.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  taxonomy: shape({
    breadCrumbs: arrayOf(shape({
      label: string
    }))
  }).isRequired,
};
