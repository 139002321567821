/* eslint-disable  react/destructuring-assignment */
import React, { Component } from 'react';
import {
  arrayOf, bool, func, node, oneOfType, string
} from 'prop-types';
import classNames from 'classnames';
import { Button, Heading } from '@thd-olt-component-react/core-ui';
import { OverlayContext } from './OverlayContext';

class Overlay extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = OverlayContext;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.bottom = React.createRef();
    const { show } = this.props;
    this.state = {
      bottomScrolled: false,
      scrolled: false,
      show
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
      scrolled: nextProps.scrollToBottom,
      bottomScrolled: false
    });
  }

  componentDidUpdate() {
    const { scrollToBottom } = this.props;
    const { state } = this;
    const { show, bottomScrolled } = state;
    if ((scrollToBottom || this.context.scrollToBottom) && !bottomScrolled) {
      this.scrollToBottom();
    }

    if ((!show && !this.context.show) && document.body.classList.contains('body--noscroll')) {
      document.body.classList.remove('body--noscroll');
    }
  }

  hasScrolled = () => {
    const overlay = this.ref.current;
    const scrolled = overlay && overlay.scrollTop > 0;
    this.setState({ scrolled });
  };

  onBack = (evt) => {
    if (this.context.onBack) {
      this.context.onBack(evt);
    } else if (this.props.onBack) {
      this.props.onBack(evt);
    }
  };

  scrollToBottom() {
    if (this.bottom && this.bottom.current?.scrollIntoView) {
      this.bottom.current.scrollIntoView({ behavior: 'instant' });
      this.setState({ bottomScrolled: true });
    }
  }

  render() {
    const {
      className,
      children,
      name,
      overlayTitle,
    } = this.props;

    const { title } = this.context;

    const { scrolled, show } = this.state;

    const classes = classNames(
      'content-card__overlay',
      className
    );

    if (!show && !this.context.show) {
      return null;
    }

    const backClasses = classNames('content-card__overlay-back', {
      'content-card__overlay-back--shadow': scrolled
    });

    return (
      <div ref={this.ref} className={classes} onScroll={this.hasScrolled}>
        <Button
          link
          className={backClasses}
          contentClass="content-card__overlay-bttn-content"
          onClick={this.onBack}
        >
          <span>
            <img
              className="content-card__overlay-caret"
              src="https://assets.homedepot-static.com/images/v1/back-arrow-orange.svg"
              alt="Back"
              width="100%"
              height="14px"
            />
          </span>
          Back
        </Button>
        <Heading
          title={overlayTitle || this.props.title || title}
          className="content-card__overlay-title"
          noborder
          noMargin
        />
        <div className="content-card__overlay-content" id={name}>
          {children}
        </div>
        <div
          ref={this.bottom}
          className="content-card__overlay-bottom"
        />
      </div>
    );
  }
}

Overlay.displayName = 'CardOverlay';

Overlay.propTypes = {
  /** Overlay content */
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  /** Additional classes for the overlay */
  className: string,
  /** Overlay name targeted by Footer's `triggerFor` */
  name: string,
  /** Override the title passed in from Content */
  overlayTitle: string,
  /** Scroll to bottom on open */
  scrollToBottom: bool,
  /** Whether the overlay should be shown */
  show: bool,
  /** Title passed in from parent Content */
  title: string,
  /** Contains a function that allows us to close the Card Overlay */
  onBack: func
};

Overlay.defaultProps = {
  className: null,
  name: null,
  overlayTitle: null,
  scrollToBottom: false,
  show: false,
  title: null,
  onBack: null
};

export default Overlay;
