import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

export const ARIcon = ({ isThumbnail }) => {
  const classes = classNames(
    'sui-absolute sui-top-0 sui-left-0 sui-pointer-events-none',
    {
      // eslint-disable-next-line max-len
      'sui-h-24 sui-w-24 sui-m-auto sui-right-0 sui-bottom-0 sui-cursor-default sui-scale-75': !isThumbnail,
      'sui-h-full sui-w-full sui-scale-75': isThumbnail
    }
  );

  const styles = {
    background: 'url(https://assets.thdstatic.com/images/v1/AR-cube-white.svg) no-repeat center',
    backgroundSize: '60% auto'
  };

  return <span className={classes} data-testid="ar-icon" style={styles} />;
};

ARIcon.propTypes = {
  isThumbnail: bool
};

ARIcon.defaultProps = {
  isThumbnail: false
};