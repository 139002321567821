import React from 'react';
import { bool, oneOf } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { ProductAddonsTooltip } from '../../common/ProductAddOnsTooltip';

const ProReferralTooltip = ({ channel, checked }) => {
  const tooltipContent = (
    <div
      data-channel={`${channel}`}
      data-component="ProReferralTooltip"
      className="sui-flex sui-flex-col sui-flex-grow"
    >
      <ul className="sui-list-disc sui-pl-5 sui-mb-8 sui-ml-2">
        <li>Get connected to the pro that&apos;s right for you and your budget</li>
        <li>Reduce stress and save time</li>
      </ul>

      <Typography variant="h6" weight="bold">What to Expect</Typography>
      <ul className="sui-list-disc sui-pl-5 sui-mt-4 sui-ml-2">
        <li>
          After completing your purchase, you&apos;ll be contacted by 1-5 Pro Referral pros.
        </li>
        <li>
          Choose the one that&apos;s best for your needs, and then work with them to arrange schedules,
          payments, and all other details of the job.
        </li>
      </ul>

      <p className="sui-my-4">
        Please note that by continuing with this option, you consent to be
        contacted via phone by local pros, and you agree to the&nbsp;
        <a
          className="sui-underline"
          href="https://www.proreferral.com/about/legal/tos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pro Referral Terms of Service
        </a>
      </p>
    </div>
  );
  return (
    <ProductAddonsTooltip
      isProReferral
      checked={checked}
      tooltipContent={tooltipContent}
      title="Why Choose Pro Referral?"
    />
  );
};

ProReferralTooltip.displayName = 'ProductAddOns-proReferral-tooltip';

ProReferralTooltip.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  checked: bool
};

ProReferralTooltip.defaultProps = {
  channel: 'desktop',
  checked: false
};

export { ProReferralTooltip };
