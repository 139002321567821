import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useForkRef from '../private/hooks/useForkRef';

const ListItem = React.forwardRef((props, ref) => {

  const {
    children: childrenProp,
    component: ListItemRoot = 'li',
    ...other
  } = props;

  const listItemRef = React.useRef(null);

  const children = React.Children.toArray(childrenProp);

  const handleRef = useForkRef(listItemRef, ref);

  const classes = classNames('sui-flex sui-justify-start sui-items-center sui-relative sui-no-underline sui-w-full sui-box-border sui-text-left');

  return (
    <ListItemRoot
      ref={handleRef}
      className={classes}
      {...other}
    >
      {children}
    </ListItemRoot>
  );
});

ListItem.displayName = 'ListItem';

ListItem.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType
};

ListItem.defaultProps = {};

export { ListItem };
