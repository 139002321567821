export default {
  pr: {
    SECURE_WWW_HOST: 'https://www.homedepot.com',
    COOKIE_DOMAIN: '.homedepot.com',
  },
  defaults: {
    SECURE_WWW_HOST: 'https://hd-{llc}.homedepotdev.com',
    COOKIE_DOMAIN: '.hd-{llc}.homedepotdev.com',
  },
};
