import React, { useState } from 'react';
import { useStore } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { string } from 'prop-types';
import { Input, FormController, FormLabel, Typography } from '@one-thd/sui-atomic-components';
import { disclaimer, errorMessage } from '../../../helpers/helpers';
import { IntroPane } from './IntroPane';
import { wallAreaCalculatorDataModel } from '../../dataModel';

const AVERAGE_DOOR_AREA = 21;
const AVERAGE_WINDOW_AREA = 12;
const SQUARE_FEET_PER_GALLON = 400;
const MIN_GALLONS = 2;
const unitOfMeasure = '(ft.)';
const optionalLabel = '(optional)';
const defaultState = {
  height: '',
  length: '',
  doors: '',
  windows: ''
};

const validateInputText = (value) => value && /^\d+(\.\d+)?$/.test(value);

export const WallAreaCalculator = ({ itemId }) => {

  const [calculatorState, setCalculatorState] = useState(defaultState);
  const { height, length, doors, windows } = calculatorState;
  const totalSquareFeet = length * height * 4;

  const { storeId } = useStore();
  const { data, loading, error } = useDataModel('clientOnlyProduct', {
    skip: !itemId,
    ssr: false,
    variables: {
      itemId,
      storeId
    }
  });

  const isPaint = !!data?.product?.info?.dotComColorEligible;

  if (!data || !isPaint) return null;

  const getGallons = () => {
    return Math.ceil((totalSquareFeet - (AVERAGE_DOOR_AREA * doors) - (AVERAGE_WINDOW_AREA * windows))
      / SQUARE_FEET_PER_GALLON);
  };

  const gallons = (length && height) ? getGallons() : null;
  const amount = gallons < MIN_GALLONS ? '1 Gallon' : `${gallons} Gallons`;
  const doorsText = `${Math.ceil(doors) || 'no'} door${Math.ceil(doors) === '1' ? '' : 's'} `;
  const windowsText = `${Math.ceil(windows) || 'no'} window${Math.ceil(windows) === '1' ? '' : 's'}`;

  const showError = Object.keys(calculatorState).some((key) => {
    return calculatorState[key] && !validateInputText(calculatorState[key]);
  });
  const showResult = gallons !== null && !showError;

  const getInput = (label, propertyName, propertyValue) => {
    const errorStatus = (propertyValue && !validateInputText(propertyValue)) ? 'error' : null;
    return (
      <div className="sui-mb-3">
        <FormController fullWidth>
          <FormLabel htmlFor={`input-${propertyName}`}>{label}</FormLabel>
          <Input
            id={`input-${propertyName}`}
            type="number"
            fullWidth
            inputProps={{ maxLength: 5, pattern: '^\\d{0,4}(\\.\\d{0,2})?$' }}
            value={propertyValue}
            onChange={(event) => {
              let newValue = event.target.value
                .replace(/[^\d.]/g, '')
                .replace(/^0*(\d+)/, '$1')
                .replace(/^(\d*\.\d{0,2})\..*/, '$1');

              if (newValue.length > 5) {
                newValue = newValue.slice(0, 5);
              }

              setCalculatorState({ ...calculatorState, [propertyName]: newValue });
            }}
            status={errorStatus}
          />
        </FormController>
      </div>
    );
  };

  // @TODO: We need a more generic message to display the results. It's too specific to paint.

  return (
    <>
      <div
        className="sui-flex sui-flex-col md:sui-grid md:sui-grid-cols-3 md:sui-gap-5"
        data-component="WallAreaCalculator"
        id="wall-area-calculator"
      >
        <div className="sui-w-full">
          <form autoComplete="off" noValidate>
            {getInput(`Height ${unitOfMeasure}`, 'height', height)}
            {getInput(`Length ${unitOfMeasure}`, 'length', length)}
            {getInput(`# of doors ${optionalLabel}`, 'doors', Math.ceil(doors))}
            {getInput(`# of windows ${optionalLabel}`, 'windows', Math.ceil(windows))}
          </form>
        </div>
        <div className="sui-flex sui-flex-col md:sui-col-span-2">
          <div className="sui-flex sui-bg-subtle sui-basis-full sui-justify-center">
            {!showResult && !showError && IntroPane && (
              <div
                className="sui-flex sui-flex-col sui-p-5 sui-text-md sui-items-center sui-text-center
            md:sui-flex-row"
              >
                <IntroPane />
              </div>
            )}
            {showError && errorMessage && (
              <div className="sui-p-3 sui-m-3">
                <Typography align="center" color="danger">
                  {errorMessage}
                </Typography>
              </div>
            )}
            {showResult && (
              <div className="sui-flex sui-flex-col sui-p-5 sui-items-center sui-text-center">
                <Typography variant="h5" color="subtle">
                  YOU WILL NEED
                </Typography>
                <Typography variant="h1" weight="display">
                  {amount}
                </Typography>
                <Typography align="center" variant="body-lg">
                  Based on one coat of paint for a {height}&#8217; x {length}&#8217; room with{' '}
                  {doorsText} and {windowsText}
                </Typography>
              </div>
            )}
          </div>
          {showResult && disclaimer && (
            <Typography variant="body-xs">PLEASE NOTE: {disclaimer}</Typography>
          )}
        </div>
      </div>
    </>
  );
};

WallAreaCalculator.displayName = 'WallAreaCalculator';

WallAreaCalculator.dataModel = wallAreaCalculatorDataModel;

WallAreaCalculator.propTypes = {
  itemId: string.isRequired
};