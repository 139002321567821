import React, { useContext } from 'react';
import {
  number,
  oneOfType,
  shape,
  string
} from 'prop-types';
import {
  Checkbox,
  SelectionControlLabel
} from '@one-thd/sui-atomic-components';
import { CalculatorContext } from '../../CalculatorProvider';
import { checkBulkPrice } from '../../../../helpers/helpers';

const fixedValue = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2);
};

const numberWithComma = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const Results = ({
  additionalCoveragePercentage,
  caseUnitOfMeasure,
  pricing,
  unitsPerCase,
  uom
}) => {
  const {
    calculate: {
      addAdditionalCoverage,
      quantity
    },
    setCalculateStateAndRef
  } = useContext(CalculatorContext);

  if (!uom || !quantity) {
    return null;
  }

  const onAdditionalChange = (event) => {
    const { checked } = event.target;
    setCalculateStateAndRef((prevState) => {
      return {
        ...prevState,
        addAdditionalCoverage: checked,
        changesAwaitingLocalStorage: true
      };
    });
  };

  const { price: newPrice } = checkBulkPrice(quantity, pricing);
  const totalPrice = +quantity * +newPrice;
  const updatedTotalPrice = fixedValue(totalPrice);
  const totalCoverage = +quantity * +unitsPerCase;
  const measureUnit = quantity && parseInt(quantity, 10) > 1 ? uom + 's' : uom;
  return (
    <div className={'ui-flex sui-flex-wrap sui-relative sui-bg-primary sui-border-solid sui-border-1 '
      + 'sui-border-primary sui-p-2 sui-mr-3 max-lg:sui-mr-0 max-lg:sui-w-full'}
    >
      <div className="sui-float-left sui-p-2">
        <div className="sui-font-bold sui-text-3xl">{quantity} {measureUnit}</div>
        <div> will cover {fixedValue(totalCoverage)} {caseUnitOfMeasure}</div>
      </div>
      <div className="sui-flex sui-flex-col sui-items-end sui-float-right sui-p-2">
        <div className="sui-font-bold sui-text-3xl"> ${numberWithComma(updatedTotalPrice)} </div>
        <div>Est. Total</div>
      </div>
      <div className="sui-clear-both sui-p-2">
        <SelectionControlLabel
          value="additional-coverage"
          checked={addAdditionalCoverage}
          label={`Include an extra ${additionalCoveragePercentage}% to cover potential waste and breaks`}
        >
          <Checkbox
            onChange={onAdditionalChange}
            inputAttributes={{ 'aria-label': 'controlled' }}
          />
        </SelectionControlLabel>
      </div>
    </div>
  );
};

Results.propTypes = {
  additionalCoveragePercentage: number,
  caseUnitOfMeasure: string,
  pricing: shape({
    bulkPrice: number,
    bulkPriceThresholdQty: number
  }),
  unitsPerCase: oneOfType([string, number]),
  uom: string
};

Results.defaultProps = {
  additionalCoveragePercentage: 10,
  caseUnitOfMeasure: '',
  pricing: {},
  unitsPerCase: '',
  uom: ''
};