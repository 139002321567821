import React, { lazy } from 'react';

export const DigitalTicketImporter = lazy(() => import(
  /* webpackChunkName: "digital-ticket-drawer" */
  './digital-ticket-drawer/DigitalTicketDrawer'
).then((mod) => {

  return {
    default: (props) => {
      const { DigitalTicketDrawer } = mod;
      return (
        /* eslint-disable-next-line */
        <DigitalTicketDrawer {...props} />
      );
    }
  };
}));
