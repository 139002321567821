import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AccordionContext from './AccordionContext';
import { Typography } from '../typography/Typography';

/**
 * `AccordionBody` houses the content to be expanded or collapsed in [Accordion](#accordion)
 *
 * Use it along with [AccordionHeader](#accordionheader)
 *
 * Usage:
 *
 * ```jsx
 * import { AccordionBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const AccordionBody = React.forwardRef((props, ref) => {
  const { children: childrenProp, ...other } = props;

  const { noBorders } = React.useContext(AccordionContext);

  let children;
  if (typeof childrenProp === 'string') {
    children = <Typography>{childrenProp}</Typography>;
  } else {
    children = childrenProp;
  }

  const classes = classNames('sui-rounded-base', {
    'sui-p-4': !noBorders,
    'sui-pt-2': noBorders,
    'sui-border-solid sui-border-b-1 sui-border-x-1 sui-border-primary': !noBorders
  });

  return (
    <div
      ref={ref}
      className={classes}
      {...other}
    >
      {children}
    </div>
  );
});

AccordionBody.displayName = 'AccordionBody';

AccordionBody.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
};

export { AccordionBody };
