import React, { lazy, useContext } from 'react';
import {
  QueryContext, QueryProvider, bool, string, shape, extend, arrayOf, number, alias,
  params, useDataModel
} from '@thd-nucleus/data-sources';
import { string as stringType, bool as boolType } from 'prop-types';
import { useStoreId } from '@thd-nucleus/experience-context';
import { FulfillmentAlert } from './FulfillmentAlert';
import { QuantityAdjustmentsContext } from '../QuantityAdjustmentsContext';

const WrappedFulfillment = lazy(() => import(
  /* webpackChunkName: "fulfillment" */
  '@thd-olt-component-react/fulfillment'
).then((mod) => {
  return {
    default: (props) => {
      const { podFulFillmentUtils } = mod;
      const { defaultVariables } = props || {};
      return (
        <QueryProvider
          dataSource="catalog"
          defaultVariables={defaultVariables}
          cacheKey="selected-item-details-fulfillment"
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FulfillmentAlert {...props} podFulFillmentUtils={podFulFillmentUtils} />
        </QueryProvider>
      );
    }
  };
}));

const FulfillmentLazy = ({ itemId, displayTotalCountAlert, disableUI }) => {
  const { defaultVariables } = useContext(QueryContext) || {};
  const { itemMap } = useContext(QuantityAdjustmentsContext);
  const itemIds = Object.keys(itemMap);

  const { data, loading, error } = useDataModel('clientOnlyProducts', {
    variables: {
      itemIds,
      storeId: useStoreId()
    },
    ssr: false,
    skip: !itemIds.length
  });

  if (loading || error) return <div />;

  const fulfillmentDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <WrappedFulfillment
      products={data?.products}
      itemId={itemId}
      displayTotalCountAlert={displayTotalCountAlert}
      disableUI={disableUI}
      defaultVariables={fulfillmentDefaultVariables}
    />
  );
};

FulfillmentLazy.dataModel = extend({
  clientOnlyProducts: alias('products').params({
    itemIds: arrayOf(string().isRequired()).isRequired(),
    dataSource: string()
  }).arrayOf(shape({
    itemId: string(),
    dataSources: string(),
    fulfillment: params({ storeId: string() }).shape({
      backordered: bool(),
      backorderedShipDate: string(),
      bossExcludedShipStates: string(),
      bodfsAssemblyEligible: bool(),
      inStoreAssemblyEligible: bool(),
      seasonStatusEligible: bool(),
      onlineStoreStatus: bool(),
      anchorStoreStatus: bool(),
      sthExcludedShipState: string(),
      bossExcludedShipState: string(),
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(shape({
          type: string(),
          isBossDominant: bool(),
          mode: shape({
            code: string(),
            desc: string(),
            longDesc: string(),
            group: string()
          }),
          isDefault: bool(),
          deliveryCharge: string(),
          freeDeliveryThreshold: number(),
          hasFreeShipping: bool(),
          itemLevelFreeShipping: bool(),
          deliveryTimeline: string(),
          deliveryDates: shape({
            startDate: string(),
            endDate: string(),
            timeOfArrival: string()
          }),
          totalCharge: number(),
          pickupTime: string(),
          locations: arrayOf(shape({
            type: string(),
            locationId: string(),
            isAnchor: bool(),
            storeName: string(),
            storePhone: string(),
            state: string(),
            distance: number(),
            inventory: shape({
              quantity: number(),
              isInStock: bool(),
              isOutOfStock: bool(),
              isLimitedQuantity: bool(),
              isUnavailable: bool(),
              maxAllowedBopisQty: number(),
              minAllowedBopisQty: number(),
            }),
            curbsidePickupFlag: bool(),
            isBuyInStoreCheckNearBy: bool(),
          })),
        }))
      })),
    }),
    info: shape({
      quantityLimit: number()
    }),
    identifiers: shape({
      itemId: string(),
    })
  }))
});

FulfillmentLazy.displayName = 'FulfillmentLazy';

FulfillmentLazy.propTypes = {
  itemId: stringType,
  displayTotalCountAlert: boolType,
  disableUI: boolType
};

FulfillmentLazy.defaultProps = {
  itemId: '',
  displayTotalCountAlert: false,
  disableUI: false
};

export { FulfillmentLazy };
