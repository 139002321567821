/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import {
  string, object, bool, oneOfType
} from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { DtsTntVariables, UseRecsCxtAndProps } from './tnt-variables';
import { TntComponent } from './TntComponent';
import { dynamicRecsDataModel } from '../dataModel';

export const TntWrapper = (props) => {
  const {
    tntData, recsprops
  } = props;
  const storeInfo = useStore();
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const queryProps = {};

  if (tntData?.isDts) {
    const { qName, dtsopts, dtsAnchor } = DtsTntVariables(tntData?.dtsModelVariables, recsprops, storeInfo, channel, isConsumerApp);
    queryProps.qName = qName;
    queryProps.vars = dtsopts?.opts;
    queryProps.anchor = dtsAnchor;
  } else {
    const { qName, recsOpts, recsAnchor } = UseRecsCxtAndProps(tntData?.recsModelVariables, recsprops, storeInfo, channel, isConsumerApp);
    queryProps.qName = qName;
    queryProps.vars = recsOpts?.opts;
    queryProps.anchor = recsAnchor;
  }
  return (
    <TntComponent data-testid="tnt-component" recsprops={recsprops} {...recsprops} queryProps={queryProps} tntData={tntData} storeInfo={storeInfo} />
  );
};

TntWrapper.displayName = 'TntWrapper';
TntWrapper.propTypes = {
  tntData: oneOfType([object]).isRequired,
  recsprops: oneOfType([object]).isRequired
};

TntWrapper.dataModel = dynamicRecsDataModel;