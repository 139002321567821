import React, { useEffect, useContext } from 'react';
import { string, shape, arrayOf, bool } from 'prop-types';
import { Alert } from '@thd-olt-component-react/core-ui';
import { QuantityAdjustmentsContext } from '../QuantityAdjustmentsContext';

const SetProducts = (products, podFulFillmentUtils) => {
  const { itemMap, setItemMap, setFulfillmentFetched } = useContext(QuantityAdjustmentsContext);
  useEffect(() => {
    const mappedProducts = products.reduce((map, product) => {
      const { ShipToHome, BOPIS } = podFulFillmentUtils.getAllFulfillmentInventories(product);
      const availableQty = (ShipToHome || BOPIS) || podFulFillmentUtils.getSellableQuantityNearbyStore(product);
      const fulfillmentMethod = ShipToHome ? 'ShipToHome' : 'BOPIS';
      const isBackordered = podFulFillmentUtils.isBackordered(product);
      const isBackorderedWithDate = podFulFillmentUtils.isBackorderedWithDate(product);

      return {
        ...map,
        [product.itemId]: {
          ...map[product.itemId],
          availableQty,
          fulfillmentMethod,
          quantityLimit: product.info?.quantityLimit,
          isBackordered,
          backOrderDate: isBackorderedWithDate || null,
        }
      };
    }, itemMap);

    setItemMap(mappedProducts);
    setFulfillmentFetched(true);
  }, [products]);
};

export const FulfillmentAlert = ({
  products, itemId, podFulFillmentUtils, displayTotalCountAlert, disableUI
}) => {
  const { itemMap } = useContext(QuantityAdjustmentsContext);

  SetProducts(products, podFulFillmentUtils);

  const getAlertTypeProps = () => {
    if (disableUI) {
      return null;
    }

    if (displayTotalCountAlert) {
      const itemMapValues = Object.values(itemMap);
      const outOfStockQty = itemMapValues.filter(({ availableQty }) => !availableQty).length;

      if (outOfStockQty) {
        return {
          title: `${outOfStockQty} of ${itemMapValues.length} items are out of stock`,
          type: 'danger'
        };
      }

      return null;
    }

    if (itemMap[itemId]?.isBackordered) {
      return {
        title: 'Backordered',
        type: 'warning',
        message: itemMap[itemId].backOrderDate ? `until ${itemMap[itemId].backOrderDate}` : ''
      };
    }
    const itemAvailableQty = itemMap[itemId]?.availableQty;

    if (!itemAvailableQty) {
      return { title: 'This product is out of stock', type: 'danger', message: '' };
    }
    if (itemMap[itemId]?.quantityLimit
      && itemMap[itemId].quantityLimit > 0
      && itemMap[itemId]?.selectedQty > itemMap[itemId].quantityLimit) {
      return {
        title: '',
        type: 'warning',
        message: `This item has a quantity limit of ${itemMap[itemId].quantityLimit}. 
        Please reduce the quantity to proceed.`
      };
    }
    if (itemAvailableQty < itemMap[itemId]?.selectedQty) {
      return {
        title: '',
        type: 'warning',
        message: `There are only ${itemAvailableQty} in stock at this time. Please reduce the quantity to proceed.`
      };
    }
    return null;
  };
  const alertTypeProps = getAlertTypeProps();

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {alertTypeProps ? <Alert inline {...alertTypeProps} /> : <div />}
    </>
  );
};

FulfillmentAlert.propTypes = {
  itemId: string,
  products: arrayOf(shape({})),
  podFulFillmentUtils: shape({}),
  displayTotalCountAlert: bool,
  disableUI: bool
};

FulfillmentAlert.defaultProps = {
  itemId: '',
  products: [],
  podFulFillmentUtils: {},
  displayTotalCountAlert: false,
  disableUI: false
};

FulfillmentAlert.displayName = 'FulfillmentAlert';
