/* eslint-disable react/no-this-in-sfc */
import React, { useState } from 'react';
import { oneOf, func, bool } from 'prop-types';
import { Select } from '@thd-olt-component-react/core-ui';
import './frequency.scss';

const Frequency = ({ defaultFrequency, onChange, disabled }) => {

  const [selected, setSelected] = useState(defaultFrequency);

  const frequencyMaps = [{
    value: '1',
    get name() { return '1 Month' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '2',
    get name() { return '2 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '3',
    get name() { return '3 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '4',
    get name() { return '4 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '5',
    get name() { return '5 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '6',
    get name() { return '6 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '9',
    get name() { return '9 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }, {
    value: '12',
    get name() { return '12 Months' + (this.isDefault ? ' (Most Common)' : ''); },
    get isDefault() { return this.value === defaultFrequency; }
  }];

  const getOgFrequencyFromValue = (value) => {
    const NO_FREQUENCY = '0_0';
    const frequencyMap = {
      1: '1_3',
      2: '2_3',
      3: '3_3',
      4: '4_3',
      5: '5_3',
      6: '6_3',
      9: '9_3',
      12: '12_3'
    };

    return value ? frequencyMap[value] : NO_FREQUENCY;
  };

  const handleChange = (evt) => {
    setSelected(evt.target.value);
    onChange(getOgFrequencyFromValue(evt.target.value));
  };

  if (disabled) return null;

  return (
    <div className="subscribe-and-save-frequency buybox__add-on__drop-down">
      <Select
        header="Ships Every:"
        selected={selected}
        options={frequencyMaps}
        onChange={handleChange}
      />
    </div>
  );
};

Frequency.displayName = 'SubscribeAndSave-Frequency';

Frequency.propTypes = {
  defaultFrequency: oneOf(['1', '2', '3', '4', '5', '6', '9', '12']),
  onChange: func.isRequired,
  disabled: bool
};

Frequency.defaultProps = {
  defaultFrequency: '6',
  disabled: false
};

export { Frequency };
