/* eslint-disable no-tabs */
import React from 'react';
import { PayPalMessages } from '@paypal/react-paypal-js';
import { paypalMessageClickNewRelic } from '../utils/NewRelicHelper';

export function PaypalMessage(orderTotal, fromPage, fontSize = 12) {
  const msgStyle = {
    layout: 'text',
    logo: {
      type: 'inline'
    },
    text: {
      color: 'black',
      size: fontSize
    }
  };

  const onClick = () => {
    paypalMessageClickNewRelic(fromPage);
  };

  return (
    <PayPalMessages
      style={msgStyle}
      amount={orderTotal}
      forceReRender={[orderTotal]}
      onClick={onClick}
    />
  );
}