import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExperienceContext, useConfigService, useStore } from '@thd-nucleus/experience-context';
import {
  bool,
  params,
  shape,
  string,
  number,
  useDataModel
} from '@thd-nucleus/data-sources';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import {
  getEndDate,
  getEndDateWithTimezoneOffset,
  getLongDescription,
  getPromoToDisplay,
  getShortDescription,
  getSpecialBuy,
  getStartDate,
  hasSpecialBuy,
} from './util';

import * as analytics from './analytics/PromotionsAnalytics';
import './promotion.style.scss';

const Promotion = ({ itemId,
  hideConditionalPromoDescription }) => {
  const { channel } = useContext(ExperienceContext);
  const { isExchangeCustomer } = useThdCustomer() || {};
  const { storeId } = useStore();
  const [mounted, setMounted] = useState(false);
  const hiddenPromosConfig = useConfigService('fs:pip-hidden-promotion-types');

  const hiddenPromos = hiddenPromosConfig
    ? JSON.parse(hiddenPromosConfig)
    : [];

  useEffect(() => {
    setMounted(true);
  }, []);

  const { data } = useDataModel('product', { variables: { itemId, storeId } });

  const promotion = getPromoToDisplay({ data,
    hiddenPromos,
    hideConditionalPromoDescription });
  const isSpecialBuy = hasSpecialBuy(promotion);
  const specialBuy = isSpecialBuy ? getSpecialBuy(promotion) : null;

  const shortDesc = getShortDescription(promotion);
  const longDesc = getLongDescription(promotion);
  const start = getStartDate(promotion);
  const end = getEndDateWithTimezoneOffset(promotion, isSpecialBuy);

  if (!promotion) return null;

  return (
    <div data-testid="promotion" data-component="Promotion">
      {isSpecialBuy && (
        <div data-testid="promotion1" className="promotion__special-buy-wrapper">
          <div className="promotion__special-buy-icon" />
          <div>
            <div className="promotion__special-buy-timer">Special Buy ends in&nbsp;
              {mounted && (
                <CountdownTimer
                  isPromotionTimer
                  endDate={end}
                />
              )}
            </div>
            {!isExchangeCustomer
            && (
              <a className="promotion__special-buy-link" href={specialBuy.link}>
                {specialBuy.description}
              </a>
            )}
          </div>
        </div>
      )}
      {!isSpecialBuy && (
        <div className="promotion">
          <Tooltip
            channel={channel}
            content={(
              <div data-component="PromotionTooltip">
                <b>Promotion Details</b>
                <div className="promotion__tooltip">{`Price Valid: ${start} - ${end}`}</div>
                <div>{longDesc || shortDesc}</div>
              </div>
            )}
            closeButton
          >
            <button
              type="button"
              className="promotion__link"
              href="#"
              onClick={analytics.promoClickEvent}
            >
              {shortDesc}
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

Promotion.displayName = 'Promotion';

Promotion.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    pricing: params({ storeId: string() }).shape({
      preferredPriceFlag: bool(),
      promotion: shape({
        dates: shape({
          start: string(),
          end: string()
        }),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        experienceTag: string(),
        subExperienceTag: string(),
      }),
      // this block must remain commented until searchModel supports conditionalPromotions
      // we use conditionalPromotions on PIP where it is included in the dataModel by the
      // promotion-products component. When search model supports the block, then we will have
      // conditionalPromotions in PLPs too
      // conditionalPromotions: arrayOf(shape({
      //   dates: shape({
      //     start: string(),
      //     end: string()
      //   }),
      //   description: shape({
      //     shortDesc: string(),
      //     longDesc: string()
      //   }),
      // }))
    })
  })
};

Promotion.propTypes = {
  /** The item */
  itemId: PropTypes.string.isRequired,
  /** To use the config service to hide certain promos */
  hideConditionalPromoDescription: PropTypes.bool,

};

Promotion.defaultProps = {
  hideConditionalPromoDescription: false,
};

export default Promotion;
