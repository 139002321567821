import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { arrayOf, shape, string } from 'prop-types';
import { Links } from './Links';

export const LinkSets = ({ sets }) => {
  return (
    <div className="sui-grid sui-grid-cols-1 sui-gap-4 lg:sui-grid-cols-3 lg:sui-gap-6">
      {(sets || []).map((set, i) => {
        return (
          <div className="sui-grow emt-links-sets__set" key={`set_${set.category}_${i}`}>
            <Typography variant="h6">{set.category}</Typography>
            <Links links={set.links || []} />
          </div>
        );
      })}
    </div>
  );
};

LinkSets.propTypes = {
  sets: arrayOf(shape({
    category: string,
    links: arrayOf(shape({
      url: string,
      keyword: string
    })),
  })).isRequired
};
