import React from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { shape, bool as boolType } from 'prop-types';

const CollectionsImage = ({ metadata, isMobile, showColUpLevel }) => {

  return (
    <div>
      {!showColUpLevel && !isMobile && metadata?.collectionImageUrl && (
        <div>
          <Image
            src={metadata?.collectionImageUrl}
            alt="main-product-image"
            height="300"
            width="300"
            data-testid="anchor-image"
            className="sui-w-full"
          />
        </div>
      )}

    </div>
  );
};

CollectionsImage.propTypes = {
  metadata: shape({
  }),
  isMobile: boolType,
  showColUpLevel: boolType
};

CollectionsImage.defaultProps = {
  metadata: {},
  isMobile: false,
  showColUpLevel: false
};

export default CollectionsImage;