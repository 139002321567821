import React from 'react';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';

import { PopularVariationsComponent } from './PopularVariations/PopularVariationsComponent';
import { RelatedSearchPlaceholder } from './RelatedSearch/RelatedSearchPlaceholder';

const HydratedPopularVariations = withHydrator({
  id: 'popular-variations',
  scrollBuffer: 750,
  delay: 2000,
  preserveCtxVal: 'clientStore',
  placeholder: (
    <RelatedSearchPlaceholder
      color="#E0E0E0"
      showLoadingAnimation
    />
  )
}, PopularVariationsComponent);

const DynamicPopularVariations = withDynamicComponent(HydratedPopularVariations);
export const PopularVariations = withErrorBoundary(DynamicPopularVariations);

PopularVariations.dataModel = PopularVariationsComponent.dataModel;
PopularVariations.propTypes = PopularVariationsComponent.propTypes;