import {
  params, string, shape, arrayOf, bool, number, extend, alias, customType
} from '@thd-nucleus/data-sources';
import { FBTCustomProductPod } from './RecsFBT/FBTCustomProductPod';

// 'identifiers', 'info', 'media', 'pricing', 'reviews'
const productModel = extend({
  itemId: string(),
  dataSource: string(),
  identifiers: shape({
    canonicalUrl: string(),
    itemId: string(),
    brandName: string(),
    productLabel: string(),
    productType: string()
  }),
  info: shape({
    label: string()
  }),
  media: shape({
    image: shape({
      url: string()
    }).client(),
    images: arrayOf(shape({
      url: string(),
      type: string(),
      subType: string(),
      sizes: arrayOf(string())
    }))
  }),
  badges: params({ storeId: string() }).arrayOf(shape({
    name: string()
  })),
  pricing: params({ storeId: string() }).shape({
    message: string(),
    original: number({ float: true }),
    mapAboveOriginalPrice: bool(),
    promotion: shape({
      dollarOff: number({ float: true })
    }),
    value: number({ float: true }),
  }),
  reviews: shape({
    ratingsReviews: shape({
      totalReviews: string(),
      averageRating: string()
    })
  })
}, FBTCustomProductPod.dataModel.product);

const wrappedProduct = shape({
  category: string(),
  description: string(),
  idEndDate: string(),
  idPromoDetails: string(),
  idStartDate: string(),
  isAnchor: bool(),
  strategy: string(),
  product: params({
    dataSource: string()
  })
    .shape(FBTCustomProductPod.dataModel.product),
  slot: shape({
    description: string(),
    isRequired: bool(),
    name: string(),
  })
});

export const fbtRecsDataModel = extend({
  dts: params({
    anchorId: string().isRequired(),
    model: string().isRequired(),
    storeId: string(),
    appId: string().isRequired(),
    key: string().isRequired(),
    endpointType: string().isRequired()
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      version: string(),
      modelName: string(),
      fallbackModelName: string(),
      type: string()
    }),
    products: arrayOf(wrappedProduct)
  }),
  recs: params({
    anchorId: string().isRequired(),
    apiName: string().isRequired(),
    storeId: string(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    }),
    serviceType: string()
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      version: string(),
      modelName: string(),
      fallbackModelName: string(),
      type: string()
    }),
    products: arrayOf(wrappedProduct)
  }),
  clientOnlyProduct: extend(alias('product').params({
    itemId: string().isRequired(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    }),
  }).shape({
    itemId: string(),
    availabilityType: shape({
      discontinued: bool()
    }),
    fulfillment: params({ storeId: string() }).shape({
      fulfillmentOptions: arrayOf(shape({
        fulfillable: bool(),
        type: string(),
        services: arrayOf(shape({
          type: string(),
          locations: arrayOf(shape({
            isAnchor: bool(),
            inventory: shape({
              quantity: number()
            })
          }))
        }))
      }))
    })
  }), productModel),
  product: params({
    itemId: string().isRequired(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    })
  }).shape(productModel)
}, FBTCustomProductPod);
