import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import './scss/thd-sharebelt.scss';
import { SharebeltContext } from './Sharebelt.context';
import ShareBeltFavorite from './modules/ShareBeltFavorite/ShareBeltFavorite';
import { dataModel as sharebeltDataModel } from './dataModel';

const Sharebelt = ({
  children,
  collectionId,
  horizontal,
  itemId,
  vertical
}) => {

  const contextValue = {
    collectionId,
    horizontal: !vertical,
    itemId,
    vertical
  };

  const shareBeltClasses = classNames('sui-justify-end', 'sui-items-center',
    'sui-flex', {
      'sui-flex-col': vertical,
      'sui-mt-2': vertical,
      'sui-box-border': vertical,
      'sui-shadow-sm': vertical,
      'sui-bg-primary': vertical,
      'sui-w-20': vertical,
      'sui-clear-both': vertical,
      'sui-p-2': vertical,
      'sui-border-primary': vertical,
      'sui-border-1': vertical,
      'sui-border-solid': vertical
    });

  return (
    <div
      className={shareBeltClasses}
      data-component="ShareBelt"
      data-section="sharing"
      id="thd-sharebelt"
    >
      <SharebeltContext.Provider value={contextValue}>
        {children}
      </SharebeltContext.Provider>
    </div>
  );

};

Sharebelt.displayName = 'Sharebelt';

Sharebelt.propTypes = {
  children: PropTypes.node.isRequired,
  collectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  horizontal: PropTypes.bool,
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  vertical: PropTypes.bool
};

Sharebelt.defaultProps = {
  collectionId: null,
  horizontal: true,
  itemId: null,
  vertical: false
};

Sharebelt.dataModel = extend({}, sharebeltDataModel, ShareBeltFavorite);

export { Sharebelt };
