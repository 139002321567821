import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import {
  Typography
} from '@one-thd/sui-atomic-components';
import { RelatedProductsCard } from './RelatedProductsCard';

const RelatedSearchProducts = ({ relatedSearchProducts, maxRelatedProducts, relatedProductTitle }) => {
  const relatedProductsToShow = [...relatedSearchProducts].slice(0, maxRelatedProducts);

  return (
    <div id="related-products" className="related-products sui-w-full sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{relatedProductTitle}</Typography>

      <div className="sui-grid xl:sui-grid-cols-6 lg:sui-grid-cols-4 md:sui-grid-cols-4 sui-grid-cols-2 sui-gap-6">
        {(relatedProductsToShow || []).map((product, index) => {
          const { details, identifiers, media } = product || {};
          const { canonicalUrl, productLabel } = identifiers || {};
          const { description } = details || {};
          const { images } = media || {};
          const image = images?.[0];
          const itemId = product?.itemId;
          return (

            <RelatedProductsCard
              itemId={itemId}
              canonicalUrl={canonicalUrl}
              image={image}
              productLabel={productLabel}
              description={description}
              index={index}
              key={`relatedProduct-${image?.url}-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

RelatedSearchProducts.defaultProps = {
  maxRelatedProducts: 4,
  relatedProductTitle: 'Related Products',
  relatedSearchProducts: []
};

RelatedSearchProducts.propTypes = {
  maxRelatedProducts: number,
  relatedProductTitle: string,
  relatedSearchProducts: arrayOf(shape({}))
};

export { RelatedSearchProducts };