import { DEFAULT_IMAGE_SIZE } from '../../../constants';

export const replaceImageSize = (url) => url.replace(/<SIZE>/g, DEFAULT_IMAGE_SIZE);
export const getProductInfo = (product) => {
  const { itemId, identifiers, media, taxonomy } = product || {};
  const { canonicalUrl, productLabel, brandName } = identifiers || {};
  const { brandLinkUrl } = taxonomy || {};
  const { images } = media || {};
  const defaultImageView = images?.[0];
  const imageUrl = replaceImageSize(defaultImageView?.url);

  return {
    itemId,
    canonicalUrl,
    productLabel,
    brandName,
    imageUrl,
    brandLinkUrl,
  };
};