export class Utils {
  static sendAnalyticsEvent = ({
    thdAnalyticsEvent,
    timeOut = 1000,
  }) => new Promise((resolve) => {
    if (!window.digitalData.event) {
      window.digitalData.event = [];
    }
    window.digitalData.event.push(thdAnalyticsEvent);
    window.setTimeout(resolve(), timeOut);
  });
}
const exportObj = {
  Utils,
};

export default exportObj;
