/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import {
  oneOf,
  bool as boolType,
  any,
  shape as shapeType,
  string as stringType,
} from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import {
  bool,
  extend,
  string,
  shape,
  QueryProvider,
  QueryContext,
  params
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import classNames from 'classnames';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { CarouselHeader } from '../core/CarouselHeader';
import { getSkippers, isAppliance } from '../utils/helpers';
import { FallbackCard } from '../core/FallbackCard';
import { RecsProductPod } from '../core/RecsProductPod';

// the QueryProvider on line 54 doesnt get access to ProductPod because data?.products is empty at first
// this is what hoist() was originally intended for but the problem with hoist() is that it mutates
// components and runs into issues when they are in multiple places
// eslint-disable-next-line react/prop-types
const Wrapper = ({ children }) => children;
Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string(),
  })
}, RecsProductPod);
const ProductCarousel = (props) => {

  const { channel } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext) || {};
  const isMobile = channel === 'mobile';
  const {
    data,
    hideTitle,
    loading,
    scheme,
    showLoading,
    storeId,
    showFallback,
    fallbackInfo,
    userId,
    subtitle,
    brandTitleMaxLine,
    hideATC,
    hideFavorites,
    hideBadge,
    hideRating,
    showSubscribeAndSave,
    membershipInformation,
    zipCode,
    slidesPer,
    noATCFulfillment,
    hideControls,
    analyticsAnchorProductSku,
    parent,
    slidesToShow,
  } = props;

  const qtxDefaultVariables = {
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    ...getSkippers({ skipSubscribeAndSave: !showSubscribeAndSave }),
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const { products, metadata } = data || {};

  const classes = classNames('sui-grid', 'sui-gap-4', 'sui-grid-cols-1');

  let title = metadata?.collectionName || metadata?.title;

  let breakpoints = ({});
  if (slidesToShow) {
    breakpoints = ({
      sm: {
        slidesPerView: slidesToShow?.sm || 2,
        slidesPerGroup: slidesToShow?.sm || 2
      },
      md: {
        slidesPerView: slidesToShow?.md || 2,
        slidesPerGroup: slidesToShow?.md || 2
      },
      lg: {
        slidesPerView: slidesToShow?.lg || 5,
        slidesPerGroup: slidesToShow?.lg || 5
      },
      xl: {
        slidesPerView: slidesToShow?.xl || 5,
        slidesPerGroup: slidesToShow?.xl || 5
      }
    });
  } else if (!slidesPer) {
    breakpoints = ({
      sm: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      md: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      lg: {
        slidesPerView: 6,
        slidesPerGroup: 6
      },
      xl: {
        slidesPerView: 6,
        slidesPerGroup: 6
      }
    });
  } else if (slidesPer) {
    breakpoints = ({
      sm: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      md: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      lg: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      xl: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      }
    });
  }

  let placeholderTxtLines;
  if (!isMobile) {
    placeholderTxtLines = 7;
  } else {
    placeholderTxtLines = hideATC ? 4 : 6;
  }

  const getScheme = (schemeName) => {
    return (schemeName === 'products_b2b' || schemeName === 'products') ? 'todays' : schemeName;
  };

  return (
    <>
      {showLoading && loading && (
        showFallback && !userId && !products
          ? (
            <FallbackCard
              imageUrl={fallbackInfo.imageUrl}
              header={fallbackInfo.header}
              subheader={fallbackInfo.subheader}
              footerUrl={fallbackInfo.footerUrl}
              footer={fallbackInfo.footer}
            />
          )
          : <LoadingPlaceholder txtPlaceholderLines={placeholderTxtLines} hideTitle={hideTitle} />
      )}
      {products?.length > 0 && (
        <>
          {!hideTitle && (
            <CarouselHeader title={title} subtitle={subtitle} />
          )}

          <div className={classes}>
            <div>
              <QueryProvider
                dataSource={scheme}
                cacheKey={`${scheme}-recommendations-carousel`}
                defaultVariables={qtxDefaultVariables}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideControls={hideControls}
                  disableMargin
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                >
                  {products.map((dynamicProduct, idx) => {
                    const { product } = dynamicProduct;
                    const { itemId } = product;
                    const { preferredPriceFlag } = product?.pricing;
                    return (
                      <Wrapper key={idx}>
                        <RecsProductPod
                          itemId={itemId}
                          key={itemId}
                          position={idx}
                          storeId={storeId}
                          scheme={getScheme(scheme)}
                          anchorProduct={analyticsAnchorProductSku}
                          parent={parent || 'recs-product-pod-carousel'}
                          hideFavorites={hideFavorites}
                          hideBadge={hideBadge}
                          hideATC={hideATC}
                          hideRating={hideRating}
                          preferredPriceFlag={preferredPriceFlag}
                          hideSavingsText={!preferredPriceFlag}
                          brandTitleMaxLine={brandTitleMaxLine}
                          strategy={dynamicProduct?.strategy}
                          noATCFulfillment={isAppliance(product) ? false : noATCFulfillment}
                        />
                      </Wrapper>
                    );
                  }
                  )}
                </Carousel>
              </QueryProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const productModel = extend({}, {
  itemId: string(),
  dataSource: string(),
}, RecsProductPod.dataModel.product);

export const udsWrappedProduct = shape({
  category: string(),
  description: string(),
  idEndDate: string(),
  idPromoDetails: string(),
  idStartDate: string(),
  strategy: string(),
  isAnchor: bool(),
  product: params({
    dataSource: string()
  })
    .shape(productModel),
  slot: shape({
    description: string(),
    isRequired: bool(),
    name: string(),
  })
});

ProductCarousel.dataModel = extend(Wrapper, udsWrappedProduct);

ProductCarousel.displayName = 'ProductCarousel';

ProductCarousel.propTypes = {
  ...RecsProductPod.propTypes,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  loading: boolType,
  hideTitle: boolType,
  scheme: stringType,
  showLoading: boolType,
  storeId: stringType.isRequired,
  subtitle: stringType,
  hideATC: boolType,
  hideFavorites: boolType,
  hideBadge: boolType,
  keepAddToCart: boolType,
  analyticsAnchorProductSku: stringType,
  hideRating: boolType,
  noATCFulfillment: boolType,
  hideControls: boolType
};

ProductCarousel.defaultProps = {
  hideTitle: false,
  scheme: 'drecs',
  showLoading: true,
  loading: false,
  data: null,
  subtitle: null,
  hideBadge: false,
  hideATC: false,
  hideFavorites: true,
  keepAddToCart: null,
  analyticsAnchorProductSku: '',
  hideRating: false,
  noATCFulfillment: true,
  hideControls: false
};

export { ProductCarousel };
