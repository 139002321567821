import React from 'react';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { bool } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './media-gallery-loader.module.scss';

const cx = classNames.bind(styles);

export const MediaGalleryLoader = ({ isMobile, isCarousel }) => {
  return (
    <LoadingPlaceholder
      type={isCarousel ? 'rect' : 'mediaGallery'}
      ready={false}
      color="#E0E0E0"
      showLoadingAnimation
      className={cx({
        'media-gallery-loader--mobile': !isCarousel && isMobile,
        'media-gallery-loader--desktop': !isCarousel && !isMobile,
        'media-gallery-loader--carousel': isCarousel
      })}
      rows={0}
      isMobile={isMobile}
    >
      Media Loader
    </LoadingPlaceholder>
  );
};

MediaGalleryLoader.displayName = 'MediaGalleryLoader';

MediaGalleryLoader.propTypes = {
  isMobile: bool.isRequired,
  isCarousel: bool
};

MediaGalleryLoader.defaultProps = {
  isCarousel: false
};