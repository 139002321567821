import React, {
  createContext, useContext, useRef, useEffect, useState, useCallback
} from 'react';
import { element, func, string } from 'prop-types';

import { useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';

import { laundryAccessoryFilter } from '../helpers/helpers';

const isFulfillable = (product) => {
  return !!(product?.fulfillment?.fulfillmentOptions || []).find((option) => option.fulfillable);
};

const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};

const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
    && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

const isDisplayableBasedOnFulfillment = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return !!(!isDiscontinued(product)
    && (deliveryService
      || shippingFulfillment
      || bossFulfillment
      || isBOPISHaveQuantity(product?.fulfillment)));
};

export const FrequentlyBoughtTogetherContext = createContext({});
export const useFBTContext = () => useContext(FrequentlyBoughtTogetherContext);

export const FrequentlyBoughtTogetherProvider = ({
  itemId,
  onChange,
  onLoadDynamicRecs,
  children
}) => {

  const storeId = useStoreId({ varnish: true });
  const { isServer } = useContext(ExperienceContext);
  const loadedStoreId = useRef(storeId);
  const isFulfillableRef = useRef(true);
  const isDisplayableRef = useRef(true);

  const bundleResponse = useDataModel('bundle', {
    variables: {
      itemId,
      storeId
    },
    ssr: true
  });

  const clientProductResponse = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId
    },
    ssr: false
  });

  const productResponse = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    skip: (!isServer && storeId !== '8119')
  });

  const loadedProduct = useRef(productResponse);

  const [checkedItems, setCheckedItems] = useState([itemId] || []);

  const handleDynamicRecsLoad = useCallback((resp) => {
    const type = resp?.data?.bundle?.metadata?.type;
    const anchorProduct = resp?.data?.bundle?.products.find((product) => product.isAnchor);
    const products = resp?.data?.bundle?.products.filter((product) => laundryAccessoryFilter(anchorProduct, product));
    if (products?.length) {
      const additionalIds = type === 'APPLIANCE_BUNDLE'
        ? (products.filter((item) => !item.isAnchor).map(({ product }) => product?.identifiers?.itemId)).slice(0, 1)
        : (products.map(({ product }) => product?.identifiers?.itemId).filter((val) => val));
      setCheckedItems([itemId, ...additionalIds]);
    }
    if (onLoadDynamicRecs) {
      onLoadDynamicRecs(resp);
    }
  }, [itemId, onLoadDynamicRecs]);

  useEffect(() => {
    if (onChange && !bundleResponse.loading) {
      handleDynamicRecsLoad(bundleResponse);
    }
  }, [itemId, bundleResponse, onChange]);

  const bundleResponseComplete = bundleResponse.data && !bundleResponse.loading && bundleResponse.called;
  const clientProductComplete = clientProductResponse.data
    && !clientProductResponse.loading
    && clientProductResponse.called;

  if (bundleResponseComplete && clientProductComplete) {
    loadedStoreId.current = bundleResponse.variables.storeId;
  }

  if (productResponse.data && !productResponse.loading && productResponse.called) {
    loadedProduct.current = productResponse;
  }

  if (clientProductComplete) {
    loadedProduct.current = clientProductResponse;
  }

  if (clientProductResponse.data?.product?.fulfillment) {
    const isProductFulfillable = isFulfillable(clientProductResponse.data?.product);
    isFulfillableRef.current = isProductFulfillable;
  } else if (clientProductResponse.data?.product) {
    isFulfillableRef.current = false;
  }

  if (clientProductResponse.data?.product?.availabilityType && clientProductResponse.data?.product?.fulfillment) {
    const isFbtDisplayable = isDisplayableBasedOnFulfillment(clientProductResponse.data?.product);
    isDisplayableRef.current = isFbtDisplayable;
  }

  const bundleType = bundleResponse.data?.bundle?.metadata?.type;
  const isApplianceBundle = bundleType === 'APPLIANCE_BUNDLE';
  const isPromotionalBundle = bundleType === 'PROMO_BUN';

  const contextValue = {
    bundleResponseData: bundleResponse.data,
    bundleResponseDataLoading: bundleResponse.loading,
    storeId: loadedStoreId.current,
    isFulfillable: isFulfillableRef.current,
    isDisplayableBasedOnFulfillment: isDisplayableRef.current,
    productResponseData: loadedProduct.current.data,
    productResponseLoading: loadedProduct.current.loading,
    bundleType,
    isApplianceBundle,
    isPromotionalBundle,
    checkedItems,
    setCheckedItems
  };

  return (
    <FrequentlyBoughtTogetherContext.Provider value={contextValue}>
      {children}
    </FrequentlyBoughtTogetherContext.Provider>
  );
};

FrequentlyBoughtTogetherProvider.propTypes = {
  itemId: string.isRequired,
  onChange: func,
  onLoadDynamicRecs: func,
  children: element.isRequired,
};

FrequentlyBoughtTogetherProvider.defaultProps = {
  onChange: () => {},
  onLoadDynamicRecs: () => {}
};