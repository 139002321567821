const NOT_APPLICABLE = 'n/a';

export const fbtAddToCartAnalytics = (payload = {}) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    const {
      metadata = {},
      bundle = false,
      analyticsItems: items = [],
      checkedItems,
      products
    } = payload;

    const {
      modelName = NOT_APPLICABLE,
      version = NOT_APPLICABLE,
      apiName = NOT_APPLICABLE
    } = metadata;

    const output = {
      bundle,
      items,
      modelName,
      version,
      apiName,
      category: {
        primaryCategory: 'stash'
      },
      eventInfo: {
        eventName: 'recommendation cart addition'
      },
      eventMode: 'stash',
      stash: {
        component: 'product pod',
        item: products?.filter((product) => checkedItems.includes(product.itemId)).map((product) => {
          return {
            productInfo: {
              sku: product.itemId
            },
            displayPosition: typeof product?.isAnchor === 'undefined' ? 1 : 2,
            recommendation: {
              anchorProductSku: 'n/a',
              featureVersion: 'n/a',
              interval: 'n/a',
              pageType: 'pip',
              recommendationType: '',
              recommendationVersion: 'n/a',
              strategy: 'n/a'
            }
          };
        }),
        pageType: '',
        section: 'dyn prod rec',
        sharedSection: 'n/a',
        target: 'atc'
      }
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('fbt.add-to-cart', output);
  }

  return null;
};

export const track = (opts) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('fbt.click', opts);
  }
};
