import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { rvDataModel } from '../dataModel';
import RecentlyViewed from './RecentlyViewed';
import ZoneCard from '../utils/zone-card';

const RecentlyViewedWrapperComponent = (props) => {
  const { schemaName } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <RecentlyViewed {...props} schemaName={schemaName} />
    );
  }

  return null;
};

RecentlyViewedWrapperComponent.displayName = 'RecentlyViewedWrapper';

const propTypes = {
  schemaName: string.isRequired
};

const defaultProps = {};

const dataModel = rvDataModel;

RecentlyViewedWrapperComponent.propTypes = propTypes;
RecentlyViewedWrapperComponent.defaultProps = defaultProps;
RecentlyViewedWrapperComponent.dataModel = dataModel;

const HydratedRVRecs = withHydrator({
  delay: 1500,
  id: 'product-section-rv',
  placeholder: (<LoadingPlaceholder />),
  preserveCtxVal: 'clientStore',
  tag: ZoneCard,
}, RecentlyViewedWrapperComponent);

export const RecentlyViewedWrapper = withErrorBoundary(HydratedRVRecs);

RecentlyViewedWrapper.propTypes = propTypes;
RecentlyViewedWrapper.defaultProps = defaultProps;
RecentlyViewedWrapper.dataModel = dataModel;
