import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';

const EducationSummaryPlaceholder = ({ color, showLoadingAnimation }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  return (
    <div data-component="EducationSummaryPlaceholder" data-testid="educational-summary-placeholder">
      <ComponentPlaceholder
        height={isMobile ? 300 : 80}
        rows={isMobile ? 5 : 3}
        color={color}
        showLoadingAnimation={showLoadingAnimation}
      />
    </div>
  );
};

EducationSummaryPlaceholder.propTypes = {
  color: string,
  showLoadingAnimation: bool
};

EducationSummaryPlaceholder.defaultProps = {
  color: '#E0E0E0',
  showLoadingAnimation: false
};

export { EducationSummaryPlaceholder };
