import {
  bool,
  object
} from 'prop-types';
import { Point } from './Point';
import { SmallImageShape } from './Image';

export const LensPropTypes = {
  cursorOffset: Point,
  isActive: bool,
  isPositionOutside: bool,
  position: Point,
  smallImage: SmallImageShape,
  style: object
};