import React, { useContext } from 'react';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Image, Carousel, Col, Row } from '@thd-olt-component-react/core-ui';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { getPackageUrl } from '../../utils';
import './visual-product-images.scss';

const VisualProductImages = (props) => {
  const { itemId } = props;
  const { channel } = useContext(ExperienceContext);
  const { products, packageModel } = useContext(PackagesMiniContext);
  const packageUrl = getPackageUrl(packageModel);
  const getImageUrl = (url) => url.replace(/1000(?=(.(jpg|webp)))/, '600');
  const slidesPerViewAndGroup = { slidesPerView: 2, slidesPerGroup: 2 };
  const isMobile = channel === 'mobile';
  const isHighFetchPriority = ({ index }) => {
    const maxIndex = isMobile ? 1 : 2;
    return index < maxIndex;
  };
  const onImageClick = (index) => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('visual-packages-mini.click', { itemId, index });
  };
  return (
    <Row data-testid="visual-product-images">
      <Col className="product-images__wrapper">
        <Carousel
          breakpoints={{
            sm: slidesPerViewAndGroup,
            md: slidesPerViewAndGroup,
            lg: slidesPerViewAndGroup,
            xl: slidesPerViewAndGroup
          }}
          slidesPerGroup={1}
          slidesPerView={1}
        >
          {products.map((product, index) => (
            <div
              data-testid="visual-product-image"
              className="product-images__image-item"
              key={`image-${product.itemId}`}
            >
              <a
                href={packageUrl}
                onClick={() => onImageClick(index)}
              >
                <Image
                  src={getImageUrl(product?.media?.image?.url || '')}
                  alt="package product image"
                  stretchy
                  lazy={!isHighFetchPriority({ index })}
                  fetchpriority={isHighFetchPriority({ index }) ? 'high' : 'auto'}
                />
              </a>
            </div>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
};

const propTypes = {
  itemId: string
};

const defaultProps = {
  itemId: ''
};

VisualProductImages.displayName = 'VisualProductImages';
VisualProductImages.propTypes = propTypes;
VisualProductImages.defaultProps = defaultProps;

export { VisualProductImages };