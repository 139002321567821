import React, { createContext, useContext, useReducer, useMemo } from 'react';
import { oneOfType, arrayOf, node, string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const qaReducer = (state, { action, value }) => {

  switch (action) {
  case 'SET_ITEM_ID':
    return { ...state, itemId: value };
  case 'SET_START_INDEX':
    return { ...state, startIndex: value };
  case 'SET_CURRENT_PAGE':
    return { ...state, currentPage: value };
  case 'SET_SEARCH_TEXT':
    return { ...state, searchText: value, startIndex: 1, currentPage: 1 };
  case 'SET_SORT_BY':
    return { ...state, sortBy: value, startIndex: 1, currentPage: 1 };
  case 'SET_LOADING':
    return { ...state, qaLoading: value };
  case 'SET_RESULTS':
    return { ...state, ...value };
  default:
    return state;
  }
};

const initialState = {
  channel: '',
  itemId: '',
  startIndex: 1,
  currentPage: 1,
  searchText: '',
  sortBy: 'newest',
  TotalResults: 0,
  Results: [],
  Answers: [],
  qaLoading: false,
};

const QAContext = createContext();
QAContext.displayName = 'QA-Context';

export const QAContextProvider = (props) => {
  const { children, itemId, ...otherProps } = props;
  const { channel } = useContext(ExperienceContext);
  const [state, dispatch] = useReducer(qaReducer, { ...initialState, itemId, channel, ...otherProps });

  return (
    <QAContext.Provider value={{ ...state, dispatch }}>
      {children}
    </QAContext.Provider>
  );
};

QAContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  itemId: string.isRequired,
};

export default QAContext;
