import React from 'react';
import { Row, Col } from '@thd-olt-component-react/grid';
import { useConfigService } from '@thd-nucleus/experience-context';
import { getInstantCheckoutPath } from '../../utils';
import { ENABLE_NEW_INSTANT_CHECKOUT } from '../../constants/features';

const InstantCheckoutEdit = () => {
  const enableNewInstantCheckoutUrl = useConfigService(ENABLE_NEW_INSTANT_CHECKOUT);
  const instantCheckoutUrl = getInstantCheckoutPath(enableNewInstantCheckoutUrl);
  return (
    <div className="instant-checkout" data-component="InstantCheckoutEdit">
      <div className="instant-checkout__wrapper">
        <div className="instant-checkout__box">
          <Row>
            <Col>
              <div className="instant-checkout__heading">Your Fastest Checkout</div>
            </Col>
            <Col nopadding>
              <span>
                <a href={instantCheckoutUrl} className="u__default-link">
                  Update Instant Checkout Settings
                </a>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export { InstantCheckoutEdit };
