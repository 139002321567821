/* eslint-disable max-len */
import React, {useEffect, useState, useRef} from 'react';
import {useStoreId} from '@thd-nucleus/experience-context';
import {
  useSearchDeprecated,
  useRelatedSearchNavDeprecated
} from '@thd-nucleus/data-sources/react/dataModel/migration';
import {ImpressionProvider} from '@thd-olt-component-react/impression';
import {RelatedSearchLinks} from '../subcomponents/RelatedSearchLinks';
import {RelatedSearchProducts} from '../subcomponents/RelatedSearchProducts';
import {RecentSearch} from '../subcomponents/RecentSearch';
import {RelatedSearchPlaceholder} from './RelatedSearchPlaceholder';
import {
  dataModelRelatedSearch,
  defaultPropsRelatedSearch,
  propTypesRelatedSearch
} from './constants';

// @todo this should really be 2 components, one for search and one for product.
const RelatedSearchComponent = (props) => {
  const {
    itemId,
    keyword,
    navParam,
    recent,
    limitItems,
    noOfItems,
    storeId: defaultStoreId,
  } = props;

  const {
    searchData: searchDataProps,
    searchError: searchErrorProps,
    searchLoading: searchLoadingProps
  } = props;

  let searchData;
  let searchError;
  let searchLoading;

  if (recent) {
    return <RecentSearch limitItems={limitItems} noOfItems={noOfItems}/>;
  }

  const storeId = defaultStoreId || useStoreId();
  const [loadedStoreId, setLoadedStoreId] = useState(() => storeId);

  const hasPropData = !!(searchDataProps || searchErrorProps || typeof searchLoadingProps !== 'undefined');
  ({
    data: searchData,
    error: searchError,
    loading: searchLoading
  } = useSearchDeprecated({
    domains: ['relatedResults'],
    storeId,
    keyword,
    skip: (!keyword || hasPropData),
    ssr: true
  }));

  if (hasPropData) {
    searchData = searchDataProps;
    searchError = searchErrorProps;
    searchLoading = searchLoadingProps;
  }

  const loadedStoreRef = useRef(storeId);
  const {
    data: navData,
    error: navError,
    loading: navLoading,
    variables,
  } = useRelatedSearchNavDeprecated({
    domains: ['relatedSearch', 'products'],
    productDomains: ['mediaSlim', 'identifiers', 'details'],
    navParam,
    storeId,
    itemId,
    skip: (!navParam && !itemId),
    ssr: true
  });

  if (!navLoading && navData && variables.storeId) {
    if (variables.storeId !== loadedStoreId) {
      loadedStoreRef.current = variables.storeId;
    }
  }

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('related-search.ready');
  }, []);

  if (searchLoading || navLoading) {
    return (<RelatedSearchPlaceholder/>);
  }

  const searchDataIssue = ((searchError && !searchData) || !searchData || searchLoading) && (keyword);
  const navDataIssue = ((navError && !navData) || !navData || navLoading) && (navParam);

  const relatedSearchModel = searchData?.searchModel;
  const relatedNavModel = navData?.relatedSearchModel;
  const relatedSearchModelKeywords = !relatedSearchModel?.relatedResults?.relatedKeywords?.length ? null
    : relatedSearchModel?.relatedResults?.relatedKeywords;

  const relatedKeywords = relatedSearchModelKeywords || relatedNavModel?.relatedSearch;
  const relatedProducts = navData?.relatedSearchModel?.products || [];

  if ((searchDataIssue || navDataIssue)) {
    return null;
  }

  const searchImpressionContainer = {
    id: '',
    component: 'RelatedSearch',
    name: 'RelatedSearch',
    type: 'content'
  };

  const productImpressionContainer = {
    id: '',
    component: 'RelatedSearch',
    name: 'RelatedProducts',
    type: 'product'
  };

  return (
    <div className="related-search" data-component="RelatedSearch">
      <div className="sui-flex sui-flex-col sui-w-full sui-gap-8">
        {
          !!relatedKeywords?.length
          && (
            <ImpressionProvider
              data={searchImpressionContainer}
            >
              <RelatedSearchLinks relatedLinks={relatedKeywords}/>
            </ImpressionProvider>
          )
        }
        {
          !!relatedProducts?.length
          && (
            <ImpressionProvider
              data={productImpressionContainer}
            >
              <RelatedSearchProducts relatedSearchProducts={relatedProducts}/>
            </ImpressionProvider>
          )
        }
      </div>
    </div>
  );
};

RelatedSearchComponent.displayName = 'RelatedSearch';

RelatedSearchComponent.propTypes = propTypesRelatedSearch;

RelatedSearchComponent.defaultProps = defaultPropsRelatedSearch;

RelatedSearchComponent.dataModel = dataModelRelatedSearch;

export {RelatedSearchComponent};
