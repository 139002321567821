import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params,
  string,
  shape,
  bool,
  customType,
  number as numberType,
  fragment,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import {
  Button,
  Typography,
  CardContent,
  CardTitle
} from '@one-thd/sui-atomic-components';
import { Calculator } from '@one-thd/sui-icons';

const CalcLink = ({ itemId }) => {

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('moving-calculator-link.ready'); }, []);
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });
  if (!data || loading || error) {
    return null;
  }

  const { movingCalculatorEligible } = data?.product?.info || {};
  if (!movingCalculatorEligible) return null;

  return (
    <div data-testid="calc-link" data-component="CalcLink">
      <CardContent
        disablePadding
        className="sui-flex sui-flex-col xl:sui-flex-row xl:sui-items-center sui-justify-between sui-gap-4"
      >
        <div className="sui-flex">
          <div className="sui-flex sui-me-3 sui-mt-1">
            <Calculator color="primary" size="large" />
          </div>
          <CardTitle
            header={<Typography variant="h4">Getting ready for your move?</Typography>}
            subheader={
              <Typography variant="body-base" color="primary">Calculate how many supplies you need.</Typography>
            }
          />
        </div>
        <div className="sui-w-full xl:sui-w-auto">
          <Button
            data-testid="calc-btn"
            fullWidth
            variant="secondary"
            onClick={() => { window.location.href = 'https://www.homedepot.com/c/moving-box-calculator'; }}
          >Calculate Now
          </Button>
        </div>
      </CardContent>
    </div>
  );
};

CalcLink.displayName = 'CalcLink';

CalcLink.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    info: shape({
      movingCalculatorEligible: bool()
    })
  })
};

CalcLink.propTypes = {
  itemId: PropTypes.string,
};

CalcLink.defaultProps = {
  itemId: null
};

export { CalcLink };