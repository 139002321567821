export const FULFILLMENT_LOCATION_STH = 'DirectShip';
export const FULFILLMENT_METHOD_STH = 'ShipToHome';
export const FULFILLMENT_METHOD_BOSS = 'ShipToStore';
export const FULFILLMENT_METHOD_BOPIS = 'BOPIS';
export const FULFILLMENT_METHOD_BODFS = 'DeliverFromStore';
export const FULFILLMENT_METHOD_APPLIANCE = 'DirectDelivery';
export const ATC_TEXT = 'Add to Cart';
export const ATTACH_LABOR_TYPE = {
  ASSEMBLY: 'assembly',
  INSTALL: 'install'
};
export const BOGO_ATC_TEXT = 'Add Both to Cart';

export const STORE_ASSEMBLY_VALUES = {
  SELECTED: 'Y',
  UN_SELECTED: 'N'
};

// Product Type
export const MA = 'MAJOR_APPLIANCE';

// PipFigurator Types
export const CUSTOM_PRICE_VSO = 'Custom - Starting Price & Virtual Assist Only';
