import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { getInstantCheckoutPath } from '../../utils';
import { ENABLE_NEW_INSTANT_CHECKOUT } from '../../constants/features';

const InstantCheckoutTooltip = () => {
  const enableNewInstantCheckoutUrl = useConfigService(ENABLE_NEW_INSTANT_CHECKOUT);
  const instantCheckoutUrl = getInstantCheckoutPath(enableNewInstantCheckoutUrl);
  return (
    <div data-component="InstantCheckoutTooltip">
      <h3 className="ic-tooltip__header tooltip_title">
        <div className="instant-checkout-tooltip__header-text">Instant Checkout Settings</div>
      </h3>
      <div className="ic-hint__text">
        <div>Make a purchase in just one step with your saved information:</div>
        <ul className="list ic-tooltip__list">
          <li className="diy__link--bold">Delivery address</li>
          <li className="diy__link--bold">Payment method</li>
        </ul>
        <a className="u__default-link" href={instantCheckoutUrl}>
          {' '}
          Get started now
        </a>
      </div>
    </div>
  );
};

export { InstantCheckoutTooltip };
