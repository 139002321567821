/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-console */
import { patchResult } from '../transformers/patchDataModel';
import { EVENTS } from '../util/AnalyticsHelper';

let UPDATE_TYPE = '';
export const getOptimisticResponse = (cartRequest, updateType) => {
  UPDATE_TYPE = updateType;
  // TODO: calculate response
  // console.warn('Implement optimistic cart udpate...', cartRequest, updateType);
  return null;
};

export const getOptimisticCartUpdate = (variables, cartInfo) => {
  console.warn(
    '\n\n\n <<<<<<<<<<<<<<<<<<<<============>>>>>>>>>>>>>>>>>>>>getOptimisticCartUpdate()',
    variables,
    cartInfo
  );
  if (variables.cartRequest) {
    variables = destructureCartUpdateRequest(variables);
  }
  const { localStoreId, itemDetails } = variables;
  let { lineItemId, event } = itemDetails;
  const item = cartInfo.items.find((item) => (item.id === lineItemId));

  if (event === EVENTS.QUANTITY_CHANGE || UPDATE_TYPE === 'quantity') {
    const origQuantity = item.quantity;
    const quantity = variables.itemDetails.quantity;
    item.quantity = quantity;
    cartInfo.itemCount += (quantity - origQuantity);

    // pricing
    let { pricing } = item.product;
    // original:  19.98
    // total: 39.94
    // totalWithNoDiscount: 39.96
    // type: "cart_pricing"
    // value: 19.97
    // valueEndDate: "11/01/2023"
    // valueStartDate: "07/20/2023"
    const origItemTotal = pricing.total;
    const newTotal = (
      (item.quantity * (pricing.value || pricing.original)).toFixed(2)
    );
    pricing.total = getFloatFromString(newTotal);
    const totalDelta = parseFloat(newTotal) - parseFloat(origItemTotal);
    // update order summary totals
    cartInfo.totals.total += totalDelta;
    // subtotal
    // total
  } else if (event === EVENTS.FULFILLMENT_CHANGE) {
    console.log(EVENTS.FULFILLMENT_CHANGE);
    handleFulfillmentChange(cartInfo, item, itemDetails);
  }

  cartInfo = { ...patchResult(cartInfo) };
  cartInfo.itemGrouping = setItemGrouping(cartInfo);

  UPDATE_TYPE = '';
  return { updateCart: cartInfo };
};

// function handleQuantityChange() {

// }

const currentFfmMap = {
  boss: 'ShipToStore',
  bopis: 'BOPIS',
  sth: 'ShipToHome',
  'express delivery': 'DeliverFromStore',
  delivery: 'DirectDelivery',
  ShipToStore: 'ShipToStore',
  BOPIS: 'BOPIS',
  ShipToHome: 'ShipToHome',
  DeliverFromStore: 'DeliverFromStore',
  DirectDelivery: 'DirectDelivery',
};

function handleFulfillmentChange(cartInfo, item, itemDetails) {
  const {
    lineItemId: id,
    itemId,
    quantity,
    fulfillmentMethod,
    fulfillmentLocation
  } = itemDetails;

  item.product.fulfillment.fulfillmentOptions.forEach((ffmOpt) => {
    ffmOpt.services.forEach((svc) => {
      svc.selected = (currentFfmMap[svc.type] === fulfillmentMethod);
    });
  });
}

const setItemGrouping = (cart) => {
  let itemGrouping = {
    byFulfillment: {
      pickup: null,
      delivery: null,
      appliance: null,
      __typename: 'ByFulfillment'
    },
    __typename: 'ItemGrouping'
  };
  try {
    const _grouping = itemGrouping.byFulfillment;
    cart.items.forEach((item) => {
      if (['ShipToStore', 'BOPIS'].includes(item.selectedFulfillment)) {
        _grouping.pickup = _grouping.pickup || [];
        const storeGroup = _grouping.pickup.find((group) => (group.store === parseInt(item.fulfillmentLocation)));
        if (storeGroup) {
          storeGroup.ids.push(item.id);
        } else {
          _grouping.pickup.push({
            store: parseInt(item.fulfillmentLocation),
            // storeName: '', // get storename
            // curbsidePickupEligible: true, // TODO: get this?
            ids: [item.id],
            __typename: 'Pickup'
          });
        }
      } else if (item.selectedFulfillment === 'Appliance') {
        _grouping.appliance = _grouping.appliance || { type: 'Appliance', ids: [], __typename: 'Appliance' };
        _grouping.appliance[0].ids.push(item.id);
      } else {
        _grouping.delivery = _grouping.delivery || {
          calendar: null,
          grouping: [
            {
              type: 'scheduled',
              ids: [],
              __typename: 'Grouping'
            },
            {
              type: 'unscheduled',
              ids: [],
              __typename: 'Grouping'
            },
          ],
          __typename: 'Delivery'
        };
        _grouping.delivery.grouping[0].ids.push(item.id);
      }
    });
  } catch (err) {
    console.error(err);
  }
  return itemGrouping;
};

const ffmTypeMap = {
  boss: 'ShipToStore',
  bopis: 'BOPIS',
  sth: 'ShipToHome',
  'express delivery': 'DeliverFromStore',
  delivery: 'DirectDelivery',
};
const deliveryZipLocationFfms = [
  'ShipToHome',
  'DirectDelivery',
  'DeliverFromStore'
];
const storeIdLocationFfms = [
  'BOPIS',
  'ShipToStore'
];
function destructureCartUpdateRequest({ cartRequest }) {
  let itemDetails = null;
  try {
    const ffmTypeItems = cartRequest.items.delivery || cartRequest.items.pickup;
    const {
      id,
      itemId,
      type, // : "sth",
      quantity, // : "1",
      location, // : "75238",
      store, // : "121"
    } = ffmTypeItems[0];

    const fulfillmentMethod = ffmTypeMap[type];
    const fulfillmentLocation = storeIdLocationFfms.includes(fulfillmentMethod) ? store : location;

    itemDetails = {
      lineItemId: id,
      itemId,
      quantity: parseInt(quantity, 10),
      fulfillmentMethod,
      fulfillmentLocation,
    };
  } catch (err) {
    console.error(err);
  }
  return { itemDetails, localStoreId: cartRequest.localization.primaryStoreId };
}

const getFloatFromString = (floatString) => {
  try {
    return (parseFloat(floatString) || 0.0);
  } catch (err) {
    console.error(err);
  }
  return 0.0;
};