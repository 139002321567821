import React, { useContext } from 'react';
import classnames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button, Image } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';

import { shape, arrayOf, bool } from 'prop-types';

import './totalPrice.scss';
import { QuantityAdjustmentsContext } from '@thd-olt-component-react/bundling-product-tabs';
import { calculateTotal } from '../../utils';

// eslint-disable-next-line
const TOOLTIP_CONTENT = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
export const TotalPrice = ({
  products, itemMapProp, isPackageLite, isPackageTransactional, isPackageVisual
}) => {
  const { itemMap: itemMapContext } = useContext(QuantityAdjustmentsContext);
  const itemMap = itemMapProp || itemMapContext;
  const { channel } = useContext(ExperienceContext);
  const totalPrice = calculateTotal(products, itemMap);

  const amountClasses = classnames('total-price__savings-details__amounts-saved', {
    'total-price__savings-details__lite-style': isPackageLite,
    'total-price__savings-details__transactional': isPackageTransactional,
    'total-price__savings-details__visual': isPackageVisual
  });
  const currencyClasses = classnames('total-price__total-value__currency', {
    'total-price__total-value__currency__transactional': isPackageTransactional
  });
  const dollarsClasses = classnames('total-price__total-value__dollars', {
    'total-price__total-value__dollars__transactional': isPackageTransactional,
    'total-price__total-value__dollars__visual': isPackageVisual
  });
  const centsClasses = classnames('total-price__total-value__cents', {
    'total-price__total-value__cents__transactional': isPackageTransactional
  });
  const savingsMessage = (
    // eslint-disable-next-line no-nested-ternary
    isPackageLite ? 'Package Savings'
      : (isPackageTransactional || isPackageVisual) ? 'Bundled Savings'
        : 'Save'
  );

  return (
    <>
      <div className="total-price__total-container" data-testid="total-price">
        {
          totalPrice.showMapMessage
            ? (
              <div className="total-price__total-tooltip">
                <Button link>
                  <Tooltip
                    channel={channel}
                    closeButton
                    content={TOOLTIP_CONTENT}
                    maxWidth={300}
                    placement="bottom"
                  >
                    <div id="summary-total">See Lower Price In Cart</div>
                  </Tooltip>
                </Button>
              </div>
            )
            : (
              <div className="total-price__total-value-container">
                <div className="total-price__total-value u__bold" data-testid="total-price-value">
                  <span className={currencyClasses}>
                    $
                  </span>
                  <span className={dollarsClasses}>
                    {totalPrice.totalValue?.dollars}
                  </span>
                  <span className={centsClasses}>
                    {totalPrice.totalValue?.cents}
                  </span>
                </div>
                { totalPrice.original && totalPrice.hasTotalSavings && totalPrice.totalSavings
              && (
                <div className="total-price__savings-details">
                  <span className="total-price__savings-details__original">
                    <span className="u__strike">
                      ${totalPrice.original}
                    </span>
                  </span>
                  <span className={amountClasses}>
                    <span className="total-price__savings-details__dollar-off u__text--success">
                      {savingsMessage} ${totalPrice.totalSavings}
                    </span>
                    <span className="total-price__savings-details__percentage u__text--success">
                      ({totalPrice.percentage}%)
                    </span>
                  </span>
                  {totalPrice.bundleLabel
                    && (
                      <div className="total-price__savings-details__label" data-testid="savings-label">
                        <Image asset="savings-tag.svg" alt="Savings Tag" title="Savings Tag" />
                        <span className="total-price__savings-details__label-text">
                          {totalPrice.bundleLabel}
                        </span>
                      </div>
                    )}
                </div>
              )}
              </div>
            )
        }
      </div>
    </>
  );
};

TotalPrice.displayName = 'TotalPrice';

TotalPrice.propTypes = {
  products: arrayOf(shape({})),
  itemMapProp: shape({}),
  isPackageLite: bool,
  isPackageTransactional: bool,
  isPackageVisual: bool
};

TotalPrice.defaultProps = {
  products: [],
  itemMapProp: null,
  isPackageLite: false,
  isPackageTransactional: false,
  isPackageVisual: false
};
