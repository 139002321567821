import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  QueryProvider,
  useDataModel,
  alias,
  bool,
  params,
  string,
  shape,
  number,
  arrayOf,
  extend,
  QueryContext
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { Image } from '@thd-olt-component-react/core-ui';
import { Rating } from '@thd-olt-component-react/rating';
import { Button } from '@thd-olt-component-react/button';
import { Row, Col } from '@thd-olt-component-react/grid';

import './discontinued.scss';

const Discontinued = (props) => {
  const { itemId } = props;
  const {
    channel,
  } = useContext(ExperienceContext);

  const storeId = useStoreId();

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  const {
    availabilityType = {},
    info = {}
  } = data?.product || {};

  // eslint-disable-next-line max-len
  const replacementSkuResponse = useDataModel('replacementProduct', { skip: !availabilityType?.discontinued || !info?.replacementOMSID, variables: { itemId: info?.replacementOMSID, storeId } });
  const { defaultVariables } = useContext(QueryContext) || {};
  const ctxDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };
  if (!data || loading || (error && !data)) {
    return null;
  }

  const isReplacementDiscontinued = !!replacementSkuResponse?.data?.product?.availabilityType?.discontinued;

  if (!replacementSkuResponse.data
    || replacementSkuResponse.loading
    || (replacementSkuResponse.error && !replacementSkuResponse.data)
    || isReplacementDiscontinued
  ) {
    return null;
  }

  const {
    identifiers = {},
    media = {},
    reviews = {},
    pricing = {}
  } = replacementSkuResponse?.data?.product || {};

  const { ratingsReviews = {} } = reviews || {};
  const {
    brandName,
    modelNumber,
    itemId: replacementID,
    productLabel,
    storeSkuNumber,
    canonicalUrl
  } = identifiers || {};

  const imageUrl = media?.images?.find(img => img.subType === 'PRIMARY')?.url || '';

  const { averageRating = '0.0', totalReviews = '0' } = ratingsReviews || {};
  const price = pricing?.value;

  return (
    <div className="discontinued" data-component="Discontinued" data-testid="discontinued">
      <div className="discontinued__bookend">
        The product you chose is discontinued, but we found something similar you might like.
      </div>
      <Row className="discontinued__container">
        <Col fallback="12" sm="2">
          <div className="discontinued__image">
            <Image
              src={imageUrl.replace(/<SIZE>/g, '400')}
              stretchy={channel !== 'mobile'}
              alt={productLabel}
              width="1"
              height="1"
            />
          </div>
        </Col>
        <Col fallback="12" sm="10">
          <ul className="u__clearfix">
            <li className="u__bold list__item--type-inline">
              {brandName}
            </li>
            <li className="list__item--type-inline">
              Model {modelNumber}
            </li>
            <li className="list__item--type-inline">
              Internet #{replacementID}
            </li>
            <li className="list__item--type-inline">
              Store SO SKU #{storeSkuNumber}
            </li>
          </ul>
          <div className="discontinued__title">
            <span>{productLabel}</span>
          </div>
          <div className="u__clearfix">
            {ratingsReviews && (
              <Row className="discontinued__rating">
                <Rating
                  value={parseFloat(averageRating)} // eslint-disable-line
                />
                <span>({(totalReviews)})</span>
              </Row>
            )}
          </div>
          <Row>
            <Col fallback="12" md="4">
              <a
                href={`/compare?item1=${itemId}&item2=${replacementID}`}
                className="u__default-link"
              >
                Compare to Discontinued Model
              </a>
            </Col>
            <Col fallback="12" md="8">
              <div className="discontinued__item-price-and-view-container">
                {price && (
                  <div className="discontinued__price">
                    <QueryProvider cacheKey="discontinued-price" defaultVariables={ctxDefaultVariables}>
                      <Price
                        itemId={replacementID}
                        channel={channel}
                        large={false}
                        storeId={storeId}
                      />
                    </QueryProvider>
                  </div>
                )}
                <div className="discontinued__item-info__ctas__view">
                  <Button tag="a" href={canonicalUrl} inline>View Product</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="discontinued__bookend">Discontinued Model:</div>
    </div>
  );
};

Discontinued.displayName = 'Discontinued';

Discontinued.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      replacementOMSID: string()
    }),
    availabilityType: shape({
      discontinued: bool()
    })
  }),
  replacementProduct: alias('product').params({ itemId: string().isRequired() }).shape(extend({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      modelNumber: string(),
      itemId: string(),
      productLabel: string(),
      storeSkuNumber: string(),
      canonicalUrl: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        subType: string()
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string()
      }),
    }),
    pricing: params({ storeId: string() }).shape({
      value: number()
    }),
    availabilityType: shape({
      discontinued: bool()
    }),
  }, Price.dataModel.product))
});

Discontinued.propTypes = {
  itemId: PropTypes.string,
};

Discontinued.defaultProps = {
  itemId: null,
};

export { Discontinued };
