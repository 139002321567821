export const getBreadcrumbStructuredData = ({ canonical, breadcrumbs = [], title } = {}) => {

  if (breadcrumbs && breadcrumbs.length && breadcrumbs?.[0]?.label !== 'Home') {
    breadcrumbs.unshift({ label: 'Home', url: '' });
  }

  return {
    '@type': 'WebPage',
    name: title,
    url: canonical,
    breadcrumb: {
      '@type': 'BreadcrumbList',
      itemListElement: (breadcrumbs || []).map(({ label, url }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        item: {
          '@type': 'Thing',
          name: label,
          '@id': `https://www.homedepot.com${url}`,
        },
      })),
    },
    '@context': 'http://schema.org',
  };
};
