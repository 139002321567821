import { string, func, oneOfType, number } from 'prop-types';

export const propTypes = {
  itemId: string.isRequired,
  onClick: func,
  onHover: func,
  productType: string,
  impressionData: {
    id: string,
    component: string,
    type: string
  },
  name: string
};

export const defaultProps = {
  onClick: null,
  onHover: null,
  productType: 'Products',
  impressionData: {
    id: null,
    component: null,
    type: null
  },
  name: null
};

export const impressionContainerNameParser = (title) => {
  if (title && typeof title === 'string') {
    const name = title.replace(/ /g, '');
    return name;
  }
  return '';
};