import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { track } from '../../analytics';
import './educational-info.style.scss';

const EducationalInfo = ({
  link, title, shortDescription, multiView
}) => {
  const renderLink = () => {
    if (!link) return null;
    const { url = '/', text = '' } = link;
    return (
      <div className="educational-info__link">
        <a href={url} onClick={() => track({ location: 'link', text })}>{text}</a>
      </div>
    );
  };

  const containerClasses = classNames('educational-info__container', {
    'educational-info__container--desktop': !multiView,
    'educational-info__container--multi-view': multiView
  });
  const educationalInfo = classNames('educational-info');

  return (
    <div className={educationalInfo}>
      <div className={containerClasses}>
        <div className="educational-info__title">{title}</div>
        <div className="educational-info__description">{shortDescription}</div>
        {link && renderLink()}
      </div>
    </div>
  );
};

EducationalInfo.propTypes = {
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  }),
  shortDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  multiView: PropTypes.bool
};

EducationalInfo.defaultProps = {
  link: null,
  multiView: false
};

export { EducationalInfo };
