import React from 'react';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { extend } from '@thd-nucleus/data-sources';
import {
  bool, number, string
} from 'prop-types';

export const BuyboxAddToList = ({ quantity, itemId, isStickyChild }) => {
  return (
    <AddToList
      itemId={itemId}
      quantity={quantity}
    />
  );
};

BuyboxAddToList.dataModel = extend(
  AddToList
);

BuyboxAddToList.defaultProps = {
  quantity: 1,
  isStickyChild: false
};

BuyboxAddToList.propTypes = {
  quantity: number,
  itemId: string.isRequired,
  isStickyChild: bool
};
