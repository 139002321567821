const publishNewRelicPageAction = ({ actionName = '', actionObject = null }) => {
  if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    LIFE_CYCLE_EVENT_BUS.trigger('cart.cartPageAction', { actionName, actionObject });
  }
};

export const paypalSmartButtonClickNewRelic = (fromPage, payInFour, hasSavedPaypal, ffm, isUpdatePaypal = false) => {
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonClick',
    actionObject: {
      page: fromPage,
      payInFour,
      hasSavedPaypal,
      fufillment: ffm,
      isUpdatePaypal
    }
  });
};

export const paypalSmartButtonSuccessNewRelic = (fromPage, payInFour, cartId, paypalData) => {
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonSuccess',
    actionObject: {
      page: fromPage,
      payInFour,
      cartId,
      paypalData
    }
  });
};

export const paypalSmartButonCancelNewRelic = (fromPage, payInFour, cartId) => {
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonCancel',
    actionObject: {
      page: fromPage,
      payInFour,
      cartId
    }
  });
};

export const paypalSmartButtonErrorNewRelic = (fromPage, payInFour, error, cartId) => {
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonError',
    actionObject: {
      page: fromPage,
      payInFour,
      error: JSON.stringify(error),
      cartId
    }
  });
};

export const paypalSmartButtonAddToCartFailNewRelic = (fromPage, errorObj, payInFour) => {
  const { description, errorCode } = errorObj;
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonAddToCartFail',
    actionObject: {
      page: fromPage,
      error: description || 'Unknown error',
      errorCode: errorCode || '',
      payInFour
    }
  });
};

export const paypalSmartButtonInitFailNewRelic = (description, errorCode, payInFour) => {
  publishNewRelicPageAction({
    actionName: 'paypalSmartButtonInitFail',
    actionObject: {
      error: description || 'Unknown error',
      errorCode: errorCode || '',
      payInFour
    }
  });
};

export const savedPaypalButtonClickNewRelic = (payInFour, isValidCart, isAuthUser) => {
  publishNewRelicPageAction({
    actionName: 'savedPaypalButtonClick',
    actionObject: {
      payInFour,
      isValidCart,
      isAuthUser
    }
  });
};

export const paypalMessageClickNewRelic = (fromPage) => {
  publishNewRelicPageAction({
    actionName: 'paypalMessageClick',
    actionObject: {
      page: fromPage,
    }
  });
};

export const paypalMissingData = (fromPage, payInFour, missingProps) => {
  publishNewRelicPageAction({
    actionName: 'paypalMissingData',
    actionObject: {
      page: fromPage,
      payInFour,
      missingProps,
    }
  });
};