import React, { useEffect } from 'react';
import { PropTypes, string, bool, func } from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { shimThdCustomerHook, useAddToCart } from '@thd-olt-component-react/cart-common-data';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { SavedPaypalButton } from './SavedPaypalButton';
import { PaypalSmartButton } from './PaypalSmartButton';
import { PaypalMessage } from './PaypalMessage';
import { usePaypalInit } from '../hooks/usePaypalInit';

const PaypalCheckoutButton = (props) => {
  const {
    hasSavedPaypal,
    payInFour,
    orderTotal,
    orderId,
    isValidCart,
    itemCount,
    fromPage,
    ffm,
    cartRequest,
    cart,
    customer,
    page,
    hideButton,
    hideMessage,
    paypalButtonStyle,
    itemPrice,
    namespace,
    checkoutType,
    useSubmitHandler = () => {},
    isUpdatePaypal,
    triggerGetCart = () => {},
    CAACExperience = false,
    CAACCheckbox = true,
    disabled,
    customPaypalHandler,
  } = props;

  const [addToCart] = useAddToCart();
  const [initPaypal] = usePaypalInit();
  const [triggerSubmit] = useSubmitHandler({
    page,
    customer,
    checkoutType
  }) || [() => { }];

  const isPaypalEnabled = useConfigService('PAYPAL_ENABLE');
  const paypalClientId = useConfigService('PAYPAL_CLIENT_ID');
  const isNucleusPaypalCheckout = useConfigService('isNucleusPaypalCheckout');

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('paypal-checkout-button.ready'); }, []);
  const { isLoggedIn, isPersisted } = shimThdCustomerHook(useThdCustomer());
  if (!isPaypalEnabled) {
    return false;
  }

  const isUserAuth = !isLoggedIn || (isLoggedIn && !isPersisted);

  return (
    <div data-component="PaypalCheckoutButton">
      <ErrorBoundary name="paypal-checkout-button">
        <PayPalScriptProvider options={{
          clientId: paypalClientId,
          enableFunding: 'paylater',
          disableFunding: 'card',
          components: 'buttons,funding-eligibility,messages',
          intent: 'order',
          commit: 'false',
          dataNamespace: namespace
        }}
        >
          <div className="sui-grid sui-grid-cols-2 sui-gap-1">
            {hasSavedPaypal && !hideButton && (
              <div className={payInFour ? '' : 'sui-col-span-2'}>
                <SavedPaypalButton
                  payInFour={payInFour}
                  isValidCart={isValidCart}
                  isAuthUser={isUserAuth}
                  disabled={disabled}
                />
              </div>
            )}
            {(!hasSavedPaypal || payInFour) && !hideButton
            && (
              <div
                className={
                  (hasSavedPaypal && payInFour)
                    ? 'sui-relative sui-z-0'
                    : 'sui-col-span-2 sui-relative sui-z-0'
                }
                data-testid={(hasSavedPaypal && payInFour) ? 'paypal-pay-in-four-button' : 'paypal-smart-button'}
              >
                {PaypalSmartButton(
                  orderId,
                  payInFour,
                  hasSavedPaypal,
                  cartRequest,
                  itemCount,
                  customer,
                  page,
                  fromPage,
                  ffm,
                  paypalButtonStyle,
                  addToCart,
                  itemPrice,
                  checkoutType,
                  triggerSubmit,
                  isUpdatePaypal,
                  triggerGetCart,
                  initPaypal,
                  isNucleusPaypalCheckout,
                  CAACExperience,
                  CAACCheckbox,
                  disabled,
                  customPaypalHandler
                )}
              </div>
            )}
            {(!hideMessage && payInFour) && (
              <div className="sui-col-span-2" data-testid="paypal-pay-in-four-message">
                {PaypalMessage(orderTotal, fromPage)}
              </div>
            )}
          </div>
        </PayPalScriptProvider>
      </ErrorBoundary>
    </div>
  );
};

PaypalCheckoutButton.propTypes = {
  hasSavedPaypal: bool,
  payInFour: bool,
  isValidCart: bool.isRequired,
  orderId: string,
  orderTotal: string.isRequired,
  itemCount: string.isRequired,
  fromPage: string.isRequired,
  ffm: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cartRequest: PropTypes.object,
  hideButton: bool,
  hideMessage: bool,
  isUpdatePaypal: bool,
  paypalButtonStyle: string,
  itemPrice: string,
  namespace: string,
  useSubmitHandler: func,
  triggerGetCart: func,
  checkoutType: string,
  CAACExperience: bool,
  CAACCheckbox: bool,
  // eslint-disable-next-line react/forbid-prop-types
  cart: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  customer: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  page: PropTypes.object,
  disabled: bool,
  customPaypalHandler: func,
};

PaypalCheckoutButton.defaultProps = {
  orderId: '',
  cartRequest: null,
  hasSavedPaypal: false,
  payInFour: false,
  hideButton: false,
  hideMessage: false,
  paypalButtonStyle: 'paypal',
  isUpdatePaypal: false,
  itemPrice: '',
  namespace: 'paypalSdk',
  checkoutType: '',
  cart: {},
  customer: {},
  page: {},
  CAACExperience: false,
  CAACCheckbox: false,
  useSubmitHandler: () => {},
  triggerGetCart: () => {},
  disabled: false,
  customPaypalHandler: null,
};

PaypalCheckoutButton.displayName = 'PaypalCheckoutButton';

export { PaypalCheckoutButton };