import React, {
  useState,
  useEffect
} from 'react';
import { Row } from '@thd-olt-component-react/core-ui';
import { useConfigService } from '@thd-nucleus/experience-context';
import { PackagesMiniVisual } from '@thd-olt-component-react/packages-mini';
import { FrequentlyBoughtTogether } from '@thd-olt-component-react/frequently-bought-together';
import { ThdRecsFbt } from '@thd-olt-component-react/thd-recs-fbt';
import {
  string, object, bool
} from 'prop-types';

import {
  shouldDisplayRecsFBT,
  shouldDisplayFbtbundle,
  shouldDisplayFBTPMV,
  shouldDisplayPipInStock,
  isDisplayableBasedOnFulfillment,
} from './product-utils';
import ZoneCard from './zone-card';

const RenderPackagesMiniVisual = (props) => {
  const {
    itemId,
  } = props;

  return (
    <PackagesMiniVisual
      itemId={itemId}
      layoutGroup={{
        tag: Row,
      }}
      dynamic={{
        pageType: 'pip'
      }}
      hydrator={{
        className: 'grid',
        tag: ZoneCard
      }}
    />
  );
};

const RenderFrequentlyBoughtTogether = (props) => {
  const {
    itemId,
  } = props;

  return (
    <FrequentlyBoughtTogether
      itemId={itemId}
      layoutGroup={{
        tag: Row,
      }}
      dynamic={{
        pageType: 'pip'
      }}
      hydrator={{
        className: 'grid',
        tag: ZoneCard
      }}
    />
  );
};

const RenderThdRecsFBT = (props) => {
  const {
    itemId,
  } = props;

  return (
    <Row>
      <ThdRecsFbt
        apiName="fbt_test"
        itemId={itemId}
        errorBoundary
        hydrator={{
          className: 'grid',
          tag: ZoneCard,
          id: 'fbt',
        }}
      />
    </Row>
  );
};

export const PMVAndFBT = (props) => {
  const {
    itemId,
    productData,
    isPackage,
    referer
  } = props;

  const [isPMVEnabled, setIsPMVEnabled] = useState(false);
  const [isFBTEnabled, setIsFBTEnabled] = useState(false);
  const [isPMVAndFBTEnabled, setIsPMVAndFBTEnabled] = useState(false);
  const fsRecsFBT = useConfigService('fs:isFbtRecsEnable');
  const abcTestEnable = useConfigService('fs:isABCTestEnable');
  const hasOneActiveABCTest = [isPMVEnabled, isFBTEnabled, isPMVAndFBTEnabled].filter((val) => !!val).length === 1;

  useEffect(() => {
    shouldDisplayFBTPMV(setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled);
  }, []);

  const { product } = productData || {};

  if (!product
    // check to make sure the item is in stock for ABC test
    || (abcTestEnable && isPackage && !isDisplayableBasedOnFulfillment(product))) {
    return null;
  }

  const packagesMiniVisual = (
    <RenderPackagesMiniVisual itemId={itemId} />
  );
  const thdRecsFbt = <RenderThdRecsFBT itemId={itemId} />;
  const frequentlyBoughtTogether = <RenderFrequentlyBoughtTogether itemId={itemId} />;

  const shouldDisplayRecs = !shouldDisplayFbtbundle(productData)
  && shouldDisplayRecsFBT(productData, referer) && fsRecsFBT;

  const renderFBT = (
    shouldDisplayRecs ? (
      <>
        {thdRecsFbt}
      </>
    ) : (
      <>
        {!shouldDisplayPipInStock(productData, referer) && frequentlyBoughtTogether}
      </>
    )
  );

  return (
    <>
      {abcTestEnable && isPackage ? (
        <>
          {/* There is no `or` case on purpose for nothing to render when the test is active but no variant enabled */}
          {hasOneActiveABCTest && (
            <>
              {isPMVEnabled && (
                <>
                  {packagesMiniVisual}
                </>
              )}
              {isFBTEnabled && (
                <>
                  {renderFBT}
                </>
              )}
              {isPMVAndFBTEnabled && (
                <>
                  {packagesMiniVisual}
                  {renderFBT}
                </>
              )}
            </>
          )}
        </>
      )
        : (
          <>
            {
              isPackage ? (
                <>
                  {packagesMiniVisual}
                </>
              ) : (
                <>
                  {renderFBT}
                </>
              )
            }
          </>
        )}
    </>
  );
};

RenderPackagesMiniVisual.propTypes = {
  itemId: string,
};

RenderFrequentlyBoughtTogether.propTypes = {
  itemId: string,
};

RenderThdRecsFBT.propTypes = {
  itemId: string,
};

PMVAndFBT.propTypes = {
  itemId: string,
  // eslint-disable-next-line
  productData: object,
  isPackage: bool,
  referer: string,
};

RenderPackagesMiniVisual.defaultProps = {
  itemId: '',
};

RenderFrequentlyBoughtTogether.defaultProps = {
  itemId: '',
};

RenderThdRecsFBT.defaultProps = {
  itemId: '',
};

PMVAndFBT.defaultProps = {
  itemId: '',
  productData: {},
  isPackage: false,
  referer: ''
};
