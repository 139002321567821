import React from 'react';
import {
  bool, string, number
} from 'prop-types';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AddToQuote } from '@thd-olt-component-react/add-to-quote';

export const BuyboxAddToQuote = ({
  itemId, fulfillmentLocation, fulfillmentMethod, quantity, disabled
}) => {
  return (
    <ErrorBoundary name="buybox-add-to-quote">
      <AddToQuote
        itemId={itemId}
        fulfillmentLocation={fulfillmentLocation}
        fulfillmentMethod={fulfillmentMethod}
        quantity={quantity}
        disabled={disabled}
      />
    </ErrorBoundary>
  );
};

BuyboxAddToQuote.defaultProps = {
  quantity: 1
};

BuyboxAddToQuote.propTypes = {
  itemId: string.isRequired,
  fulfillmentLocation: string.isRequired,
  fulfillmentMethod: string.isRequired,
  quantity: number,
  disabled: bool.isRequired
};
