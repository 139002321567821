/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */

/**
 * It appears we are the first to use the customer-information useThdCustomer hook!
 * While we work through issues, we'll fall back on the window global value
 */
export const shimThdCustomerHook = (ThdCustomer) => {
  try {
    const isServer = typeof window === 'undefined';
    if (!isServer && !ThdCustomer && window.THDCustomer?.default) {
      // console.warn('useThdCustomer() hook failed, using window.THDCustomer');
      ThdCustomer = window.THDCustomer.default;
    }
  } catch (err) {
    console.error(err);
  }
  return ThdCustomer || {};
};
