import React, { useEffect, useState, useContext, Suspense } from 'react';
import { bool, number, string } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { clickEvent, loadEvent } from '../../utils/publisher';
import { getDefaultApplyNowURL } from '../../utils/payment-estimator-utils';
import { PLCN_CARD_IMAGE_SMALL, CARD_IMAGE, INFO_ICON } from '../../utils/constants';
import { getDynamicOffer } from '../../utils/general';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';

export const IntroOffer = ({
  mobile, paymentEstimatorLoaded, price, isB2B, showGenericDiscount
}) => {
  const [applyNowUrl, setApplyNowUrl] = useState(null);
  const { isConsumerApp } = useContext(ExperienceContext);
  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const logLoadEventAnalytics = () => {
    if (isB2B && price > 298.99) {
      loadEvent('plcc 299+ disc');
    } else if (price > 24.99 && price < 850.00) {
      loadEvent('plcc 25-850 disc');
    } else if (paymentEstimatorLoaded) {
      loadEvent('plcc');
    }
  };

  const logClickEventAnalytics = () => {
    if (isB2B && price > 298.99) {
      clickEvent('plcc 299+ disc');
    } else if (price > 24.99 && price < 850.00) {
      clickEvent('plcc 25-850 disc');
    } else if (paymentEstimatorLoaded) {
      clickEvent('plcc');
    }
  };

  const applyText = isB2B
    ? 'Apply for a Pro Xtra Credit Card'
    : 'Apply for a Home Depot Consumer Card';
  const cardIcon = isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : PLCN_CARD_IMAGE_SMALL;

  useEffect(() => {
    if (paymentEstimatorLoaded) {
      setApplyNowUrl(getDefaultApplyNowURL(mobile, 'pip', isB2B));
    }
    logLoadEventAnalytics();
  }, [paymentEstimatorLoaded]);

  const calculateIntroOfferDiscountPrice = (amount) => {
    return (amount - getDynamicOffer(amount)).toFixed(2);
  };

  return (
    <>
      <TermsAndConditionsDrawer
        closeDrawer={closeDrawer}
        open={showDrawer}
        isB2B={isB2B}
        price={price}
      />
      <div className="sui-inline-flex sui-items-center">
        <Image
          src={cardIcon}
          alt="Apply Now"
          title="Apply Now"
          height={50}
          width={50}
          className="sui-pr-2"
        />
        <div>
          <div>
            {showGenericDiscount
              ? <><strong>Save up to $100</strong>{' on your qualifying purchase'}<>&nbsp;</></>
              : (
                <>Pay<strong>{` $${calculateIntroOfferDiscountPrice(price)}`}</strong>
                  {'\xA0after '}<strong>{`$${getDynamicOffer(price)} OFF`}</strong>
                  {' your total qualifying purchase upon opening a new card.\xA0'}
                </>
              )}
            <Image
              id="crc-image"
              src={INFO_ICON}
              alt="info"
              title="Enjoy special savings with your new account"
              height={12}
              width={12}
              onClick={openDrawer}
              className="sui-inline sui-cursor-pointer sui-mb-1"
            />
          </div>
          <a
            href={applyNowUrl}
            className="u__default-link"
            target={isConsumerApp ? '_self' : '_blank'}
            rel="noopener noreferrer"
            onClick={logClickEventAnalytics}
          >
            {applyText}
          </a>
        </div>
      </div>
    </>
  );

};

IntroOffer.displayName = 'IntroOffer';

IntroOffer.propTypes = {
  mobile: bool.isRequired,
  paymentEstimatorLoaded: bool.isRequired,
  price: number.isRequired,
  isB2B: bool.isRequired,
  showGenericDiscount: bool.isRequired,
};
