import React from 'react';

export const HammerWrenchIcon = (
  <svg id="mdi-hammer-wrench" width="24" height="24" viewBox="0 0 24 24">
    { /* eslint-disable-next-line max-len */ }
    <path d="M13.78 15.3L19.78 21.3L21.89 19.14L15.89 13.14L13.78 15.3M17.5 10.1C17.11 10.1 16.69 10.05 16.36 9.91L4.97 21.25L2.86 19.14L10.27 11.74L8.5 9.96L7.78 10.66L6.33 9.25V12.11L5.63 12.81L2.11 9.25L2.81 8.55H5.62L4.22 7.14L7.78 3.58C8.95 2.41 10.83 2.41 12 3.58L9.89 5.74L11.3 7.14L10.59 7.85L12.38 9.63L14.2 7.75C14.06 7.42 14 7 14 6.63C14 4.66 15.56 3.11 17.5 3.11C18.09 3.11 18.61 3.25 19.08 3.53L16.41 6.2L17.91 7.7L20.58 5.03C20.86 5.5 21 6 21 6.63C21 8.55 19.45 10.1 17.5 10.1Z" />
  </svg>
);

export const LeadingIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1053_301)">
      { /* eslint-disable-next-line max-len */ }
      <path d="M23.9785 6.37492L17.625 0.0214844L0.0214844 17.6249L6.37497 23.9784L23.9785 6.37492ZM11.2097 9.61871L13.1187 7.70971L15 9.59096L16.5909 7.99997L14.7097 6.11872L17.625 3.20346L20.7965 6.37493L6.37498 20.7965L3.20346 17.6249L6.11871 14.7097L7.99997 16.5909L9.59096 15L7.7097 13.1187L9.61871 11.2097L11 12.591L12.591 11L11.2097 9.61871Z" fill="#F96302" />
    </g>
    <defs>
      <clipPath id="clip0_1053_301">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
