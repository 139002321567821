import React, { useState, Suspense } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { FlooringCalculatorWrapper, shouldDisplayPipCalculator } from '@thd-olt-component-react/calculator';
import { useConfigService, useStore } from '@thd-nucleus/experience-context';
import {
  client,
  extend,
  params,
  useDataModel,
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';

import { DrawerWidget } from './DrawerWidget';

const CalculatorDrawer = React.lazy(() => import(
  /* webpackChunkName: "calculator-drawer" */'../calculator-drawer/CalculatorDrawer'
));

export const FlooringDrawer = ({ itemId, products }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const estimatorEnabled = useConfigService('fs:estimatorEnabled'); // todo remove
  const { storeId } = useStore();

  const { data } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
    skip: !itemId,
    ssr: true
  });

  const { product = {} } = data || {};
  const {
    fulfillment = {},
    info = {},
    installServices = {},
  } = product;

  const taxonomy = itemId ? data?.product?.taxonomy : products?.[0]?.taxonomy;
  const label = taxonomy?.breadCrumbs?.[0]?.label || '';
  const isVinylSheet = taxonomy?.breadCrumbs?.some((element) => element?.label === 'Vinyl Sheet Flooring');

  if (
    !label
    || !estimatorEnabled
    || (itemId && !shouldDisplayPipCalculator({ fulfillment, info, installServices }))
  ) {
    return null;
  }

  return (
    <div
      className="sui-border-1 sui-border-solid sui-bg-primary sui-p-2 sui-rounded sui-border-primary sui-rounded-md"
      data-component="FlooringDrawer"
    >
      <FlooringCalculatorWrapper taxonomy={taxonomy}>
        <DrawerWidget
          drawerOpen={drawerOpen}
          label={label}
          onClick={setDrawerOpen}
          pageType={itemId ? 'pip' : 'plp'}
          isVinylSheet={isVinylSheet}
        />
        { drawerOpen && (
          <Suspense fallback={<div />}>
            <CalculatorDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              label={label}
            />
          </Suspense>
        )}
      </FlooringCalculatorWrapper>
    </div>
  );
};

FlooringDrawer.displayName = 'FlooringDrawer';

FlooringDrawer.propTypes = {
  itemId: string,
  products: arrayOf(shape({}))
};

FlooringDrawer.defaultProps = {
  itemId: null,
  products: []
};

FlooringDrawer.dataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape({
      dataSources: stringType(),
      fulfillment: client(params({ storeId: stringType() }).shape({
        fulfillmentOptions: arrayOfType(shapeType({
          type: stringType(),
          fulfillable: boolType(),
        }))
      })),
      info: shapeType({
        pipCalculator: shapeType({
          coverageUnits: stringType(), // Dimensional units Ex: "Panel", "Block", "Sheet", "Bag"
          display: boolType(), // Whether or not to display the calculator
          publisher: numberType(), // units per case
          toggle: stringType(), // "2-Point" (sq. ft.) or "3-Point" (cu. ft.)
          defaultAdditionalCoverage: numberType(), // Whether additional coverage is used by default
          additionalCoveragePercentage: boolType() // The additional coverage percentage
        })
      }),
      installServices: params({ storeId: stringType() }).shape({
        scheduleAMeasure: boolType()
      }),
      taxonomy: shapeType({
        breadCrumbs: arrayOfType(shapeType({
          label: stringType()
        }))
      }),
    })
  },
);