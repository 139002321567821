import helper from '../../helper';

export const getUrl = (url, corporateInformation, productDepartment, classNumber, itemId) => {
/* eslint-disable camelcase */
  let cm_mmc;
  let urlPath = url;
  if (corporateInformation?.fiscalYear) {
    cm_mmc = `socialmedia|O|FY${helper.getFiscalYear(corporateInformation)}|NA|PIN|${productDepartment}|${classNumber}|PIPsave|${itemId}|`; // eslint-disable-line max-len
  }
  if (/cm_mmc=/.test(urlPath)) {
    urlPath = urlPath.replace(/cm_mmc=([^&]+)/, cm_mmc);
  } else if (urlPath.indexOf('?') !== -1) {
    urlPath += '&cm_mmc=' + cm_mmc;
  } else {
    urlPath += '?cm_mmc=' + cm_mmc;
  }
  return urlPath;
};
export const calcFiscalYear = (today) => {
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear().toString();
  let fiscalYear;
  switch (yyyy) {
  case '2018':
    fiscalYear = 18;
    break;
  case '2019':
    if (mm === 1 || (mm === 2 && dd <= 3)) {
      fiscalYear = 18;
    } else {
      fiscalYear = 19;
    }
    break;
  case '2020':
    if (mm === 1 || (mm === 2 && dd <= 2)) {
      fiscalYear = 19;
    } else {
      fiscalYear = 20;
    }
    break;
  case '2021':
    if (mm === 1) {
      fiscalYear = 20;
    } else {
      fiscalYear = 21;
    }
    break;
  case '2022':
    if (mm === 1 && dd <= 30) {
      fiscalYear = 21;
    } else {
      fiscalYear = 22;
    }
    break;
  case '2023':
    if (mm === 1 && dd <= 29) {
      fiscalYear = 22;
    } else {
      fiscalYear = 23;
    }
    break;
  case '2024':
    if (mm === 1 && dd <= 28) {
      fiscalYear = 23;
    } else {
      fiscalYear = 24;
    }
    break;
  default:
    fiscalYear = 24;
  }
  return fiscalYear.toString();
};
export const generatePinCode = (collectionId) => {
  let url = '?cm_mmc=socialmedia|O|FY{fiscal_year}|NA|PIN|Multi|Multi|SpaceCollectionsave|coll_{collection_id}';
  let today = new Date();
  url = url.replace('{fiscal_year}', calcFiscalYear(today));
  url = url.replace('{collection_id}', collectionId);
  return url;
};
