import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  Typography,
  Link
} from '@one-thd/sui-atomic-components';
import { getProductInfo } from './helpers';

const ProductCard = ({ product }) => {
  const {
    brandLinkUrl, brandName, imageUrl, productLabel, canonicalUrl
  } = getProductInfo(product);

  const isUnbranded = brandName?.toLowerCase() === 'unbranded';

  return (
    <Card className="sui-rounded-base sui-border-1">
      <CardMedia
        src={imageUrl}
        alt={`${productLabel}`.replace(/"/g, '\'\'')}
        aspect="square"
      />

      {
        (!isUnbranded) && (
          <Link
            height="tight"
            weight="bold"
            variant="body-base"
            href={brandLinkUrl}
            target="_blank"
            underline="hover"
          >
            {brandName}
          </Link>
        )
      }

      <Typography lineClamp="3">
        <Link
          height="tight"
          href={canonicalUrl}
          target="_blank"
          underline="hover"
        >
          {productLabel}
        </Link>
      </Typography>

      <Link
        variant="body-base"
        height="tight"
        href={canonicalUrl}
        target="_blank"
      >
        View Product
      </Link>
    </Card>
  );
};

ProductCard.displayName = 'ProductCard';
ProductCard.propTypes = {
  product: PropTypes.shape({
    brandLinkUrl: PropTypes.string,
    canonicalUrl: PropTypes.string,
    image: PropTypes.string,
    productLabel: PropTypes.string,
    brandName: PropTypes.string
  }).isRequired
};

export default ProductCard;