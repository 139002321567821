import React, { useEffect, useRef } from 'react';
import {
  string,
  number,
  oneOf,
  func,
  arrayOf,
  shape,
  bool
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { MOBILE } from '@thd-olt-functional/utils';
import { Assemble } from '@one-thd/sui-icons';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod/ProductAddOnsPodStencil';
import { AttachLaborTooltip } from './AttachLaborTooltip';
import { useAttachLabor } from '../../../hooks/useAttachLabor';
import { usePrevious } from '../../../hooks/usePrevious';

import {
  FULFILLMENT_METHOD_STH,
  FULFILLMENT_METHOD_BOPIS,
  attachLaborType,
  cartBinding,
  FULFILLMENT_METHOD_BODFS,
  getFulfillmentByAddOn,
} from '../../../utils/product-add-on-constants';

const AttachLabor = ({
  channel,
  price,
  type,
  itemId,
  fulfillment,
  onChange,
  installProcess,
  priceIncludes,
  availableFulfillments,
  isInstallationSku,
  inStoreAssemblyAvailable,
  bodfsAssemblyEligible,
  isTwoTileEnabled,
  zipCode,
  applicableFulfillments
}) => {
  const {
    heading,
    subHeading,
    userOptions,
    toolTipContentHeading,
    descriptionList,
    contentList,
    displayDisabledText,
    assemblyDisplayText,
    checked,
    setChecked,
    BODFS_ASSEMBLY_ELIGIBLE
  } = useAttachLabor({
    fulfillment,
    availableFulfillments,
    inStoreAssemblyAvailable,
    bodfsAssemblyEligible,
    isInstallationSku,
    type,
    priceIncludes,
    price,
    installProcess,
    onChange,
    itemId,
    isTwoTileEnabled
  });

  const isTooltipExplicitlyOpened = useRef(false);

  const prevFulfillment = usePrevious(fulfillment);
  const prevBodfsAssemblyEligible = usePrevious(BODFS_ASSEMBLY_ELIGIBLE);
  const isAssembly = type === attachLaborType.ASSEMBLY;

  useEffect(() => {
    isTooltipExplicitlyOpened.current = checked && !displayDisabledText;
  }, [checked]);

  useEffect(() => {
    if (channel !== MOBILE
      && !displayDisabledText
      && checked
      && !isTooltipExplicitlyOpened.current) {
      isTooltipExplicitlyOpened.current = true;
    }
  }, [displayDisabledText]);

  const emitBodfsAssemblyEvent = (val) => {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'bodfs-assembly.change',
      {
        isOptedIn: val,
        currentFulfillment: prevFulfillment,
        nextFulfillment: FULFILLMENT_METHOD_BODFS
      }
    );
  };

  const emitInHomeAssemblySTHEvent = (val, expectedFulfillment) => {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'attach-labor.change',
      {
        isOptedIn: val,
        itemId,
        currentFulfillment: fulfillment,
        nextFulfillment: expectedFulfillment
      }
    );
  };

  const emitDeliveryReset = (val) => {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'attach-labor.change',
      {
        isOptedIn: val,
        itemId,
        currentFulfillment: val ? FULFILLMENT_METHOD_STH : FULFILLMENT_METHOD_BODFS,
        nextFulfillment: FULFILLMENT_METHOD_BOPIS
      }
    );
  };

  useEffect(() => {
    if (!BODFS_ASSEMBLY_ELIGIBLE && !displayDisabledText) {
      const addOnType = userOptions[0]?.value;

      if (checked && addOnType === 'attach-labor') {
        const expectedFulfillment = getFulfillmentByAddOn(addOnType);
        if (!expectedFulfillment || (expectedFulfillment && expectedFulfillment === fulfillment)
        || !(applicableFulfillments.includes(expectedFulfillment))) {
          return;
        }
        emitInHomeAssemblySTHEvent(checked, expectedFulfillment);
        return;
      }
      // when assembly selected and STH was prevFulfillment, on pickup, reset to optimal logic
      if (addOnType !== 'attach-labor' && prevFulfillment === FULFILLMENT_METHOD_STH) {
        emitDeliveryReset(false);
      }

    }

  }, [fulfillment]);

  useEffect(() => {
    if (!isAssembly || !checked) return;

    // when assembly checkbox selected, then switch bodfs to pickup, send event out to remove bodfs assembly
    if (prevFulfillment === FULFILLMENT_METHOD_BODFS && fulfillment !== FULFILLMENT_METHOD_BODFS) {
      emitBodfsAssemblyEvent(false);
    }
    // when assembly checkbox selected, then switch pickup to bodfs, send event out to add bodfs assembly
    if (prevFulfillment !== FULFILLMENT_METHOD_BODFS && fulfillment === FULFILLMENT_METHOD_BODFS) {
      emitBodfsAssemblyEvent(true);
    }
  }, [fulfillment, isAssembly, checked]);

  useEffect(() => {
    if (!isAssembly || !checked) return;

    if (prevBodfsAssemblyEligible && !BODFS_ASSEMBLY_ELIGIBLE) {
      setChecked(false);
    }
    // eslint-disable-next-line consistent-return
    return () => {
      setChecked(false);
    };
  }, [BODFS_ASSEMBLY_ELIGIBLE, zipCode, isAssembly, checked]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'attach-labor-paypal.change',
      {
        isOptedIn: displayDisabledText ? false : userOptions[0]?.checked,
        attachLaborValue: displayDisabledText ? 0 : userOptions[0]?.price
      }
    );
  }, [userOptions, displayDisabledText]);

  const handleChange = (event) => {
    if (event.target.value === cartBinding.BODFS_ASSEMBLY) {
      emitBodfsAssemblyEvent(event.target.checked);
      LIFE_CYCLE_EVENT_BUS.trigger(
        'attach-labor.change',
        {
          isOptedIn: event.target.checked,
          currentFulfillment: fulfillment,
          nextFulfillment: FULFILLMENT_METHOD_BODFS
        }
      );
    } else if (event.target.value === cartBinding.ATTACH_LABOR_STORE_ASSEMBLY) {
      LIFE_CYCLE_EVENT_BUS.trigger(
        'attach-labor.change',
        {
          isOptedIn: event.target.checked,
          currentFulfillment: fulfillment,
          nextFulfillment: FULFILLMENT_METHOD_BOPIS
        }
      );
    } else {
      LIFE_CYCLE_EVENT_BUS.trigger(
        'attach-labor.change',
        {
          isOptedIn: event.target.checked,
          itemId,
          currentFulfillment: fulfillment,
          nextFulfillment: event.target.checked ? FULFILLMENT_METHOD_STH : FULFILLMENT_METHOD_BODFS
        }
      );
    }
    setChecked(event.target.checked);
  };

  const tooltip = (
    (descriptionList?.length || contentList?.length) ? (
      <AttachLaborTooltip
        contentHeading={toolTipContentHeading}
        contentDescriptionList={(descriptionList).map((install) => (install.longText))}
        contentSubHeading="Price Includes"
        contentList={(contentList).map((priceInclude) => (priceInclude.shortText))}
        channel={channel}
        checked={checked && !isTooltipExplicitlyOpened.current}
      />
    ) : null
  );

  const assemblyInfo = (
    (assemblyDisplayText && (
      <div className="sui-my-2" data-automation-id="itemAssemblyFreeAssemblyIncludedText">
        <Typography>
          {assemblyDisplayText}
        </Typography>
      </div>
    ))
  );

  return (
    <ProductAddOnsPodStencil
      heading={heading}
      subHeading={subHeading}
      tooltip={tooltip}
      assemblyInfo={assemblyInfo}
      userOptions={userOptions}
      icon={<Assemble size="large" />}
      onChange={handleChange}
      displayDisabledText={displayDisabledText}
    />
  );
};

AttachLabor.displayName = 'ProductAddOns-AttachLabor';

AttachLabor.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  /** Starting Price */
  price: number,
  /** Type of labor ['install' || 'assembly'] */
  type: oneOf([attachLaborType.INSTALL, attachLaborType.ASSEMBLY]),
  /** For fulfillment type fulfillment */
  fulfillment: string.isRequired,
  /** Populates the tooltip */
  installProcess: arrayOf(shape({
    longText: string.isRequired
  })),
  /** Populates the tooltip */
  priceIncludes: arrayOf(shape({
    shortText: string.isRequired
  })),
  /** onChange handler passed in from parent */
  onChange: func.isRequired,
  /** installSku */
  itemId: string.isRequired,
  /** Available fulFillment methods */
  availableFulfillments: arrayOf(string).isRequired,
  /** Is Installation Sku (in-home install or assembly) */
  isInstallationSku: bool,
  /** Store Assembly option is present or not  */
  inStoreAssemblyAvailable: bool,
  /** BODFS Assembly option is present or not  */
  bodfsAssemblyEligible: bool,
  isTwoTileEnabled: bool,
  zipCode: string,
  applicableFulfillments: arrayOf(string)
};

AttachLabor.defaultProps = {
  channel: 'desktop',
  inStoreAssemblyAvailable: false,
  bodfsAssemblyEligible: false,
  isInstallationSku: false,
  price: 0,
  type: 'assembly',
  installProcess: [],
  priceIncludes: [],
  isTwoTileEnabled: false,
  zipCode: '',
  applicableFulfillments: []
};

export { AttachLabor };
