const mod = 'pdp_nocontent';

const handleError = (err) => {
  if (window.isDebugMode) {
    // eslint-disable-next-line
    console.log('ERROR: pip-subscribe_and_save', err);
  }

  if (typeof window.newrelic === 'object' && window.newrelic.noticeError && window.newrelic.addPageAction) {
    window.newrelic.addPageAction('JSError', {
      errorSourceFile: 'pip-subscribe_and_save',
      errorType: err.name,
      errorMessage: err.message,
      errorStack: err.stack
    });
    window.newrelic.noticeError(err);
  }
};

const setOgFrequency = ({ itemId, frequency }) => {
  window.OG.API(
    mod,
    itemId,
    'setFrequency',
    frequency
  );
};

const ATC = ({ itemId, quantity }) => {
  try {
    window.OG.updateCart([{ id: itemId, module: mod, quantity }]);
  } catch (err) {
    handleError(err);
  }
};

const update = ({ itemId, quantity, frequency }) => {
  try {
    window.OG.setQuantity({ id: itemId, module: mod, quantity });
    setOgFrequency({ itemId, frequency });
  } catch (err) {
    handleError(err);
  }
};

const init = ({ itemId, quantity }) => {
  try {
    window.og_settings = {
      page_type: '1'
    };
    window.OG.setProduct({ id: itemId, module: mod, quantity });
    window.OG.setQuantity({ id: itemId, module: mod, quantity });
  } catch (err) {
    handleError(err);
  }
};

export {
  setOgFrequency,
  ATC,
  update,
  init,
  handleError
};
