import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import {
  extend,
  params,
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { Heading } from '@thd-olt-component-react/core-ui';
import { Card } from '@thd-olt-component-react/card';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { EducationalSummaryContent } from './subcomponents/EducationalSummaryContent';
import { AdditionalDetails } from './AdditionalDetails/AdditionalDetails';
import { EducationSummaryPlaceholder } from './EducationSummaryPlaceholder';
import { getSupplementVideos } from './Helper';
import './educational-summary.style.scss';

const EducationalSummaryComponent = ({
  itemId, title, maxVideos, showDetails, children
}) => {
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });
  if (loading) {
    return (
      <EducationSummaryPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  if ((error && !data) || !data?.product) {
    return null;
  }
  const itemTitle = data?.product?.identifiers?.productLabel;
  const video = data?.product?.media?.video;
  const supplementalVideos = maxVideos ? getSupplementVideos(video).slice(0, maxVideos) : getSupplementVideos(video);

  if (!supplementalVideos.length && !children?.length) { return null; }

  return (
    <div className="educational-summary" data-component="EducationalSummary">
      <Card noHide className="educational-summary__desktop-view--wrapper">
        <Heading title={title || itemTitle} className="educational-summary__header" underline />
        <EducationalSummaryContent
          itemId={itemId}
          product={data?.product}
          maxVideos={maxVideos}
          showDetails={showDetails}
        >
          {children}
        </EducationalSummaryContent>
        <Card.Content maxHeight="none" title={title || itemTitle} underline>
          <EducationalSummaryContent
            itemId={itemId}
            product={data?.product}
            maxVideos={maxVideos}
            showDetails={showDetails}
          >
            {children}
          </EducationalSummaryContent>
        </Card.Content>
      </Card>
    </div>
  );
};

EducationalSummaryComponent.displayName = 'EducationalSummary';

const dataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape({
      dataSources: stringType(),
      identifiers: shapeType({
        productLabel: stringType()
      }),
      media: shapeType({
        video: arrayType(shapeType({
          link: shapeType({
            text: stringType(),
            url: stringType()
          }),
          shortDescription: stringType(),
          title: stringType(),
          type: stringType(),
          videoId: stringType(),
          videoStill: stringType()
        }))
      })
    })
  },
  AdditionalDetails
);

EducationalSummaryComponent.dataModel = dataModel;
const propTypes = {
  itemId: PropTypes.string.isRequired,
  title: PropTypes.string,
  showDetails: PropTypes.bool,
  children: PropTypes.oneOfType([node, arrayOf(node)]),
  maxVideos: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8])
};
EducationalSummaryComponent.propTypes = propTypes;

const defaultProps = {
  title: '',
  showDetails: false,
  children: null,
  maxVideos: null
};
EducationalSummaryComponent.defaultProps = defaultProps;

const HydratedComponent = withHydrator({
  id: 'product-section-eduction-summary',
  scrollBuffer: 750,
  delay: 1500,
  placeholder: (
    <EducationSummaryPlaceholder
      color="#E0E0E0"
      showLoadingAnimation
    />
  )
}, EducationalSummaryComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);
const EducationalSummary = withErrorBoundary(DynamicComponent);
EducationalSummary.propTypes = propTypes;
EducationalSummary.defaultProps = defaultProps;
EducationalSummary.dataModel = dataModel;
EducationalSummary.displayName = 'EducationalSummary';

export { EducationalSummary };
