/* global cookieUtils  THDCustomer */
import thdCustomer from '@thd-olt-global/thd-customer';

export const isInventoryInStock = (shippingFulfillment) => {
  const { locations = [] } = shippingFulfillment || {};
  if (locations.length) {
    return locations[0]?.inventory?.isInStock;
  }
  return false;
};

export const isShipToHomeAvailable = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find(
    (option) => option.type === 'delivery'
  );
  const shippingFulfillment = (deliveryService?.services || []).find(
    (service) => service.type === 'sth'
  );
  return isInventoryInStock(shippingFulfillment);
};

export const userSessionValid = (isICRoadsDisabled) => {
  const hasUserID = thdCustomer.userID;
  const hasUserSession = thdCustomer.token;
  const isLoggedIn = thdCustomer.isLoggedIn;
  const isTwoFactorAuth =
    typeof cookieUtils !== 'undefined' &&
    (isICRoadsDisabled || (!isICRoadsDisabled && !!cookieUtils.fed_ReadCookie('IC_ROADS')));
  return (hasUserID && !hasUserSession && !isTwoFactorAuth) || (isLoggedIn && isTwoFactorAuth);
};

export const isB2CCustomer = () => {
  return typeof window !== 'undefined' && window?.THDCustomer?.default?.isB2CCustomer;
};

export const isInstantCheckoutEligible = (fulfillment) => {
  return !!(isShipToHomeAvailable(fulfillment) && isB2CCustomer());
};

export const isGuestInstantCheckoutEligible = (fulfillment) => {
  return !!(isShipToHomeAvailable(fulfillment) && isB2CCustomer());
};

export const getCookies = (cookieNames) => {
  return cookieNames.reduce((cookies, cookieName) => {
    return {
      [cookieName]: typeof cookieUtils !== 'undefined' && cookieUtils.fed_ReadCookie(cookieName),
      ...cookies,
    };
  }, {});
};

export const getInstantCheckoutAtcRequest = ({ itemId, quantity, productAddOns }) => {
  const { warrantyItem = {}, service = {} } = productAddOns || {};

  let atcRequest = {
    itemId,
    quantity,
    fulfillmentMethod: 'ShipToHome',
    fulfillmentLocation: 'DirectShip',
  };

  // Protection Plan (HDPP) Add on
  if (warrantyItem?.itemId) {
    atcRequest = {
      ...atcRequest,
      warrantyItem,
    };
  }

  // Assembly or ProReferral Add on
  if (service?.type && service?.category === 'proReferral') {
    atcRequest = {
      ...atcRequest,
      service: {
        type: service.type,
        selected: service.selected,
      },
    };
  }

  return atcRequest;
};
