import React, { useState } from 'react';
import { oneOf } from 'prop-types';
import { MOBILE } from '@thd-olt-functional/utils';
import { HdppLearnmore } from '@thd-olt-component-react/hdpp-learnmore';
import { Drawer, DrawerBody, DrawerHeader } from '@one-thd/sui-atomic-components';
import WhatToExpect from '../../common/WhatToExpect';

const ProtectionPlanDrawer = ({
  channel
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = channel === MOBILE;
  const drawerMaxSize = isMobile ? 100 : 500;

  const openDrawer = (event) => {
    event.preventDefault();
    setIsDrawerOpen(true);
  };

  return (
    <>
      <WhatToExpect
        onClick={openDrawer}
      />
      {isDrawerOpen && (
        <Drawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          initialItem="Protection Plan View"
          position="right"
          maxSize={drawerMaxSize}
          isMobile={isMobile}
        >
          <DrawerHeader onClose={() => setIsDrawerOpen(false)}>
            <span className="sui-text-lg sui-font-black">Protect Your Purchase</span>
          </DrawerHeader>
          <DrawerBody name="Protection Plan View">
            <HdppLearnmore />
          </DrawerBody>
        </Drawer>
      )}
    </>
  );
};

ProtectionPlanDrawer.displayName = 'ProductAddOns-HdppDrawer';

ProtectionPlanDrawer.propTypes = {
  /** Device Type: Mobile or Desktop */
  channel: oneOf(['desktop', 'mobile'])
};

ProtectionPlanDrawer.defaultProps = {
  channel: 'desktop'
};

export { ProtectionPlanDrawer };