import React, { useContext } from 'react';
import {
  arrayOf, bool, func, node, oneOfType, string
} from 'prop-types';
import classNames from 'classnames';
import { CardContext } from './CardContext';

const Footer = (props) => {
  const { onTriggerClick, scrollToBottom: scrollToBottomCtx } = useContext(CardContext);
  const {
    className,
    children,
    scrollToBottom: scrollToBottomProp,
    triggerFor,
    hideTopBorder,
    nopadding
  } = props;
  const scrollToBottom = typeof scrollToBottomCtx !== 'undefined'
    ? scrollToBottomCtx
    : scrollToBottomProp;
  const classes = classNames(
    'content-card__footer',
    className,
    {
      'content-card__footer--no-top-border': hideTopBorder,
      'content-card__footer--no-padding': nopadding
    }
  );

  const trigger = ((scrollToBottomProp || scrollToBottomCtx) && !triggerFor) ? 'default-overlay' : triggerFor;

  const triggerClick = (event) => {
    if (trigger) {
      onTriggerClick(event, trigger, scrollToBottomProp || scrollToBottomCtx);
    }
  };

  return (
    <div className={classes}>
      {
        trigger
          ? (
            <button
              type="button"
              className="bttn--inline"
              onClick={(event) => triggerClick(event.target)}
            >
              { children }
            </button>
          )
          : children
      }
    </div>
  );
};

Footer.propTypes = {
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  className: string,
  nopadding: bool,
  scrollToBottom: bool,
  triggerFor: string,
  hideTopBorder: bool
};

Footer.defaultProps = {
  className: null,
  nopadding: false,
  scrollToBottom: false,
  triggerFor: null,
  hideTopBorder: false
};

export default Footer;
