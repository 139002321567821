import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

export const PlayIcon = ({ isThumbnail }) => {
  const classes = classNames(
    'sui-absolute sui-h-24 sui-w-24 hover:sui-rounded-full hover:sui-opacity-100 hover:sui-bg-brand',
    {
      'sui-m-auto sui-inset-0 sui-cursor-default sui-scale-75': !isThumbnail,
      'sui-scale-50': isThumbnail
    }
  );
  return (
    <span
      className={classes}
      data-testid="play-icon"
      style={{ background: 'url(https://assets.thdstatic.com/images/v1/play-transparent.svg) no-repeat center' }}
    />
  );
};

PlayIcon.propTypes = {
  isThumbnail: bool
};
PlayIcon.defaultProps = {
  isThumbnail: false
};