import { useState, useMemo, useEffect } from 'react';
import {
  FULFILLMENT_METHOD_STH,
  FULFILLMENT_METHOD_BOPIS,
  FULFILLMENT_METHOD_BODFS,
  FULFILLMENT_METHOD_BOSS,
  attachLaborType,
  cartBinding
} from '../utils/product-add-on-constants';

import { isAssemblyOrInstallValid } from '../utils/product-add-on-utils';

export function useAttachLabor({
  fulfillment,
  availableFulfillments,
  inStoreAssemblyAvailable,
  bodfsAssemblyEligible,
  isInstallationSku,
  type,
  priceIncludes,
  price,
  installProcess,
  onChange,
  itemId,
  isTwoTileEnabled,
}) {

  const [checked, setChecked] = useState(false);

  const {
    IN_HOME_INSTALL,
    IN_HOME_ASSEMBLY,
    IN_STORE_ASSEMBLY,
    HOME_AND_STORE_ASSEMBLY,
    BODFS_ASSEMBLY_ELIGIBLE,
    DELIVERY_ASSEMBLY_ELIGIBLE
  } = useMemo(() => {

    const inHomeInstall = isTwoTileEnabled ? (type === attachLaborType.INSTALL
      && isInstallationSku) : (
      type === attachLaborType.INSTALL
      && isInstallationSku
      && availableFulfillments.includes(FULFILLMENT_METHOD_STH));
    const inStoreAssembly = isTwoTileEnabled ? inStoreAssemblyAvailable : (
      !isInstallationSku
      && inStoreAssemblyAvailable
      && availableFulfillments.includes(FULFILLMENT_METHOD_BOPIS)) || (
      inStoreAssemblyAvailable
      && isInstallationSku
      && !availableFulfillments.includes(FULFILLMENT_METHOD_STH)
      && type === attachLaborType.ASSEMBLY
    );
    const inHomeAssembly = isTwoTileEnabled ? (type === attachLaborType.ASSEMBLY
      && isInstallationSku && availableFulfillments.includes(FULFILLMENT_METHOD_STH)) : (
      type === attachLaborType.ASSEMBLY
      && isInstallationSku
      && !inStoreAssemblyAvailable
      && availableFulfillments.includes(FULFILLMENT_METHOD_STH)) || (
      inStoreAssemblyAvailable
      && isInstallationSku
      && !availableFulfillments.includes(FULFILLMENT_METHOD_BOPIS)
      && type === attachLaborType.ASSEMBLY
    );
    const _bodfsAssemblyEligible = isTwoTileEnabled && bodfsAssemblyEligible
      && availableFulfillments.includes(FULFILLMENT_METHOD_BODFS);
    const _deliveryAssemblyEligible = (isTwoTileEnabled && inHomeAssembly) || _bodfsAssemblyEligible;
    const homeAndStoreAssembly = inStoreAssemblyAvailable && isInstallationSku
      && availableFulfillments.includes(FULFILLMENT_METHOD_STH)
      && availableFulfillments.includes(FULFILLMENT_METHOD_BOPIS);

    return {
      IN_HOME_INSTALL: inHomeInstall,
      IN_HOME_ASSEMBLY: inHomeAssembly,
      IN_STORE_ASSEMBLY: inStoreAssembly,
      HOME_AND_STORE_ASSEMBLY: homeAndStoreAssembly,
      BODFS_ASSEMBLY_ELIGIBLE: _bodfsAssemblyEligible,
      DELIVERY_ASSEMBLY_ELIGIBLE: _deliveryAssemblyEligible
    };

  }, [availableFulfillments, inStoreAssemblyAvailable, isInstallationSku, type, isTwoTileEnabled]);

  // When STH/BODFS assembly is eligible, and STH/BODFS is selected as the FFL type
  const bodfsAvailable = fulfillment === FULFILLMENT_METHOD_BODFS || (
    !IN_HOME_ASSEMBLY
    && fulfillment === FULFILLMENT_METHOD_STH
    && availableFulfillments.includes(FULFILLMENT_METHOD_BODFS));
  const inStoreAvailableForBODFS = (fulfillment === FULFILLMENT_METHOD_BOPIS || fulfillment === FULFILLMENT_METHOD_BOSS)
  && bodfsAssemblyEligible && !IN_STORE_ASSEMBLY;
  const isBODFSAssembly = BODFS_ASSEMBLY_ELIGIBLE && bodfsAvailable;
  const onlyBODFSAssemblyEligible = BODFS_ASSEMBLY_ELIGIBLE && !IN_HOME_ASSEMBLY
  && !IN_HOME_INSTALL && !IN_STORE_ASSEMBLY;
  const onlyStoreAssemblyEligible = !BODFS_ASSEMBLY_ELIGIBLE && !IN_HOME_ASSEMBLY
  && !IN_HOME_INSTALL && IN_STORE_ASSEMBLY;
  const onlyDeliveryAssemblyEligible = (BODFS_ASSEMBLY_ELIGIBLE || IN_HOME_ASSEMBLY || IN_HOME_INSTALL)
  && !IN_STORE_ASSEMBLY;

  const heading = useMemo(
    () => ((type === attachLaborType.INSTALL) ? 'Need This Installed?' : 'Need This Assembled?'),
    [type]
  );

  const subHeading = useMemo(
    function () {
      const subHeadingText = {
        shipToHomebodfsAssemblyOnly: 'Available only with Delivery',
        storePickUpOnly: 'Available only with Store Pickup',
        freeWithStorePickUp: 'Free with Store Pickup'
      };

      if (onlyDeliveryAssemblyEligible) {
        return subHeadingText.shipToHomebodfsAssemblyOnly;
      }

      if (HOME_AND_STORE_ASSEMBLY && !BODFS_ASSEMBLY_ELIGIBLE) {
        return subHeadingText.freeWithStorePickUp;
      }

      if (onlyStoreAssemblyEligible) {
        return subHeadingText.storePickUpOnly;
      }

      return '';
    },
    [
      IN_HOME_ASSEMBLY,
      IN_HOME_INSTALL,
      IN_STORE_ASSEMBLY,
      HOME_AND_STORE_ASSEMBLY,
      BODFS_ASSEMBLY_ELIGIBLE,
      DELIVERY_ASSEMBLY_ELIGIBLE,
      fulfillment
    ]
  );

  const userOptions = useMemo(() => {
    const options = {
      inHomeInstall: [{ value: cartBinding.ATTACH_LABOR, checked, price, label: 'Add In-Home Installation /' }],
      inHomeAssembly: [{ value: cartBinding.ATTACH_LABOR, checked, price, label: 'Add In-Home Assembly /' }],
      inStoreAssembly: [{ value: cartBinding.ATTACH_LABOR_STORE_ASSEMBLY,
        checked,
        price: 0,
        label: 'Add In-Store Assembly /' }],
      bodfsAssembly: [{ value: cartBinding.BODFS_ASSEMBLY,
        checked,
        price: BODFS_ASSEMBLY_ELIGIBLE ? 0 : price,
        label: 'Get it Pre-assembled /' }],
    };

    if (IN_HOME_INSTALL) {
      return options.inHomeInstall;
    }

    if (((fulfillment === FULFILLMENT_METHOD_BODFS || fulfillment === FULFILLMENT_METHOD_BOSS)
      && BODFS_ASSEMBLY_ELIGIBLE)
    || (fulfillment === FULFILLMENT_METHOD_BOPIS && !IN_STORE_ASSEMBLY && BODFS_ASSEMBLY_ELIGIBLE)) {
      return options.bodfsAssembly;
    }

    if ((IN_STORE_ASSEMBLY && fulfillment === FULFILLMENT_METHOD_BOPIS)
    || (fulfillment === FULFILLMENT_METHOD_BODFS && onlyStoreAssemblyEligible)
     || (fulfillment === FULFILLMENT_METHOD_STH && onlyStoreAssemblyEligible)) {
      return options.inStoreAssembly;
    }

    return options.inHomeAssembly;
  },
  [
    checked,
    price,
    fulfillment,
    IN_HOME_INSTALL,
    IN_STORE_ASSEMBLY,
    HOME_AND_STORE_ASSEMBLY,
    BODFS_ASSEMBLY_ELIGIBLE,
    DELIVERY_ASSEMBLY_ELIGIBLE
  ]
  );

  const displayDisabledText = useMemo(() => {
    if (!checked) return '';
    // assembly is selected and is valid in any case
    if (isAssemblyOrInstallValid(fulfillment, { inStoreAssemblyAvailable, bodfsAssemblyEligible, isInstallationSku })) {
      return '';
    }

    // If checked is true, get the disabled text
    const DOES_NOT_APPLY_COPY = {
      inHomeInstall: 'We\'re sorry, you can only get this product installed with Delivery',
      // eslint-disable-next-line max-len
      inHomeAssembly: isTwoTileEnabled ? 'We\'re sorry, you can only get this product assembled with Delivery.' : 'We\'re sorry, you can only get this product assembled with Delivery',
      inStoreAssemblyOnly: 'We\'re sorry, you can only get this product assembled with Store Pickup',
      storeAndHomeAssembly: 'We\'re sorry, you can only get this product assembled with Store Pickup',
      bodfsAssembly: 'We\'re sorry, you can only get this product assembled with Delivery.'
    };

    if (IN_HOME_INSTALL && fulfillment !== FULFILLMENT_METHOD_STH) {
      return DOES_NOT_APPLY_COPY.inHomeInstall;
    }

    if (
      HOME_AND_STORE_ASSEMBLY
      && ![FULFILLMENT_METHOD_STH, FULFILLMENT_METHOD_BOPIS, FULFILLMENT_METHOD_BODFS].includes(fulfillment)
    ) {
      return DOES_NOT_APPLY_COPY.storeAndHomeAssembly;
    }

    if (IN_STORE_ASSEMBLY && fulfillment !== FULFILLMENT_METHOD_BOPIS && !BODFS_ASSEMBLY_ELIGIBLE) {
      return DOES_NOT_APPLY_COPY.inStoreAssemblyOnly;
    }

    if (
      IN_HOME_ASSEMBLY
      && fulfillment !== FULFILLMENT_METHOD_STH
    ) {
      return DOES_NOT_APPLY_COPY.inHomeAssembly;
    }

    // show the error if it is only bodfs assembly eligible and selected fulfillment is not bodfs
    if (BODFS_ASSEMBLY_ELIGIBLE && fulfillment !== FULFILLMENT_METHOD_BODFS
      && !HOME_AND_STORE_ASSEMBLY && !IN_STORE_ASSEMBLY) {
      return DOES_NOT_APPLY_COPY.bodfsAssembly;
    }
    return '';
  },
  // eslint-disable-next-line max-len
  [checked, fulfillment, IN_HOME_ASSEMBLY, IN_HOME_INSTALL, IN_STORE_ASSEMBLY, HOME_AND_STORE_ASSEMBLY, BODFS_ASSEMBLY_ELIGIBLE, isTwoTileEnabled]);

  const assemblyDisplayText = useMemo(() => {
    if (!checked) return '';

    // If checked is true, get the disabled text
    const ASSEMBLY_DISPLAY_TEXT = {
      inStoreAssembly: `Assembly times may vary and we cannot guarantee orders will be available within 2 hours.
      We'll email when your order is ready.`,
      bodfsAssembly: `A store associate will call you within 48 hours to schedule delivery.
      Assembly may affect delivery times.`
    };

    if (!!checked && (IN_STORE_ASSEMBLY || HOME_AND_STORE_ASSEMBLY) && fulfillment === FULFILLMENT_METHOD_BOPIS) {
      return ASSEMBLY_DISPLAY_TEXT.inStoreAssembly;
    }

    if (!!checked && BODFS_ASSEMBLY_ELIGIBLE && fulfillment === FULFILLMENT_METHOD_BODFS) {
      return ASSEMBLY_DISPLAY_TEXT.bodfsAssembly;
    }
    return '';
  },
  // eslint-disable-next-line max-len
  [checked, fulfillment, IN_HOME_ASSEMBLY, IN_HOME_INSTALL, IN_STORE_ASSEMBLY, HOME_AND_STORE_ASSEMBLY, BODFS_ASSEMBLY_ELIGIBLE, isTwoTileEnabled]);

  const toolTipContentHeading = useMemo(
    () => {
      const headingTexts = {
        install: 'Installation Process',
        assembly: 'Assembly Process'
      };
      return type === attachLaborType.INSTALL ? headingTexts.install : headingTexts.assembly;
    },
    [type]);

  const contentList = useMemo(() => {

    if ((IN_STORE_ASSEMBLY || HOME_AND_STORE_ASSEMBLY) && fulfillment === FULFILLMENT_METHOD_BOPIS) {
      return [];
    }

    if ((isBODFSAssembly || onlyBODFSAssemblyEligible || inStoreAvailableForBODFS)) {
      return [{ shortText: 'Professional assembly of grill to manufacturer’s specifications for free delivery.' }];
    }

    return priceIncludes;
  }, [IN_STORE_ASSEMBLY, HOME_AND_STORE_ASSEMBLY, BODFS_ASSEMBLY_ELIGIBLE, fulfillment, priceIncludes]);

  const descriptionList = useMemo(() => {
    const TOOLTIP_DESCRIPTION_LIST = [
      { longText: `Assembly times may vary and we cannot guarantee orders will be available within 2 hours.
      We'll email when your order is ready.` }
    ];
    // eslint-disable-next-line max-len
    const TOOLTIP_BODFS_ASSEMBLY_ELIGIBLE = [{ longText: `We'll do the work to put your grill together and deliver it to you already assembled.
     Delivery does not include hooking up a grill to any natural gas source or propane tank.` }];

    if (!!checked && (IN_STORE_ASSEMBLY || HOME_AND_STORE_ASSEMBLY) && fulfillment === FULFILLMENT_METHOD_BOPIS) {
      return [];
    }

    if ((IN_STORE_ASSEMBLY && fulfillment === FULFILLMENT_METHOD_BOPIS)
      || (onlyStoreAssemblyEligible
      && (fulfillment === FULFILLMENT_METHOD_STH || fulfillment === FULFILLMENT_METHOD_BODFS))) {
      return TOOLTIP_DESCRIPTION_LIST;
    }

    if (((fulfillment === FULFILLMENT_METHOD_BODFS || fulfillment === FULFILLMENT_METHOD_BOSS)
      && BODFS_ASSEMBLY_ELIGIBLE)
    || (fulfillment === FULFILLMENT_METHOD_BOPIS && !IN_STORE_ASSEMBLY && BODFS_ASSEMBLY_ELIGIBLE)) {
      return TOOLTIP_BODFS_ASSEMBLY_ELIGIBLE;
    }

    return installProcess;
  }, [checked, IN_STORE_ASSEMBLY, HOME_AND_STORE_ASSEMBLY, BODFS_ASSEMBLY_ELIGIBLE, fulfillment, installProcess]);

  let addOnType = '';

  useEffect(() => {
    if (!checked) {
      const uncheckPayload = {
        checked: false,
        addOnType: cartBinding.ATTACH_LABOR,
        type,
        itemId
      };

      if (isBODFSAssembly) {
        addOnType = cartBinding.ATTACH_LABOR_STORE_ASSEMBLY;
        onChange({
          ...uncheckPayload,
          addOnType: cartBinding.ATTACH_LABOR_STORE_ASSEMBLY,
          isBODFSAssembly: true
        });
        return;
      }

      if ((HOME_AND_STORE_ASSEMBLY && fulfillment === FULFILLMENT_METHOD_BOPIS) || IN_STORE_ASSEMBLY) {
        addOnType = cartBinding.ATTACH_LABOR_STORE_ASSEMBLY;
        onChange({
          ...uncheckPayload,
          addOnType: cartBinding.ATTACH_LABOR_STORE_ASSEMBLY,
        });
        return;
      }

      onChange(uncheckPayload);
    } else {
      if (isBODFSAssembly
        || (onlyBODFSAssemblyEligible && fulfillment !== FULFILLMENT_METHOD_BODFS)) {
        addOnType = cartBinding.ATTACH_LABOR_STORE_ASSEMBLY;
        onChange({
          checked: true,
          addOnType,
          type,
          itemId,
          isBODFSAssembly: true
        });
        return;
      }
      if (HOME_AND_STORE_ASSEMBLY) {
        addOnType = fulfillment === FULFILLMENT_METHOD_BOPIS
          ? cartBinding.ATTACH_LABOR_STORE_ASSEMBLY : cartBinding.ATTACH_LABOR;
        if (fulfillment === FULFILLMENT_METHOD_BOPIS) {
          onChange({
            checked: true,
            addOnType,
            type
          });
        } else if (fulfillment === FULFILLMENT_METHOD_STH) {
          onChange({
            checked: true,
            addOnType,
            itemId,
            type
          });
        } else {
          onChange({
            checked: true,
            addOnType,
            itemId,
            type
          });
        }

        return;
      }

      if (IN_STORE_ASSEMBLY) {
        addOnType = cartBinding.ATTACH_LABOR_STORE_ASSEMBLY;
        if (fulfillment === FULFILLMENT_METHOD_BOPIS) {
          onChange({
            checked: true,
            addOnType,
            itemId,
            type
          });

        } else {
          onChange({
            checked: false,
            addOnType,
            itemId,
            type
          });
        }
        return;
      }

      if (IN_HOME_INSTALL || IN_HOME_ASSEMBLY) {
        addOnType = cartBinding.ATTACH_LABOR;
        if (fulfillment === FULFILLMENT_METHOD_STH) {
          onChange({
            checked: true,
            addOnType,
            itemId,
            type
          });
        } else {
          onChange({
            checked: false,
            addOnType,
            itemId,
            type
          });
        }
      }
    }
  }, [HOME_AND_STORE_ASSEMBLY, IN_STORE_ASSEMBLY, isBODFSAssembly, checked, itemId, fulfillment]);

  return {
    heading,
    subHeading,
    userOptions,
    toolTipContentHeading,
    contentList,
    descriptionList,
    displayDisabledText,
    assemblyDisplayText,
    checked,
    setChecked,
    BODFS_ASSEMBLY_ELIGIBLE,
    addOnType
  };
}
