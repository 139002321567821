import React, { useContext, useState } from 'react';
import { string, func } from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { VisualPlaceholder } from './loader/VisualPlaceholder';
import PackagesMiniProvider from './PackagesMiniProvider';
import { dataModel } from './dataModel';
import { VisualPackageSummary } from './subcomponents/VisualPackageSummary';
import { VisualPackageInfo } from './subcomponents/VisualPackageInfo';
import { VisualProductImages } from './subcomponents/VisualProductImages';
import './packages-mini-visual.scss';

const PackagesMiniVisualComponent = ({ itemId, callOnLoaded }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const [doRender, setDoRender] = useState(true);
  const handleDoRenderFalse = () => setDoRender(false);

  if (!doRender) return <></>;

  return (
    <div className="packages-mini-visual" data-testid="packages-mini" data-component="PackagesMiniVisual">
      <PackagesMiniProvider
        itemId={itemId}
        callOnLoaded={callOnLoaded}
        placeholder={<VisualPlaceholder />}
        callOnNoPackageData={handleDoRenderFalse}
      >
        <Row>
          {!isMobile && (
            <Col
              xs="12"
              sm="7"
              className="packages-mini-visual__desktop-images-container"
              flatten
            >
              <VisualProductImages itemId={itemId} />
            </Col>
          )}
          <Col sm={isMobile ? '12' : '5'} className="packages-mini-visual__info-summary-container">
            <VisualPackageInfo />
            <VisualPackageSummary itemId={itemId} />
          </Col>
        </Row>
      </PackagesMiniProvider>
    </div>
  );
};

const propTypes = {
  itemId: string,
  callOnLoaded: func
};

const defaultProps = {
  itemId: '',
  callOnLoaded: () => {},
};

PackagesMiniVisualComponent.displayName = 'PackagesMiniVisual';
PackagesMiniVisualComponent.propTypes = propTypes;
PackagesMiniVisualComponent.defaultProps = defaultProps;
PackagesMiniVisualComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-packages-mini-visual',
  scrollBuffer: 100,
  delay: 1500,
  preserveCtxVal: 'clientStore',
  placeholder: <VisualPlaceholder />
}, PackagesMiniVisualComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent, { placeholder: <VisualPlaceholder /> });
const PackagesMiniVisual = withErrorBoundary(DynamicComponent);
PackagesMiniVisual.displayName = PackagesMiniVisualComponent.displayName;

export { PackagesMiniVisual };
