import React, { useEffect, useState, Suspense } from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { bool, string } from 'prop-types';
import { clickEvent, loadEvent } from '../../utils/publisher';
import { getPaymentEstimatorDetails } from '../../utils/payment-estimator-utils';
import { PLCN_CARD_IMAGE_SMALL } from '../../utils/constants';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';
import './offers-for-you.style.scss';

export const OffersForYou = ({ isMobile, price, isAppliance, paymentEstimatorLoaded }) => {
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const openModal = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const displayIntroOffer = price < 299;

  function updatePaymentEstimatorDetails() {
    setPaymentEstimatorDetails(getPaymentEstimatorDetails(price, isMobile, isAppliance, false, 'bundles'));
  }

  useEffect(() => {
    if (paymentEstimatorLoaded) {
      loadEvent('plcc-est-bundle');
      updatePaymentEstimatorDetails();
    }
  }, [price, paymentEstimatorLoaded]);

  const {
    displayPrice, promotionTerm
  } = paymentEstimatorDetails || {};

  const paymentEstimatorText = () => {
    return (
      <div className="offers-text">
        <span className="u__bold">Pay ${displayPrice}.00 per mo.** </span>suggested
        payments w/ <span className="u__bold">{promotionTerm}-mo. </span>financing**
        on this <span className="u__bold">${price} </span>purchase
      </div>
    );
  };

  const introOfferText = () => {
    return (
      <div className="offers-text">
        <span className="u__bold">GET UP TO $100 OFF* </span>on
        <br />
        your qualifying purchase.
      </div>
    );
  };

  return (
    <>
      {showDrawer
      && (
        <TermsAndConditionsDrawer
          closeDrawer={closeDrawer}
          open={showDrawer}
          showFinancing
          applyNowClick={() => clickEvent('plcc-est-bundle')}
          price={price}
          isAppliance={isAppliance}
        />
      )}
      <div className="offers-wrapper">
        <span className="offer-label u__bold">Offers for you</span>
        <div className="offers-content">
          <div>
            <Image
              src={PLCN_CARD_IMAGE_SMALL}
              alt="Apply Now"
              title="Apply Now"
              height={50}
              width={50}
              className="offers-card-image"
            />
          </div>
          <div className="offers-flex">
            {displayIntroOffer && introOfferText()}
            {!displayIntroOffer && paymentEstimatorText()}
            <button
              className="u__default-link u--paddingRight offers-no-wrap"
              onClick={openModal}
              type="button"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

OffersForYou.displayName = 'OffersForYou';

OffersForYou.propTypes = {
  isMobile: bool,
  price: string.isRequired,
  isAppliance: bool,
  paymentEstimatorLoaded: bool.isRequired
};

OffersForYou.defaultProps = {
  isMobile: false,
  isAppliance: false
};
