import React, { useState, useEffect } from 'react';
import {
  shape, oneOf, func, bool, string, number, arrayOf
} from 'prop-types';
import { Secure } from '@one-thd/sui-icons';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod/ProductAddOnsPodStencil';
import { ProtectionPlanDrawer } from './ProtectionPlanDrawer';

export const HomeDepotProtectionPlan = ({
  channel,
  onChange,
  optionsForProtectionPlan,
  setErrorProtectionPlan,
}) => {
  const [isDualPathSelected, setIsDualPathSelected] = useState(null);
  const subHeading = 'Select a Home Depot Protection Plan by Allstate for:';
  /** Props for checkbox component */

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.on('dual-path.change', ({ output } = {}) => {
      setIsDualPathSelected(output?.isOptedIn);
    });
  }, []);

  const drawer = (
    <ProtectionPlanDrawer channel={channel} />
  );

  if (isDualPathSelected) {
    return null;
  }

  return (
    <ProductAddOnsPodStencil
      heading="Protect This Item"
      subHeading={subHeading}
      tooltip={drawer}
      icon={<Secure size="large" color="primary" />}
      onChange={onChange}
      channel={channel}
      optionsForProtectionPlan={optionsForProtectionPlan}
      setErrorProtectionPlan={setErrorProtectionPlan}
      radio
    />
  );
};

HomeDepotProtectionPlan.displayName = 'ProductAddOns-HomeDepotProtectionPlan';

HomeDepotProtectionPlan.propTypes = {
  /** Device Type: Mobile or Desktop */
  channel: oneOf(['desktop', 'mobile']),
  /** handler for select or unselect hdpp */
  onChange: func.isRequired,
  optionsForProtectionPlan: shape({
    variables: shape({
      storeId: string,
      price: number,
      protectionPlanParentId: string,
    }),
    ssr: bool,
    skip: bool,
    protectionPlanSkuArr: arrayOf(string),
  }).isRequired,
  setErrorProtectionPlan: func.isRequired
};

HomeDepotProtectionPlan.defaultProps = {
  channel: 'desktop',
};