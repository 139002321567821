import React, { useContext } from 'react';
import { number } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';

import { CalculatorContext } from './subcomponents/CalculatorProvider';
import { hasError } from '../helpers/errorHandlingUtil';

export const CalculatorFooter = ({ selectedArea }) => {
  const {
    addElement,
    calculate: {
      areas,
      areaErrors
    },
    calculateTotal,
    lineItemName,
  } = useContext(CalculatorContext);

  const filteredAreas = selectedArea !== null ? [areas[selectedArea]] : areas;

  return (
    <>
      <Button
        variant="text"
        onClick={() => addElement({ calcByArea: filteredAreas[0].calcByArea })}
        data-testid="add-area"
      >
        + Add {lineItemName}
      </Button>
      <div className="sui-mt-4">
        <Button
          variant="primary"
          disabled={hasError(areas, areaErrors)}
          onClick={() => calculateTotal({ isClick: true })}
        >
          Calculate
        </Button>
      </div>
    </>
  );
};

CalculatorFooter.displayName = 'CalculatorFooter';

CalculatorFooter.propTypes = {
  selectedArea: number
};

CalculatorFooter.defaultProps = {
  selectedArea: null
};