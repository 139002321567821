import React, { useState } from 'react';
import { string, oneOf, func, bool } from 'prop-types';
import { Apron, Assemble } from '@one-thd/sui-icons';
import { Button, DrawerHeader, DrawerBody, Drawer } from '@one-thd/sui-atomic-components';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod/ProductAddOnsPodStencil';
import { virtualAssistanceContactInformation } from '../../../utils/product-add-on-constants';
import { VirtualAssistanceTooltip } from './VirtualAssistanceTooltip';

const VirtualAssistance = (props) => {
  const {
    channel,
    itemId,
    onChange,
    value,
    activateRequestAppointment,
    buttonUrl,
    isGarbageDisposalVirtualAssistance,
    isInstallVirtualAssistance,
    installationCategory
  } = props;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(activateRequestAppointment || value);
  const iframeButtonUrl = buttonUrl?.length > 0
    ? buttonUrl : '/services/i/doorswindows-dp-tv/b66b6826d/form?flow=the_home_depot_product_email';

  const handleChange = (evt) => {
    LIFE_CYCLE_EVENT_BUS.trigger('virtual-assistance.change', { isOptedIn: !checked, itemId });
    onChange({
      itemId,
      checked: evt.target.checked,
      addOnType: evt.target.value,
    });
    setChecked(!checked);
  };

  const displayModal = () => {
    setOpen(true);
  };

  const onDrawerClose = () => {
    setOpen(false);
  };

  const ContactInformation = () => (
    <div className="sui-underline">
      Or call 1-833-HD-APRON
      <a href={`tel:${virtualAssistanceContactInformation.help_fast_contact.tel}`}>
        <span>({virtualAssistanceContactInformation.help_fast_contact.label})</span>
      </a>
    </div>
  );

  const subHeading = (
    <div className="sui-mr-14 sm:sui-mr-0">
      <div className="sui-leading-relaxed">Speak to a Virtual Associate about Doors or Windows today.</div>
      <div className="sui-flex sui-flex-col">
        <span>Monday - Friday from 9AM - 11PM ET &amp;</span>
        <span>Saturday - Sunday from 9AM - 9PM ET.</span>
      </div>
    </div>
  );

  const installVirtualAssistanceSubHeading = (
    <div
      data-testid="installVirtualAssistanceMessage"
      className="sui-text-base sui-mb-2"
    >
      <div className="sui-leading-relaxed">Call us! Our Virtual Associates can help you get it installed.</div>
      <div className="sui-flex sui-flex-col">
        <span>Monday - Saturday from 9AM - 9PM ET &amp;</span>
        <span>Sunday from 9AM - 7PM ET.</span>
      </div>
      <div>
        <a href="tel:18002613498">
          <span className="sui-underline sui-underline-offset-1">1-800-261-3498</span>
        </a>
      </div>
    </div>
  );

  // eslint-disable-next-line quotes
  const heading = (isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance)
    ? 'Need This Installed?'
    : 'Have Questions? We\'re Here to Help.';

  return (
    <div data-component="VirtualAssistance">
      <ProductAddOnsPodStencil
        channel={channel}
        heading={heading}
        subHeading={
          (isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance)
            ? installVirtualAssistanceSubHeading
            : subHeading
        }
        icon={
          (isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance)
            ? <Assemble size="large" />
            : <Apron size="large" />
        }
        onChange={handleChange}
        tooltipOptional={(
          <VirtualAssistanceTooltip
            checked={checked}
            isInstallVirtualAssistance={isInstallVirtualAssistance}
            installationCategory={installationCategory}
            isGarbageDisposalVirtualAssistance={isGarbageDisposalVirtualAssistance}
            channel={channel}
          />
        )}
        contactInfo={(
          (channel === 'mobile' || channel === 'desktop')
          && !isInstallVirtualAssistance && !isGarbageDisposalVirtualAssistance
        ) && (
          <div>
            <ContactInformation />
          </div>
        )}
        userOptions={[
          {
            id: 'item ID',
            value: 'virtual-assistance',
            checked,
            label: 'Request Appointment',
          },
        ]}
        activateRequestAppointment={activateRequestAppointment}
        isInstallVirtualAssistance={isInstallVirtualAssistance}
        isGarbageDisposalVirtualAssistance={isGarbageDisposalVirtualAssistance}
      >
        {!isInstallVirtualAssistance && !isGarbageDisposalVirtualAssistance && (
          <>
            <div className={`${!checked && 'u--hide'} sui-mt-4`}>
              <Button variant="primary" onClick={displayModal} fullWidth>
                Request Appointment
              </Button>
            </div>
          </>
        )}
      </ProductAddOnsPodStencil>
      {open && (
        <Drawer
          open={open}
          onClose={onDrawerClose}
        >
          <DrawerHeader onClose={onDrawerClose} />
          <DrawerBody data-component="VirtualAssistanceDrawer">
            <iframe title="Request Appointment" src={iframeButtonUrl} width="100%" height="900" />
          </DrawerBody>
        </Drawer>
      )}
    </div>
  );
};

VirtualAssistance.displayName = 'ProductAddOns-VirtualAssistance';

VirtualAssistance.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  itemId: string.isRequired,
  onChange: func.isRequired,
  buttonUrl: string,
  value: bool,
  activateRequestAppointment: bool,
  isInstallVirtualAssistance: bool,
  installationCategory: string,
  isGarbageDisposalVirtualAssistance: bool
};

VirtualAssistance.defaultProps = {
  channel: 'desktop',
  value: false,
  activateRequestAppointment: false,
  buttonUrl: '/services/i/doorswindows-dp-tv/b66b6826d/form?flow=the_home_depot_product_email',
  isInstallVirtualAssistance: false,
  installationCategory: null,
  isGarbageDisposalVirtualAssistance: false
};

export { VirtualAssistance };
