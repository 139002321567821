import { useEffect, useState } from 'react';

export const useMACheckAvailability = ({ skip }) => {
  const [isAvailable, setIsAvailable] = useState(skip); // it's available until it's proven it is not
  const [applianceDeliveryStore, setApplianceDeliveryStore] = useState(null);
  const [isLoading, setIsLoading] = useState(!skip);

  useEffect(() => {
    const cb = (event) => {
      const availabilityStatus = event.output?.processedData?.defaultMessagingStatusToUse || '';
      const primaryStoreNumber = event.output?.processedData
        ?.products?.find((product) => !!product.primaryStoreNumber)?.primaryStoreNumber || null;

      if (!skip) {
        setIsAvailable(availabilityStatus === 'AVAILABLE' || availabilityStatus === 'BACK_ORDERED');
        setApplianceDeliveryStore(primaryStoreNumber);
        setIsLoading(false);
      }
    };

    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('check-availability.depot-direct-success', cb);

    return () => {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.off('check-availability.depot-direct-success', cb);
    };
  }, [skip]);

  return {
    isLoading,
    isAvailable,
    applianceDeliveryStore,
  };
};
