import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Heading } from '@thd-olt-component-react/core-ui';
import './ApplianceBundle.style.scss';
import ApplianceBundleItem from '../BundleItem/ApplianceBundleItem';
import Buybox from '../Buybox/Buybox';
import AnchorItem from '../AnchorItem/AnchorItem';

const Bundle = ({
  bundleHeading,
  anchorItemTitle,
  bundleItemTitle,
  cartOptions,
  cartReqParams,
  itemId,
  itemInCart,
  message,
  onAddToCartClick,
  openInNewTab,
  updateCheckedItems,
  currentItem = {},
  pricing,
  product,
  products,
  checkedItems,
  storeId
}) => {

  const nonPrimary = products && products.slice(1).filter((item) => !item.isAnchor);
  const productCount = nonPrimary.length;

  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && products) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('frequently-bought-together.ready', { products });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const ApplianceItems = () => {
    if (!nonPrimary) return null;

    const getPriceDifference = (item) => {
      if (item.itemId !== currentItem.itemId) {
        const alternateItemsMapExists = nonPrimary.some((val) => val.product?.pricing?.mapAboveOriginalPrice !== null);
        const diff = currentItem.product?.pricing?.value - item.product?.pricing?.value;
        if (diff === 0 || alternateItemsMapExists) return '';
        return diff > 0 ? `/ -$${diff.toFixed(2)}` : `/ +$${Math.abs(diff).toFixed(2)}`;
      }
      return '';
    };

    const buildAlternateItems = () => {
      return nonPrimary.map((item) => {
        return {
          label: item.product?.info?.label,
          itemId: item.product?.identifiers?.itemId,
          price: getPriceDifference(item)
        };
      });
    };

    const updateCurrentSelection = (newItemId) => {
      updateCheckedItems(currentItem.itemId, newItemId);
    };

    const hasMultipleNonPrimaries = nonPrimary.length > 1;

    return (
      <ApplianceBundleItem
        key={`bundle-item-${currentItem.itemId}`}
        anchorProduct={product}
        checked
        openInNewTab={openInNewTab}
        product={currentItem.product}
        bundleItemTitle={bundleItemTitle}
        alternateItems={hasMultipleNonPrimaries ? buildAlternateItems() : null}
        updateCurrentSelection={hasMultipleNonPrimaries ? updateCurrentSelection : null}
        storeId={storeId}
      />
    );
  };

  return (
    <div data-component="FrequentlyBoughtTogetherMA">
      <Col>
        <Heading title={bundleHeading} underline />
      </Col>
      <Col className="fbt-appliance-bundle">
        <Col nopadding sm="8" className="fbt-appliance-bundle__products-container">
          <Col nopadding className="fbt-appliance-bundle__anchor-wrapper" xs="5">
            <AnchorItem
              itemId={itemId}
              itemInCart={itemInCart}
              storeId={storeId}
              applianceBundle
              anchorItemTitle={anchorItemTitle}
            />
          </Col>
          <Col nopadding className="fbt-appliance-bundle__plus-sign" xs="1">+</Col>
          <Col flatten xs="6">
            <ApplianceItems />
          </Col>
        </Col>
        <Col nopadding xs="12" sm="4" className="fbt-appliance-bundle__buybox-wrapper">
          <Col nopadding fallback="0" xs="2" className="fbt-appliance-bundle__equal-sign">=</Col>
          <Col nopadding xs="12" sm="10" className="fbt-appliance-bundle__buybox">
            {productCount >= 1 && (
              <Buybox
                cartOptions={cartOptions}
                cartReqParams={cartReqParams}
                finalPriceMessage
                itemId={itemId}
                message={message}
                onAddToCartClick={onAddToCartClick}
                pricing={pricing}
                strikethrough
                applianceBundle
                checkedItems={checkedItems}
                storeId={storeId}
              />
            )}
          </Col>
        </Col>
      </Col>
    </div>
  );
};

Bundle.propTypes = {
  cartOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cartReqParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  itemId: PropTypes.string.isRequired,
  itemInCart: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  openInNewTab: PropTypes.bool.isRequired,
  pricing: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string
  })),
  checkedItems: PropTypes.arrayOf(PropTypes.string),
  storeId: PropTypes.string.isRequired,
  bundleHeading: PropTypes.string,
  anchorItemTitle: PropTypes.string,
  bundleItemTitle: PropTypes.string,
  updateCheckedItems: PropTypes.func,
  currentItem: PropTypes.shape({})
};

Bundle.defaultProps = {
  products: [],
  bundleHeading: '',
  anchorItemTitle: '',
  bundleItemTitle: 'Item',
  updateCheckedItems: null,
  checkedItems: [],
  currentItem: {}
};

Bundle.displayName = 'FBTApplianceBundle';

export default Bundle;
