import { useEffect } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import thdCustomer from '@thd-olt-global/thd-customer';
import * as helpers from '../components/InstantCheckoutHelper';
import { track } from '../components/analytics/instant-checkout-analytics';

export const useUserDetails = ({ itemId, storeId }) => {
  const productOptions = {
    ssr: false,
    variables: {
      itemId,
      storeId,
    },
  };

  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useDataModel('clientOnlyProduct', productOptions);
  const { fulfillment = {} } = productData?.product || {};

  const shouldFetchInstantCheckout =
    (thdCustomer.userID || thdCustomer.svocID) &&
    productData &&
    fulfillment &&
    helpers.isInstantCheckoutEligible(fulfillment);

  const { THD_CUSTOMER, THD_USER_SESSION, THD_USER } = helpers.getCookies([
    'THD_CUSTOMER',
    'THD_USER_SESSION',
    'THD_USER',
  ]);

  let variables = {
    userId: thdCustomer.userID,
    customerAccountId: thdCustomer.svocID,
  };

  if (!THD_CUSTOMER) {
    variables = {
      ...variables,
      thdUserCookie: THD_USER,
    };
  }

  const {
    data: icData,
    loading: icLoading,
    error: icError,
  } = useDataModel('instantCheckout', {
    skip: !shouldFetchInstantCheckout,
    variables,
    context: {
      headers: {
        Authorization: THD_CUSTOMER || THD_USER_SESSION,
      },
    },
  });

  useEffect(() => {
    if (productData && fulfillment) {
      if (
        !helpers.isInstantCheckoutEligible(fulfillment) &&
        helpers.isGuestInstantCheckoutEligible(fulfillment)
      ) {
        track({ ICTemplate: 'DEFAULT' });
      }
    }
  }, [productData]);
  useEffect(() => {
    if (icData && !icError) {
      track({ ICTemplate: icData?.instantCheckout?.displayOption });
    }
  }, [icData, icLoading]);
  const ICModel = icData?.instantCheckout;

  return [
    {
      ICTemplate: ICModel?.displayOption || 'DEFAULT',
      addressCity: ICModel?.addressCity || '',
      addressZipCode: ICModel?.addressZipCode || '',
      xref: ICModel?.xref || '',
      displayCardBrand: ICModel?.displayCardBrand || '',
      loading: productLoading || icLoading,
    },
  ];
};
