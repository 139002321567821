import React, { useContext, useCallback, useState, useRef } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ShareWeb } from '@one-thd/sui-icons';
import { Typography, Popover, Button } from '@one-thd/sui-atomic-components';
import { SharebeltContext } from '../../Sharebelt.context';
import ShareBeltTooltip from './ShareBeltTooltip';
import { dataModel as sharebeltDataModel } from '../../dataModel';

const ShareBeltShareButton = ({
  collectionImageSrc,
  email,
  hrefPath,
  pinterestTitle
}) => {

  const {
    channel = 'desktop'
  } = useContext(ExperienceContext);
  const { storeId } = useStore();

  const {
    vertical,
    horizontal,
    itemId,
    collectionId
  } = useContext(SharebeltContext);

  let shareButtonTrigger = useRef(null);
  const [openPopOver, setopenPopOver] = useState(false);

  const handleClick = () => {
    setopenPopOver(!openPopOver);
  };

  const handleClose = () => {
    setopenPopOver(false);
  };
  const closeTooltip = useCallback(() => {
    if (shareButtonTrigger) {
      shareButtonTrigger.current.click();
    }
  }, []);

  const shareBeltButtonClasses = classNames({
    'sui-mr-2': horizontal,
    'sui-inline-block': vertical,
    'sui-flex': horizontal,
    'sui-mr-0': vertical
  });
  const shareBeltIconClasses = classNames('sui-inline-block', 'sui-float-left', {
    'sui-pr-2': horizontal,
    'sui-mt-1': vertical,
    'sui-mr-6': vertical,
    'sui-ml-6': vertical
  });
  const shareBeltShareClasses = classNames('sui-inline-block', {
    'sui-pr-4': vertical, 'sui-pl-4': vertical
  });
  const shareBeltItemClasses = classNames('sui-mb-2', 'sui-inline-block', 'sui-mt-2',
    'hover:sui-cursor-pointer', 'last:hover:sui-no-underline');
  // @todo check mobile requirements
  // TODO: can we just return null on MW?
  if (channel?.toLowerCase() !== 'desktop') {
    return (
      <></>
    );
  }

  return (
    <div className={shareBeltItemClasses} data-component="ShareBeltShareButton">
      <Popover
        open={openPopOver}
        onClose={handleClose}
        placement="bottom"
        anchorEl={shareButtonTrigger?.current}
        title="Share"
        data-component="ShareBeltShareTooltip"
        className="sui-z-10"
      >
        <ShareBeltTooltip
          storeId={storeId}
          collectionImageSrc={collectionImageSrc}
          email={email}
          itemId={itemId}
          collectionId={collectionId}
          toolTipClose={closeTooltip}
          hrefPath={hrefPath}
          pinterestTitle={pinterestTitle}
        />
      </Popover>
      <Button
        className={shareBeltButtonClasses}
        tabIndex={0}
        ref={shareButtonTrigger}
        onClick={handleClick}
        variant="ghost"
      >
        <span className={shareBeltIconClasses}>
          <ShareWeb size="regular" />
        </span>
        <span className={shareBeltShareClasses}>
          <Typography color="primary" variant="body-base">Share</Typography>
        </span>
      </Button>
    </div>
  );
};

ShareBeltShareButton.displayName = 'ShareBeltShareButton';

ShareBeltShareButton.dataModel = sharebeltDataModel;

ShareBeltShareButton.propTypes = {
  collectionImageSrc: string,
  email: bool,
  hrefPath: string,
  pinterestTitle: string,
};

ShareBeltShareButton.defaultProps = {
  collectionImageSrc: null,
  email: false,
  hrefPath: typeof window !== 'undefined' ? window.location.href : '',
  pinterestTitle: typeof document !== 'undefined' ? document.title : '',
};

export default ShareBeltShareButton;
