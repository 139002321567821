/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { handleUpdateCall, useCartMutation } from './helpers/mutation-helper';
import { getRawCartResp } from './useCartDataModel';
import { optimalOverride } from '../util/utils';

export const useDeleteLineItem = () => {
  const [deleteLineItem, deleteItemResponse] = useCartMutation('deleteItem');

  // mutation wrapper
  const deleteItems = async (idList, skipOptimistic, onComplete = null) => {
    // build request variables
    const variables = {
      cartRequest: {
        items: {
          itemReq: [],
        }
      },
      ...optimalOverride()
    };
    variables.cartRequest.items.itemReq = idList.map((id) => ({ id }));

    // get optimistic response
    // const optimisticResponse = skipOptimistic ? null : getOptimisticResponse(idList);

    // returns promise
    return handleUpdateCall({
      mutationName: 'deleteItem',
      mutator: deleteLineItem,
      variables,
      optimisticResponse: null,
      onComplete
    });
  };

  return [deleteItems, deleteItemResponse];
};

export const getOptimisticResponse = (idList) => {
  // optimistic needs to be in pre-patched form as it will
  // be returned via getCartDataModel
  let { cartInfo } = getRawCartResp();
  cartInfo = removeItemFromCart(cartInfo, idList);
  cartInfo.optimistic = true;
  return { deleteItem: cartInfo };
};

const removeItemFromCart = (cartInfo, idList) => {
  try {
    // adjust count by quantity of each item
    const keep = [];
    // eslint-disable-next-line array-callback-return
    cartInfo.items.filter((item) => {
      if (idList.includes(item.id)) {
        cartInfo.itemCount -= item.quantity;
      } else {
        keep.push(item);
      }
    });
    cartInfo.items = keep;
  } catch (err) {
    console.error(err);
  }
  return cartInfo;
};