import React from 'react';
import {
  bool,
  string,
  oneOf,
  arrayOf
} from 'prop-types';
import { ProductAddonsTooltip } from '../../common/ProductAddOnsTooltip';

const AttachLaborTooltip = ({
  label,
  contentHeading,
  contentDescriptionList,
  contentSubHeading,
  contentList,
  channel,
  checked
}) => {
  const popoverContent = (
    <div className="product-add-on-tooltip-content" data-channel={channel} data-component="AttachLaborTooltip">
      {contentDescriptionList?.length ? (
        <>
          <div className="product-add-on-tooltip-content__body">
            {
              (contentDescriptionList)
                .map((listItem, index) => {
                  return <p className="product-add-on-tooltip-content__body__" key={index}>{listItem}</p>;
                })
            }
          </div>
        </>
      ) : ''}

      {contentList?.length ? (
        <>
          <div className="product-add-on-tooltip-content__sub-heading">{contentSubHeading}</div>
          <ul className="product-add-on-tooltip-content__list">
            {contentList.map((listItem, index) => listItem && <li key={index}> {listItem} </li>
            )}
          </ul>
        </>
      ) : ''}
    </div>
  );

  return (
    <div>
      <ProductAddonsTooltip
        tooltipContent={popoverContent}
        title={contentHeading}
        label={label}
        checked={checked}
      />
    </div>
  );
};

AttachLaborTooltip.displayName = 'ProductAddOns-tooltips-attach-labor-tooltip';

AttachLaborTooltip.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  /** Render large or small version */
  /** toolTipLabel -> Renders as anchor link for the tooltip */
  label: string,
  /** Whether or not the checkbox is checked */
  checked: bool,
  /** toolTipContentHeading -> Renders as Heading for tooltip content */
  contentHeading: string.isRequired,
  /** toolTipContentBody -> Renders tooltip body text */
  contentDescriptionList: arrayOf(string).isRequired,
  /** toolTipContentBody -> Renders tooltip body text */
  contentSubHeading: string.isRequired,
  /** toolTipContentBody -> Renders tooltip body text */
  contentList: arrayOf(string).isRequired,
};

AttachLaborTooltip.defaultProps = {
  label: 'What to Expect',
  channel: 'desktop',
  checked: false
};

export { AttachLaborTooltip };
