import React, { useState, useMemo } from 'react';
import { string, oneOf, func, bool } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Assemble } from '@one-thd/sui-icons';
import { RequestAQuoteCta } from '@thd-olt-component-react/home-services-cta-buttons';
import { DualPathTooltip } from './DualPathTooltip';
import { ProductAddOnsPodStencil } from '../../common/product-add-ons-pod';
import { dualPathContactInformation } from '../../../utils/product-add-on-constants';
import {
  getDualPathHeading, getDualPathButtonText, getDualPathCheckbox, DUAL_PATH_CATEGORIES
} from './dualPathCategories';

const DualPath = ({
  channel,
  itemId,
  onChange,
  category,
  value
}) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (evt) => {
    LIFE_CYCLE_EVENT_BUS.trigger('dual-path.change', { isOptedIn: !checked, itemId });
    onChange({
      itemId,
      checked: evt.target.checked,
      addOnType: evt.target.value,
      category: DUAL_PATH_CATEGORIES[category]
    });
    setChecked(!checked);
  };

  const subHeading = useMemo(() => {
    const categoryKey = DUAL_PATH_CATEGORIES[category];
    if (['TANK_WATER_HEATER', 'PLUMBING_REPAIR', 'WATER_HEATER_REPAIR'].includes(categoryKey)) {
      return (
        <div>Need help fast? Call&nbsp;
          <a className="sui-underline sui-text-primary" href={`tel:${dualPathContactInformation[categoryKey].tel}`}>
            {dualPathContactInformation[categoryKey].label}
          </a>
        </div>
      );
    } if (['BATHROOM_REMODEL'].includes(categoryKey)) {
      return (
        <div><div className="sui-mb-2">Call today to speak to a consultant</div>
          <a className="sui-underline sui-text-primary" href={`tel:${dualPathContactInformation[categoryKey].tel}`}>
            {dualPathContactInformation[categoryKey].label}
          </a><span className="sui-ml-2 sui-font-bold">OR</span>
        </div>
      );
    } if (['WALKIN_TUBS'].includes(categoryKey)) {
      return (
        <div>
          <div>Get started with a free consultation</div>
        </div>
      );
    }

    return <div>A local pro will take care of the job for you</div>;
  }, [checked, category]);

  return (
    <>
      <ProductAddOnsPodStencil
        channel={channel}
        heading={getDualPathHeading(category)}
        subHeading={subHeading}
        icon={<Assemble size="large" />}
        onChange={handleChange}
        tooltip={(
          <DualPathTooltip
            checked={checked}
            category={category}
          />
        )}
        userOptions={[
          {
            id: 'item ID',
            value: 'dual-path',
            checked,
            label: getDualPathCheckbox(category)
          }
        ]}
      >
        <div className={`${!checked && 'u--hide'} sui-mt-4`}>
          <QueryProvider cacheKey="request-a-quote">
            <RequestAQuoteCta itemId={itemId} buttonText={getDualPathButtonText(category)} hideContactInfo />
          </QueryProvider>
        </div>
      </ProductAddOnsPodStencil>
    </>
  );
};

DualPath.displayName = 'ProductAddOns-DualPath';

DualPath.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  itemId: string.isRequired,
  onChange: func.isRequired,
  category: string,
  value: bool
};

DualPath.defaultProps = {
  channel: 'desktop',
  category: 'brio_inaisle_sfi_generalfs',
  value: false
};

export { DualPath };