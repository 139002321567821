import React, { useEffect } from 'react';
import {
  node, oneOfType, arrayOf, func, string
} from 'prop-types';
import { QuantityAdjustmentsContext } from '@thd-olt-component-react/bundling-product-tabs';
import { PackagesMiniContext } from './PackagesMiniContext';
import { usePackages } from '../hooks/usePackages';
import { getPackageData } from '../utils';

const PackagesMiniProvider = ({
  children, itemId, callOnLoaded, placeholder, callOnNoPackageData
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('packages-mini.ready'); }, []);

  const {
    data,
    loading,
    hasEnoughPackageData,
    itemMap,
    setItemMap,
    fulfillmentFetched,
    setFulfillmentFetched
  } = usePackages({ itemId, callOnLoaded });

  if (loading) return placeholder;

  if (!hasEnoughPackageData) {
    callOnNoPackageData();
    return null;
  }

  const { selectedProducts, packageModel, packageCategory } = getPackageData(data);

  return (
    <PackagesMiniContext.Provider value={{
      products: selectedProducts,
      packageModel,
      packageCategory,
      anchorItemId: itemId
    }}
    >
      <QuantityAdjustmentsContext.Provider
        value={{
          itemMap,
          setItemMap,
          fulfillmentFetched,
          setFulfillmentFetched
        }}
      >
        {children}
      </QuantityAdjustmentsContext.Provider>
    </PackagesMiniContext.Provider>
  );
};

PackagesMiniProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]),
  itemId: string,
  callOnLoaded: func,
  placeholder: oneOfType([
    arrayOf(node),
    node
  ]),
  callOnNoPackageData: func,
};

PackagesMiniProvider.defaultProps = {
  children: null,
  itemId: '',
  callOnLoaded: () => {},
  placeholder: null,
  callOnNoPackageData: () => {}
};

export default PackagesMiniProvider;
