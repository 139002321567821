import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import { ProductAddonsTooltip } from '../../common/ProductAddOnsTooltip';

const tooltipContent = (
  <div className="sui-p-2 sui-w-96" data-component="VirtualAssistanceTooltip">
    <div className="sui-pb-3 sui-leading-loose">
      <p>What can we help you with?</p>
      <ul className="sui-list-disc sui-ml-4 sui-pl-3">
        <li>Available styles</li>
        <li>How to measure</li>
        <li>Custom door/window designs</li>
        <li>Pro orders</li>
        <li>Installation</li>
      </ul>
    </div>
    <ol className="sui-ml-4 sui-list-decimal sui-leading-relaxed">
      <li className="sui-pb-3">
        <p className="sui-font-bold">Call now or Request a free virtual appointment</p>
        <p>
          Call 1-833-HDAPRON (432-7766) Monday - Friday from 9 a.m - 11 p.m EST &amp; Saturday - Sunday
          from 9 a.m - 9 p.m EST, or request a free
          virtual appointment to schedule a time to speak with a door and window associate.
        </p>
      </li>
      <li className="sui-pb-3">
        <p className="sui-font-bold">Speak with an associate</p>
        <p>
          How can we help? Whether you have questions about product availability or features or
          need a quote for a custom door or window solution, we&apos;ve got you covered.
        </p>
      </li>
      <li className="sui-pb-3">
        <p className="sui-font-bold">We&apos;ll follow up</p>
        <p>We&apos;ll make sure you have everything you need for your project!</p>
      </li>
    </ol>
  </div>
);

const toiletVirtualAssistancePopoverContent = ({
  channel,
}) => {
  return (
    <div
      className="sui-w-72 sui-py-3 sui-px-0"
      data-component="ToiletVirtualAssistanceTooltip"
      data-channel={channel}
    >
      <div className="sui-text-xs sui-leading-loose">
        After you discuss your project and pay an installation fee,
        a professional will call you back within one business day
        to schedule your installation.
      </div>

      <div className="sui-font-bold sui-mt-5"><b>What You Get</b></div>

      <ul className="sui-text-xs sui-list-disc sui-pl-6 sui-leading-loose">
        <li key="0">Haul away and removal of old toilet</li>
        <li key="1">New toilet installed with all the supplies necessary
          including flange bolts, wax rings and supply line
        </li>
        <li key="2">Clean up</li>
      </ul>

      <div className="sui-text-xs sui-mt-5 sui-leading-loose">
        *Pricing varies depending on size of project or code regulation requirements.
        Items delivered from the store incur an additional fee.
        Specialized installs (concealed p-traps and those requiring electrical work) do not qualify.
      </div>
    </div>
  );
};

const faucetVirtualAssistancePopoverContent = ({
  channel,
}) => {
  return (
    <div
      className="sui-w-72 sui-py-3 sui-px-0"
      data-component="FaucetVirtualAssistanceTooltip"
      data-channel={channel}
    >
      <div className="sui-text-xs sui-leading-loose">
        After you discuss your project and pay the installation fee,
        a professional will call you back within one business day
        to schedule your installation.
      </div>

      <div className="sui-font-bold sui-mt-5"><b>What You Get</b></div>

      <ul className="sui-text-xs sui-list-disc sui-pl-6 sui-leading-loose">
        <li key="0">Haul away and removal of old faucet</li>
        <li key="1">New faucet installed with all the supplies necessary and supply line
        </li>
        <li key="2">Testing of the faucet and debris cleanup</li>
      </ul>

      <div className="sui-text-xs sui-mt-5 sui-leading-loose">
        *Additional charges may apply for drain repair or to replace supply lines and shutoff valves.
        We may need to do a site vist before quoting your projesct due to the complexity of some installations.
        Installation costs vary, and prices are subject to change.
      </div>
    </div>
  );
};

const garbageDisposalVirtualAssistancePopoverContent = ({
  channel,
}) => {
  return (
    <div
      className="sui-w-72 sui-py-3 sui-px-0"
      data-component="GarbageVirtualAssistanceTooltip"
      data-channel={channel}
    >
      <div className="sui-text-xs sui-leading-loose">
        After you discuss your project and pay an installation fee,
        a professional will call you back within one business day
        to schedule your installation.
      </div>

      <div className="sui-font-bold sui-mt-5"><b>What You Get</b></div>

      <ul className="sui-text-xs sui-list-disc sui-pl-6 sui-leading-loose">
        <li key="0">Removal and haul away of your old garbage disposal</li>
        <li key="1">Installation of your newly purchased garbage disposal</li>
        <li key="2">Connection of existing plumbing and electrical supply</li>
        <li key="3">Testing of the new garbage disposal</li>
        <li key="4">Cleanup</li>
      </ul>

      <div className="sui-text-xs sui-mt-5 sui-leading-loose">
        *Installation is available for replacement garbage disposals only.
        Installation costs vary, and prices are subject to change.
      </div>
    </div>
  );
};

const VirtualAssistanceTooltip = ({
  checked,
  isInstallVirtualAssistance,
  installationCategory,
  isGarbageDisposalVirtualAssistance,
  channel
}) => {
  let popoverContent = tooltipContent;
  const category = installationCategory?.toLowerCase();
  if (isInstallVirtualAssistance) {
    if (category === 'toilet') {
      popoverContent = toiletVirtualAssistancePopoverContent({ channel });
    } else if (category === 'faucet') {
      popoverContent = faucetVirtualAssistancePopoverContent({ channel });
    }
  } else if (isGarbageDisposalVirtualAssistance) {
    popoverContent = garbageDisposalVirtualAssistancePopoverContent({ channel });
  }
  return (
    <div className="sui-flex sui-items-center sui-my-2">
      <ProductAddonsTooltip
        tooltipContent={popoverContent}
        checked={checked}
        title={category === 'faucet' ? 'What to Expect' : 'How it Works'}
        zIndexClassName={isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance ? 'sui-z-max' : 'sui-z-10'}
        isInstallVirtualAssistance={isInstallVirtualAssistance}
        isGarbageDisposalVirtualAssistance={isGarbageDisposalVirtualAssistance}
      />
    </div>
  );
};

VirtualAssistanceTooltip.displayName = 'ProductAddOns-virtual-assistance-tooltip';

VirtualAssistanceTooltip.propTypes = {
  /** Whether or not the checkbox is checked  */
  checked: bool,
  /** Whether or not this is for toilet virtual assistance */
  isInstallVirtualAssistance: bool,
  installationCategory: string,
  /** Whether or not this is for Garbge Disposal virtual assistance */
  isGarbageDisposalVirtualAssistance: bool,
  /** Channel will be mobile if mobile */
  channel: oneOf(['desktop', 'mobile']),
};

VirtualAssistanceTooltip.defaultProps = {
  checked: false,
  isInstallVirtualAssistance: false,
  installationCategory: null,
  isGarbageDisposalVirtualAssistance: false,
  channel: 'desktop',
};

export { VirtualAssistanceTooltip };