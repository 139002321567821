import React, { Suspense } from 'react';
import { string } from 'prop-types';
import { extend, useDataModel } from '@thd-nucleus/data-sources';
import { ProductPod, ProductImage } from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { useStore } from '@thd-nucleus/experience-context';
import { DigitalTicketImporter } from './DigitalTicketImporter';
import { dataModel } from './DigitalTicketDataModel';

export const DigitalTicket = ({ itemId }) => {

  const params = typeof window !== 'undefined' ? window?.location?.search : '';
  const urlParam = new URLSearchParams(params);
  const { storeId, isLocalized } = useStore();
  let qrScanned = urlParam?.get('qrScanned') === 'digital-ticket';
  const maxProductsCount = 16;
  const expirationDate = Date.now() + 604800000;

  const localStorageReady = typeof window !== 'undefined' && window.localStorage;

  let scannedTickets = localStorageReady && localStorage.getItem('digitalTickets')
    ? JSON.parse(localStorage.getItem('digitalTickets')) : [];
  scannedTickets = scannedTickets.length && scannedTickets[0] !== null ? scannedTickets
    .filter((item) => typeof item === 'object').filter((item) => item.expirationDate > Date.now()) : [];
  let ssku = localStorageReady && localStorage.getItem('ssku') && localStorage.getItem('ssku') !== 'undefined'
    ? JSON.parse(localStorage.getItem('ssku')) : [];
  const scannedTicketIndex = scannedTickets.length ? scannedTickets.findIndex((obj) => obj.itemId === itemId) : -1;

  let ticket;

  if (qrScanned || ssku.indexOf(itemId) >= 0 || scannedTicketIndex >= 0) {
    qrScanned = true;

    if (scannedTicketIndex >= 0) {
      ticket = scannedTickets.splice(scannedTicketIndex, 1);
    }
    scannedTickets.unshift(ticket ? ticket[0] : { itemId, expirationDate });

    if (scannedTickets.length > maxProductsCount) {
      scannedTickets = scannedTickets.slice(0, maxProductsCount);
    }

    if (localStorageReady) {
      localStorage.setItem('digitalTickets', JSON.stringify(scannedTickets));
    }
  }

  const { data, loading } = useDataModel('products', {
    variables: {
      itemIds: scannedTickets.map((item) => item.itemId),
      storeId,
      dataSource: 'digital-ticket'
    },
    skip: !qrScanned || !isLocalized,
    ssr: false
  });

  if (!qrScanned || (loading && !data)) {
    return null;
  }

  return (
    <Suspense fallback="Loading...">
      <DigitalTicketImporter digitalTickets={data?.products} />
    </Suspense>
  );

};

DigitalTicket.propTypes = {
  itemId: string.isRequired
};

DigitalTicket.displayName = 'DigitalTicket';

DigitalTicket.dataModel = extend(dataModel);
