/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSticker,
  PodSection,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType,
  object,
  any,
  func,
  array
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import './FbrProductPod.scss';

const FbrProductPod = (props) => {

  const {
    product,
    itemId,
    storeId,
    position,
    parent,
    hideRating,
    scheme,
    anchorProduct,
    onclick,
    fbrItem,
    recommendedFeatureSet,
    recommendedProductSet,
    current
  } = props;

  const merchRec = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-${anchorProduct}-_-${position}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [anchorProduct, position]);

  const { preferredPriceFlag } = product?.pricing || {};

  const theme = useTheme(FbrProductPod);
  const { hideAddToList } = theme.props;

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2"
      analyticsData={{ parent, position, scheme, anchorProduct }}
      render={(pod) => (
        <div className={(props?.current ? 'current-product-pod' : ' fbr-product-pod ')}>
          <PodSection columnAlign>
            {!hideAddToList && (
              <PodSticker position="top-right">
                <div className="sui-pr-4 sui-pt-2">
                  <AddToList
                    itemId={pod.itemId}
                    storeId={storeId}
                    showIconButton
                  />
                </div>
              </PodSticker>
            )}
            <PodSpacer padding={['BOTTOM']}>
              <ProductImage
                itemId={pod.itemId}
                showSecondaryImage={pod.showSecondaryImage}
                merchQueryParam={merchRec}
                onClick={(event) => {
                  if (onclick) {
                    onclick(event, fbrItem);
                  }
                }}
              />
            </PodSpacer>
            <PodSection columnAlign>
              <PodSpacer>
                <div className="product-header">
                  <ProductHeader
                    brand="above"
                    itemId={pod.itemId}
                    disableShopThisCollection
                    merchQueryParam={merchRec}
                    onClick={(event) => {
                      if (current) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </PodSpacer>
              <PodSpacer>
                {!hideRating && (
                  <ProductRating itemId={pod.itemId} hideRating={hideRating} merchQueryParam={merchRec} />
                )}
              </PodSpacer>
              <PodSpacer>
                <Price
                  itemId={pod.itemId}
                  large={false}
                  storeId={storeId}
                  displayEachUom={false}
                  hideSavingsText={!preferredPriceFlag}
                  showPreferredPricingBadge
                  stackWasPrice
                />
              </PodSpacer>
            </PodSection>
          </PodSection>
        </div>
      )}
    />
  );
};

FbrProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductSponsored,
  ProductRating,
  ProductIdentifier,
  ProductATC,
  Price,
  ProductHeader,
  AddToList,
);

FbrProductPod.propTypes = {
  storeId: stringType,
  product: any,
  fbrItem: any,
  current: boolType,
  position: numType,
  parent: stringType,
  hideRating: boolType,
  anchorProduct: stringType,
  itemId: stringType,
  scheme: stringType,
  onclick: func,
  recommendedFeatureSet: stringType,
  recommendedProductSet: stringType
};

FbrProductPod.defaultProps = {
  storeId: '',
  position: '',
  current: false,
  parent: '',
  hideRating: false,
  anchorProduct: '',
  itemId: '',
  scheme: '',
  onclick: () => { },
  product: null,
  fbrItem: [],
  recommendedFeatureSet: '',
  recommendedProductSet: ''
};

FbrProductPod.displayName = 'FbrProductPod';

FbrProductPod.themeProps = {
  hideAddToList: boolType
};

FbrProductPod.defaultThemeProps = {
  hideAddToList: false
};

export {
  FbrProductPod
};