import React from 'react';
import { Row, Col } from '@thd-olt-component-react/grid';

const InstantCheckoutSignin = () => {
  const redirectToSigninPage = () => {
    const redirect = window.top.location.href.replace(window.top.location.origin, '');
    window.location.href = '/auth/view/signin?redirect=' + redirect;
  };
  return (
    <div className="instant-checkout" data-component="InstantCheckoutSignin">
      <div className="instant-checkout__wrapper">
        <div className="instant-checkout__box">
          <Row>
            <Col>
              <div className="instant-checkout__heading">Your Fastest Checkout</div>
            </Col>
            <Col nopadding>
              <button type="button" onClick={redirectToSigninPage} className="u__default-link">
                Sign in to use Instant Checkout
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export { InstantCheckoutSignin };
