import * as React from 'react';
import { Skeleton, SkeletonLine, SkeletonBlock, DrawerBody } from '@one-thd/sui-atomic-components';

export const DrawerSkeleton = () => {
  return (
    <DrawerBody>
      <Skeleton className="sui-flex-none" data-testid="drawer-skeleton">
        <SkeletonBlock />
        <SkeletonLine numberOfLines={3} />
        <SkeletonBlock />
      </Skeleton>
    </DrawerBody>
  );
};