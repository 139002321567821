import { Button } from '@one-thd/sui-atomic-components';
import { func, string } from 'prop-types';
import { Info } from '@one-thd/sui-icons';
import React from 'react';

const WhatToExpect = React.forwardRef(({ onClick, label }, ref) => {
  return (
    <Button
      variant="text"
      className="sui-border-none sui-h-6 sui-flex sui-flex-row sui-justify-start sui-gap-1 sui-items-center sui-w-fit"
      onClick={onClick}
      ref={ref}
    >
      <Info size="small" color="primary" />
      <span className="sui-text-base sui-underline sui-decoration-2
              sui-underline-offset-1 sui--mt-1"
      >
        {label || 'What to Expect'}
      </span>
    </Button>
  );
});

WhatToExpect.displayName = 'WhatToExpect';

WhatToExpect.propTypes = {
  onClick: func,
  label: string
};

WhatToExpect.defaultProps = {
  onClick: () => {},
  label: null
};

export default WhatToExpect;