/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  bool,
  func,
  shape,
  string,
  arrayOf,
  node
} from 'prop-types';
import { Image, Rating } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import * as analytics from '../../analytics/frequentlyboughttogether-analytics';
import { AlternateOptions } from './AlternateOptions';
import './ApplianceBundleItem.style.scss';
import { QuickViewWrapper } from '../QuickView/QuickView';

const ProductLinkWrapper = ({ canonicalUrl, anchorProduct, openInNewTab, children }) => (
  <a
    href={canonicalUrl}
    onClick={() => analytics.track({
      itemId: anchorProduct?.itemId,
      bundle: true
    })}
    rel={openInNewTab ? 'noopener noreferrer' : ''}
    target={openInNewTab ? '_blank' : '_self'}
  >
    {children}
  </a>
);

ProductLinkWrapper.propTypes = {
  children: node.isRequired,
  canonicalUrl: string.isRequired,
  anchorProduct: shape({}).isRequired,
  openInNewTab: bool.isRequired
};

const ApplianceBundleItem = ({
  anchorProduct,
  checked,
  openInNewTab,
  product = {},
  bundleItemTitle,
  alternateItems,
  updateCurrentSelection,
  storeId
}) => {

  const {
    identifiers, media, reviews
  } = product;
  const { brandName, canonicalUrl, productLabel, itemId } = identifiers || {};
  const { url } = media?.images?.[0] || {};
  const imageUrl = (url || '').replace('<SIZE>', 400);
  const { averageRating, totalReviews } = reviews?.ratingsReviews || {};

  return (
    <div className="appliance-bundle-item">
      <div className="appliance-bundle-item__image">
        <QuickViewWrapper itemId={itemId}>
          <ProductLinkWrapper canonicalUrl={canonicalUrl} anchorProduct={anchorProduct} openInNewTab={openInNewTab}>
            <Image
              src={imageUrl}
              alt={brandName}
              stretchy
              width={1}
              height={1}
            />
          </ProductLinkWrapper>
        </QuickViewWrapper>
      </div>
      <div className="appliance-bundle-item__wrapper">
        {alternateItems && updateCurrentSelection && (
          <AlternateOptions
            bundleItemTitle={bundleItemTitle}
            currentSelectedItemId={itemId}
            alternateItems={alternateItems}
            updateCurrentSelection={updateCurrentSelection}
          />
        )}
        <div className="appliance-bundle-item__product-details">
          <ProductLinkWrapper canonicalUrl={canonicalUrl} anchorProduct={anchorProduct} openInNewTab={openInNewTab}>
            <div className="appliance-bundle-item__label">
              <span>{brandName} </span>
              {productLabel}
            </div>
            <div className="appliance-bundle-item__ratings-reviews">
              <Rating value={parseFloat(averageRating)} />
              <span>({Number(totalReviews)})</span>
            </div>
          </ProductLinkWrapper>
          <Price
            itemId={itemId}
            large={false}
            displayEachUom={false}
            hideSavingsText
            storeId={storeId}
            product={product}
          />
          {checked && (
            <div className="appliance-bundle-item__triangle" />
          )}
        </div>
      </div>
    </div>
  );
};

ApplianceBundleItem.propTypes = {
  anchorProduct: shape({}).isRequired,
  checked: bool.isRequired,
  openInNewTab: bool.isRequired,
  product: shape({}),
  bundleItemTitle: string,
  alternateItems: arrayOf(shape({})),
  updateCurrentSelection: func,
  storeId: string.isRequired,
};

ApplianceBundleItem.defaultProps = {
  bundleItemTitle: 'Item',
  product: {},
  alternateItems: [],
  updateCurrentSelection: () => {}
};

ApplianceBundleItem.displayName = 'FBTApplianceBundleItem';

export default ApplianceBundleItem;
