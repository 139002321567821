import React, { useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import {
  params,
  shape,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';

import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';

const ProductPageEventCountdownTimer = ({
  eventLink, eventMessage, endDate, backgroundImage, itemId
}) => {
  const configEventMessage = useConfigService('PIPEventMessage');
  const configEventLink = useConfigService('PIPEventLink');
  const configEndDate = useConfigService('PIPEventEndDate');
  const configBackgroundImage = useConfigService('PIPEventBackgroundImage');
  const configAlwaysShow = useConfigService('PIPEventAlwaysShow');
  const configStartDate = useConfigService('PIPEventStartDate');
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const { data, loading, error } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  function getEventBadge(badges) {
    const itemBadges = badges?.filter((badge) => {
      return badge?.name === 'Event Badge';
    });
    return itemBadges?.length ? itemBadges[0] : null;
  }

  const eventBadge = getEventBadge(data?.product?.badges);
  // for testing purposes
  // const eventBadge = { label: 'Black Friday', endDate: '2024-10-30T03:00:00-04:00', name: 'Event Badge' };

  if (!eventBadge && !configAlwaysShow) {
    return null;
  }
  const defaultStartDate = '2024-11-30T03:00:00-04:00';
  const usedBackgroundImage = configBackgroundImage || backgroundImage;
  const usedEventMessage = configEventMessage || eventMessage;
  const usedEventLink = configEventLink || eventLink;
  const optionalEndDate = configEndDate || endDate;
  const usedEndDate = new Date(optionalEndDate).valueOf();
  const tempTimerStartDate = configStartDate || defaultStartDate;
  const showTimerStartDate = new Date(tempTimerStartDate).valueOf();

  const currentDate = Date.now();
  if (error || currentDate > usedEndDate) {
    return null;
  }

  if (loading) {
    return (
      <Skeleton disablePadding className="sui-h-full" SkeletonContentProps={{ grow: true }}>
        <SkeletonBlock aspect="wide" />
      </Skeleton>
    );
  }

  const style = {
    bannerWrapper: classNames(
      'sui-bg-inverse',
      'sui-h-full',
      'sui-bg-cover',
      'sui-bg-left',
      'sui-flex',
      'sui-items-center',
      'sui-h2-display',
      'sui-justify-center',
      'hover:sui-no-underline',
      { 'sui-py-10': !isMobile },
      { 'sui-py-8': isMobile },
    ),
    bannerWithTimerWrapper: classNames(
      'sui-block',
      'sui-p-3',
      'sui-py-5',
      'sui-bg-inverse',
      'sui-h-full',
      'sui-bg-cover',
      'sui-bg-left',
      'hover:sui-no-underline'
    )
  };

  return (
    <span>
      {((showTimerStartDate > currentDate))
        ? (
          <div data-component="PIPEventCountdownTimer">
            <a
              aria-label="Event Countdown Timer"
              href={usedEventLink}
              className={style.bannerWrapper}
              // eslint-disable-next-line react/style-prop-object
              style={{ backgroundImage: `url(${usedBackgroundImage})` }}
            >
              <div className="sui-uppercase sui-text-inverse">{'Shop ' + usedEventMessage + ' Deals'}</div>
            </a>
          </div>
        )
        : (
          <div data-component="PIPEventCountdownTimer">
            <a
              aria-label="Event Countdown Timer"
              href={usedEventLink}
              className={style.bannerWithTimerWrapper}
              // eslint-disable-next-line react/style-prop-object
              style={{ backgroundImage: `url(${usedBackgroundImage})` }}
            >
              <CountdownTimer
                shouldUseMessagingString
                shouldIncludeSvg
                shouldEventTimer
                invertText
                customMessagingString={usedEventMessage + ' Deals End:'}
                endDate={usedEndDate}
                fontSize="small"
              />
            </a>
          </div>
        )}
    </span>
  );
};

ProductPageEventCountdownTimer.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  eventMessage: PropTypes.string,
  eventLink: PropTypes.string,
  backgroundImage: PropTypes.string,
  itemId: PropTypes.string.isRequired
};

ProductPageEventCountdownTimer.defaultProps = {
  // eslint-disable-next-line max-len
  backgroundImage: 'https://dam.thdstatic.com/content/production/w0mPZtJw6FbCYoYSSz7dYA/u2QdUrAcCWbUkB3veIMVKA/Original%20file/BF_Desktop_Banner_BG.png',
  eventLink: 'https://www.homedepot.com/b/Black-Friday-Deals/N-5yc1vZ1z1zdoh?catStyle=ShowProducts',
  eventMessage: 'Black Friday',
  endDate: '2024-12-05T03:00:00-04:00',

};

ProductPageEventCountdownTimer.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    badges:
      params({ storeId: string() }).arrayOf(
        shape({
          endDate: string(),
          label: string(),
          name: string()
        })
      )
  }),
};

ProductPageEventCountdownTimer.displayName = 'ProductPageEventCountdownTimer';

export { ProductPageEventCountdownTimer };
