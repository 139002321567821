import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Col, Button } from '@thd-olt-component-react/core-ui';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { TotalPrice } from './TotalPrice';
import { getPackageUrl } from '../../utils';
import './visual-package-summary.scss';

const VisualPackageSummary = (props) => {
  const { itemId } = props;
  const { products, packageModel } = useContext(PackagesMiniContext);
  const packageUrl = getPackageUrl(packageModel);
  const onHeaderClick = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('visual-packages-mini.click', { itemId });
  };
  return (
    <>
      <Col className="visual-package-summary__total-price-container">
        <div className="visual-package-summary__total-price-text">{`Total Price for ${products.length} items`}</div>
        <TotalPrice products={products} isPackageVisual />
      </Col>
      <Col className="visual-package-summary__view-bundle-bttn-container">
        <Button
          outline
          tag="a"
          href={packageUrl}
          target="_self"
          data-testid="view-bundle-bttn-link"
          onClick={() => onHeaderClick()}
        >
          View Bundle
        </Button>
      </Col>
    </>
  );
};

const propTypes = {
  itemId: string
};

const defaultProps = {
  itemId: ''
};

VisualPackageSummary.displayName = 'VisualPackageSummary';
VisualPackageSummary.propTypes = propTypes;
VisualPackageSummary.defaultProps = defaultProps;

export { VisualPackageSummary };