import {
  params, shape, bool, string, number, arrayOf, alias
} from '@thd-nucleus/data-sources';

export const dataModel = {
  clientOnlyProduct: alias('product').params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSource: string(),
    identifiers: shape({
      modelNumber: string()
    }),
    info: shape({
      protectionPlanSku: string(),
      eligibleProtectionPlanSkus: string(),
      hasServiceAddOns: bool(),
      productDepartment: string(),
      classNumber: string(),
      subClassNumber: string(),
      consultationType: string()
    }),
    pricing: params({ storeId: string() }).shape({
      value: number({ float: true })
    }),
    fulfillment: params({ storeId: string() }).shape({
      inStoreAssemblyEligible: bool(),
      bodfsAssemblyEligible: bool(),
    }),
    availabilityType: shape({
      discontinued: bool(),
    }),
    details: shape({
      installation: shape({
        leadGenUrl: string()
      })
    })
  }),
  protectionPlan: params({
    protectionPlanParentId: string(),
    storeId: string(),
    price: number({ float: true })
  }).shape({
    itemId: string(),
    price: string(),
    termLength: string(),
    category: string(),
    detailsUrl: string()
  }),
  serviceAddOns: params({
    itemId: string().isRequired(),
    storeId: string().isRequired(),
    zipCode: string()
  }).shape({
    attachLabor: shape({
      installSkus: arrayOf(shape({
        serviceType: string(),
        installSku: string(),
        installRetailPrice: shape({
          price: number({ float: true })
        }),
        doItForMe: shape({
          priceIncludes: arrayOf(shape({
            shortText: string(),
            longText: string(), // not sure if i need
          })),
          installProcess: arrayOf(shape({
            longText: string()
          }))
        })
      }))
    }),
    consultationDetails: shape({
      virtualAssistanceFlag: bool()
    }),
    installationDetails: shape({
      virtualAssistanceFlag: bool(),
      installationCategory: string()
    }),
    proReferrals: arrayOf(shape({
      assemblyCategory: string(),
      serviceName: string(),
      available: bool(),
    })),
    sellFurnishInstall: shape({
      dualPathSku: bool(),
      installationProgramCategory: string()
    })
  })
};