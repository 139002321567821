import React from 'react';
import { LensPropTypes } from '../../propTypes';
import { FADE_DURATION_IN_MS } from '../../constants';
import { returnClosestNumberInRange } from '../../helpers/math';

export const PositiveSpaceLens = ({
  cursorOffset,
  isActive,
  isPositionOutside,
  position,
  smallImage,
  style: userSpecifiedStyle
}) => {
  const {
    x: cursorOffsetX,
    y: cursorOffsetY
  } = cursorOffset;
  const {
    x: positionX,
    y: positionY
  } = position;
  const {
    height: imageHeight,
    width: imageWidth
  } = smallImage;

  const dimensions = {
    width: cursorOffsetX * 2,
    height: cursorOffsetY * 2
  };

  const positionOffset = () => {
    const {
      width,
      height
    } = dimensions;

    const top = positionY - cursorOffsetY;
    const left = positionX - cursorOffsetX;
    const maxTop = imageHeight - height;
    const maxLeft = imageWidth - width;
    const minOffset = 0;

    return {
      top: returnClosestNumberInRange(top, minOffset, maxTop),
      left: returnClosestNumberInRange(left, minOffset, maxLeft)
    };
  };

  const defaultStyle = {
    transition: `opacity ${FADE_DURATION_IN_MS}ms ease-in`
  };

  const isVisible = isActive && !isPositionOutside;

  const priorityStyle = () => {
    const {
      width,
      height
    } = dimensions;

    const {
      top,
      left
    } = positionOffset();

    return {
      position: 'absolute',
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      opacity: isVisible ? 1 : 0
    };
  };

  const compositeStyle = {
    ...defaultStyle,
    ...userSpecifiedStyle,
    ...priorityStyle()
  };

  return (
    <div style={compositeStyle} />
  );
};

PositiveSpaceLens.propTypes = LensPropTypes;

PositiveSpaceLens.defaultProps = {
  style: {}
};