/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { useLazyDataModel } from '@thd-nucleus/data-sources';
import { getTmxId } from '../utils/utils';
import { initPaypal as iniPaypalDataModel } from './data-models/paypalInitDataModel';

let promiseResolver;
let promiseRejecter;
export const usePaypalInit = (props) => {

  const [initPaypalFn, paypalInitResponse] = useLazyDataModel('initPaypal', {
    ssr: false,
    fetchPolicy: 'no-cache',
    variables: {
      requestContext: {
        cartId: null, // populate from variables or global
        experience: { component: 'checkout', type: 'INITPAYPAL' }, // populate from variables or global
        expTypeFilter: { type: '' }, // populate from variables or global
        tmxProfileId: getTmxId(),
      },
    },
    errorPolicy: 'all',
    dataModel: iniPaypalDataModel,
    onCompleted: (data) => {
      promiseResolver(data);
    },
    onError: (errors) => {
      console.log(errors);
      promiseRejecter(errors);
    }
  });

  const initPaypal = (params) => {
    initPaypalFn(params);
    return new Promise((resolve, reject) => {
      promiseResolver = resolve;
      promiseRejecter = reject;
    });
  };

  return [initPaypal, paypalInitResponse];

};
