/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { bool, func } from 'prop-types';

import { Image } from '@thd-olt-component-react/core-ui';

import './bundling-tab.scss';

const BundlingTab = ({
  isSelected, handleClick, data, isTabOpen, handleHover
}) => {

  const selectedClasses = classNames('bundling-tab__selector', {
    'bundling-tab__arrow_box': isSelected && isTabOpen,
  });
  const url = data?.media?.image?.url?.replace(/1000(?=(.(jpg|webp)))/, '145');

  return (
    <div
      className={selectedClasses}
      role="tab"
      onClick={handleClick}
      onMouseEnter={handleHover}
      data-testid="bundling-tab"
      tabIndex={0}
    >
      <Image
        className="bundling-tab__selector--image"
        src={url}
        alt=""
        width={1}
        height={1}
      />
    </div>
  );
};

BundlingTab.displayName = 'BundlingTab';

BundlingTab.propTypes = {
  isSelected: bool,
  handleClick: func,
  handleHover: func,
  isTabOpen: bool.isRequired
};

BundlingTab.defaultProps = {
  isSelected: false,
  handleClick: () => {},
  handleHover: () => {}
};
export { BundlingTab };
