/* eslint-disable max-len */
import {
  alias,
  arrayOf as arrayOfType,
  bool as boolType,
  client,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';

const baseCalculatorDataModelValues = {
  dataSources: stringType(),
  info: shapeType({
    pipCalculator: shapeType({
      coverageUnits: stringType(), // Dimensional units Ex: "Panel", "Block", "Sheet", "Bag"
      display: boolType(), // Whether or not to display the calculator
      publisher: numberType(), // units per case
      toggle: stringType(), // "2-Point" (sq. ft.), "3-Point" (cu. ft.), or "Fixed_Width" (linear ft.)
      defaultAdditionalCoverage: numberType(), // Whether additional coverage is used by default
      additionalCoveragePercentage: boolType() // The additional coverage percentage
    })
  }),
  taxonomy: shapeType({
    breadCrumbs: arrayOfType(shapeType({
      label: stringType()
    }))
  })
};

export const pipCalculatorDataModel = {
  clientOnlyProduct: alias('product').params({
    itemId: stringType().isRequired()
  }).shape({
    ...baseCalculatorDataModelValues,
    fulfillment: client(params({ storeId: stringType() }).shape({
      fulfillmentOptions: arrayOfType(shapeType({
        type: stringType(),
        fulfillable: boolType(),
      }))
    })),
    installServices: params({ storeId: stringType() }).shape({
      scheduleAMeasure: boolType()
    }),
    pricing: params({ storeId: stringType() }).shape({
      unitOfMeasure: stringType(),
      alternate: shapeType({
        bulk: shapeType({
          thresholdQuantity: numberType({ float: true }),
          value: numberType({ float: true })
        }),
        unit: {
          caseUnitOfMeasure: stringType()
        }
      }),
      value: numberType({ float: true })
    }),
  })
};

export const samDataModel = {
  product: params({
    itemId: stringType().isRequired()
  }).shape({
    ...baseCalculatorDataModelValues,
    pricing: params({ storeId: stringType() }).shape({
      alternate: shapeType({
        unit: {
          unitsPerCase: stringType(),
          value: stringType()
        }
      })
    }),
  })
};

export const wallAreaCalculatorDataModel = { clientOnlyProduct: alias('product').params({
  itemId: stringType().isRequired()
}).shape({
  dataSources: stringType(),
  info: shapeType({
    dotComColorEligible: boolType()
  }),
  pricing: params({ storeId: stringType() }).shape({
    alternate: shapeType({
      unit: {
        value: stringType()
      }
    })
  })
}) };