import _configs from './configs';

// Get Current Environment from host
export const ENV =
  typeof window !== 'undefined' && /hd-[a-zA-Z0-9]{4}\.homedepotdev\.com/.test(window.location.host)
    ? window.location.host.match(/hd-([a-zA-Z0-9]{4})\.homedepotdev\.com/)[1]
    : 'pr';

// Get Environment Specific properties
export function getEnvProp(propName) {
  // If configs not exist for an environment, use llc configs
  const envConfigs = typeof _configs[ENV] !== 'undefined' ? _configs[ENV] : _configs.defaults;
  // If environment specific configs not exist, use generic configs
  return typeof envConfigs[propName] !== 'undefined'
    ? envConfigs[propName].replace('{llc}', ENV)
    : _configs.defaults[propName].replace('{llc}', ENV);
}

// Host Names
export const SECURE_WWW_HOST = getEnvProp('SECURE_WWW_HOST');

// Thank page url
export const THANK_YOU_URL = '{host}/order-confirmation/';

// My Account Page URL
export const MYACCOUNT_SIGN_IN_URL = '/auth/view/signin';

export const NUCLEUS_CHECKOUT = '/checkout';
export const MY_CHECKOUT_OLD = '/mycheckout/checkout';

export const INSTANT_CHECKOUT_PATH = '/account/view/instantcheckout';

export const NEW_INSTANT_CHECKOUT_PATH = '/myaccount/payments/instant-checkout';
