import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Price } from '@thd-olt-component-react/price';
import '../../frequently-bought-together.style.scss';

const PriceMessage = ({
  cartOptions,
  cartReqParams,
  channel,
  hideBadge,
  itemId,
  message,
  onAddToCartClick,
  pricing,
  storeId
}) => (
  <>
    <div className="fbtAtc">
      <div>
        <div className={classNames(
          'fbtAtcPrice',
          {
            fbtOpacitizer: message.isDisable
          }
        )}
        >
          <div>
            {message.priceMessage}
          </div>
          <Price
            basic
            channel={channel}
            hideBadge={hideBadge}
            itemId={itemId}
            large
            product={pricing}
            storeId={storeId}
          />
        </div>
      </div>
      <AddToCart
        disabled={message.isDisable}
        cartOptions={cartOptions}
        cartReqParams={cartReqParams}
        onClick={onAddToCartClick}
        outline
      >{message.atcMessage}
      </AddToCart>
    </div>
  </>
);

PriceMessage.propTypes = {
  cartOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cartReqParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  channel: PropTypes.oneOf([
    'desktop',
    'tablet',
    'mobile'
  ]),
  itemId: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  pricing: PropTypes.shape({}).isRequired,
  hideBadge: PropTypes.bool,
  storeId: PropTypes.string
};

PriceMessage.defaultProps = {
  channel: 'desktop',
  hideBadge: false,
  storeId: null
};

export default PriceMessage;
