import React, { useContext, useState, lazy, Suspense } from 'react';
import { Button } from '@thd-olt-component-react/core-ui';
import { string } from 'prop-types';
import { QueryContext, QueryProvider } from '@thd-nucleus/data-sources';
import './product-details-button.scss';

const QuickViewLazy = lazy(() => import(
  /* webpackChunkName: "quick-view" */
  '@thd-olt-component-react/quick-view'
).then((mod) => {
  const { QuickView } = mod;
  return {
    default: (props) => {
      const { defaultVariables } = props || {};
      return (
        <QueryProvider
          dataSource="catalog"
          defaultVariables={defaultVariables}
          cacheKey="bundling-tabs-product-details-quick-item"
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <QuickView {...props} />
        </QueryProvider>
      );
    }
  };
}));

export const ProductDetailsButton = ({ itemId }) => {
  const { defaultVariables } = useContext(QueryContext) || {};
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [mountQuickView, setMountQuickView] = useState(false);
  const openQuickView = () => {
    if (!isQuickViewOpen) {
      setMountQuickView(true);
      setIsQuickViewOpen(true);
    }
  };
  const loadQuickView = (event) => {
    event.preventDefault();
    if (!mountQuickView) {
      setMountQuickView(true);
    }
  };

  const productDetailsDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <>
      <div className="product-details-button" data-testid="product-details-button">
        <Button
          contentClass="product-details-button__content"
          dark
          outline
          onClick={openQuickView}
          onMouseEnter={loadQuickView}
        >
          View Product Details
        </Button>
      </div>
      {typeof window !== 'undefined' && mountQuickView && (
        <Suspense fallback={<div />}>
          <QuickViewLazy
            isOpen={isQuickViewOpen}
            onClose={() => {
              setIsQuickViewOpen(false);
            }}
            fetch={mountQuickView}
            itemId={itemId}
            defaultVariables={productDetailsDefaultVariables}
          />
        </Suspense>
      )}
    </>
  );
};

ProductDetailsButton.propTypes = {
  itemId: string.isRequired
};

ProductDetailsButton.displayName = 'ProductDetailsButton';
