/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter
} from '@one-thd/sui-atomic-components';
import { ExperienceContext, useConfigService, useStore } from '@thd-nucleus/experience-context';
import { CARD_IMAGE } from '../utils/constants';
import { getPaymentEstimatorValues, loadEstimatorScript, getDefaultApplyNowURL } from '../utils/payment-estimator-utils';
import { getDynamicOffer, redirectToAppliances, redirectToSavings } from '../utils/general';
import { financingDetails, financingTerms, suggestedPaymentDetails } from '../utils/terms-and-conditions-utils';

const TermsAndConditionsDrawer = (
  {
    closeDrawer,
    open,
    showFinancing,
    applyNowClick,
    isB2B,
    price,
    isHDHome,
    isAppliance,
    isCountertops,
    isEcmPromo
  }) => {

  const [paymentEstimatorValues, setPaymentEstimatorValues] = useState({});
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.6.0';

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion, () => setPaymentEstimatorValues(getPaymentEstimatorValues(price, isAppliance, isHDHome)), channel === 'mobile'
    );

  }, []);

  const {
    applyNowUrl, financingMonths, promoStartDate, promoEndDate, toolTipText,
    discountEndDate, interestRate, suggestedAmount
  } = paymentEstimatorValues;

  const isAppliancePromo = toolTipText?.includes('Appliance');
  const isTieredPromo = toolTipText?.includes('24 Months');
  const isTieredAppliancePromo = toolTipText?.includes('18 Months on Appliance');
  const isAnyPromo = !toolTipText?.includes(' EVERY DAY ');

  const dynamicDiscountDetails = () => {
    return (
      <Typography variant="body-lg" align="center">
        <span className="sui-font-bold">${getDynamicOffer(price)} OFF</span>
      </Typography>
    );
  };

  const isEverydayFinancingUrl = () => {
    if (isCountertops) {
      return getDefaultApplyNowURL(isMobile, 'countertops', isB2B);
    }
    return isMobile
      ? applyNowUrl?.mobileUrl ?? ''
      : applyNowUrl?.desktopUrl ?? '';

  };

  const discountDetails = () => {
    let financingValue = `${financingMonths}-months ${isAnyPromo ? 'special' : 'everyday'}`;
    if (!price) {
      if (isTieredAppliancePromo || isTieredPromo) {
        financingValue = 'up to 24 months special';
      } else if (isAnyPromo) {
        financingValue = '12-months special';
      }
    }
    let qualifyingWord = '';
    if (isAppliancePromo) {
      qualifyingWord = 'Appliance ';
    } else if (isAnyPromo) {
      qualifyingWord = 'qualifying ';
    }
    return (
      <Typography variant="body-base">
        Get<span className="sui-font-bold"> $25 off</span> purchases of $25-$299*,
        <span className="sui-font-bold"> $50 off</span> purchases of $300-999*
        or <span className="sui-font-bold">$100 off</span> purchases of $1000+*
        on your next qualifying purchase when you open a new card.
        {showFinancing && (
          <>
            <span className="sui-font-bold"> Plus, receive {financingValue} financing** </span>
            on {qualifyingWord}purchases $299 or more when you use your Home Depot Consumer Credit Card.
          </>
        )}
      </Typography>
    );
  };

  const discountTerms = () => {
    const validThrough = discountEndDate ? ` and valid now through ${discountEndDate}. ` : '. ';
    return (
      <ul className="sui-flex sui-flex-col sui-list-none">
        <li className="sui-flex sui-gap-2px">
          <Typography variant="body-base" weight="bold">*&nbsp;</Typography>
          <Typography variant="body-base">
            <span className="sui-font-bold">Offer is for new accounts and subject to credit approval
              {validThrough}
            </span>
            This offer is redeemable for either a $25 discount on a single-receipt purchase of $25 up to $299,
            a $50 discount on a single-receipt purchase of $300 up to $999 or a $100 discount on a single-receipt purchase
            of $1,000 or more, excluding tax and delivery fees, within 30 days of your account open date at The Home Depot®
            retail stores or homedepot.com when made with your The Home Depot Consumer Credit Card, The Home Depot Home Improver Card,
            the Pro Xtra Credit Card, or The Home Depot Commercial Account. Coupon is on a single transaction and expires upon use.
            Valid in the U.S., U.S.V.I., Puerto Rico and Guam. Offer does not apply to prior purchases, The Home Depot Gift Cards
            or Certificates, third party gift cards, installation products or services purchased in home, Tool Rental or to Traeger®,
            Weber®, Bona®, Simplehuman®, Dacor®, Viking®, Fisher & Paykel®, Sharp InsightTM, Delonghi®, Buckhaven, Lynx®,
            AlfrescoTM, OCI, Marvel®, Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef Collection, Bosch Benchmark® Series,
            Liebherr, Zephyr, Miele, Signature Kitchen Suite, Jenn-Air®, Thermador®, Jeld-Wen® Door Systems, Monogram
            products and trade styles. Additional exclusions may apply which will be reflected at checkout. May not be
            combined with other discounts; ask an Associate for details.
          </Typography>
        </li>
      </ul>
    );
  };

  return (
    <>
      {paymentEstimatorValues ? (
        <Drawer
          open={open}
          onClose={closeDrawer}
          position="right"
          id="drawer-terms-conditions"
        >
          <DrawerHeader title="Enjoy Special Savings with your new Account" onClose={closeDrawer} />
          <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
            <DrawerBody>
              <div className="sui-flex sui-flex-col sui-gap-4">
                <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                  <img
                    src={isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT}
                    alt="Credit Card Icon"
                    width="185"
                    height="103"
                  />
                </div>
                <div className="sui-flex sui-flex-col sui-gap-3">

                  {price >= 300 && (showFinancing ? suggestedPaymentDetails(suggestedAmount, price, financingMonths) : dynamicDiscountDetails())}
                  {discountDetails()}
                  {showFinancing && financingDetails(isTieredPromo, isAppliancePromo, isTieredAppliancePromo, isAnyPromo ? '12' : financingMonths, promoStartDate, promoEndDate, price)}
                </div>
                <div className="sui-grow">
                  {showFinancing && financingTerms(interestRate, price, showFinancing)}
                  {discountTerms()}
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter>
              {isEcmPromo ? (
                <Button
                  variant="primary"
                  data-testid="drawer-apply-now"
                  onClick={isAppliance ? redirectToAppliances : redirectToSavings}
                  fullWidth
                >
                  Shop {isAppliance ? 'Appliance Sales' : 'Specials & Offers'}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  data-testid="drawer-apply-now"
                  href={
                    isEverydayFinancingUrl()
                  }
                  onClick={applyNowClick}
                  fullWidth
                >
                  Apply Now
                </Button>
              )}
            </DrawerFooter>
          </div>
        </Drawer>
      ) : null}
    </>

  );
};

TermsAndConditionsDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showFinancing: PropTypes.bool,
  applyNowClick: PropTypes.func, // Replace with analytics built in?
  isB2B: PropTypes.bool,
  price: PropTypes.number,
  isHDHome: PropTypes.bool,
  isAppliance: PropTypes.bool,
  isCountertops: PropTypes.bool,
  isEcmPromo: PropTypes.bool
};

TermsAndConditionsDrawer.defaultProps = {
  open: true,
  applyNowClick: () => { },
  isB2B: false,
  showFinancing: false,
  price: null,
  isHDHome: false,
  isAppliance: false,
  isCountertops: false,
  isEcmPromo: false
};

export default TermsAndConditionsDrawer;
