import React from 'react';
import { string, bool } from 'prop-types';
import {
  IconButton,
  Typography,
  Card,
  CardContent,
  CardActionArea
} from '@one-thd/sui-atomic-components';
import { ArrowForward, Mobile } from '@one-thd/sui-icons';
import { DEFAULT_NON_PRO_SMS_CODE, DEFAULT_PRO_SMS_CODE, IOS_SMS_LINK } from '../constants';

export const TextAnExpertCard = ({ sms, proUX, isiPhone }) => {

  const defaultDisplayCode = proUX ? DEFAULT_PRO_SMS_CODE : DEFAULT_NON_PRO_SMS_CODE;
  const displayCode = sms || defaultDisplayCode;
  const smsActionLink = (isiPhone && !proUX) ? IOS_SMS_LINK : `sms:${displayCode}`;

  return (
    <Card grow data-testid="TextAnExpertCard-component" href={smsActionLink}>
      <CardContent grow initialSize="4/4" disablePadding>
        <div className="sui-flex sui-items-center">
          <div className="sui-basis-1/12 sui-mr-2">
            <Mobile size="large" />
          </div>
          <div className="sui-basis-10/12">
            <Typography variant="h4">Text Customer Service</Typography>
            <Typography variant="body-xs">&#42;Data &#38; messaging rates may apply</Typography>
          </div>
          <div className="sui-basis-1/12">
            <CardActionArea>
              <IconButton icon={ArrowForward} />
            </CardActionArea>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const propTypes = {
  sms: string,
  proUX: bool,
  isiPhone: bool
};
TextAnExpertCard.propTypes = propTypes;

const defaultProps = {
  sms: null,
  isiPhone: false,
  proUX: false
};
TextAnExpertCard.defaultProps = defaultProps;