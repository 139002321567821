import React from 'react';
import {
  shape, number, bool, string, func, arrayOf
} from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { CarouselHeader } from './CarouselHeader';

const CollectionsTitle = (props) => {
  const {
    metadata,
    productIdentifier,
    isMobile,
    isBatteryCollection,
    showColUpLevel,
    tntTitle,
    scheme
  } = props;

  const productBrandName = productIdentifier?.brandName;
  let title = (tntTitle) || ((showColUpLevel) ? 'Make It Match' : metadata?.collectionName);

  return (
    <>
      <div className={isBatteryCollection ? 'sui-grid sui-grid-cols-3' : 'sui-grid sui-grid-cols-2'}>
        <div className={isBatteryCollection ? 'sui-col-span-2' : ''}>
          <CarouselHeader title={title} />
        </div>
        {((metadata?.collectionPlpUrl && showColUpLevel) || isBatteryCollection) && (
          <div className="sui-pt-5 sui-pb-5 sui-text-right">
            <Link
              href={metadata?.collectionPlpUrl}
              target={isMobile ? '_self' : '_blank'}
              rel="noreferrer"
              data-testid="tooltip-product"
            > {isBatteryCollection
                ? <span className="sui-text-sm">Shop All</span>
                : <span className="sui-text-sm">Shop More In This Style</span>}
            </Link>
          </div>
        )}
      </div>
      {scheme === 'battery_collection' && (
        <div className="sui-mb-3">
          <Typography variant="h6">Use the same battery and charger for all these tools</Typography>
        </div>
      )}
    </>
  );
};

CollectionsTitle.propTypes = {
  metadata: shape({
  }),
  productIdentifier: shape({
  }),
  isMobile: bool,
  isBatteryCollection: bool,
  showColUpLevel: bool,
  tntTitle: string,
  scheme: string
};

CollectionsTitle.defaultProps = {
  metadata: {},
  productIdentifier: {},
  isMobile: false,
  isBatteryCollection: false,
  showColUpLevel: false,
  tntTitle: '',
  scheme: ''
};

export default CollectionsTitle;
