import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import classNames from 'classnames';
import { SharebeltContext } from '../../Sharebelt.context';
import { Typography } from '~/@one-thd/sui-atomic-components/dist/index';

const ShareBeltFavorite = ({
  disable,
  showCircle,
  showLabel,
  showFavoritesCount
}) => {
  const {
    itemId,
    horizontal,
    vertical
  } = useContext(SharebeltContext);

  if (disable) {
    return null;
  }

  const shareBeltIconClasses = classNames('sui-inline-block', 'sui-float-left', {
    'sui-pr-2': horizontal,
    'sui-mt-1': vertical,
    'sui-ml-7': vertical,
  });

  const shareBeltItemClasses = classNames('sui-mb-2', 'sui-inline-block',
    'hover:sui-cursor-pointer', 'last:hover:sui-no-underline', 'sui-mt-2', {
      'sui-text-center': vertical
    });

  const shareBeltFavoriteClasses = classNames('sui-inline-block', {
    'sui-pr-4': vertical,
    'sui-pl-4': vertical
  });

  return (
    <div className={shareBeltItemClasses} data-component="ShareBeltFavorite">
      <span className={shareBeltIconClasses}>
        <AddToList
          itemId={itemId}
          showIconButton={showCircle}
          showCount={showFavoritesCount}
        />
      </span>
      {
        showLabel
          && (
            <span className={shareBeltFavoriteClasses}>
              <Typography color="primary" variant="body-base">Favorite</Typography>
            </span>
          )
      }
    </div>
  );
};

ShareBeltFavorite.displayName = 'ShareBeltFavorite';

ShareBeltFavorite.propTypes = {
  disable: PropTypes.bool,
  showCircle: PropTypes.bool,
  showLabel: PropTypes.bool,
  showFavoritesCount: PropTypes.bool
};

ShareBeltFavorite.defaultProps = {
  disable: false,
  showCircle: false,
  showLabel: false,
  showFavoritesCount: false
};

ShareBeltFavorite.dataModel = extend({}, AddToList);

export default ShareBeltFavorite;