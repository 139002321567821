import React, { useContext } from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const RelatedSearchPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  return (
    <div data-component="RelatedSearchPlaceholder">
      <Placeholder
        className="placeholder"
        width="100%"
        height={isMobile ? '262px' : '132.5px'}
        color="#E0E0E0"
      />
    </div>
  );
};

export { RelatedSearchPlaceholder };
