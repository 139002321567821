import PropTypes from 'prop-types';
import {
  params, string, shape, arrayOf
} from '@thd-nucleus/data-sources';

export const dataModelPopularVariations = {
  emtPopularVariations: params({ itemId: string().isRequired() }).shape({
    popularVariations: arrayOf(shape({
      itemId: string(),
      taxonomy: shape({
        brandLinkUrl: string(),
      }),
      identifiers: shape({
        brandName: string(),
        canonicalUrl: string(),
        productLabel: string(),
      }),
      media: shape({
        images: arrayOf(shape({
          url: string()
        }))
      })
    }))
  })
};

export const propTypesPopularVariations = {
  productId: PropTypes.string.isRequired
};