import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';

import { FADE_DURATION_IN_MS } from './constants';
import {
  getLensModeEnlargedImageCoordinates,
  getInPlaceEnlargedImageCoordinates
} from './helpers/imageCoordinates';
import {
  ContainerDimensions,
  LargeImageShape,
  SmallImageShape,
  Point
} from './propTypes';
import {
  getEnlargedImageContainerStyle,
  getEnlargedImageStyle
} from './helpers/styles';

export const EnlargedImage = ({
  containerClassName,
  containerDimensions,
  cursorOffset,
  isActive,
  isInPlaceMode,
  isPositionOutside,
  largeImage,
  position,
  smallImage
}) => {
  const {
    alt = ''
  } = largeImage;

  const [isTransitionEntering, setIsTransitionEntering] = useState(false);
  const [isTransitionActive, setIsTransitionActive] = useState(false);
  const [isTransitionLeaving, setIsTransitionLeaving] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const timers = [];

  useEffect(() => {
    setIsMounted(true);
    return () => {
      timers.forEach((timerId) => {
        clearTimeout(timerId);
      });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (isActive && !isPositionOutside) {
        setIsTransitionEntering(true);

        timers.push(setTimeout(() => {
          setIsTransitionEntering(false);
          setIsTransitionActive(true);
        }, 0));
      } else {
        setIsTransitionLeaving(true);
        setIsTransitionActive(false);

        timers.push(setTimeout(() => {
          setIsTransitionLeaving(false);
        }, FADE_DURATION_IN_MS));
      }
    }
  }, [isActive, isPositionOutside]);

  const imageCoordinates = isInPlaceMode
    ? getInPlaceEnlargedImageCoordinates({
      containerDimensions,
      largeImage,
      position
    })
    : getLensModeEnlargedImageCoordinates({
      containerDimensions,
      cursorOffset,
      largeImage,
      position,
      smallImage
    });

  const isVisible = isTransitionEntering
      || isTransitionActive
      || isTransitionLeaving;

  const containerStyle = getEnlargedImageContainerStyle({
    containerDimensions,
    isTransitionActive,
    isInPlaceMode
  });

  const imageStyle = getEnlargedImageStyle({
    imageCoordinates,
    largeImage
  });

  const component = (
    <div
      className={containerClassName}
      data-testid="enlarged-image"
      style={containerStyle}
    >
      {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
      <img
        alt={alt}
        className="sui-max-w-none"
        src={largeImage.src}
        srcSet={largeImage.srcSet}
        sizes={largeImage.sizes}
        style={imageStyle}
      />
    </div>
  );

  return isVisible ? component : null;
};

EnlargedImage.displayName = 'EnlargedImage';

EnlargedImage.propTypes = {
  containerClassName: string,
  containerDimensions: ContainerDimensions.isRequired,
  cursorOffset: Point.isRequired,
  isActive: bool,
  isInPlaceMode: bool.isRequired,
  isPositionOutside: bool,
  largeImage: LargeImageShape.isRequired,
  position: Point,
  smallImage: SmallImageShape.isRequired
};

EnlargedImage.defaultProps = {
  containerClassName: '',
  isActive: false,
  isPositionOutside: false,
  position: { x: 0, y: 0 }
};