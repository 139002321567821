import { useStore } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';

import { getPIPCalculatorProps, shouldDisplayPipCalculator } from '../helpers/pipCalculatorHelper';

export const usePipCalculatorData = ({ itemId, isInstalledCarpet }) => {
  const { storeId } = useStore();
  const { data } = useDataModel(isInstalledCarpet ? 'product' : 'clientOnlyProduct', {
    skip: !itemId,
    ssr: isInstalledCarpet,
    variables: {
      itemId,
      storeId
    }
  });

  const { product = {} } = data || {};
  const {
    fulfillment = {},
    info = {},
    installServices = {},
    pricing = {},
    taxonomy = {}
  } = product;

  const shouldDisplay = shouldDisplayPipCalculator({
    fulfillment, info, installServices, isInstalledCarpet
  });

  const pipCalculatorProps = shouldDisplay
    ? getPIPCalculatorProps({ info })
    : { calcProps: {}, resultsProps: {} };

  return {
    shouldDisplay, info, pipCalculatorProps, pricing, taxonomy
  };
};