import React from 'react';
import PropTypes, { node, element, arrayOf } from 'prop-types';
import { SalientPoints } from '@thd-olt-component-react/salient-points';
import { extend } from '@thd-nucleus/data-sources';
import './additional-details.style.scss';

export const AdditionalDetails = ({ itemId, children }) => {

  return (
    <div className="additional-details">
      {children || (<SalientPoints itemId={itemId} />)}
    </div>
  );
};

AdditionalDetails.displayName = 'AdditionalDetails';

AdditionalDetails.dataModel = extend(SalientPoints);

AdditionalDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([node, element, arrayOf(element)])
};

AdditionalDetails.defaultProps = {
  children: null
};
