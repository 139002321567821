import React, {
  Suspense, useState, useCallback, useEffect, useMemo
} from 'react';
import {
  bool, func, number, oneOfType, string
} from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { Email } from '@one-thd/sui-icons';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import * as actions from './share.action';
import { track } from '../../../analytics';

const FRAME_HEIGHT = '436';
const FRAME_WIDTH = '626';

// eslint-disable-next-line max-len
const ShareToPinterest = React.lazy(() => import(/* webpackChunkName: "share-to-pinterest-overlay" */ './ShareToPinterest'));
// eslint-disable-next-line max-len
const ShareToEmail = React.lazy(() => import(/* webpackChunkName: "share-to-email-overlay" */ './ShareToEmail'));

const ShareBeltTooltip = ({
  collectionImageSrc,
  collectionId,
  email,
  hrefPath,
  itemId,
  pinterestTitle,
  toolTipClose
}) => {
  const [pinterestOverlayOpen, setPinterestOverlayOpen] = useState(false);
  const [emailOverlayOpen, setEmailOverlayOpen] = useState(false);
  const [isInStore, setIsInStore] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const { storeId } = useStore();
  const { data: productData, loading, error } = useDataModel('product', {
    variables: { itemId },
    skip: !itemId
  });

  const thdCustomer = useThdCustomer();

  const shouldEnableEmail = email && (thdCustomer?.isLoggedIn
    || isInStore || isInternal);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-unused-expressions
      window.THD_LOCALIZER_AUTO_INIT?.Localizer?.status?.isUserInStoreAsync()
        .then(() => setIsInStore(true))
        .catch(() => setIsInStore(false));

      setIsInternal(window.THD_LOCALIZER_AUTO_INIT?.Localizer?.status?.isInternal());
    }
  }, []);

  if ((loading && !productData) || error) { // What if there's no error and no data?
    return null;
  }

  const closeOverlays = () => {
    setEmailOverlayOpen(false);
    setPinterestOverlayOpen(false);
    toolTipClose();
  };

  const onPinImgClick = () => {
    // The collectionImageSrc is been used as per the current
    // functionality implemented for collection
    const pinterestUrl = 'https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(hrefPath)
        + actions.generatePinCode(collectionId) + '&media=' + encodeURIComponent(collectionImageSrc) + '&description=' + encodeURIComponent(pinterestTitle); // eslint-disable-line max-len
    window.open(pinterestUrl, 'pinterest_share_dialog', `width=${FRAME_WIDTH},height=${FRAME_HEIGHT}`);
  };

  const onClickPinterest = (event) => {
    track({
      primaryCategory: 'interaction',
      eventName: 'pip sub-content',
      section: 'sharing',
      component: 'socialmedia',
      target: 'pinterest'
    });
    event.preventDefault();
    if (collectionId) {
      onPinImgClick();
    } else {
      setPinterestOverlayOpen(true);
    }
  };

  const onClickFacebook = (event) => {
    event.preventDefault();
    track({
      primaryCategory: 'interaction',
      eventName: 'pip sub-content',
      section: 'sharing',
      component: 'socialmedia',
      target: 'facebook'
    });
    const encodeURI = encodeURIComponent(hrefPath);
    const opts = `width=${FRAME_WIDTH},height=${FRAME_HEIGHT}`;
    const uri = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI}, 'facebook_share_dialog', ${opts}`;
    window.open(uri);
  };

  const onClickEmail = (event) => {
    event.preventDefault();
    track({
      primaryCategory: 'interaction',
      eventName: 'pip sub-content',
      section: 'sharing',
      component: 'socialmedia',
      target: 'email'
    });
    setEmailOverlayOpen(true);
  };

  return (
    <div
      data-section="sharing"
      className="sui-m-3"
      data-socialmedia="pinterest,facebook"
    >
      <Button
        tabIndex={0}
        onClick={(event) => onClickPinterest(event)}
        className="sui-block sui-mb-2"
        data-target="pinterest"
        variant="ghost"
      >
        <span
          className="sui-bg-no-repeat sui-inline-block
        sui-relative thd-sharebelt-icons--pinterest
        sui-float-none sui-scale-100"
        />
        <span className="sui-inline-block">
          <Typography variant="body-lg">
            Pinterest
          </Typography>
        </span>
      </Button>
      {pinterestOverlayOpen && (
        <Suspense fallback={<div>Loading...</div>}>
          <ShareToPinterest
            closeOverlays={closeOverlays}
            data={productData}
            hrefPath={hrefPath}
            itemId={itemId}
            pinterestOverlayOpen={pinterestOverlayOpen}
            pinterestTitle={pinterestTitle}
          />
        </Suspense>
      )}
      <Button
        tabIndex={0}
        onClick={(event) => onClickFacebook(event)}
        className="sui-block sui-mb-2"
        data-target="facebook"
        variant="ghost"
      >
        <span
          className="sui-bg-no-repeat sui-inline-block
        sui-relative thd-sharebelt-icons--facebook
        sui-float-none sui-scale-100"
        />
        <span className="sui-inline-block">
          <Typography variant="body-lg">
            Facebook
          </Typography>
        </span>
      </Button>
      {shouldEnableEmail && (
        <>
          <Button
            tabIndex={0}
            onClick={(event) => onClickEmail(event)}
            className="sui-flex sui-mb-2"
            data-target="email"
            data-testid="email"
            variant="ghost"
          >
            <Email size="regular" />
            <span className="sui-inline-block sui-ml-4">
              <Typography variant="body-lg"> Email A Friend  </Typography>
            </span>
          </Button>
          {emailOverlayOpen && (
            <Suspense fallback={<div>Loading...</div>}>
              <ShareToEmail
                closeOverlays={closeOverlays}
                emailOverlayOpen={emailOverlayOpen}
                itemId={itemId}
                storeId={storeId}
              />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};

ShareBeltTooltip.displayName = 'ShareBeltTooltip';

ShareBeltTooltip.propTypes = {
  collectionImageSrc: string,
  collectionId: string,
  email: bool,
  hrefPath: string,
  itemId: oneOfType([
    string,
    number,
  ]),
  pinterestTitle: string,
  toolTipClose: func
};

ShareBeltTooltip.defaultProps = {
  collectionImageSrc: null,
  collectionId: null,
  email: false,
  hrefPath: null,
  itemId: null,
  pinterestTitle: null,
  toolTipClose: () => {}
};

export default ShareBeltTooltip;
