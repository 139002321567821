import React from 'react';
import {
  string,
  func
} from 'prop-types';

import { Typography } from '@one-thd/sui-atomic-components';
import { useConfigService } from '@thd-nucleus/experience-context';

import { usePipCalculatorData } from '../../../hooks/usePipCalculatorData';
import { DEFAULT_CALC_CLASSES, DEFAULT_DESCRIPTION } from '../../../constants';
import { CalculateByDimensions } from '../../CalculateByDimensions';
import { CalculatorFooter } from '../../CalculatorFooter';
import { CalculatorHeader } from '../../CalculateHeader';
import { CalculatorInputSections } from '../../CalculatorInputSections';
import { CalculatorProvider } from '../CalculatorProvider';
import { Results } from './Results/Results';
import { pipCalculatorDataModel } from '../../dataModel';

export const PIPCalculator = ({ itemId, onCalculate }) => {
  const estimatorEnabled = useConfigService('fs:estimatorEnabled');
  const {
    shouldDisplay,
    info,
    pipCalculatorProps,
    pricing,
    taxonomy
  } = usePipCalculatorData({ itemId, isInstalledCarpet: false });

  if (estimatorEnabled || !shouldDisplay) {
    return null;
  }

  const { calcProps, resultsProps } = pipCalculatorProps;

  return (
    <div data-component="PIPCalculator">
      <CalculatorProvider
        allowAdditionalCoverage
        calculatorType={calcProps.calculatorType}
        info={info}
        lineItemName={calcProps.lineItemName}
        onCalculate={onCalculate}
        unitsPerCase={resultsProps.unitsPerCase}
        taxonomy={taxonomy}
      >
        <div className={DEFAULT_CALC_CLASSES}>
          <CalculatorHeader />
          <Typography>{DEFAULT_DESCRIPTION}</Typography>
          <CalculateByDimensions dimensionNames={calcProps.dimensionNames} />
          <CalculatorInputSections dimensionNames={calcProps.dimensionNames} />
          <CalculatorFooter />
        </div>
        <Results
          additionalCoveragePercentage={resultsProps.additionalCoveragePercentage}
          caseUnitOfMeasure={resultsProps.caseUnitOfMeasure}
          pricing={pricing}
          unitsPerCase={resultsProps.unitsPerCase}
          uom={resultsProps.uom}
        />
      </CalculatorProvider>
    </div>
  );
};

PIPCalculator.displayName = 'PIPCalculator';

PIPCalculator.dataModel = pipCalculatorDataModel;

PIPCalculator.propTypes = {
  itemId: string.isRequired,
  onCalculate: func.isRequired
};