import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Image, Rating } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import { FrequentlyBoughtTogetherContext } from '../../contexts/FrequentlyBoughtTogetherContext';
import './AnchorItem.style.scss';

const AnchorItem = ({
  itemId, itemInCart, storeId, applianceBundle, anchorItemTitle
}) => {

  const { channel } = useContext(ExperienceContext);

  const { productResponseData } = useContext(FrequentlyBoughtTogetherContext);

  const [loadedData, setLoadedData] = useState(productResponseData || {});

  useEffect(() => {
    if (productResponseData && productResponseData?.product) {
      setLoadedData(productResponseData);
    }
  }, [productResponseData]);

  if (!loadedData) return null;

  const { productLabel, brandName } = loadedData?.product?.identifiers || {};
  const { averageRating, totalReviews } = loadedData?.product?.reviews?.ratingsReviews || {};

  const { media } = loadedData?.product || {};
  const { images } = media || {};
  const primary = (images || []).find((img) => img.subType === 'PRIMARY');
  if (!primary) return null;
  const primaryImageUrl = primary.url.replace('<SIZE>', 300);
  const mobileImageUrl = primary.url.replace('<SIZE>', 100);

  const anchorActionText = itemInCart ? 'Already In Your Cart' : 'This Item';

  const anchorTitle = `This ${anchorItemTitle}`;

  const anchorItemContainerClasses = classNames('fbt-anchor-item', {
    'fbt-anchor-item__appliance': applianceBundle
  });

  const mobileAnchor = (
    <>
      <div className="fbt-anchor-item-mobile__image">
        <Image
          src={mobileImageUrl}
          alt={productLabel}
          stretchy
          width={1}
          height={1}
        />
      </div>
      <div className="fbt-anchor-item-mobile__content-wrapper">
        {productLabel
        && (
          <div className="fbt-anchor-item-mobile__label">
            <span className="fbt-anchor-item-mobile__title">
              {applianceBundle ? anchorTitle : anchorActionText}:
            </span>
            {' '}
            <span>{applianceBundle && brandName } </span>
            {productLabel}
          </div>
        )}
        <div className="fbt-anchor-item-mobile__ratings-reviews">
          <Rating value={parseFloat(averageRating)} />
          <span>({Number(totalReviews)})</span>
        </div>
        <div className="fbt-anchor-item-mobile__price">
          <Price
            itemId={itemId}
            large={false}
            displayEachUom={false}
            hideSavingsText
            storeId={storeId}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {channel === 'mobile'
      && (
        <div className="fbt-anchor-item-mobile">
          {mobileAnchor}
        </div>
      )}
      {(channel === 'desktop' || channel === 'tablet')
      && (
        <div className={anchorItemContainerClasses}>
          <div className="fbt-anchor-item__breakpoint-title">{applianceBundle ? anchorTitle : anchorActionText}</div>
          <div className="fbt-anchor-item__image">
            <Image
              src={primaryImageUrl}
              alt={productLabel}
              stretchy
              width={1}
              height={1}
            />
          </div>
          <div className="fbt-anchor-item__content-wrapper">
            <div className="fbt-anchor-item__title">{applianceBundle ? anchorTitle : anchorActionText}</div>
            {productLabel
          && (
            <div className="fbt-anchor-item__label">
              <span>{applianceBundle && brandName } </span>
              {productLabel}
            </div>
          )}
            <div className="fbt-anchor-item__ratings-reviews">
              <Rating value={parseFloat(averageRating)} />
              <span>({Number(totalReviews)})</span>
            </div>
            <div className="fbt-anchor-item__price">
              <Price
                itemId={itemId}
                large={false}
                displayEachUom={false}
                hideSavingsText
                storeId={storeId}
              />
            </div>
          </div>
          <div className="fbt-anchor-item__breakpoint-price">
            <Price
              itemId={itemId}
              large={false}
              displayEachUom={false}
              hideSavingsText
              storeId={storeId}
            />
          </div>
        </div>
      )}
    </>
  );
};

AnchorItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  itemInCart: PropTypes.bool.isRequired,
  storeId: PropTypes.string.isRequired,
  applianceBundle: PropTypes.bool,
  anchorItemTitle: PropTypes.string
};

AnchorItem.defaultProps = {
  applianceBundle: false,
  anchorItemTitle: ''
};

AnchorItem.displayName = 'FBTAnchorItem';

export default AnchorItem;
