import React, { Component, Fragment } from 'react';
import {
  arrayOf, bool, func, node, oneOfType, shape, string
} from 'prop-types';
import classNames from 'classnames';
import { Heading } from '@thd-olt-component-react/core-ui';
import Overlay from './Overlay';
import { CardContext } from './CardContext';
import { OverlayContext } from './OverlayContext';

class Content extends Component {

  // eslint-disable-next-line react/static-property-placement
  static contextType = CardContext;

  static Overlay = Overlay;

  static setOverlayHash() {
    window.history.pushState({}, 'Overlay', '#overlay');
  }

  constructor(props) {
    super(props);
    const { children, title } = this.props;
    this.state = {};
    this.card = React.createRef();
    const kids = Array.isArray(children) ? children : [children];
    const overlays = kids.filter((element) => element.type === Overlay);

    if (overlays) {
      const overlayStates = {};
      overlays.map((eachOverlay, index) => {
        const name = eachOverlay.props.name || 'default-overlay';
        overlayStates[name] = false;
        const ctx = {
          title,
          onBack: () => this.onOverlayBack(eachOverlay.props.name),
          show: false,
        };
        return (
          <OverlayContext.Provider value={ctx} key={index}>
            {eachOverlay}
          </OverlayContext.Provider>
        );
      });
      this.state = overlayStates;
    }
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.checkOverlayHash);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.checkOverlayHash);
  }

  triggerCardClick = () => {
    const { title } = this.props;
    if (!title) return;

    const cardEventData = {
      section: 'product card',
      component: title.toLowerCase(),
      target: 'n/a'
    };

    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('zone-b.click', cardEventData);
    }
  };

  onClick = (overlayName, openedByTriggerFor) => {
    const hasOverlays = Object.keys(this.state).length;
    if (!hasOverlays) {
      return;
    }
    Content.setOverlayHash();
    this.setState((currentState) => {
      const newState = currentState;
      newState[overlayName] = openedByTriggerFor ? false : !newState[overlayName];
      return newState;
    });
    if (this.card?.current?.scrollIntoView) {
      this.card.current.scrollIntoView({ behavior: 'instant' });
    }
    document.body.classList.toggle(
      'body--noscroll',
      // eslint-disable-next-line react/destructuring-assignment
      openedByTriggerFor && !!Object.keys(this.state).find((overlay) => this.state[overlay])
    );
    this.triggerCardClick();
  };

  onOverlayBack = (overlayName) => {
    this.closeOverlay(overlayName);
    window.history.back();
  };

  getRenderedOverlays = () => {
    const {
      title, children
    } = this.props;
    const { triggered, scrollToBottom: scrollToBottomCtx } = this.context;
    const { scrollToBottom: scrollToBottomProp } = this.props;
    const kids = Array.isArray(children) ? children : [children];
    const renderedOverlays = kids.filter((element) => element.type === Overlay);
    if (renderedOverlays) {
      return renderedOverlays.map((overlay, overlayindex) => {
        const name = overlay.props.name || 'default-overlay';
        const isTriggered = name === triggered;
        // eslint-disable-next-line react/destructuring-assignment
        const show = isTriggered ? !this.state[name] : this.state[name];
        if (show && isTriggered) {
          document.body.classList.toggle('body--noscroll', true);
        }
        const ctx = {
          show,
          title,
          key: `overlay-${overlayindex}`, // eslint-disable-line
          onBack: () => this.onOverlayBack(name),
          scrollToBottom: scrollToBottomProp || scrollToBottomCtx,
        };
        return (
          <OverlayContext.Provider value={ctx} key={overlayindex}>
            {overlay}
          </OverlayContext.Provider>
        );
      });
    }
    return renderedOverlays;
  };

  getOnClick = () => {
    const {
      title, getTitleOnClick
    } = this.props;
    if (getTitleOnClick && title) getTitleOnClick(title);
  };

  getAdditionallListeners = () => {
    return Object.keys(this.props).filter((propName) => {
      // eslint-disable-next-line react/destructuring-assignment
      const value = this.props[propName];
      return (/^on[A-Z]/.test(propName) && typeof value === 'function');
    }).reduce((acc, cur) => {
      return {
        ...acc,
        // eslint-disable-next-line react/destructuring-assignment
        [cur]: this.props[cur]
      };
    }, {});
  };

  closeOverlay = (overlayName) => {
    this.setState((currentState) => {
      const newState = currentState;
      newState[overlayName] = false;
      return newState;
    });
  };

  // hide the overlay if browser back
  checkOverlayHash = (event) => {
    const { oldURL = '' } = event;
    if (oldURL.indexOf('#overlay') > -1) {
      this.setState((prevState) => {
        const overlays = Object.keys(prevState);
        const newState = prevState;
        overlays.forEach((overlay) => {
          newState[overlay] = false;
        });
        return newState;
      });
    }
  };

  render() {
    const {
      className,
      ctaText,
      fade,
      link,
      verticalAccent,
      title,
      maxHeight,
      children,
      noDivider,
      underline,
      ...additional
    } = this.props;

    // Don't apply custom actions to the div.
    ['scrollToBottom'].forEach((overlayAction) => {
      if (Object.keys(additional).indexOf(overlayAction) > -1) {
        delete additional[overlayAction];
      }
    });

    const overlays = this.getRenderedOverlays();

    const classes = classNames(
      'content-card__body',
      className, {
        'content-card__body--clickable': overlays.length > 0
      }
    );

    const contentClasses = classNames('content-card__summary', {
      'content-card__summary--fade': fade
    });

    const headerClasses = classNames('content-card__header', {
      'content-card__vertical-accent': verticalAccent
    });

    const kids = Array.isArray(children) ? children : [children];
    const headerCTA = link || ctaText;

    return (
      <>
        {overlays}
        <div
          role="presentation"
          ref={this.card}
          className={classes}
          onClick={() => { this.onClick('default-overlay'); this.getOnClick(); }}
          {...this.getAdditionallListeners()} // eslint-disable-line react/jsx-props-no-spreading
        >
          <Heading
            title={title}
            className={headerClasses}
            clickable={headerCTA || overlays.length > 0}
            noborder={noDivider}
            underline={underline}
          />
          <div className={contentClasses} style={{ maxHeight }}>
            {
              kids
              && kids.map((element) => {
                if (element.type === Overlay) {
                  return null;
                }
                return element;
              })
            }
          </div>
        </div>
      </>
    );
  }
}

Content.propTypes = {
  /** Card content */
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  /** Additional classes for the card content */
  className: string,
  /** Text before the arrow */
  ctaText: string,
  /** Fade content */
  fade: bool,
  /** Add Orange Accent to top left of title bar */
  verticalAccent: bool,
  /** Link in header action */
  link: shape({
    title: string,
    href: string
  }),
  /** Max height for the card. Defaults to 150px. Can be set to 'none' to extend to full height */
  maxHeight: string,
  /** Remove the divider under the title */
  noDivider: bool,
  underline: bool,
  /** Scrolls to the bottom of the overlay when opened */
  scrollToBottom: bool,
  /** Content title. */
  title: string,
  /** Name of trigger fired */
  triggered: string,
  /** callback of triggering content onclick */
  getTitleOnClick: func
};

Content.defaultProps = {
  className: null,
  ctaText: null,
  fade: false,
  verticalAccent: false,
  link: null,
  maxHeight: '150px',
  noDivider: false,
  underline: false,
  scrollToBottom: false,
  title: null,
  triggered: null,
  getTitleOnClick: null
};

export default Content;
