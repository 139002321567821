import PropTypes from 'prop-types';
import {
  arrayOf as arrayType, params, shape as shapeType, string as stringType
} from '@thd-nucleus/data-sources';

export const dataModelRelatedSearch = {
  relatedSearchModel: params({ itemId: stringType(), navParam: stringType(), storeId: stringType() }).shape({
    products: arrayType(shapeType({
      itemId: stringType(),
      media: shapeType({
        images: arrayType(shapeType({
          url: stringType()
        }).client())
      }),
      identifiers: shapeType({
        canonicalUrl: stringType(),
        productLabel: stringType()
      }),
      details: shapeType({
        description: stringType()
      })
    })),
    relatedSearch: arrayType(shapeType({
      url: stringType(),
      anchor: stringType()
    }))
  }),
  popularCategories: arrayType(shapeType({
    text: stringType(),
    url: stringType()
  })),
  searchModel: params({ keyword: stringType(), storeId: stringType() }).shape({
    relatedResults: shapeType({
      relatedKeywords: arrayType(shapeType({
        keyword: stringType()
      }))
    })
  })
};

export const defaultPropsRelatedSearch = {
  itemId: null,
  keyword: null,
  navParam: null,
  storeId: null,
  searchData: undefined,
  searchError: undefined,
  searchLoading: undefined,
  recent: false,
  limitItems: false,
  noOfItems: 10,
};

export const propTypesRelatedSearch = {
  itemId: PropTypes.string,
  keyword: PropTypes.string,
  navParam: PropTypes.string,
  storeId: PropTypes.string,
  searchData: PropTypes.shape({}), // needs to be defined and removed from lint ignore
  searchError: PropTypes.shape({}),
  searchLoading: PropTypes.bool,
  recent: PropTypes.bool,
  limitItems: PropTypes.bool,
  noOfItems: PropTypes.number,
};