/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  ButtonGroup,
  FormController,
  FormLabel,
  DrawerBody,
  DrawerFooter
} from '@one-thd/sui-atomic-components';
import { Quotes } from '../shared/Quotes';
import { ProductInfo } from '../shared/ProductInfo';
import { DELIMITER } from '../shared/Constants';

const AddToQuoteFormDrawer = ({
  product,
  quoteList,
  selectedQuote,
  setSelectedQuote,
  quotesPageSize,
  setQuotesPageSize,
  showLoadMore,
  onClose,
  setAddToQuoteFormDrawer,
  setCreateNewQuoteDrawer,
  setPriceChangeWarningDrawer
}) => {

  const selectQuote = (evt) => {
    const { value } = evt?.target;
    const [quoteId, userId, quoteName] = value.split(DELIMITER);
    setSelectedQuote({
      userId,
      quoteId,
      quoteName
    });
  };

  const createNewQuoteClick = () => {
    setAddToQuoteFormDrawer(false);
    setCreateNewQuoteDrawer(true);
    window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.createAQuoteOverlay');
  };

  const priceChangeWarningDrawerClick = () => {
    setAddToQuoteFormDrawer(false);
    setPriceChangeWarningDrawer(true);
  };

  return (
    <>
      <DrawerBody>
        <ProductInfo product={product} />
        <Button
          className="sui-mb-4 sui-mt-6 sui-text-base sui-flex"
          variant="text"
          onClick={createNewQuoteClick}
          data-testid="create-quote"
        >
          <u>Create New Quote</u>
        </Button>
        <div className="sui-pb-4 sui-text-base">-or-</div>
        <FormController>
          <FormLabel id="quote-names" className="sui-cursor-default sui-text-base">
            Add to an Existing Quote:
          </FormLabel>
          <Quotes
            quoteList={quoteList}
            selectedQuote={selectedQuote}
            selectQuote={selectQuote}
            showLoadMore={showLoadMore}
            quotesPageSize={quotesPageSize}
            setQuotesPageSize={setQuotesPageSize}
          />
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={priceChangeWarningDrawerClick}
            disabled={!selectedQuote?.quoteId.length}
            data-testid="add-to-existing-quote"
          >
            Add to Quote
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

AddToQuoteFormDrawer.propTypes = {};

AddToQuoteFormDrawer.displayName = 'AddToQuoteFormDrawer';

export { AddToQuoteFormDrawer };