import {
  params, client, string, shape, extend, arrayOf, number
} from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { SalientPoints } from '@thd-olt-component-react/salient-points';
import { FulfillmentLazy } from './components/FulfillmentLazy';

const Product = extend({
  itemId: string(),
  dataSources: string(),
  media: shape({
    image: client(shape({
      url: string()
    })),
    images: arrayOf(shape({
      url: string(),
      sizes: arrayOf(string()),
      type: string(),
      subType: string()
    })),
  }),
  identifiers: shape({
    modelNumber: string(),
    canonicalUrl: string(),
    brandName: string(),
    productLabel: string()
  }),
  reviews: shape({
    ratingsReviews: shape({
      averageRating: string(),
      totalReviews: string()
    })
  }),
  info: shape({
    quantityLimit: number()
  })
},
KeyProductFeatures.dataModel.product,
Price.dataModel.product,
AddToList.dataModel.product,
SalientPoints.dataModel.product
);

export const dataModel = extend({
  products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf(Product),
  product: Product
},
KeyProductFeatures.dataModel,
Price.dataModel,
AddToList.dataModel,
FulfillmentLazy.dataModel,
SalientPoints.dataModel
);