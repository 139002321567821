import {
  params, shape, string as stringType, arrayOf, client, number, bool as boolType, extend
} from '@thd-nucleus/data-sources';
import { ProductPod, ProductImage } from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';

export const ProductModel = extend({
  itemId: stringType(),
  dataSource: stringType(),
  identifiers: shape({
    storeSkuNumber: stringType(),
    isSuperSku: boolType(),
    parentId: stringType()
  }),
  fulfillment: client(params({ storeId: stringType(), zipCode: stringType() }).shape({
    fulfillmentOptions: arrayOf(shape({
      type: stringType(),
      services: arrayOf(shape({
        type: stringType(),
        locations: arrayOf(shape({
          inventory: shape({
            quantity: number()
          }),
          isAnchor: boolType(),
          type: stringType()
        }))
      }))
    }))
  }))
}, ProductPod.dataModel.product, ProductImage.dataModel.product, ProductHeader.dataModel.product);

export const dataModel = extend(
  {
    products: params({
      itemIds: arrayOf(stringType().isRequired()).isRequired(),
      dataSource: stringType()
    }).arrayOf(shape(ProductModel))
  },
  {
    metadata: params({ parentId: stringType().isRequired() }).shape({
      attributes: arrayOf(shape({
        attributeValues: arrayOf(shape({
          value: stringType()
        }))
      }))
    })
  }
);
