import React from 'react';
import { shape } from 'prop-types';
import { RatingMeter, Typography } from '@one-thd/sui-atomic-components';

export const AttributeRatings = ({ secondaryRatingsAverages: secondaryRatingsAveragesRaw }) => {
  if (!secondaryRatingsAveragesRaw) {
    return '';
  }
  const secondaryRatingsAverages = Object.values(secondaryRatingsAveragesRaw)?.filter((attribute) => attribute);

  return (
    <>
      {!!secondaryRatingsAverages[0]
      && (
        <div data-testid="attribute-ratings" className="sui-flex sui-flex-col">
          <Typography component="h3" variant="body-xs" weight="bold">Attribute Ratings</Typography>
          {(secondaryRatingsAverages).map((val, index) => {
            const averageRatingNumber = Number(val.AverageRating);
            if (averageRatingNumber) {
              return (
                <div
                  key={index}
                  data-testid="attribute-rating"
                  className="sui-flex sui-flex-row sui-justify-between sui-items-center"
                >
                  <span className="sui-text-center sui-h-min"> {val.Id} </span>
                  <RatingMeter value={averageRatingNumber} />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
};

AttributeRatings.propTypes = {
  secondaryRatingsAverages: shape({}).isRequired
};