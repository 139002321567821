import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Checkbox, CheckboxGroup, Heading, Image } from '@thd-olt-component-react/core-ui';
import { Typography } from '@one-thd/sui-atomic-components';
import PriceMessage from '../Partials/PriceMessage.component';
import * as analytics from '../../analytics/frequentlyboughttogether-analytics';
import { FbtBadge } from '../Badge/FbtBadge';
import './Legacy.style.scss';
import { getTotalPrice, getFinalPriceMessageCount } from '../../helpers/helpers';

class LegacyFBT extends Component {

  // eslint-disable-next-line react/static-property-placement
  static contextType = ExperienceContext;

  componentDidMount() {
    const { products } = this.props;
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && products) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('frequently-bought-together.ready', { products });
    }
  }

  onChangeCheckbox = (id) => {
    const { onChangeCheckbox } = this.props;
    onChangeCheckbox(id);
  };

  onAddToCartClick = () => {
    const { products = [], itemId, setCheckedItems } = this.props;
    let analyticsItems = [];
    // Uncheck all items on ATC callback.
    setCheckedItems([]);
    products.forEach((product) => {
      if (product.checked) {
        if (product.itemId !== itemId) {
          analyticsItems.push(product);
        }
      }
    });
    analytics.fbtAddToCartAnalytics({ analyticsItems });
  };

  getCart(items) {
    const isMajorAppliance = items.every((item) => item.product?.identifiers?.productType === 'MAJOR_APPLIANCE');
    const { channel } = this.context;
    const options = {
      host: typeof window !== 'undefined'
        ? window.location.origin
        : null,
      channel,
      paypal: false,
      misship: false,
      directCheckout: !isMajorAppliance,
      alterBrowserHistory: isMajorAppliance ? null : true
    };
    const zipCode = typeof window !== 'undefined' ? window.cookieUtils?.readBrowserCookie?.('DELIVERY_ZIP') : '';
    const itemDetails = items.filter((item) => item.checked)
      .map((item) => {
        const isMajorApplianceItem = item.product?.identifiers?.productType === 'MAJOR_APPLIANCE';
        if (isMajorApplianceItem) {
          return {
            itemId: String(item.itemId),
            quantity: 1,
            fulfillmentMethod: 'DirectDelivery',
            fulfillmentLocation: zipCode || '',
          };
        }
        return {
          itemId: String(item.itemId),
          quantity: 1
        };
      });

    return {
      itemDetails,
      options
    };
  }

  getPricingDetails = (item) => {
    const {
      mapAboveOriginalPrice, promotion, value
    } = item.pricing || {};
    const pricingValue = value || 0;
    const dollarOff = promotion?.dollarOff || 0;
    return (
      <>
        {mapAboveOriginalPrice
          && (
            <div className="fbtProductLine__priceContainer">
              <span>
                {dollarOff > 0
                  && (
                    <span
                      className="fbtStrikethroughPrice"
                    >{this.formatCurrency(pricingValue + dollarOff)}
                    </span>
                  )}
                <span className="fbtProductLine__price"> See Low Price in Cart</span>
              </span>
            </div>
          )}
        {!mapAboveOriginalPrice
          && (
            <div className="fbtProductLine__priceContainer">
              {dollarOff > 0
                ? (
                  <span>
                    <span
                      className="fbtStrikethroughPrice"
                    >{this.formatCurrency(pricingValue + dollarOff)}
                    </span>
                    <span
                      className="fbtProductLine__price"
                    > {this.formatCurrency(pricingValue)}
                    </span>
                  </span>
                )
                : (
                  <span
                    className="fbtProductLine__price"
                  >{this.formatCurrency(pricingValue + dollarOff)}
                  </span>
                )}
            </div>
          )}
      </>
    );
  };

  getPrimaryLabel = (item) => {
    const { productName } = item;
    const productDescriptionClasses = classNames(
      'fbt__productdesc',
      'fbt__productdesc--truncate', {
        fbt__productdesc__opacity: !item.checked
      });
    return (
      <>
        <div className={productDescriptionClasses}>
          <b>This item: </b>{item && productName} {' '}
        </div>
        {this.getPricingDetails(item)}
      </>
    );
  };

  getLink = (item = {}) => {
    const { products } = this.props;
    const anchor = products[0].itemId;
    const displayPosition = products.findIndex((i) => i.itemId === item.itemId);
    const {
      brand, canonicalURL, productName
    } = item;
    const productDescriptionClasses = classNames(
      'fbt__productdesc',
      'fbt__productdesc--truncate', {
        fbt__productdesc__opacity: !item.checked
      });
    return (
      <>
        <div className={productDescriptionClasses}>
          <a
            href={canonicalURL}
            onClick={() => analytics.track({
              itemId: anchor,
              displayPosition
            })}
          >
            {brand} {' '}
            {productName} {' '}
          </a>
        </div>
        {this.getPricingDetails(item)}
      </>
    );
  };

  getAddToCartMessage = (selectedItemsCount, priceMsgCount) => {
    const message = {
      isDisable: false,
      atcMessage: 'Add to cart'
    };
    if (selectedItemsCount === 1) {
      message.priceMessage = 'Price:';
    } else if (selectedItemsCount === 2) {
      message.priceMessage = 'Price for both:';
      message.atcMessage = 'Add both to cart';
    } else if (selectedItemsCount === 3) {
      message.priceMessage = 'Price for all three:';
      message.atcMessage = 'Add all three to cart';
    } else if (selectedItemsCount === 4) {
      message.priceMessage = 'Price for all four:';
      message.atcMessage = 'Add all four to cart';
    } else if (selectedItemsCount === 5) {
      message.priceMessage = 'Price for all five:';
      message.atcMessage = 'Add all five to cart';
    } else if (selectedItemsCount === 0) {
      message.priceMessage = '';
      message.isDisable = true;
    }
    if (priceMsgCount > 0) message.priceMessage = '';
    return message;
  };

  getItemImage = (item) => {
    const { products } = this.props;
    const anchor = products[0].itemId;
    const displayPosition = products.findIndex((i) => i.itemId === item.itemId);
    const badges = item.badges;
    const imageUrl = item.imageURL.replace('<SIZE>', 145);
    const imageClasses = classNames('fbtProductImage', {
      fbtProductImage__opacity: !item.checked
    });

    return item.type === 'primary'
      ? (
        <div>
          <FbtBadge badges={badges} />
          <Image
            className={imageClasses}
            src={imageUrl}
            alt={item.productLabel || ''}
            lazy
            width={1}
            height={1}
          />
        </div>
      )
      : (
        <a
          href={item.canonicalURL}
          onClick={() => analytics.track({
            itemId: anchor,
            displayPosition
          })}
        >
          <FbtBadge badges={badges} />
          <Image
            className={imageClasses}
            src={imageUrl}
            alt={item.productLabel || ''}
            lazy
            width={1}
            height={1}
          />
        </a>
      );
  };

  formatCurrency = (price) => {
    const value = parseFloat(price);
    let priceFormat;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(value)) || value === 0) return '';
    if (value < 1) {
      priceFormat = `${(Number(value) * 100).toFixed(0)}¢`;
    } else {
      priceFormat = `$${Number(value).toFixed(2)}`;
    }
    return priceFormat;
  };

  render() {
    const { itemId, products, storeId } = this.props;
    const checkedItems = products.filter((item) => item.checked);
    const cart = this.getCart(products);
    const priceMsgCount = getFinalPriceMessageCount(checkedItems);
    const message = this.getAddToCartMessage(checkedItems.length, priceMsgCount);
    let totalPrice = {};
    if (checkedItems.length) {
      totalPrice = getTotalPrice(products);
    }
    const count = products.reduce((n, item) => (item.type !== 'primary' ? n + 1 : n), 0);
    const fbtPlusIcon = classNames(
      'u__bold',
      'fbtPlus', {
        'fbtPlus--mobile':
        cart.options.channel === 'mobile'
      }
    );

    const fbtProductLineItemClasses = classNames({
      fbtProductLine__item:
        cart.options.channel === 'mobile'
    });

    if (count > 0) {
      return (
        <div className="frequently-bought-together" data-type="container" data-component="LegacyFBT">
          <meta data-prop="name" content="pip_fbt" />
          <div className="sui-mt-4 sui-mb-2">
            <div className="sui-mb-3">
              <Typography variant="h2">Frequently Bought Together</Typography>
            </div>
          </div>
          <div className="fbt_label">
            <div className="fbt_price fbt_container">
              {products.length > 1 && products.map((item, index) => {
                return (
                  <Fragment key={`fbt-product-image-${item.itemId}-${index}`}>
                    {item.type === 'primary' ? '' : <span className={fbtPlusIcon}>+</span>}
                    <div
                      className="fbtProductImageCont"
                      key={`fbt-Product-Image ${item.itemId}`}
                      data-itemid={item.itemId}
                      data-type="product"
                    >
                      <meta data-prop="productID" content={item.itemId} />
                      {this.getItemImage(item)}
                    </div>
                  </Fragment>
                );
              })}
              {cart.options.channel !== 'mobile'
                && (
                  <PriceMessage
                    cartOptions={cart.options}
                    cartReqParams={cart.itemDetails}
                    channel={cart.options.channel}
                    itemId={itemId}
                    storeId={storeId}
                    message={message}
                    onAddToCartClick={this.onAddToCartClick}
                    pricing={totalPrice}
                    hideBadge
                  />
                )}
            </div>
          </div>
          <div className="fbt__prodcontainer u__clearfix">
            {products.length > 1 && products.map((item, index) => {
              const key = `fbt-product-line-${item.itemId}-${index}`;
              return (
                <div
                  key={key}
                  className={classNames('fbtProductLine', { fbtOpacitizer: !item.checked })}
                >
                  <CheckboxGroup>
                    <Checkbox
                      className={fbtProductLineItemClasses}
                      key={key}
                      name={key}
                      id={item.itemId}
                      onChange={() => this.onChangeCheckbox(item.itemId)}
                      checked={item.checked}
                      value={`${item.itemId}`}
                    >{
                        item.type === 'primary'
                          ? this.getPrimaryLabel(item)
                          : this.getLink(item)
                      }
                    </Checkbox>
                  </CheckboxGroup>
                </div>
              );
            })}
          </div>
          {cart.options.channel === 'mobile'
            && (
              <PriceMessage
                cartOptions={cart.options}
                cartReqParams={cart.itemDetails}
                channel={cart.options.channel}
                itemId={itemId}
                storeId={storeId}
                message={message}
                onAddToCartClick={this.onAddToCartClick}
                pricing={totalPrice}
                hideBadge
              />
            )}
        </div>
      );
    }
    return null;
  }
}

LegacyFBT.propTypes = {
  itemId: PropTypes.string.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  setCheckedItems: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string
  })),
  product: PropTypes.shape({}).isRequired,
  storeId: PropTypes.string
};

LegacyFBT.defaultProps = {
  products: [],
  storeId: null,
  setCheckedItems: () => { }
};

LegacyFBT.displayName = 'LegacyFBT';
export default LegacyFBT;
