import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import {
  params,
  bool as boolType,
  string as stringType,
  shape as shapeType,
  useDataModel,
} from '@thd-nucleus/data-sources';

export const BathRenovation = ({ itemId }) => {
  const { isConsumerApp } = useContext(ExperienceContext);
  const fsEnableBathRenovation = useConfigService('fs:enableBathRenovation');
  const { data, loading, error } = useDataModel('product', {
    skip: !itemId || !fsEnableBathRenovation || isConsumerApp,
    variables: {
      itemId,
    },
  });

  if ((error && !data) || !data || loading) {
    return null;
  }
  const enableBathRenovation = data?.product?.info?.bathRenovation;
  if (!enableBathRenovation) {
    return null;
  }
  return (
    <a href="/bath-renovation" data-component="BathRenovation">
      <picture>
        <source
          alt="Image for Try Out Bathroom Design Studio Tool"
          // eslint-disable-next-line max-len
          srcSet="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/fetch/Test_and_Target/061923-VIP-Test1-DT.png"
          media="(min-width: 768px)"
        />
        {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
        <img
          // eslint-disable-next-line max-len
          src="https://contentgrid.homedepot-static.com/hdus/en_US/DTCCOMNEW/fetch/Test_and_Target/061923-VIP-Test1-MW.png"
          alt="Try Out Bathroom Design Studio Tool"
        />
      </picture>
    </a>
  );
};

BathRenovation.displayName = 'BathRenovation';

BathRenovation.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
  }).shape({
    dataSources: stringType(),
    info: shapeType({
      bathRenovation: boolType(),
    }),
  }),
};

BathRenovation.propTypes = {
  itemId: string.isRequired,
};
