import { createContext } from 'react';

/*
  itemMap = {
    '123456789': {
      availableQty: number,
      selectedQty: number,
    }
*/
export const QuantityAdjustmentsContext = createContext({
  itemMap: {},
  setItemMap: () => {},
  fulfillmentFetched: false,
  setFulfillmentFetched: () => {},
});