import React, { useEffect, useContext, useState, useCallback } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography
} from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { params, string as stringType, useDataModel } from '@thd-nucleus/data-sources';
import { EMTPlaceholder } from './EMTPlaceholder/EMTPlaceholder.component';
import { LinkSets } from './LinkSets';

const EmtLinksComponent = ({ path }) => {
  const { isServer } = useContext(ExperienceContext);
  let linkSets = [];
  let headerLocation = 'Explore More on homedepot.com';

  const defaultRender = () => {
    return null;
  };

  const parts = path.replace(/^\/render[^/]*/, '').split('?');
  const cleanedPath = parts[0] || '';
  const options = {
    ssr: true,
    variables: { path: cleanedPath }
  };

  const { loading, error, data } = useDataModel('emtLinks', options);

  // SEOIM-44: do we want to update new relic to emt as well?
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('seo-links.ready'); }, []);

  // showMore needs to be true so that it's expanded when SSR for SEO bots
  const [showMore, setOverlay] = useState(true);
  const toggleOverlay = useCallback(() => {
    // this will be called on frontend load and collapse the accordion
    setOverlay((seeMore) => !seeMore);
  }, []);

  useEffect(() => {
    setOverlay(isServer);
  }, []);

  if (!path) return defaultRender();

  if (loading) {
    return <EMTPlaceholder />;
  }

  if ((error && !data) || !data?.emtLinks) {
    return null;
  }

  try {
    let emtLinks = JSON.parse(data.emtLinks);
    linkSets = emtLinks?.sets || [];
    headerLocation = emtLinks?.heading || 'Explore More on homedepot.com';
  } catch (err) {
    return defaultRender();
  }

  if (linkSets.length === 0) {
    return defaultRender();
  }

  return (
    <div
      className={'sui-w-full ' + (showMore ? 'expanded' : 'collapsed')}
      data-component="EmtLinks"
    >
      <Accordion expanded={showMore} onChange={toggleOverlay}>
        <AccordionHeader aria-controls="accordion1-content" id="accordion1-header">
          <div className="sui-w-52 sm:sui-w-full">
            <Typography variant="body-lg" weight="bold">{headerLocation}</Typography>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <LinkSets sets={linkSets} />
        </AccordionBody>
      </Accordion>
    </div>
  );
};

EmtLinksComponent.displayName = 'EmtLinks';

const dataModel = {
  emtLinks: params({ path: stringType() }).string()
};

const propTypes = {
  path: string.isRequired
};
EmtLinksComponent.propTypes = propTypes;

const HydratedEMTLinks = withHydrator({
  id: 'emt-links',
  scrollBuffer: 750,
  delay: 2000,
  preserveCtxVal: 'clientStore',
  placeholder: <EMTPlaceholder />
}, EmtLinksComponent);

const DynamicEmtLinks = withDynamicComponent(
  HydratedEMTLinks,
  { placeholder: <EMTPlaceholder /> }
);
export const EmtLinks = withErrorBoundary(DynamicEmtLinks);

EmtLinks.propTypes = propTypes;
EmtLinks.dataModel = dataModel;
