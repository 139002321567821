function getKeywordsFromLocalStorage() {
  if (typeof localStorage === 'undefined') return [];
  const storageKeywords = localStorage?.getItem('search_history');
  const keywords = JSON.parse(storageKeywords) || [];

  return keywords.map((kw) => {
    return {
      url: `/s/${kw.keyword}`,
      anchor: kw.keyword
    };
  });
}

function getKeywordsFromCookie() {
  if (typeof document === 'undefined') return [];
  let searchHistory = window?.cookieUtils?.readBrowserCookie('SEARCH_HST') || '';
  const keywords = searchHistory.split('||');
  return keywords.filter((kw) => kw !== '').map((keyword) => {
    return {
      url: `/s/${keyword}`,
      anchor: keyword
    };
  });
}

export { getKeywordsFromCookie, getKeywordsFromLocalStorage };
