import React from 'react';
import { Placeholder, Col } from '@thd-olt-component-react/core-ui';
import './FBTPlaceholder.style.scss';

export const FBTPlaceholder = () => (
  <Col className="fbt-placeholder" data-component="FBTPlaceholder">
    <Placeholder width="100%" height="100%" />
  </Col>
);

FBTPlaceholder.displayName = 'FBTPlaceholder';