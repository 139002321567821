import { useContext } from 'react';
import PropTypes from 'prop-types';
import { params, shape, string, useDataModel } from '@thd-nucleus/data-sources';
import { useConfigService } from '@thd-nucleus/experience-context';
import { useHelmet, RedirectTag } from '@thd-nucleus/thd-helmet';
import { RedirectContext } from './RedirectContext';
import {
  isInvalidItemId,
  isInvalidCanonical,
  isPEPRedirect,
  isPEPToPIPRedirect,
  isNotFound
} from './rules/pip-redirect-rules';
import { ERROR_HANDLING_EXPERIENCE, handleErrors } from '../utils/redirector-utils';

const pipHandler = ({ data, error: err, props, queries }) => {

  let error = err;
  if (queries && queries.length && !err) {
    const productQuery = queries.find((query) => query.operationName === 'product');
    if (productQuery && productQuery.error) {
      error = productQuery.error;
    }
  }

  const invalidItemId = isInvalidItemId({ itemId: props.itemId });
  if (invalidItemId) {
    return new RedirectTag(invalidItemId, props.bypass);
  }

  const errors = handleErrors(error, ERROR_HANDLING_EXPERIENCE.PRODUCT);
  if (errors) {
    return new RedirectTag(errors, props.bypass);
  }

  const notFound = isNotFound({ data, itemId: props.itemId });
  if (notFound) {
    return new RedirectTag(notFound, props.bypass);
  }

  const {
    disableCanonicalRedirect,
    disablePEPRedirect,
    disableCMMMCRedirect,
    pathWithQueryParams,
    enablePepToPipRedirectFS
  } = props;

  const [path, queryParams] = pathWithQueryParams.split('?');

  const invalidCanonical = isInvalidCanonical({ data, path, disableCanonicalRedirect });
  if (invalidCanonical) {
    return new RedirectTag(invalidCanonical, props.bypass);
  }

  const pepToPipRedirect = isPEPToPIPRedirect({ data, path, enablePepToPipRedirectFS });
  if (pepToPipRedirect) {
    return new RedirectTag(pepToPipRedirect, props.bypass);
  }

  const pepRedirect = isPEPRedirect({ data, disablePEPRedirect, disableCMMMCRedirect, path, queryParams });
  if (pepRedirect) {
    return new RedirectTag(pepRedirect, props.bypass);
  }
  return null;
};

export const PIPRedirector = (props) => {

  const disableCanonicalRedirectFS = useConfigService('fs-prop:pip-wrapper__redirector--disableCanonicalRedirect');
  const enablePepToPipRedirectFS = useConfigService('fs:enablePepToPipRedirect');
  const {
    disableCanonicalRedirect,
    disablePEPRedirect,
    disableQuestionsRedirect,
    disableReviewsRedirect,
    disableCMMMCRedirect
  } = props;

  const { pathWithQueryParams, itemId, bypass } = useContext(RedirectContext);

  const handlerProps = {
    pathWithQueryParams,
    disableCanonicalRedirect: typeof disableCanonicalRedirectFS !== 'undefined'
      ? disableCanonicalRedirectFS
      : disableCanonicalRedirect,
    disableCMMMCRedirect,
    disablePEPRedirect,
    disableQuestionsRedirect,
    disableReviewsRedirect,
    itemId,
    bypass,
    enablePepToPipRedirectFS
  };

  const opts = {
    variables: {
      itemId
    },
    skip: !itemId
  };
  const { data, error } = useDataModel('product', opts);

  useHelmet('pipRedirector', { data, error, props: handlerProps }, pipHandler, [data, itemId]);

  return null;
};

PIPRedirector.displayName = 'PIPRedirector';

PIPRedirector.propTypes = {
  disablePEPRedirect: PropTypes.bool,
  disableCanonicalRedirect: PropTypes.bool,
  disableQuestionsRedirect: PropTypes.bool,
  disableReviewsRedirect: PropTypes.bool,
  disableCMMMCRedirect: PropTypes.bool,
};

PIPRedirector.defaultProps = {
  disablePEPRedirect: false,
  disableCanonicalRedirect: false,
  disableQuestionsRedirect: false,
  disableReviewsRedirect: false,
  disableCMMMCRedirect: false,
};

PIPRedirector.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      canonicalUrl: string()
    })
  })
};
