import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withHydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { QueryContext } from '@thd-nucleus/data-sources';
import { FrequentlyBoughtTogetherProvider } from './contexts/FrequentlyBoughtTogetherContext';

import { dataModel } from './dataModel';

import { MainFBT } from './MainFBT';
import { FBTPlaceholder } from './placeholder/FBTPlaceholder';
import './frequently-bought-together.style.scss';

const placeholder = (
  <FBTPlaceholder />
);

const FrequentlyBoughtTogetherComponent = (props) => {
  const { itemId, openInNewTab, onLoadDynamicRecs } = props;

  return (
    <FrequentlyBoughtTogetherProvider
      itemId={itemId}
      onLoadDynamicRecs={onLoadDynamicRecs}
    >
      <MainFBT itemId={itemId} openInNewTab={openInNewTab} />
    </FrequentlyBoughtTogetherProvider>

  );
};

FrequentlyBoughtTogetherComponent.displayName = 'FrequentlyBoughtTogether';

const propTypes = {
  itemId: PropTypes.string.isRequired,
  onLoadDynamicRecs: PropTypes.func,
  openInNewTab: PropTypes.bool
};
const defaultProps = {
  onLoadDynamicRecs: null,
  openInNewTab: false
};
FrequentlyBoughtTogetherComponent.propTypes = propTypes;
FrequentlyBoughtTogetherComponent.defaultProps = defaultProps;

FrequentlyBoughtTogetherComponent.dataModel = dataModel;
const HydratedFBT = withHydrator({
  delay: 1500,
  id: 'product-section-fbt',
  placeholder,
  preserveCtxVal: 'clientStore',
  scrollBuffer: 100,
  contexts: declareContexts([QueryContext])
}, FrequentlyBoughtTogetherComponent);

const DynamicRenderFBT = withDynamicComponent(HydratedFBT,
  { placeholder }
);

const FrequentlyBoughtTogether = withErrorBoundary(DynamicRenderFBT);

FrequentlyBoughtTogether.propTypes = propTypes;
FrequentlyBoughtTogether.defaultProps = defaultProps;
FrequentlyBoughtTogether.dataModel = dataModel;

FrequentlyBoughtTogether.displayName = FrequentlyBoughtTogetherComponent.displayName;

export default FrequentlyBoughtTogether;
