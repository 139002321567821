import {
  alias,
  arrayOf as arrayOfType,
  bool as boolType,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';

export const icDataModel = {
  instantCheckout: params({
    userId: stringType().isRequired(),
    customerAccountId: stringType().isRequired(),
    thdUserCookie: stringType(),
  }).shape({
    displayOption: stringType(),
    addressCity: stringType(),
    addressZipCode: stringType(),
    xref: stringType(),
    daCardBrand: stringType(),
    displayCardBrand: stringType(),
  }),
};

export const icCartDataModel = {
  instantCheckout: params({
    userId: stringType().isRequired(),
    customerAccountId: stringType().isRequired(),
    thdUserCookie: stringType(),
  }).shape({
    displayOption: stringType(),
    addressCity: stringType(),
    addressZipCode: stringType(),
    xref: stringType(),
    daCardBrand: stringType(),
    displayCardBrand: stringType(),
    paymentId: stringType(),
  }),
};
