/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';

const ProductInfo = ({ product }) => {
  if (!product) {
    return null;
  }

  const image = product?.media?.images?.find(
    (img) => img.type === 'IMAGE' && img.subType === 'PRIMARY'
  );

  return (
    <div className="sui-grid sui-grid-cols-12">
      <div className="sui-col-span-12">
        <div className="sui-flex sui-flex sui-flex-row" data-testid="product-info">
          <img
            src={image?.url.replace('<SIZE>', '145')}
            alt={product?.identifiers?.productLabel}
            className="sui-aspect-square sui-max-w-[20%] sm:sui-max-w-[30%]"
            width="120"
            height="120"
          />
          <span className="sui-pl-6">
            <Typography>
              <span className="sui-font-bold">{product?.identifiers?.brandName}&nbsp;</span>
              <Typography>
                <span className="sui-font-regular">{product?.identifiers?.productLabel}</span>
              </Typography>
            </Typography>
          </span>
        </div>
      </div>
    </div>
  );
};

ProductInfo.propTypes = {};

ProductInfo.displayName = 'ProductInfo';

export { ProductInfo };
