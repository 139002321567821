import React, { useReducer, useRef } from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { RatingMeter, Typography } from '@one-thd/sui-atomic-components';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useProductReviews } from '../useProductReviews';
import { dataModel } from '../ReviewDataModel';
import { initialState } from '../../constants';
import { stateReducer } from '../../reducer';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from '../RatingsAndReviewsPropTypes';
import ReviewHelper from '../Review/helpers/ReviewHelper';

import '../ratings-and-reviews.style.scss';

const RatingsAndReviewsAccordionTitlePlaceholder = (
  <div data-component="RatingsAndReviewsAccordionTitlePlaceholder">
    <Placeholder
      type="rect"
      height="50px"
      width="100%"
    />
  </div>
);

export const RatingsAndReviewsAccordionTitleComponent = ({
  apiKey, itemId, seoPageNumber, category = 'product', noSpacing
}) => {
  const [state] = useReducer(stateReducer, initialState);
  const persistedStatistics = useRef({});

  const {
    averageRating, loading, statistics, totalReviews
  } = useProductReviews({ itemId, seoPageNumber, ...state });

  if (loading) {
    return RatingsAndReviewsAccordionTitlePlaceholder;
  }

  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;

  const {
    averageOverallRating,
    totalReviewCount
  } = reviewStatistics || {};

  // Use BV as primary but fallback to available product data
  const total = totalReviewCount || totalReviews;
  const totalWithCommas = ReviewHelper.numberWithCommas(total || 0);
  const average = averageOverallRating || Number(averageRating).toFixed(1);

  return (
    <div
      className={`sui-flex sui-flex-col sui-justify-center  ${noSpacing ? 'sui-p-0' : 'sui-py-2 sui-px-0'}`}
      data-component="RatingsAndReviewsAccordionTitle"
    >
      <Typography variant="h5" component="h3">Customer Reviews</Typography>
      {totalReviewCount > 0
        && (
          <div
            className="sui-flex sui-mt-1 sui-items-center sui-h-5 "
          >
            <div className="sui-mr-1">
              <Typography color="subtle">{average} out of 5 </Typography>
            </div>
            <RatingMeter
              value={Number(average)}
              label={`(${totalWithCommas})`}
              edge="start"
              RatingProps={{
                color: 'brand',
                size: 'small'
              }}
            />
          </div>
        )}
    </div>
  );
};

RatingsAndReviewsAccordionTitleComponent.displayName = 'RatingsAndReviewsAccordionTitleComponent';
RatingsAndReviewsAccordionTitleComponent.propTypes = RatingsAndReviewsPropTypes;
RatingsAndReviewsAccordionTitleComponent.defaultProps = RatingsAndReviewsDefaultProps;
RatingsAndReviewsAccordionTitleComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'ratings-and-reviews-accordion-title',
  scrollBuffer: 900,
  delay: 200,
  placeholder: RatingsAndReviewsAccordionTitlePlaceholder
}, RatingsAndReviewsAccordionTitleComponent);
const DynamicComponent = withDynamicComponent(HydratedComponent);

export const RatingsAndReviewsAccordionTitle = withErrorBoundary(DynamicComponent);

RatingsAndReviewsAccordionTitle.displayName = 'RatingsAndReviewsAccordionTitle';
RatingsAndReviewsAccordionTitle.propTypes = RatingsAndReviewsPropTypes;
RatingsAndReviewsAccordionTitle.defaultProps = RatingsAndReviewsDefaultProps;
RatingsAndReviewsAccordionTitle.dataModel = dataModel;
