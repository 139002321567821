import React from 'react';
import { string, bool, func, number } from 'prop-types';
import { Checkbox } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';

const SubscribeAndSaveLite = ({
  disabled,
  discount,
  itemId,
  onChange,
  subscribed
}) => {

  const handleChange = () => {
    onChange(!subscribed);
  };

  const titleClasses = classNames('subscribe-and-save-options__title', {
    'subscribe-and-save--disabled': disabled
  });

  const discountClasses = classNames({
    'subscribe-and-save-options__title-discount': !disabled
  });

  return (
    <form className="subscribe-and-save-options">
      <div className="checkbox-btn__content-wrapper">
        <Checkbox
          checked={subscribed}
          disabled={disabled}
          id={`subscribe-and-save-options__opt-in-out-${itemId}`}
          onChange={handleChange}
          padding={false}
        >
          <span className={titleClasses}>
            Subscribe &
            <span className={discountClasses}> Save {discount}%</span>
          </span>
        </Checkbox>
      </div>
    </form>
  );
};

SubscribeAndSaveLite.displayName = 'SubscribeAndSaveLite';

SubscribeAndSaveLite.propTypes = {
  disabled: bool,
  discount: string,
  itemId: string.isRequired,
  onChange: func.isRequired,
  subscribed: bool
};

SubscribeAndSaveLite.defaultProps = {
  disabled: false,
  discount: '5',
  subscribed: false
};

export { SubscribeAndSaveLite };
