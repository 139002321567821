import React, { useEffect, useRef, useState } from 'react';
import {
  bool, func, instanceOf, node, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import classNames from 'classnames/bind';
import {
  applyInlineOffsetMargin,
  getScrollPosition,
  getPosition,
  isRefInViewport,
  isScrolledPastContent,
  isScrolledPastOffset,
  isScrollingDown
} from '../helpers';
import styles from './sticky.module.scss';
import { StickyContext } from './StickyContext';

const cx = classNames.bind(styles);

const Sticky = ({
  animation,
  boxShadow,
  children,
  className,
  forwardRef,
  offsetMargin,
  offsetScroll,
  position,
  stickyType,
  stickyByDefault,
  visibleByDefault,
  zIndex
}) => {

  const [isSticky, setIsSticky] = useState(stickyByDefault);
  const [isVisible, setIsVisible] = useState(visibleByDefault);
  const [offsetMarginValue, setOffsetMarginValue] = useState(offsetMargin);

  useEffect(() => {
    setOffsetMarginValue(offsetMargin);
  }, [offsetMargin]);

  const stickyRef = useRef(null);
  const contentPosition = useRef({ bottom: 0, top: 0 });
  const lastScrollPosition = useRef({ last: 0 });

  const shouldDisplayBasic = () => {
    const shouldDisplay = isScrolledPastOffset(offsetScroll, window);
    if (isVisible !== shouldDisplay) {
      setIsVisible(shouldDisplay);
    }
  };

  const shouldDisplayRef = () => {
    const { offsetHeight } = forwardRef?.current;
    const forwardRefRect = forwardRef?.current.getBoundingClientRect();
    const stickyRefRect = stickyRef?.current.getBoundingClientRect();

    const isForwardRefInViewport = isRefInViewport({
      offsetScroll,
      refHeight: offsetHeight,
      refRect: forwardRefRect,
      windowObj: window
    });

    if (isVisible !== !isForwardRefInViewport) {
      setIsVisible(!isForwardRefInViewport);
    }
  };

  const shouldDisplayOnScrollUp = () => {
    // saves top and bottom position of the content to be made sticky
    const { bottom, top } = contentPosition?.current || {};
    if (!bottom && !top) {
      const { bottomOffset, topOffset } = getPosition(stickyRef);
      contentPosition.current.top = topOffset;
      contentPosition.current.bottom = bottomOffset;
    }

    // get current scroll position
    const scrollPosition = getScrollPosition(window);

    if (isSticky) {
      if (isScrollingDown(scrollPosition, lastScrollPosition.current.last)) {
        setOffsetMarginValue(0);
        setIsVisible(false);
      } else {
        setOffsetMarginValue(offsetMargin);
        setIsVisible(true);
      }
      if (!isScrolledPastContent(window, top)) {
        setIsSticky(false);
      }
    } else if (isScrolledPastContent(window, bottom)) {
      if (isScrollingDown(scrollPosition, lastScrollPosition.current.last)) {
        // hide content
        setIsVisible(false);
        setOffsetMarginValue(0);
      } else {
        // show content
        setOffsetMarginValue(offsetMargin);
        setIsSticky(true);
        setIsVisible(true);
      }
    }

    lastScrollPosition.current.last = scrollPosition <= 0 ? 0 : scrollPosition;
  };

  const handleScroll = () => {
    switch (stickyType) {
    case 'basic':
      shouldDisplayBasic();
      break;
    case 'forwardRef':
      shouldDisplayRef();
      break;
    case 'showOnScrollUp':
      shouldDisplayOnScrollUp();
      break;
    default:
      shouldDisplayBasic();
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, isVisible]);

  const stickyStyles = {
    zIndex,
    ...applyInlineOffsetMargin(animation, offsetMarginValue, position, stickyType, isVisible)
  };

  const stickyClasses = classNames(className, `sui-${position}-0`, {
    'sui-fixed': isSticky,
    'sui-hidden': !isVisible && !animation,
    '!sui-block': isVisible && !animation,
    /* No tailwind classes currently exist to support the legacy animations */
    // makes the content to slide in from top/bottom while showing and hiding
    [cx('sticky__slide--fixed')]: isSticky && animation === 'slide',
    [cx('sticky__slide--bottom-hide')]: !isVisible && animation === 'slide' && position === 'bottom',
    [cx('sticky__slide--top-hide')]: !isVisible && animation === 'slide' && position === 'top',
    // makes the content to zoom/scale while showing and hiding
    [cx('sticky__scale--fixed')]: isSticky && animation === 'scale',
    [cx('sticky__scale--show')]: isSticky && animation === 'scale',
    [cx('sticky__scale--hide')]: !isVisible && animation === 'scale',
    [cx('sticky__shadow--bottom')]: boxShadow && position === 'bottom',
    'sui-shadow-lg': boxShadow && position === 'top'
  });

  const wrapperStyle = {
    minHeight: stickyType === 'showOnScrollUp'
      ? stickyRef?.current?.offsetHeight
      : 0
  };

  return (
    <div style={wrapperStyle}>
      <div className={stickyClasses} ref={stickyRef} style={stickyStyles}>
        <StickyContext.Provider value={{ isVisible }}>
          {children}
        </StickyContext.Provider>
      </div>
    </div>
  );
};

Sticky.displayName = 'Sticky';

Sticky.propTypes = {
  animation: oneOf(['scale', 'slide']),
  boxShadow: bool,
  children: node,
  className: string,
  // avoid propType error by allowing undefined prop type on ssr only
  forwardRef: oneOfType([
    func,
    shape({ current:
      instanceOf(
        typeof Element === 'undefined'
          ? function () { }
          : Element
      ) })
  ]),
  offsetMargin: number,
  offsetScroll: number,
  position: oneOf(['bottom', 'top']).isRequired,
  stickyByDefault: bool,
  stickyType: oneOf(['basic', 'forwardRef', 'showOnScrollUp']),
  visibleByDefault: bool,
  zIndex: number
};

Sticky.defaultProps = {
  animation: null,
  boxShadow: false,
  children: null,
  className: '',
  forwardRef: null,
  offsetMargin: 0,
  offsetScroll: 0,
  stickyByDefault: false,
  stickyType: 'basic',
  visibleByDefault: false,
  zIndex: 899
};

export { Sticky };
