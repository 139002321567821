import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';

// As roomvo library required some aditional classes on the buttons
// We have to manually recreate the stencil buttons, so this is an utility component
export const ARCustomButton = ({ icon: Icon, text, additionalClasses, itemId }) => {
  return (
    <Button
    // eslint-disable-next-line max-len
      className={`${additionalClasses} sui-btn sui-btn-secondary sui-btn-secondary-active sui-btn-secondary-focus sui-btn-secondary-hover sui-font-bold sui-leading-normal sui-line-clamp-unset sui-normal-case sui-text-base sui-tracking-normal sui-w-full`}
      data-sku={itemId}
      type="button"
    >
      <span className="sui-mr-2 sui-flex sui--ml-1"><Icon size="small" /></span>
      {text}
    </Button>
  );
};

ARCustomButton.propTypes = {
  itemId: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

ARCustomButton.defaultProps = {
  additionalClasses: '',
};