import React from 'react';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { RelatedSearchComponent } from './RelatedSearch/RelatedSearchComponent';
import { RelatedSearchPlaceholder } from './RelatedSearch/RelatedSearchPlaceholder';

const HydratedRelatedSearch = withHydrator({
  id: 'related-search',
  scrollBuffer: 750,
  delay: 2000,
  preserveCtxVal: 'clientStore',
  placeholder: (
    <RelatedSearchPlaceholder
      color="#E0E0E0"
      showLoadingAnimation
    />
  )
}, RelatedSearchComponent);

const DynamicRelatedSearch = withDynamicComponent(HydratedRelatedSearch);
const RelatedSearch = withErrorBoundary(DynamicRelatedSearch);

RelatedSearch.dataModel = RelatedSearchComponent.dataModel;
RelatedSearch.propTypes = RelatedSearchComponent.propTypes;
RelatedSearch.defaultProps = RelatedSearchComponent.defaultProps;

export { RelatedSearch };