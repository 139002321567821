import React, { useContext } from 'react';
import { Col } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { getPackageTitle } from '../../utils';
import { VisualProductImages } from './VisualProductImages';
import './visual-package-info.scss';

const VisualPackageInfo = () => {
  const { packageModel } = useContext(PackagesMiniContext);
  const packageTitle = getPackageTitle(packageModel);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  return (
    <>
      <Col className="visual-package-info__title-container">
        <h2 className="visual-package-info__title">
          {packageTitle}
        </h2>
      </Col>
      {isMobile && (
        <Col flatten>
          <VisualProductImages />
        </Col>
      )}
    </>
  );
};

VisualPackageInfo.displayName = 'VisualPackageInfo';

export { VisualPackageInfo };