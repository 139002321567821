const baseDetailsLink = 'https://www.homedepot.com/c/Home_Depot_Protection_Plan_Coverage_Details';

const generalMerchandiseInfo = {
  bullets: [
    {
      boldText: 'Covers 100% parts and labor',
      normalText: 'for product breakdowns & defects'
    },
    {
      boldText: 'Premium tech support',
      normalText: 'for smart devices',
    },
    {
      boldText: 'Fast repairs',
      normalText: 'or replacements',
    },
    {
      boldText: '24/7 customer support',
      normalText: '& easy online claims',
    },
    {
      boldText: 'No deductibles',
      normalText: 'or hidden fees'
    },
  ],
  lengthString: '3 or 2',
  planDetailsLink: baseDetailsLink,
};

const majorApplianceInfo = {
  bullets: [
    {
      boldText: 'Covers 100% parts and labor',
      normalText: 'for product breakdowns & defects',
    },
    {
      boldText: '2 or 3 day Service Guarantee',
      normalText: '(based on customer location)',
    },
    {
      boldText: '2 to 4-hour service appointment windows',
      normalText: '',
    },
    {
      boldText: 'Premium tech support',
      normalText: 'for smart appliances',
    },
    {
      boldText: '30% reimburesment of plan price',
      normalText: 'if no service claims are made',
    },
    {
      boldText: '24/7 customer support',
      normalText: '& easy online claims',
    },
  ],
  lengthString: '3 or 5',
  planDetailsLink: `${baseDetailsLink}#majorappliances`,
};

export {
  generalMerchandiseInfo,
  majorApplianceInfo,
};