import { LAUNDRY_ACCESSORIES } from '../constants';

export const getImageURL = (images) => {
  if (!images || images.length === 0) return null;
  const primary = images.find((img) => img.subType === 'PRIMARY');
  if (!primary) return null;
  return primary.url;
};

export const getFinalPriceMessageCount = (currentCheckedItems = []) => {
  return currentCheckedItems.filter(
    (item) => (item?.pricing?.mapScenario || item?.pricing?.message === 'see final price in cart')
  ).length;
};

export const getPriceMessage = (selectedItemsCount, priceMsgCount) => {
  const message = {};
  if (selectedItemsCount === 1) {
    message.priceMessage = 'Price:';
  } else if (selectedItemsCount === 2) {
    message.priceMessage = 'Price for both:';
  } else if (selectedItemsCount === 3) {
    message.priceMessage = 'Price for all three:';
  } else if (selectedItemsCount === 4) {
    message.priceMessage = 'Price for all four:';
  } else if (selectedItemsCount === 5) {
    message.priceMessage = 'Price for all five:';
  } else if (selectedItemsCount === 0) {
    message.priceMessage = '';
  }
  if (priceMsgCount > 0) message.priceMessage = '';
  return message;
};

export const getMapAboveOriginal = (items = []) => {
  let mapAboveOriginalPrice = false;
  items.map((item) => {
    if (!item.checked) return mapAboveOriginalPrice;
    if (item.pricingMessage === 'see final price in cart' && item.mapAboveOriginalPrice) {
      mapAboveOriginalPrice = true;
    }
    return mapAboveOriginalPrice;
  });
  return mapAboveOriginalPrice;
};

export const getTotalPrice = (items = []) => {
  const currentCheckedItems = items.filter((item) => item.checked);
  const hidePromotionDetails = currentCheckedItems.some((item) => !item?.pricing?.original);
  let originalPriceTotal = '';
  let dollarOffTotal = '';
  let percentageOffTotal = '';

  if (!hidePromotionDetails) {
    // originalPrice total
    originalPriceTotal = currentCheckedItems.reduce((acc, item) => {
      return acc + (item?.pricing?.original || item?.pricing?.value);
    }, 0);
    originalPriceTotal = parseFloat(originalPriceTotal.toFixed(2));
    // dollarOff total
    dollarOffTotal = currentCheckedItems.reduce((acc, item) => {
      const dollarOff = item?.pricing?.promotion?.dollarOff
        ? item?.pricing?.promotion?.dollarOff
        : 0;
      return acc + dollarOff;
    }, 0);
    dollarOffTotal = parseFloat(dollarOffTotal.toFixed(2));
    // percentageOff total
    percentageOffTotal = originalPriceTotal === 0
      ? 100
      : (dollarOffTotal / originalPriceTotal) * 100;
    percentageOffTotal = percentageOffTotal.toFixed();
  }

  // specialPrice total
  let valuePriceTotal = currentCheckedItems.reduce((acc, item) => {
    return acc + item?.pricing?.value;
  }, 0);
  valuePriceTotal = parseFloat(valuePriceTotal.toFixed(2));
  // pricingMessage
  let pricingMessage = currentCheckedItems.reduce((acc, item) => {
    if (item?.pricing?.message) {
      return item?.pricing?.message;
    }
    return acc;
  }, '');
  const seeFinalPriceMsgCount = getFinalPriceMessageCount(currentCheckedItems);
  if (seeFinalPriceMsgCount > 0) {
    pricingMessage = 'see final price in cart';
  }

  return {
    pricing: {
      original: originalPriceTotal,
      promotion: {
        dollarOff: dollarOffTotal,
        percentageOff: percentageOffTotal,
      },
      value: valuePriceTotal,
      message: pricingMessage,
      mapAboveOriginalPrice: getMapAboveOriginal(items)
    }
  };
};

export const getCart = (items, channel) => {
  const directCheckout = items.every((item) => item.product?.identifiers?.productType !== 'MAJOR_APPLIANCE');
  const options = {
    host: typeof window !== 'undefined'
      ? window.location.origin
      : null,
    channel,
    paypal: false,
    misship: false,
    directCheckout
  };
  const itemDetails = items.filter((item) => item.checked)
    .map((item) => {
      const isMajorApplianceItem = item.product?.identifiers?.productType === 'MAJOR_APPLIANCE';
      if (isMajorApplianceItem) {
        return {
          itemId: String(item.itemId),
          quantity: 1,
          fulfillmentMethod: 'DirectDelivery',
          fulfillmentLocation: '',
        };
      }
      return {
        itemId: String(item.itemId),
        quantity: 1
      };
    });
  return {
    itemDetails,
    options
  };
};

export const activePromo = (start, end) => {
  if (start === null || end === null) return false;
  const startArray = start.split('.');
  const endArray = end.split('.');
  const startDate = new Date(startArray[0].replace(/-/g, '/'));
  const endDate = new Date(endArray[0].replace(/-/g, '/'));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return false;
  const currentDate = new Date();
  return currentDate >= startDate && currentDate < endDate;
};

export const laundryAccessoryFilter = (anchorProduct = {}, product) => {
  if (anchorProduct.category !== 'Combo' && LAUNDRY_ACCESSORIES.includes(product.category)) {
    return false;
  }
  return true;
};
