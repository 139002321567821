import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, arrayOf, bool, extend, useDataModel, number
} from '@thd-nucleus/data-sources';
import './product-details.style.scss';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { dataModel } from './components/ratings-and-reviews-data-model';
import Title from './components/ProductDetailsTitle.component';
import { ProductDetailsBadge } from './components/ProductDetailsBadge.component';
import BrandCollection from './components/ProductDetailsBrandCollection.component';
import Ratings from './components/ProductDetailsRatings.component';
import { ProductDetailsContext } from './ProductDetailsContext';

const ProductDetails = ({ itemId, children, configId }) => {

  const { channel } = useContext(ExperienceContext);
  const storeId = useStoreId();
  const payload = {
    itemId,
    configId,
    storeId,
    skipPaintDetails: !configId
  };

  const { data, error, loading } = useDataModel('product', {
    variables: payload
  });

  const { configuredProductLabel } = useLifeCycleEventBus('configurator.configurator_choice_selected_event');
  const [currentProductLabel, setCurrentProductLabel] = useState();
  // this is start of temporary code for tnt take out when tnt is done
  // tnt est start 4/24/23
  const [newTitleName, setNewTitleName] = useState({});
  const output = useLifeCycleEventBus('product-name_PN120-testing', true);

  useEffect(() => {
    const incomingName = output[itemId];
    if (incomingName) {
      const sampleObj = ({});
      sampleObj[itemId] = incomingName;
      setNewTitleName({ ...newTitleName,
        ...sampleObj });
      setCurrentProductLabel(incomingName);
    }
  }, [output, itemId]);
  // end of temp tnt code
  if (loading && !data) {
    return (
      <div data-component="ProductDetailsPlaceholder">
        <LoadingPlaceholder
          type="text"
          ready={false}
          rows={channel === 'mobile' ? 7 : 5}
          showLoadingAnimation
        >
          <div />
        </LoadingPlaceholder>
      </div>
    );
  }
  if ((error && !data) || !data) {
    return null;
  }

  const { product } = data || {};
  const contextValue = {
    channel,
    itemId,
    product,
    configuredProductLabel,
    currentProductLabel
  };

  return (
    <div className="product-details" data-component="ProductDetails">
      <ProductDetailsContext.Provider value={contextValue}>
        {children}
      </ProductDetailsContext.Provider>
    </div>
  );
};

ProductDetails.propTypes = {
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired,
  configId: PropTypes.string,
};

ProductDetails.defaultProps = {
  children: null,
  configId: undefined,
};

const paintDetails = params({ storeId: string(), configId: string() }).shape({
  colorDisplayName: string()
});
if (paintDetails.skip) {
  paintDetails.skip('skipPaintDetails', true);
}

ProductDetails.dataModel = extend(dataModel, {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      canonicalUrl: string(),
      itemId: string(),
      productLabel: string(),
      skuClassification: string()
    }),
    info: shape({
      hidePrice: bool()
    }),
    paintDetails,
    details: shape({
      collection: shape({
        url: string()
      })
    }),
    media: shape({
      images: arrayOf(shape({
        url: string()
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string()
      })
    }),
    taxonomy: shape({
      brandLinkUrl: string()
    }),
  })
}, ProductDetailsBadge);

ProductDetails.Badge = ProductDetailsBadge;
ProductDetails.BrandCollection = BrandCollection;
ProductDetails.Ratings = Ratings;
ProductDetails.Title = Title;

export {
  ProductDetailsBadge,
  BrandCollection,
  ProductDetails,
  Ratings,
  Title,
  ProductDetailsContext
};
