/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  number as numberType,
  any,
  string as stringType,
  bool as boolType
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSticker,
  PodSection,
  PodSpacer,
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const FBTCustomProductPod = ({
  itemId, storeId, itemIndex, data, channel, strategy, scheme, anchorProduct
}) => {
  const merchRec = useMemo(() => {
    const anchorId = (anchorProduct.length > 2) ? anchorProduct : 'n/a';
    return `MERCH=REC-_-${scheme}-_-${anchorId}-_-${itemIndex}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorProduct, scheme]);

  const theme = useTheme(FBTCustomProductPod);
  const { hideAddToList } = theme.props;

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      padding="sui-p-2"
      analyticsData={{ parent: 'fbt-carousel', position: itemIndex, strategy, scheme }}
      render={(pod) => (
        <>
          <PodSticker position="top-left">
            <ProductBadge itemId={pod.itemId} storeId={storeId} />
          </PodSticker>
          {!hideAddToList && (
            <PodSticker position="top-right">
              <AddToList
                itemId={pod.itemId}
                storeId={storeId}
                showIconButton
              />
            </PodSticker>
          )}
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
            merchQueryParam={merchRec}
          />
          <PodSection columnAlign>
            <PodSpacer>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                brandTitleMaxLine={3}
                disableShopThisCollection
                merchQueryParam={merchRec}
              />
            </PodSpacer>
            <ProductRating itemId={pod.itemId} merchQueryParam={merchRec} />
            <PodSpacer>
              <Price
                channel={channel}
                itemId={pod.itemId}
                large={false}
                product={data}
                showPreferredPricingBadge
                hideSavingsText={false}
                stackWasPrice
              />
            </PodSpacer>
          </PodSection>
        </>
      )}
    />
  );
};

FBTCustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductBadge,
  ProductRating,
  ProductHeader,
  Price,
  AddToList
);

FBTCustomProductPod.propTypes = {
  itemId: stringType,
  storeId: stringType,
  itemIndex: numberType,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  channel: stringType,
  strategy: stringType,
  scheme: stringType,
  anchorProduct: stringType
};

FBTCustomProductPod.defaultProps = {
  itemId: '',
  storeId: '',
  itemIndex: null,
  data: null,
  channel: '',
  strategy: '',
  scheme: '',
  anchorProduct: ''
};

FBTCustomProductPod.displayName = 'FBTCustomProductPod';

FBTCustomProductPod.themeProps = {
  hideAddToList: boolType
};

FBTCustomProductPod.defaultThemeProps = {
  hideAddToList: false
};
