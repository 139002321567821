/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  DrawerBody,
  DrawerFooter,
  Typography,
  Alert,
} from '@one-thd/sui-atomic-components';

const SuccessDrawer = ({
  quoteName,
  onClose
}) => {
  return (
    <>
      <DrawerBody>
        <Alert status="success">
          <Typography>Success!</Typography>
          Your item has been added to your quote:{' '}
          <u>{quoteName}</u>
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <Button
          fullWidth
          variant="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DrawerFooter>
    </>
  );
};

SuccessDrawer.propTypes = {};

SuccessDrawer.displayName = 'SuccessDrawer';

export { SuccessDrawer };