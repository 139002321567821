/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  client,
  params,
  arrayOf,
  bool as boolType,
  shape as shapeType,
  string as stringType,
  number as numberType,
  customType,
  useLazyDataModel,
  useDataModel
} from '@thd-nucleus/data-sources';
import * as types from '../shared/Constants';

const NumberType = numberType({ int: true });
const _getQuoteQuery = (props) => (props.isQuoteV2Enabled ? 'quoteList' : 'getQuoteList');
const _isAdmin = (props) => (props?.role === types.USER_ADMIN);

// ************************* //
/* REQUEST BUILDER SECTION */
// ************************* //
const _quoteRequest = (opts = {}) => {
  let quoteRequest = {};
  // for get all quotes
  if (opts.isGetQuote) {
    quoteRequest = {
      pageSize: opts.pageSize
    };
    if (opts.isQuoteV2Enabled) {
      if (_isAdmin(opts)) {
        // eslint-disable-next-line no-param-reassign
        opts.userId = '';
      }
      quoteRequest = {
        ...quoteRequest,
        pageNumber: 1,
        sortBy: 'createdDt',
        sortOrder: 'desc',
        quoteStatus: 'all'
      };
    }
    // for adding or creating a quote
  } else {
    // include deliveryZip for delivery fulfillments
    let deliveryZip = null;
    if (types.DELIVERY_FULFILLMENT.includes(opts.fulfillmentMethod)) {
      deliveryZip = opts.deliveryZip;
    }
    // for lineItems
    const lineItems = [{
      itemId: opts.itemId,
      quantity: opts.quantity,
      fulfillmentLocation: deliveryZip || opts.fulfillmentLocation,
      fulfillmentMethod: opts.fulfillmentMethod
    }];
    // for creating new quote
    if (opts.isNew) {
      quoteRequest = {
        lineItems,
        localStoreId: opts.localStoreId,
        cartName: opts.quoteName
      };
      if (opts.isQuoteV2Enabled) {
        quoteRequest = {
          createQuoteRequest: {
            ...quoteRequest,
            deliveryZip
          }
        };
      }
    } else {
      // for adding to existing quote
      quoteRequest = {
        lineItems,
        localStoreId: opts.localStoreId,
        quoteId: opts.quoteId
      };
      if (opts.isQuoteV2Enabled) {
        quoteRequest = {
          addToQuoteRequest: {
            ...quoteRequest,
            deliveryZip
          }
        };
      }
    }
  }
  return {
    svocId: opts.svocId,
    userId: opts.userId,
    ...quoteRequest
  };
};

// ************************************************* //
/* ADD QUOTE AND CREATE QUOTE SECTION DATA MODEL */
// ************************************************* //
const LineItem = shapeType({
  itemId: stringType(),
  quantity: stringType(),
  fulfillmentLocation: stringType(),
  fulfillmentMethod: stringType()
});

const ErrorProperties = shapeType({
  errorProperty: shapeType({
    name: stringType(),
    value: stringType()
  })
});

const Error = shapeType({
  id: stringType(),
  description: stringType(),
  submessage: stringType(),
  errorCode: stringType(),
  errorProperties: ErrorProperties
});

const ErrorData = shapeType({
  errors: arrayOf(Error),
  httpStatus: stringType(),
  userId: stringType(),
  customerAccountId: stringType(),
  correlationId: stringType(),
  correlationType: stringType()
});

const QuoteInfo = shapeType({
  cartId: stringType(),
  errorData: arrayOf(ErrorData)
});

const QuoteInfoV1 = shapeType({
  quoteId: stringType(),
  errorData: arrayOf(ErrorData)
});

const CreateQuote = params({
  svocId: stringType().isRequired(),
  userId: stringType().isRequired(),
  createQuoteRequest: customType('QuoteRequest').shape({
    localStoreId: stringType().isRequired(),
    cartName: stringType().isRequired(),
    deliveryZip: stringType(),
    lineItems: arrayOf(LineItem)
  }).isRequired()
}).mutation().shape(QuoteInfo);

const CreateQuoteV1 = params({
  svocId: stringType().isRequired(),
  userId: stringType().isRequired(),
  localStoreId: stringType().isRequired(),
  cartName: stringType().isRequired(),
  lineItems: arrayOf(
    customType('LineItemInput!').shape(LineItem)
  ).isRequired(),
}).mutation().shape(QuoteInfoV1);

const AddToQuote = params({
  svocId: stringType().isRequired(),
  userId: stringType().isRequired(),
  addToQuoteRequest: customType('QuoteRequest').shape({
    localStoreId: stringType().isRequired(),
    quoteId: stringType().isRequired(),
    deliveryZip: stringType(),
    lineItems: arrayOf(LineItem)
  }).isRequired()
}).mutation().shape(QuoteInfo);

const AddToQuoteV1 = params({
  svocId: stringType().isRequired(),
  userId: stringType().isRequired(),
  localStoreId: stringType().isRequired(),
  quoteId: stringType().isRequired(),
  lineItems: arrayOf(
    customType('LineItemInput!').shape(LineItem)
  ).isRequired(),
}).mutation().shape(QuoteInfoV1);

// ********************************** //
/* GET ALL QUOTE SECTION DATA MODEL */
// ********************************** //

/* GET ALL QUOTE V1 */
const QuoteV1 = shapeType({
  name: stringType(),
  status: stringType(),
  quoteId: stringType(),
  userId: stringType(),
  accessType: numberType(),
});

const GetQuoteV1 = shapeType({
  userId: stringType(),
  customerAccountId: stringType(),
  totalNbrOfQuotes: NumberType,
  totalNbrOfPages: NumberType,
  quotes: arrayOf(QuoteV1),
  errorData: arrayOf(ErrorData)
});

const QuoteListV1 = params({
  svocId: stringType().isRequired(),
  userId: stringType(),
  pageNo: stringType(),
  pageSize: stringType(),
}).shape(GetQuoteV1);

/* GET ALL QUOTE V2 */
const Quote = shapeType({
  name: stringType(),
  status: stringType(),
  cartId: stringType(),
  createdUserId: stringType(),
  accessType: numberType(),
});

const GetQuote = shapeType({
  customer: shapeType({
    customerId: stringType(),
    userId: stringType()
  }),
  pagination: shapeType({
    totalNbrOfQuotes: NumberType,
    totalNbrOfPages: NumberType
  }),
  quotes: arrayOf(Quote)
});

const QuoteList = params({
  svocId: stringType().isRequired(),
  userId: stringType(),
  pageNumber: stringType(),
  pageSize: stringType(),
  quoteStatus: stringType(),
  sortBy: stringType(),
  sortOrder: stringType()
}).shape(GetQuote);

const QuoteProductModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      brandName: stringType(),
      productLabel: stringType(),
      productType: stringType()
    }),
    media: shapeType({
      images: arrayOf(shapeType({
        url: stringType(),
        type: stringType(),
        subType: stringType()
      }))
    }),
    pricing: params({
      storeId: stringType()
    }).shape({
      value: numberType({ float: true }),
    }),
    fulfillment: client(params({
      storeId: stringType(),
      zipCode: stringType()
    }).shape({
      anchorStoreStatus: boolType(),
      anchorStoreStatusType: stringType(),
      backordered: boolType(),
      backorderedShipDate: stringType(),
      bossExcludedShipStates: stringType(),
      excludedShipStates: stringType(),
      seasonStatusEligible: boolType(),
      fulfillmentOptions: arrayOf(shapeType({
        type: stringType(),
        fulfillable: boolType(),
        services: arrayOf(shapeType({
          deliveryTimeline: stringType(),
          deliveryDates: shapeType({
            startDate: stringType(),
            endDate: stringType(),
          }),
          deliveryCharge: stringType(),
          dynamicEta: shapeType({
            hours: stringType(),
            minutes: stringType()
          }),
          hasFreeShipping: boolType(),
          freeDeliveryThreshold: numberType({ float: true }),
          locations: arrayOf(shapeType({
            curbsidePickupFlag: boolType(),
            isBuyInStoreCheckNearBy: boolType(),
            distance: numberType({ float: true }),
            inventory: shapeType({
              isOutOfStock: boolType(),
              isInStock: boolType(),
              isLimitedQuantity: boolType(),
              isUnavailable: boolType(),
              quantity: numberType(),
              maxAllowedBopisQty: numberType(),
              minAllowedBopisQty: numberType()
            }),
            isAnchor: boolType(),
            locationId: stringType(),
            state: stringType(),
            storeName: stringType(),
            storePhone: stringType(),
            type: stringType(),
          })),
          type: stringType(),
          totalCharge: numberType({ float: true }),
        }))
      })),
      onlineStoreStatus: boolType(),
      onlineStoreStatusType: stringType(),
    }))
  })
};

// *********************** //
/* ACTUAL HOOKS SECTION */
// ********************** //
const useQuoteProduct = (props) => {
  const { data: quoteProduct } = useDataModel('product', {
    variables: {
      itemId: props.itemId,
    },
    skip: !props.itemId
  });
  return { quoteProduct };
};

const useCreateQuote = (props) => {
  const [createQuote, quoteResponse] = useLazyDataModel('createQuote', {
    variables: _quoteRequest(props),
    ssr: false,
    refetchQueries: [_getQuoteQuery(props)],
    dataModel: { createQuote: (props.isQuoteV2Enabled ? CreateQuote : CreateQuoteV1) }
  });
  return [createQuote, quoteResponse];
};

const useAddToQuote = (props) => {
  const quoteQuery = props.isQuoteV2Enabled ? 'addToQuote' : 'addItemToQuote';
  let addToQuoteDataModel = {
    addItemToQuote: AddToQuoteV1
  };
  // for quote v2 orch
  if (props.isQuoteV2Enabled) {
    addToQuoteDataModel = {
      addToQuote: AddToQuote
    };
  }
  const [addToQuote, quoteResponse] = useLazyDataModel(quoteQuery, {
    variables: _quoteRequest(props),
    ssr: false,
    refetchQueries: [_getQuoteQuery(props)],
    dataModel: addToQuoteDataModel
  });
  return [addToQuote, quoteResponse];
};

const useGetQuoteList = (props) => {
  let getAllQuoteDataModel = {
    getQuoteList: QuoteListV1
  };
  // for quote v2 orch
  if (props.isQuoteV2Enabled) {
    getAllQuoteDataModel = {
      quoteList: QuoteList
    };
  }
  const {
    refetch,
    data: quoteData,
    loading: quoteLoading,
    error: quoteError
  } = useDataModel(_getQuoteQuery(props), {
    variables: _quoteRequest(props),
    fetchPolicy: 'cache-first',
    dataModel: getAllQuoteDataModel
  });
  return { refetch, quoteData, quoteLoading, quoteError };
};

export {
  useQuoteProduct,
  useCreateQuote,
  useAddToQuote,
  useGetQuoteList,
  QuoteProductModel
};