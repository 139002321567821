import React, { useState, useRef, useCallback, useEffect } from 'react';
import { isEventBusCreated } from '../utils/helpers';

/**
 * This listener is designed for pip page, subscribe Frequent-bought-together / RecsFBT events
 * these two events are emitted by recommendation carousel
 * Event Type are base on different experience
 * for GM, HDH, it should emit RecsFBT event to window.Eventbus
 * for MA, it should emit Frequent-bought-together to window.Eventbus
 * */
const useFbtSkuListener = (itemId) => {
  const [fbtSkus, setFbtSkus] = useState([]);
  const previousFbtSkuRef = useRef();

  useEffect(() => {
    previousFbtSkuRef.current = fbtSkus;
  }, [fbtSkus]);

  const onSKUChange = useCallback((data) => {

    if (data?.output?.products) {

      const skus = data.output.products
        .map((product) => product.itemId)
        .filter((sku) => sku !== itemId)
        .sort();
      if (previousFbtSkuRef.current.length === 0) {
        setFbtSkus([...skus]);
      }
    }
  }, [itemId]);

  const initializeListeners = useCallback(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
      'RecsFBT.ready',
      onSKUChange
    );

    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
      'frequently-bought-together.ready',
      onSKUChange);
  }, [itemId]);

  const clearListeners = useCallback(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('RecsFBT.ready');
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('frequently-bought-together.ready');
  }, []);

  useEffect(() => {
    if (isEventBusCreated()) {
      initializeListeners();
    }
    return clearListeners;
  }, [initializeListeners, clearListeners, itemId]);

  return { fbtSkus };
};
export default useFbtSkuListener;
