import React from 'react';
import { bool, oneOf } from 'prop-types';
import { ProductAddonsTooltip } from '../../common/ProductAddOnsTooltip';

const SAMDualPathTooltip = ({ channel, checked }) => {

  const tooltipContent = (
    <div className="sui-w-64 sm:sui-w-72" data-channel={channel} data-component="SAMDualPathTooltip">
      <ul className="sui-list-disc sui-ml-4">
        <li>First, schedule an in-home measure appointment so we can
          take precise measurements of the rooms needing new flooring.
        </li>
        <li>We&apos;ll provide a project quote based on your
          measurements and the flooring product you select.
        </li>
        <li>Once you&apos;ve completed your purchase,
          we&apos;ll schedule time for our licensed,
          local installer to install your new flooring.
        </li>
      </ul>
    </div>
  );

  return (
    <ProductAddonsTooltip checked={checked} tooltipContent={tooltipContent} title="HOW IT WORKS" />
  );
};

SAMDualPathTooltip.displayName = 'ProductAddOns-SAM-dualPath-tooltip';

SAMDualPathTooltip.propTypes = {
  /** What view to render ['desktop' || 'mobile']  */
  channel: oneOf(['desktop', 'mobile']),
  /** Whether or not the checkbox is checked */
  checked: bool
};

SAMDualPathTooltip.defaultProps = {
  channel: 'desktop',
  checked: false
};

export { SAMDualPathTooltip };