import React, { useState, useContext, lazy, Suspense } from 'react';
import { node, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button } from '@thd-olt-component-react/core-ui';
// import { QuickView } from '@thd-olt-component-react/quick-view';
import './QuickView.style.scss';

const QuickViewLazy = lazy(() => import(
  /* webpackChunkName: "quick-view" */
  '@thd-olt-component-react/quick-view'
).then((mod) => {
  const { QuickView } = mod;
  return {
    default: (props) => {
      return (
        <QueryProvider cacheKey="quick-view">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <QuickView {...props} />
        </QueryProvider>
      );
    }
  };
}));

export const QuickViewWrapper = ({
  children,
  itemId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { channel } = useContext(ExperienceContext);
  const openQuickView = () => {
    setIsOpen(true);
  };
  return (channel === 'desktop') ? (
    <>
      <div className="fbt-quick-view">
        <Button
          onClick={openQuickView}
          onMouseEnter={() => setFetch(true)}
          dark
          outline
          className="fbt-quick-view__bttn"
        >Quick View
        </Button>
        {children}
      </div>
      { typeof window !== 'undefined' && (
        <Suspense fallback={<div />}>
          <QuickViewLazy
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            itemId={itemId}
            fetch={fetch}
          />
        </Suspense>
      )}

    </>
  ) : children;
};

QuickViewWrapper.propTypes = {
  children: node.isRequired,
  itemId: string.isRequired
};
