import React from 'react';
import classNames from 'classnames/bind';
import { useHeaderObserver } from '@thd-olt-functional/utils';
import { Sticky } from './Sticky';

const StickyWithHeaderObserver = ({
  animation,
  boxShadow,
  children,
  className,
  forwardRef,
  offsetMargin,
  offsetScroll,
  position,
  stickyType,
  stickyByDefault,
  visibleByDefault
}) => {
  const [entry] = useHeaderObserver();
  let headerOffset = 0;

  if (position === 'bottom') {
    headerOffset = entry?.visible && entry?.navigation?.positionBottom ? entry?.navigation.clientRect?.height : 0;
  } else if (position === 'top') {
    headerOffset = entry?.visible ? entry?.root?.clientRect?.height : 0;
  }

  const calculatedOffset = offsetMargin + headerOffset;
  const stickyClassNames = classNames(className, {
    'sui-transition-[bottom]': position === 'bottom',
    'sui-transition-[top]': position === 'top',
    '!sui-duration-500': headerOffset > 0
  });

  return (
    <Sticky
      animation={animation}
      boxShadow={boxShadow}
      className={stickyClassNames}
      forwardRef={forwardRef}
      offsetMargin={calculatedOffset}
      offsetScroll={offsetScroll}
      position={position}
      stickyType={stickyType}
      stickyByDefault={stickyByDefault}
      visibleByDefault={visibleByDefault}
    >
      {children}
    </Sticky>
  );
};

StickyWithHeaderObserver.displayName = 'StickyWithHeaderObserver';

StickyWithHeaderObserver.propTypes = Sticky.propTypes;

StickyWithHeaderObserver.defaultProps = Sticky.defaultProps;

export { StickyWithHeaderObserver };
