import React from 'react';

export const IntroPane = () => {
  return (
    <div className="sui-flex sui-flex-col sui-items-center">
      <img
        className="sui-w-1/5 sui-mb-4"
        src="https://assets.thdstatic.com/images/v1/paint/1-gallon-bucket.svg"
        alt="One Gallon Paint Bucket"
        width="20%"
        height="auto"
      />
      <div>Enter your room&apos;s measurements to get your results</div>
    </div>
  );
};
