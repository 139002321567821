/* eslint-disable indent */
import React, { useContext, useState, useEffect } from 'react';
import { string, bool as boolType } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { dynamicRecsDataModel } from '../dataModel';
import DynamicRecs from './DynamicRecs';
import { VerticalRecsPodPlaceholder } from '../vertical-recommendation/VerticalRecsPodPlaceholder';

const PipSemComponent = (props) => {
  const { anchor, anchorId, withReferrer } = props;
  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (withReferrer && !mounted) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <VerticalRecsPodPlaceholder
        placeholderCount={isMobile ? 1 : 3}
        placeholderLines={4}
      />
);
  }

  if (mounted) {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <div className="sui-grid sui-w-full">
        <DynamicRecs
          {...props}
          anchor={anchor || anchorId}
          slidesPer={isMobile ? 1 : 3}
          isPipsem
        />
      </div>
    );
  }

  return null;
};

PipSemComponent.displayName = 'PipSemWrapper';

const propTypes = {
  anchorId: string.isRequired,
  schemaName: string.isRequired,
  anchor: string,
  withReferrer: string
};
const defaultProps = {
  anchor: null,
  withReferrer: ''
};

const dataModel = dynamicRecsDataModel;

PipSemComponent.propTypes = propTypes;
PipSemComponent.defaultProps = defaultProps;
PipSemComponent.dataModel = dataModel;

const HydratedSemRecs = withHydrator({
  delay: 0,
  scrollBuffer: 0,
  preserveCtxVal: 'clientStore',
  immediateRender: true
}, PipSemComponent);

export const PipSemWrapper = withErrorBoundary(HydratedSemRecs);

PipSemWrapper.propTypes = propTypes;
PipSemWrapper.defaultProps = defaultProps;
PipSemWrapper.dataModel = dataModel;
PipSemWrapper.displayName = PipSemComponent.displayName;