import React from 'react';
import { ListItem } from '@one-thd/sui-atomic-components';
import { node, string } from 'prop-types';
import classNames from 'classnames';

const SalientListItem = (props) => {
  const { children, className, point } = props;
  const content = point || children;
  const classes = classNames(className, '!sui-p-0', 'sui-text-base', 'sui-leading-normal', 'sui-font-regular');

  return (
    <ListItem className={classes}>
      {content}
    </ListItem>
  );
};

SalientListItem.displayName = 'SalientListItem';

SalientListItem.propTypes = {
  // Additional salient points apart from productHighlights
  children: node,
  // Additional class names
  className: string,
  // A salient point featuring productHighlights
  point: string
};

SalientListItem.defaultProps = {
  children: null,
  className: null,
  point: null
};

export { SalientListItem };
