import React, { useContext } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import {
  alias,
  arrayOf as arrayOfType,
  bool as boolType,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';
import { InstantCheckoutLoader } from './subcomponents/loader/InstantCheckoutLoader';
import { InstantCheckoutEdit } from './subcomponents/InstantCheckoutEdit';
import { InstantCheckoutEnabled } from './subcomponents/InstantCheckoutEnabled';
import { InstantCheckoutEnroll } from './subcomponents/InstantCheckoutEnroll';
import { InstantCheckoutSignin } from './subcomponents/InstantCheckoutSignin';
import * as helpers from './InstantCheckoutHelper';
import './instant-checkout.scss';
import { useUserDetails } from '../hooks/useUserDetails';
import { icDataModel } from '../instantCheckoutDataModel';

const InstantCheckout = ({
  itemId,
  quantity,
  fulfillmentMethod,
  productAddOns,
  hideInvitation,
  disabled,
}) => {
  const { channel } = useContext(ExperienceContext);

  const { storeId } = useStore();
  const [userDetails] = useUserDetails({ itemId, storeId });

  if (userDetails?.loading) {
    return <InstantCheckoutLoader />;
  }

  if (userDetails?.ICTemplate === 'IC') {
    return (
      <InstantCheckoutEnabled
        atcRequest={helpers.getInstantCheckoutAtcRequest({ itemId, quantity, productAddOns })}
        instantCheckoutModel={userDetails}
        channel={channel}
        fulfillmentMethod={fulfillmentMethod}
        disabled={disabled}
      />
    );
  }

  if (hideInvitation) {
    return null;
  }

  if (userDetails?.ICTemplate === 'ENROLL' || userDetails?.ICTemplate === 'DEFAULT') {
    return <InstantCheckoutEnroll channel={channel} />;
  }
  if (userDetails?.ICTemplate === 'SIGN_IN') {
    return <InstantCheckoutSignin />;
  }
  if (userDetails?.ICTemplate === 'EDIT_IC') {
    return <InstantCheckoutEdit />;
  }
  return null;
};

InstantCheckout.propTypes = {
  itemId: string,
  quantity: number,
  fulfillmentMethod: string,
  hideInvitation: bool,
  disabled: bool,
  productAddOns: shape({
    attachedLabor: arrayOf(
      shape({
        attachedLaborSku: string,
      })
    ),
    warrantyItem: shape({
      itemId: string,
    }),
    service: shape({
      type: string,
      selected: bool,
      category: string,
    }),
  }),
};

InstantCheckout.defaultProps = {
  itemId: null,
  quantity: 1,
  fulfillmentMethod: null,
  productAddOns: null,
  hideInvitation: null,
  disabled: false,
};

InstantCheckout.dataModel = {
  clientOnlyProduct: alias('product')
    .params({ itemId: stringType().isRequired() })
    .shape({
      itemId: stringType(),
      dataSources: stringType(),
      fulfillment: params({ storeId: stringType() }).shape({
        fulfillmentOptions: arrayOfType(
          shapeType({
            type: stringType(),
            services: arrayOfType(
              shapeType({
                type: stringType(),
                locations: arrayOfType(
                  shapeType({
                    inventory: shapeType({
                      isInStock: boolType(),
                    }),
                  })
                ),
              })
            ),
          })
        ),
      }),
    }),
  ...icDataModel,
};

export { InstantCheckout };
