const validatePage = () => {
  if ((window.digitalData.page)
    && (window.digitalData.page.pageInfo)
    && window.digitalData.page.pageInfo.pageName
      && window.digitalData.page.pageInfo.pageType) {
    return true;
  }
  return false;
};

const validateUser = () => {
  if ((window.digitalData.user)
    && (window.digitalData.user.profile)
      && window.digitalData.user.profile.authenticationStatus) {
    return true;
  }
  return false;
};

const tryInitialize = () => {
  if (validatePage() && validateUser()) {
    window.ddoInit(window.digitalData);
  }
};

export const setPage = (page) => {
  window.digitalData.page = page;
  tryInitialize();
};

export const setPageInfo = (pageName, pageType) => {
  const pageInfo = { pageName, pageType };

  if (!window.digitalData.page) {
    window.digitalData.page = {};
  }

  window.digitalData.page.pageInfo = pageInfo;
  tryInitialize();
};

export const setAdditionalPageInfo = (propertyName, additionalData) => {
  if (!window.digitalData.page) {
    window.digitalData.page = {};
  }

  switch (propertyName) {
  case 'category':
    window.digitalData.page.category = { ...additionalData };
    break;
  case 'eventInfo':
    window.digitalData.page.inventInfo = { ...additionalData };
    break;
  case 'pageInfo':
    window.digitalData.page.pageInfo = { ...additionalData };
    break;
  case 'item':
    if (!window.digitalData.product || window.digitalData.product.length === 0) {
      window.digitalData.product = [];
      window.digitalData.product.push({
        productInfo: {},
        ...additionalData,
      });
    }

    if (additionalData.productType === 'Refrigerators') {
      window.digitalData.product[0].productInfo.productType = 'MAJOR APPLIANCE';
    }
    if (window?.digitalData?.product?.[0]?.productInfo?.productType === 'CONFIGURABLE_BLINDS') {
      window.digitalData.product[0].productInfo.productType = 'GENERAL_MERCHANDISE';
    }

    break;
  case 'content':
    if (!window.digitalData.content) {
      window.digitalData.content = {};
    }
    if (!window.digitalData.content.module) {
      window.digitalData.content.module = [];
    }
    window.digitalData.content.module.push(additionalData);
    break;
  default:
  }
};

export const setAuthenticationStatus = (authenticationStatus) => {
  window.digitalData.user = window.digitalData.user || {};
  window.digitalData.user.profile = window.digitalData.user.profile || {};

  window.digitalData.user.profile.authenticationStatus = authenticationStatus;
  tryInitialize();
};

export const setProductInfo = ({ omsId, productName, brand }) => {
  window.digitalData.product = window.digitalData.product || [];
  const productInfo = {
    sku: `${omsId}`, productName, manufacturer: `${brand}`, productType: 'merchandise',
  };

  window.digitalData.product.push(productInfo);
};
