import {
  string as stringType,
  shape as shapeType,
  params
} from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    info: shapeType({
      fiscalYear: stringType(),
      productDepartment: stringType(),
      classNumber: stringType()
    })
  })
};
