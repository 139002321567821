import {
  toggles, lineItemNames, AREA, VOLUME, FIXED_WIDTH
} from '../constants';

const { PANEL, BLOCK, SHEET, BAG } = lineItemNames;
const shouldDisplayQuantityCalculator = ({ fulfillment, installServices }) => {
  return !!(fulfillment?.fulfillmentOptions
    && !installServices?.scheduleAMeasure);
};

export const shouldDisplayPipCalculator = ({ fulfillment, info, installServices, isInstalledCarpet = false }) => {
  return !!info?.pipCalculator
      && !!info.pipCalculator.display
      && !!info.pipCalculator.toggle
      && !!info.pipCalculator.coverageUnits
      && !!info.pipCalculator.publisher
      && (isInstalledCarpet || shouldDisplayQuantityCalculator({ fulfillment, installServices }));
};

const returnCalculatorType = (toggle) => {
  if (toggle === toggles.TWO_POINT) return AREA;
  if (toggle === toggles.THREE_POINT) return VOLUME;
  if (toggle === toggles.FIXED_WIDTH) return FIXED_WIDTH;
  return '';
};

const getLineItemName = (coverageUnits) => {
  if (coverageUnits === PANEL || coverageUnits === BLOCK || coverageUnits === SHEET) {
    return 'Wall';
  }
  if (coverageUnits === BAG) {
    return 'Space';
  }
  return 'Area';
};

const getCaseUnitOfMeasure = (toggle) => {
  if ([toggles.TWO_POINT, toggles.FIXED_WIDTH].includes(toggle)) {
    return 'sq. ft.';
  }
  if (toggle === toggles.THREE_POINT) {
    return 'cu. ft.';
  }
  return '';
};

const getDimensionNames = (coverageUnits) => {
  if (coverageUnits === BLOCK) {
    return ['Width', 'Height'];
  }
  if (coverageUnits === SHEET || coverageUnits === BAG) {
    return ['Width', 'Length'];
  }
  return ['Length', 'Width'];
};

export const getPIPCalculatorProps = ({ info }) => {
  const { additionalCoveragePercentage, coverageUnits, publisher, toggle } = info.pipCalculator;

  return {
    calcProps: {
      calculatorType: returnCalculatorType(toggle),
      lineItemName: getLineItemName(coverageUnits),
      dimensionNames: getDimensionNames(coverageUnits),
    },
    resultsProps: {
      additionalCoveragePercentage,
      caseUnitOfMeasure: getCaseUnitOfMeasure(toggle),
      unitsPerCase: publisher,
      uom: coverageUnits
    }
  };
};