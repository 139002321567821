import {
  bool,
  number,
  shape,
  string
} from 'prop-types';
import requiredIf from 'react-required-if';

const BaseImageShape = {
  alt: string,
  src: string.isRequired,
  srcSet: string,
  sizes: string
};

export const LargeImageShape = shape(
  {
    ...BaseImageShape,
    width: number.isRequired,
    height: number.isRequired
  }
);

export const SmallImageShape = shape(
  {
    ...BaseImageShape,
    isFluidWidth: bool,
    width: requiredIf(number, (props) => !props.isFluidWidth),
    height: requiredIf(number, (props) => !props.isFluidWidth)
  }
);