import { useState, useMemo } from 'react';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';
import _isObject from 'lodash/isObject';

const INITIAL_STATE = {
  configuratorUnavailable: false,
  isConfiguratorEditMode: null,
  removeLineItem: null,
  items: null,
  configuratorData: null,
  isDecking: false,
  customLoading: null,
  isSpecialOrder: false,
  configuratorHideQuantity: false,
  initialQuantity: 1,
  leadTime: null,
  disableTimer: false
};

const useConfiguratorEvents = () => {
  const [configuratorEvent, setConfiguratorEvent] = useState({ ...INITIAL_STATE });
  const { configuratorUnavailable } = useLifeCycleEventBus('configurator.configurator_unavailable');
  const { isPreview } = useLifeCycleEventBus('configurator.configurator-mounted');
  const isDecking = useLifeCycleEventBus('configurator.configurator_decking');
  const customLoading = useLifeCycleEventBus('configurator.loading');
  const { isSpecialOrder } = useLifeCycleEventBus('configurator.isSpecialOrder');
  const configuratorHideQuantity = useLifeCycleEventBus('configurator.hide_quantity');
  const initialQuantity = useLifeCycleEventBus('configurator.initial_quantity');
  const leadTime = useLifeCycleEventBus('configurator.set_lead_time');
  const disableTimer = useLifeCycleEventBus('configurator.disable_timer');

  useMemo(() => {
    const events = {
      configuratorUnavailable,
      isPreview,
      isDecking,
      customLoading,
      isSpecialOrder,
      configuratorHideQuantity,
      initialQuantity,
      leadTime,
      disableTimer
    };
    const sanitizedEvents = _mapValues(
      events,
      (eventValue, eventKey) => {
        if (_isEmpty(eventValue) && _isObject(eventValue)) {
          return INITIAL_STATE?.[eventKey];
        }

        return eventValue;
      }
    );

    setConfiguratorEvent({ ...sanitizedEvents });
  }, [
    configuratorUnavailable, isPreview, isDecking, customLoading, isSpecialOrder,
    configuratorHideQuantity, initialQuantity, leadTime, disableTimer
  ]);

  return configuratorEvent;
};

export default useConfiguratorEvents;
