const isRefInViewport = ({
  offsetScroll, refHeight, refRect, windowObj
}) => {
  return (
    (refRect.top + refHeight) >= 0
    && refRect.left >= 0
    && (refRect.bottom + offsetScroll) <= (windowObj.innerHeight)
    && Math.floor(refRect.right) <= (windowObj.innerWidth)
  );
};

const isScrolledPastOffset = (offsetScroll, windowObj) => {
  return windowObj.pageYOffset >= offsetScroll;
};

const applyInlineOffsetMargin = (animation, offsetMargin, position, stickyType, isVisible) => {
  const check = parseInt(offsetMargin, 10);
  if (!check) return {};
  const topStyle = check && position === 'top' && isVisible ? { top: `${offsetMargin}px` } : null;
  const bottomStyle = check && position === 'bottom' && isVisible ? { bottom: `${offsetMargin}px` } : null;
  return topStyle || bottomStyle || {};
};

const getPosition = (ref) => {
  if (!ref) return null;
  const pageOffset = window.pageYOffset;

  return {
    bottomOffset: pageOffset + ref.current?.getBoundingClientRect().bottom,
    topOffset: pageOffset + ref.current?.getBoundingClientRect().top
  };
};

const getScrollPosition = (windowObj) => {
  return windowObj?.pageYOffset || document?.documentElement?.scrollTop;
};

const isScrolledPastContent = (windowObj, yPosition) => {
  return getScrollPosition(windowObj) > yPosition && yPosition > 0;
};

const isScrollingDown = (currentScrollPosition, lastScrollPosition) => {
  return currentScrollPosition > lastScrollPosition;
};

export {
  applyInlineOffsetMargin,
  getScrollPosition,
  getPosition,
  isRefInViewport,
  isScrolledPastContent,
  isScrolledPastOffset,
  isScrollingDown
};
