/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { FrequentlyBoughtTogetherContext } from './contexts/FrequentlyBoughtTogetherContext';
import LegacyFBT from './components/Legacy/Legacy.component';
import Bundle from './components/Bundle/Bundle';
import ApplianceBundle from './components/Bundle/ApplianceBundle';
import * as analytics from './analytics/frequentlyboughttogether-analytics';
import {
  getImageURL,
  getFinalPriceMessageCount,
  getPriceMessage,
  getTotalPrice,
  getCart,
  activePromo,
  laundryAccessoryFilter
} from './helpers/helpers';
import { FBTPlaceholder } from './placeholder/FBTPlaceholder';
import './frequently-bought-together.style.scss';

const NRTimer = ({ startTime }) => {
  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const endTime = new Date();
      LIFE_CYCLE_EVENT_BUS.trigger('performance.component-load', {
        componentName: 'FBT',
        startTime,
        endTime: endTime.getTime()
      });
    }
  }, []);
  return null;
};

NRTimer.propTypes = {
  startTime: PropTypes.instanceOf(Date)
};
NRTimer.defaultProps = {
  startTime: null
};

export const MainFBT = (props) => {

  const { itemId, openInNewTab } = props;

  const { channel } = useContext(ExperienceContext);

  const startTime = new Date().getTime();

  const {
    bundleResponseData,
    bundleResponseDataLoading,
    storeId: loadedStoreId,
    isFulfillable,
    isDisplayableBasedOnFulfillment,
    productResponseData,
    productResponseLoading,
    bundleType,
    isApplianceBundle,
    isPromotionalBundle,
    checkedItems,
    setCheckedItems
  } = useContext(FrequentlyBoughtTogetherContext);

  const product = productResponseData?.product;
  if (productResponseLoading || bundleResponseDataLoading) {
    return (
      <FBTPlaceholder />
    );
  }
  if (!product) return null;

  const bundleProducts = bundleResponseData?.bundle?.products;
  if (!bundleProducts?.length) return null;

  if (!isApplianceBundle && !isFulfillable) return null;
  if (!isApplianceBundle && !isDisplayableBasedOnFulfillment) return null;

  const normalizeSkus = ({ products }) => {
    return products.map((product, productIndex) => {
      // primary item is a frontEnd model response
      const {
        idStartDate,
        idEndDate,
        idPromoDetails,
        category,
        description,
        isAnchor,
        product: {
          identifiers: {
            canonicalUrl: canonicalURL,
            itemId,
            brandName,
            productLabel
          },
          badges,
          pricing,
          media: {
            images
          },
          reviews
        }
      } = product;

      const averageRating = reviews?.ratingsReviews?.averageRating;
      const totalReviews = reviews?.ratingsReviews?.totalReviews;

      // ssr should check the first item
      const checked = typeof window === 'undefined' && productIndex === 0
        ? true
        : (checkedItems.indexOf(itemId) > -1);

      if (productIndex === 0) {
        const image = images?.find((i) => i.subType === 'PRIMARY');
        const imageURL = image ? image.url : '';
        return {
          brand: brandName,
          canonicalURL,
          badges,
          pricing,
          idStartDate,
          idEndDate,
          idPromoDetails,
          category,
          itemId,
          productName: productLabel,
          rating: averageRating,
          reviews: totalReviews,
          imageURL,
          checked,
          product: product.product,
          type: 'primary'
        };
      }
      return {
        brand: brandName,
        canonicalURL,
        badges,
        pricing,
        productName: productLabel,
        rating: averageRating,
        reviews: totalReviews,
        imageURL: getImageURL(images),
        itemId,
        idPromoDetails,
        category,
        description,
        isAnchor,
        checked,
        product: product.product,
        type: 'product'
      };
    });
  };

  const shouldShowBundle = (products, type) => {
    return type === 'APPLIANCE_BUNDLE' || activePromo(products[0].idStartDate, products[0].idEndDate);
  };

  const bundleHeading = isApplianceBundle ? bundleProducts[0].slot.description : '';
  let bundleItemTitle = '';
  let anchorItemTitle = '';
  if (isApplianceBundle) {
    bundleProducts.forEach((item) => {
      if (item.isAnchor) {
        anchorItemTitle = item.category;
      } else {
        bundleItemTitle = item.category;
      }
    });
  }

  const productsToNormalize = isApplianceBundle
    ? bundleProducts.filter((item) => !item.isAnchor)
    : bundleProducts;

  const normalizedProducts = normalizeSkus({ products: [{ product }, ...productsToNormalize] })
    .filter((product) => laundryAccessoryFilter(bundleProducts.find((product) => product.isAnchor), product));

  const cart = getCart(normalizedProducts, channel);
  const priceMsgCount = getFinalPriceMessageCount(bundleProducts);
  const message = getPriceMessage(checkedItems.length, priceMsgCount);
  const displayPromoBundle = shouldShowBundle(bundleProducts, bundleType);

  let totalPrice = {};

  if (checkedItems.length) {
    totalPrice = getTotalPrice(normalizedProducts);
  }

  const onChangeCheckbox = (id) => {
    const index = checkedItems.indexOf(id);
    if (index > -1) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  const updateCheckedItems = (currentItem, newItem) => {
    const newCheckedItems = checkedItems.filter((item) => item !== currentItem);
    newCheckedItems.push(newItem);
    setCheckedItems(newCheckedItems);
  };

  const onAddToCartClick = (products, promo, bundle = false) => {
    let analyticsItems = [];
    products.forEach((product) => {
      if (checkedItems) {
        if (product.itemId !== itemId || bundle) {
          analyticsItems.push(product);
        }
      }
      if (!promo && !bundle) {
        onChangeCheckbox(product.itemId);
      }
    });

    analytics.fbtAddToCartAnalytics({ analyticsItems, bundle, checkedItems, products });
  };

  const getCurrentApplianceBundleItem = (itemId) => {
    const currentBundleItemId = checkedItems.length > 1
      ? checkedItems.find((checkedItem) => checkedItem !== itemId) : '';
    return currentBundleItemId ? normalizedProducts.find((val) => val.itemId === currentBundleItemId)
      : normalizedProducts.find((val) => val.type !== 'primary');
  };

  return (
    <>
      { !displayPromoBundle && (
        <>
          <NRTimer startTime={startTime} />
          <LegacyFBT
            itemId={itemId}
            onChangeCheckbox={onChangeCheckbox}
            setCheckedItems={setCheckedItems}
            product={product}
            products={normalizedProducts}
            storeId={loadedStoreId}
          />
        </>
      )}
      { bundleType === 'PROMO_BUN' && (
        displayPromoBundle && (
          <>
            <NRTimer startTime={startTime} />
            <Bundle
              cartOptions={cart.options}
              cartReqParams={cart.itemDetails}
              channel={channel}
              itemId={itemId}
              // itemInCart={state.itemInCart}
              itemInCart={false}
              message={message}
              openInNewTab={openInNewTab}
              onAddToCartClick={() => onAddToCartClick(normalizedProducts,
                isPromotionalBundle,
                isApplianceBundle)}
              pricing={totalPrice}
              product={product}
              products={normalizedProducts}
              storeId={loadedStoreId}
            />
          </>
        )
      )}
      { isApplianceBundle && displayPromoBundle && (
        <ApplianceBundle
          bundleHeading={bundleHeading}
          anchorItemTitle={anchorItemTitle}
          bundleItemTitle={bundleItemTitle}
          cartOptions={cart.options}
          cartReqParams={cart.itemDetails}
          channel={channel}
          itemId={itemId}
          itemInCart={false}
          message={message}
          onAddToCartClick={() => onAddToCartClick(normalizedProducts,
            isPromotionalBundle,
            isApplianceBundle)}
          openInNewTab={openInNewTab}
          updateCheckedItems={updateCheckedItems}
          currentItem={getCurrentApplianceBundleItem(itemId)}
          pricing={totalPrice}
          product={product}
          products={normalizedProducts}
          checkedItems={checkedItems}
          storeId={loadedStoreId}
        />
      )}
    </>
  );
};

MainFBT.propTypes = {
  itemId: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool
};

MainFBT.defaultProps = {
  openInNewTab: false
};
