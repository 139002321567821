export const SBOTD_KEY = 'Online Exclusive Special Buy of the Day';
export const SBOTD_LINK = '/SpecialBuy/SpecialBuyOfTheDay';
export const SBOTD_DESC = 'Shop All Special Buy of the Day';
export const SBOTW_KEY = 'Online Exclusive Pro Special Buy of the Week';
export const SBOTW_LINK = '/SpecialBuy/ProSpecialBuyOfTheWeek';
export const SBOTW_DESC = 'Shop All Pro Special Buy of the Week';
export const BOGO = 'BOGO';
export const BMSM = 'BMSM';
export const MSB = 'MSBONLINE';
export const BUY_ONE_GET_ONE_FREE = 'buyOneGetOne';

export const SPECIAL_BUYS = {
  [SBOTD_KEY]: {
    link: SBOTD_LINK,
    description: SBOTD_DESC,
  },
  [SBOTW_KEY]: {
    link: SBOTW_LINK,
    description: SBOTW_DESC,
  },
};
