import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from '@thd-olt-component-react/carousel';
import './BundledItems.style.scss';

const BundledItems = ({ bundleItems, productCount }) => {
  const itemWidth = productCount > 2 ? '40%' : '50%';

  const carouselBundles = classNames('bundled-items', {
    'bundled-items__carousel': productCount >= 4,
    'bundled-items__carousel-small': productCount >= 3
  });

  const noCarouselClasses = classNames('bundled-items', {
    'bundled-items__hidden': productCount >= 4, // just to be safe
    'bundled-items__no-carousel-fallback': productCount >= 3,
    'bundled-items__no-carousel': productCount < 3 || !productCount
  });

  return (
    <>
      <div className={carouselBundles}>
        <Carousel fade itemWidthDesktop={itemWidth}>
          {bundleItems}
        </Carousel>
      </div>
      <div className={noCarouselClasses}>
        {bundleItems}
      </div>
    </>
  );
};

BundledItems.displayName = 'BundledItems';

BundledItems.propTypes = {
  bundleItems: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)]),
  productCount: PropTypes.number
};

BundledItems.defaultProps = {
  bundleItems: null,
  productCount: null
};

export { BundledItems };
