import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Printer } from '@one-thd/sui-icons';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { SharebeltContext } from '../../Sharebelt.context';
import { track } from '../../../analytics';

const ShareBeltPrint = () => {

  const {
    channel = 'desktop'
  } = useContext(ExperienceContext);

  const onClickPrint = useCallback((event) => {
    event.preventDefault();
    track({
      component: 'system',
      eventName: 'pip sub-content',
      primaryCategory: 'interaction',
      section: 'sharing',
      target: 'print'
    });
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      window.print();
    }
  }, []);

  const {
    horizontal,
    vertical
  } = useContext(SharebeltContext);

  const shareBeltButtonClasses = classNames({
    'sui-mr-2': horizontal,
    'sui-inline-block': vertical,
    'sui-flex': horizontal,
    'sui-mr-0': vertical
  });
  const shareBeltIconClasses = classNames('sui-inline-block', 'sui-float-left', {
    'sui-pr-2': horizontal,
    'sui-mt-1': vertical,
    'sui-mr-5': vertical,
    'sui-ml-5': vertical
  });

  const shareBeltItemClasses = classNames('sui-mb-2', 'sui-inline-block',
    'hover:sui-cursor-pointer', 'last:hover:sui-no-underline', 'sui-mt-2');

  const shareBeltPrintClasses = classNames('sui-inline-block', {
    'sui-pr-4': vertical, 'sui-pl-4': vertical
  });

  // @todo check mobile requirements
  // TODO: can we just return null on MW?
  if (channel?.toLowerCase() !== 'desktop') {
    return (
      <></>
    );
  }
  return (
    <div className={shareBeltItemClasses} data-component="ShareBeltPrint">
      <Button
        className={shareBeltButtonClasses}
        data-target="print"
        name="print-page"
        onClick={onClickPrint}
        tabIndex={0}
        variant="ghost"
      >
        <span className={shareBeltIconClasses}>
          <Printer size="regular" />
        </span>
        <span className={shareBeltPrintClasses}>
          <Typography color="primary" variant="body-base">Print</Typography>
        </span>
      </Button>
    </div>
  );
};

ShareBeltPrint.displayName = 'ShareBeltPrint';

export default ShareBeltPrint;
