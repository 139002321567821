import {
  arrayOf, params, string, shape, customType, bool
} from '@thd-nucleus/data-sources';
import { FbrProductPod } from './FbrProductPod';

export const dataModel = {
  fbr: params({
    itemId: string().isRequired(),
    storeId: string(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      })),
    }),
    isBrandPricingPolicyCompliant: bool()
  }).shape({
    recommendedProductSet: string(),
    fbrItems: arrayOf(shape({
      recommendedFeatures: arrayOf(shape({
        name: string(),
        value: string()
      })),
      product: params({}).shape(FbrProductPod.dataModel.product)
    }))
  })
};
