import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  arrayOf,
  bool,
  number,
  string,
} from 'prop-types';

import {
  IconButton,
  Typography
} from '@one-thd/sui-atomic-components';
import { TrashCan } from '@one-thd/sui-icons';

import { CalculatorContext } from './subcomponents/CalculatorProvider';
import { CalculatorInputElement } from './subcomponents/CalculatorInputElement';

// Don't import this into FlooringPicker
export const CalculatorInputSections = ({
  dimensionNames,
  hideAreaText,
  showNameInputs,
  selectedArea
}) => {
  const {
    calculate: {
      areas,
      areaErrors,
      isPresentInLocalStorage
    },
    calculatorType,
    removeInputElement
  } = useContext(CalculatorContext);

  const dimensionInputClasses = classNames('sui-mt-1 sui-flex sui-flex-col', {
    'sm:sui-flex-row': selectedArea === null
  });

  const [dimension1, dimension2] = dimensionNames;
  const filteredAreas = selectedArea !== null ? [areas[selectedArea]] : areas;

  return (
    <div id="calc-input-sections" className="sui-overflow-auto sui-max-h-80">
      {filteredAreas.map((element, elementIndex) => {
        const index = selectedArea ?? elementIndex;
        return (
          <div key={index} className="sui-my-2">
            <div className="sui-flex sui-items-center">
              {!hideAreaText && (
                <div className="sui-mr-4">
                  <Typography weight="bold">{element.name}</Typography>
                </div>
              )}
              {selectedArea === null && (areas.length !== 1 || isPresentInLocalStorage) && (
                <IconButton icon={TrashCan} color="brand" onClick={() => removeInputElement({ index })} />
              )}
            </div>
            <div className={dimensionInputClasses}>
              {showNameInputs && (
                <CalculatorInputElement
                  index={index}
                  propName="Description"
                  propValue={element.name}
                  isError={areaErrors[index].name}
                />
              )}
              {!areas[index].calcByArea && (
                <>
                  <CalculatorInputElement
                    index={index}
                    labelName={dimension1}
                    propName="length"
                    propValue={element.length}
                    isError={areaErrors[index].length}
                    endAdornment="ft."
                  />
                  <CalculatorInputElement
                    index={index}
                    labelName={dimension2}
                    propName="width"
                    propValue={element.width}
                    isError={areaErrors[index].width}
                    endAdornment="ft."
                  />
                </>
              )}
              {areas[index].calcByArea && (
                <CalculatorInputElement
                  index={index}
                  propName="Square Footage"
                  propValue={element.squareFootage}
                  isError={areaErrors[index].squareFootage}
                  endAdornment="sq. ft."
                />
              )}
              {calculatorType === 'Volume' && (
                <CalculatorInputElement
                  index={index}
                  propName="Depth"
                  propValue={element.depth}
                  isError={areaErrors[index].depth}
                  endAdornment="in."
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CalculatorInputSections.displayName = 'CalculatorInputSections';

CalculatorInputSections.propTypes = {
  dimensionNames: arrayOf(string),
  hideAreaText: bool,
  selectedArea: number,
  showNameInputs: bool,
};

CalculatorInputSections.defaultProps = {
  dimensionNames: ['Width', 'Height'],
  hideAreaText: false,
  selectedArea: null,
  showNameInputs: false
};