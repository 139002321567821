/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  Radio,
  RadioGroup,
  SelectionControlLabel
} from '@one-thd/sui-atomic-components';
import { DELIMITER } from './Constants';

const Quotes = ({
  quoteList,
  selectQuote,
  selectedQuote,
  showLoadMore,
  quotesPageSize,
  setQuotesPageSize
}) => {

  const handleLoadMore = () => {
    setQuotesPageSize(quotesPageSize + 20);
  };

  return (
    <div className="sui-pt-6">
      <RadioGroup
        aria-labelledby="quote-names"
        name="quote-names"
      >
        {quoteList.map((quote, i) => (
          <SelectionControlLabel
            key={quote.quoteId || quote.cartId}
            id={quote.quoteId || quote.cartId}
            onChange={selectQuote}
            value={`${(quote.quoteId || quote.cartId)}${DELIMITER}${(quote.userId || quote.createdUserId)}${DELIMITER}${quote.name}`}
            label={quote.name}
            checked={selectedQuote.quoteId === quote.quoteId || selectedQuote.quoteId === quote.cartId}
            padding="true"
          >
            <Radio />
          </SelectionControlLabel>
        ))}
      </RadioGroup>
      {showLoadMore && (
        <Button
          variant="text"
          onClick={handleLoadMore}
          data-testid="load-more-button"
        >
          Load More...
        </Button>
      )}
    </div>
  );
};

Quotes.propTypes = {};

Quotes.displayName = 'Quotes';

export { Quotes };