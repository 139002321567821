/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  alias,
  params,
  arrayOf,
  shape as shapeType,
  string as stringType,
  number as numberType,
  bool as boolType,
} from '@thd-nucleus/data-sources';

/**
 * Should resolve to the backend schema:
 * ocm-cart-service-cart-information schema.graphqls
 */

const Int = numberType();
const BigDecimal = numberType();
const String = stringType();
const Boolean = boolType();

const Address = shapeType({
  id: String,
  firstName: String,
  lastName: String,
  addressLine1: String,
  addressLine2: String,
  zipCode: String,
  state: String,
  country: String,
  county: String,
  phone: String,
  phoneNumber: String,
  hideCVV: Boolean,
  city: String,
  type: String,
  default: Boolean,
  primaryPhoneId: String,
  addressIdentifier: String,
  businessAddress: Boolean,
  category: String,
});

const Customer = shapeType({
  userId: String,
  customerId: String,
  type: String,
  addresses: arrayOf(Address)
});

const Payment = shapeType({
  emailId: String,
  amountCharged: BigDecimal,
  cardBrand: String,
  maskedCardNumber: String,
  xref: String,
  paymentId: String,
  type: String,
  addressIds: arrayOf(String),
  paypalAccountId: String
});

const Tax = shapeType({
  type: String,
  value: BigDecimal,
  percentage: String,
});

const Fee = shapeType({
  type: String,
  value: BigDecimal,
  taxTotal: BigDecimal,
  taxPercentage: BigDecimal,
  percentage: String,
  taxes: arrayOf(Tax)
});

const Tool = shapeType({
  categoryCode: String,
  subCategoryCode: String,
  deposit: BigDecimal,
  feeTotal: BigDecimal,
  taxTotal: BigDecimal,
  taxPercentage: String,
  fees: arrayOf(Fee)
});

const RentalEstimate = shapeType({
  tools: arrayOf(Tool)
});

const Message = shapeType({
  type: String,
  messageCategoryType: String,
  correlationId: String,
  correlationType: String,
  longDesc: String,
  shortDesc: String
});

const Addon = shapeType({
  installation: Boolean,
  termLength: String,
  type: String,
  price: BigDecimal,
  totalPrice: BigDecimal,
  quantity: Int,
  itemId: String,
  id: String,
  category: String,
  description: String,
  detailsUrl: String,
  selected: Boolean,
  storeId: String,
  protectionPlanParentId: String,
  brandName: String,
  configAttr: String,
  descriptiveAttributes: arrayOf(String)
});

const Info = shapeType({
  returnable: String,
  quantityLimit: Int,
  minimumOrderQuantity: Int,
  inStoreReturnEligibility: Boolean
});

const Specification = shapeType({
  specName: String,
  specValue: String,
});

const SpecificationGroup = shapeType({
  specTitle: String,
  specifications: arrayOf(Specification)
});

const Discount = shapeType({
  percentOff: String,
  dollarOff: String,
});

const Pricing = shapeType({
  value: BigDecimal,
  original: BigDecimal,
  total: BigDecimal,
  totalWithNoDiscount: BigDecimal,
  valueStartDate: String,
  valueEndDate: String,
  type: String,
  discount: Discount
});

const Image = shapeType({
  url: String,
  type: String,
  subType: String,
  sizes: arrayOf(String),
  hotspots: String,
  altText: String,
});

const Media = shapeType({
  images: arrayOf(Image)
});

const Identifiers = shapeType({
  configId: String,
  editUrl: String,
  copyUrl: String,
  productCategory: String,
  leadTime: String,
  canonicalUrl: String,
  brandName: String,
  itemId: String,
  modelNumber: String,
  productLabel: String,
  storeSkuNumber: String,
  skuClassification: String,
  productType: String,
  isSuperSku: Boolean,
  shipType: Int,
  partNumber: String,
  fromName: String,
  toName: String,
  message: String,
  deliveryMethod: String,
  recipientEmail: String
});

const DeliveryDates = shapeType({
  startDate: String,
  endDate: String,
});

const Inventory = shapeType({
  isOutOfStock: Boolean,
  quantity: Int,
  isInStock: Boolean,
  isUnavailable: Boolean,
  isLimitedQuantity: Boolean,
  backordered: Boolean,
  maxAllowedBopisQty: Int,
  minAllowedBopisQty: Int
});

const Monday = shapeType({
  open: String,
  close: String
});

const Tuesday = shapeType({
  open: String,
  close: String
});
const Wednesday = shapeType({
  open: String,
  close: String
});
const Thursday = shapeType({
  open: String,
  close: String
});
const Friday = shapeType({
  open: String,
  close: String
});
const Saturday = shapeType({
  open: String,
  close: String
});
const Sunday = shapeType({
  open: String,
  close: String
});

const StoreHours = shapeType({
  monday: Monday,
  tuesday: Tuesday,
  wednesday: Wednesday,
  thursday: Thursday,
  friday: Friday,
  saturday: Saturday,
  sunday: Sunday,
  storeTimeZone: String
});

const Location = shapeType({
  isAnchor: Boolean,
  locationId: String,
  zipCode: String,
  curbsidePickupFlag: Boolean,
  isBuyInStoreCheckNearBy: Boolean,
  distance: String,
  storeName: String,
  city: String,
  state: String,
  storePhone: String,
  type: String,
  inventory: Inventory,
  storeHours: StoreHours
});

const DynamicEta = shapeType({
  hours: String,
  minutes: String,
});

const Service = shapeType({
  type: String,
  expectedArrival: String,
  hasFreeShipping: Boolean,
  estimatedDelivery: String,
  freeDeliveryThreshold: Boolean,
  deliveryCharge: BigDecimal,
  selected: Boolean,
  optimalFulfillment: Boolean,
  dynamicEta: DynamicEta,
  deliveryDates: DeliveryDates,
  totalCharge: BigDecimal,
  deliveryTimeline: String,
  locations: arrayOf(Location)
});

const FulfillmentOption = shapeType({
  type: String,
  fulfillable: Boolean,
  services: arrayOf(Service),
  addressId: String
});

const Fulfillment = shapeType({
  backordered: Boolean,
  backorderedShipDate: String,
  bossExcludedShipStates: String,
  excludedShipStates: String,
  seasonStatusEligible: String,
  anchorStoreStatus: Boolean,
  anchorStoreStatusType: String,
  sthExcludedShipState: String,
  bossExcludedShipState: String,
  onlineStoreStatus: Boolean,
  onlineStoreStatusType: String,
  inStoreAssemblyEligible: Boolean,
  fulfillmentOptions: arrayOf(FulfillmentOption)
});

const Attribute = shapeType({
  name: String,
  value: String,
  sequenceNumber: String,
});

export const Product = shapeType({
  itemId: String,
  addons: arrayOf(Addon),
  info: Info,
  specificationGroup: arrayOf(SpecificationGroup),
  pricing: Pricing,
  media: Media,
  identifiers: Identifiers,
  fulfillment: Fulfillment,
  dataSources: String,
  attributes: arrayOf(Attribute),
  essentialAccessories: Boolean
});

export const Item = shapeType({
  id: String,
  quantity: String,
  product: Product,
  selectedFulfillment: String
});

const Appliance = shapeType({
  type: String,
  zipCode: String,
  ids: arrayOf(String)
});

const PickupLocation = shapeType({
  curbsidePickupFlag: Boolean,
  storeName: String,
  // city: String,
  // state: String,
  // storePhone: String,
  // isBuyInStoreCheckNearBy: Boolean,
  // distance: String,
  // zipCode: String,
  // type: String,
  // storeHours: StoreHours,
  // isAnchor: Boolean,
  // locationId: String,
  // inventory: Inventory,
});

const Pickup = shapeType({
  location: PickupLocation,
  ids: arrayOf(String)
});

const availableSlot = shapeType({
  date: String,
  slotsAvailable: Int,
});

const Calendar = shapeType({
  type: String,
  earliestWeekendDate: String,
  earliestDeliveryDate: String,
  availableSlots: arrayOf(availableSlot),
  ids: arrayOf(String)
});

const Grouping = shapeType({
  type: String,
  ids: arrayOf(String)
});

const Delivery = shapeType({
  calendar: arrayOf(Calendar),
  grouping: arrayOf(Grouping)
});

const ByFulfillment = shapeType({
  appliance: arrayOf(Appliance),
  pickup: arrayOf(Pickup),
  delivery: Delivery
});

const ItemGrouping = shapeType({
  byFulfillment: ByFulfillment
});

const DescriptiveAttr = shapeType({
  cartType: String,
  paypalExpress: Boolean,
  paymentOnHold: Boolean,
  isIcEnabled: Boolean,
  displayIcOption: String,
  poJobName: String,
  hasSubscriptionItems: Boolean,
  maxCartPriceContributor: String
});

const Restriction = shapeType({
  paymentType: String,
});

const PromoItem = shapeType({
  appliedDiscount: String,
  appliedOn: String,
});

const Promo = shapeType({
  desc: String,
  longDesc: String,
  type: String,
  tag: String,
  appliedDisc: BigDecimal,
  promoCode: String,
  restrictions: arrayOf(Restriction),
  message: Message,
  attached: Boolean,
  promoItems: arrayOf(PromoItem)
});

const Localization = shapeType({
  primaryStoreId: String,
  deliveryZip: String,
  deliveryStateCode: String
});

const Adjustment = shapeType({
  amount: BigDecimal,
  type: String,
});

const Totals = shapeType({
  total: BigDecimal,
  totalDiscount: BigDecimal,
  totalWithNoDiscount: BigDecimal,
  deliveryCharge: BigDecimal,
  applianceDeliveryCharge: BigDecimal,
  type: String,
  adjustments: arrayOf(Adjustment)
});

// const EssentialAccessories = shapeType(

// const Swatch = shapeType({

// const ItemLabel = shapeType({
const Badge = shapeType({
  strategygroup: String,
  score: String,
  stack: String,
  name: String,
  label: String,
  id: String,
  message: String,
  strategy: String
});

const CartInfo = shapeType({
  cartId: String,
  itemCount: Int,
  customer: Customer,
  payments: arrayOf(Payment),
  rentalEstimate: RentalEstimate,
  messages: arrayOf(Message),
  items: arrayOf(Item),
  itemGrouping: ItemGrouping,
  descriptiveAttr: DescriptiveAttr,
  promos: arrayOf(Promo),
  localization: Localization,
  totals: Totals
});

export const cartInfo = CartInfo;
export const CartResponse = params().shape({
  cartInfo
});
export const getCartQueryAndResponse = {
  getCart: alias('cartInfo').params({ optimalFulfillment: Boolean }).shape(cartInfo),
};