/* eslint-disable react/prop-types */
import React, {
  useState, useEffect, useContext
} from 'react';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { string, number, bool } from 'prop-types';
import { QueryProvider, extend } from '@thd-nucleus/data-sources';
import {
  Button
} from '@one-thd/sui-atomic-components';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { AddToQuoteDrawer } from './drawers/AddToQuoteDrawer';
import { useQuoteProduct, QuoteProductModel } from './hooks/useQuotes';

const customerInfoCache = {
  svocId: null,
  userId: null
};

const AddToQuote = ({
  itemId,
  quantity,
  fulfillmentLocation,
  fulfillmentMethod,
  disabled
}) => {
  const { deliveryZip: deliveryZipCode } = useContext(ExperienceContext);
  const store = useStore();
  const [drawerShowing, setDrawerShowing] = useState(false);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-quote.ready');
  }, []);

  /** Use new hook once ready */
  if (!customerInfoCache.svocId && typeof window !== 'undefined') {
    const { svocID, userID, role } = window?.THDCustomer?.default;
    customerInfoCache.svocId = svocID;
    customerInfoCache.userId = userID;
    customerInfoCache.role = role?.toUpperCase();
  }

  const { quoteProduct } = useQuoteProduct({ itemId });
  const product = quoteProduct?.product;
  const { pricing } = product || {};

  let isDisabled;
  if (disabled === null) {
    isDisabled = !podFulFillmentUtils.isATCEnabled(product) || podFulFillmentUtils.isAppliance(product);
  } else {
    isDisabled = disabled;
  }
  const fulfillmentMethodFromFulfillmentComponent = podFulFillmentUtils.getFulfillmentMethod(product);
  const fulfillmentLocationFromProductPodUtils = ProductPodUtils.getFulfillmentLocation({
    fulfillmentMethod: fulfillmentMethodFromFulfillmentComponent, store, product, deliveryZip: deliveryZipCode
  });

  const openDrawer = () => {
    setDrawerShowing(true);
  };

  return (
    <>
      <Button
        variant="secondary"
        fullWidth
        onClick={openDrawer}
        disabled={isDisabled}
        data-testid="add-to-quote"
        data-component="AddToQuote"
      >
        Add to Quote
      </Button>
      {
        drawerShowing && (
          <QueryProvider
            cacheKey="add-to-quote-drawer"
            defaultVariables={{ svocId: customerInfoCache?.svocId }}
          >
            <AddToQuoteDrawer
              product={product}
              itemId={itemId}
              quantity={quantity}
              customerInfo={customerInfoCache}
              localStoreId={store?.storeId}
              fulfillmentMethod={fulfillmentMethod || fulfillmentMethodFromFulfillmentComponent}
              fulfillmentLocation={fulfillmentLocation || fulfillmentLocationFromProductPodUtils}
              price={pricing?.value}
              drawerShowing={drawerShowing}
              setDrawerShowing={setDrawerShowing}
              deliveryZipCode={deliveryZipCode}
            />
          </QueryProvider>
        )
      }
    </>
  );
};

AddToQuote.propTypes = {
  itemId: string.isRequired,
  quantity: number.isRequired,
  fulfillmentMethod: string,
  fulfillmentLocation: string,
  disabled: bool,
};

AddToQuote.defaultProps = {
  fulfillmentMethod: '',
  fulfillmentLocation: '',
  disabled: null,
};

AddToQuote.displayName = 'AddToQuote';

AddToQuote.dataModel = extend(QuoteProductModel);

export { AddToQuote };
