/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  alias,
  params,
  arrayOf,
  shape as shapeType,
  string as stringType,
  number as numberType,
  bool as boolType,
  customType
} from '@thd-nucleus/data-sources';
import { cartInfo } from './cartResponseDataModel';

/**
 * Should resolve to the backend schema:
 * ocm-cart-service-cart-information mutation.graphqls
 */

const Int = numberType();
const BigDecimal = numberType();
const String = stringType();
const Boolean = boolType();

const AddonsRTO = shapeType({
  type: String,
  price: BigDecimal,
  quantity: String,
  itemId: String,
  id: String,
  description: String,
  selected: Boolean,
  category: String,
  configAttr: String,
});

const AddressRTO = shapeType({
  lastName: String,
  zipCode: String,
  country: String,
  hideCVV: Boolean,
  city: String,
  type: String,
  firstName: String,
  default: Boolean,
  primaryPhoneId: String,
  phone: String,
  addressIdentifier: String,
  state: String,
  businessAddress: Boolean,
  line1: String,
});

const GiftcardConfig = shapeType({
  brandId: String,
  brandName: String,
  merchantId: String,
  brandCode: String,
  type: String,
  id: String,
  productId: String,
  upc: String,
  price: BigDecimal,
  itemId: String,
  name: String,
  deliveryMethod: String,
  frontImage: String,
  frontSmallImage: String,
  bhnFrontImage: String,
  recipientEmail: String,
  fromName: String,
  toName: String,
  expectedArrival: String,
  instruction: String,
  canonicalUrl: String,
});

const ItemRTO = shapeType({
  itemId: String,
  quantity: String,
  location: String,
  type: String,
  id: String,
  assembleItemAtStore: String,
  configId: String,
  store: String,
  expectedArrival: String,
  addons: arrayOf(AddonsRTO),
  giftcardconfig: GiftcardConfig,
  proService: String,
  shipmentModeCode: String,
  instruction: String,
  vendorNumber: String,
  modelNumber: String,
  storeSKU: String,
  startTime: String,
  endtime: String,
  baseCharge: String,
  windowCharge: String,
  serviceLevelCharge: String,
  serviceLevel: String,
  responseCode: String,
  calendarStatus: String,
  windowCode: String,
  unattended: String,
  palletCount: String,
  vehicleTypeCode: String,
  refreshCalendar: Boolean,
  slotUpdated: Boolean,
  self: String,
  email: String,
  notify: String,
  desk: String
});

const ItemsRTO = shapeType({
  delivery: arrayOf(ItemRTO),
  pickup: arrayOf(ItemRTO),
  itemReq: arrayOf(ItemRTO)
});

const AddressGrouping = shapeType({
  id: arrayOf(String),
  address: AddressRTO
});

const CartAttribute = shapeType({
  updateFulfillmentStore: String,
  reCaptcha: String,
  csrStatusCode: String,
  taxExemptId: String,
  businessName: String,
  notifySms: Boolean,
  notifySmsNumber: String,
  sflListId: String,
});

const LocalizationRTO = shapeType({
  primaryStoreId: Int,
  deliveryZip: String,
  deliveryStateCode: String,
});

const CartInfoRequest = shapeType({
  cartId: String,
  localization: LocalizationRTO,
  cartAttr: CartAttribute,
  addressGrouping: arrayOf(AddressGrouping),
  items: ItemsRTO
});

const cartRequest = customType('CartInfoRequest').shape(CartInfoRequest).isRequired();

export const updateCart = params({
  cartRequest,
  optimalFulfillment: Boolean
}).mutation().shape(cartInfo);

export const addToCart = params({
  cartRequest,
}).mutation().shape(cartInfo);

export const deleteItem = params({
  cartRequest,
  optimalFulfillment: Boolean
}).mutation().shape(cartInfo);

// aka, removeAll
export const deleteCart = params().mutation().shape(
  // cartInfo
  shapeType({
    cartId: String,
    itemCount: Int,
  })
);

// export const addPromoAliased = alias('addPromotionCode').params({
//   promotionCode: String.isRequired(),
//   reCaptchaToken: String.isRequired()
// }).mutation().shape(cartInfo);

// export const deletePromoAliased = alias('deletePromotionCode').params({
//   promotionCode: String.isRequired(),
// }).mutation().shape(cartInfo);

export const addPromo = params({ // alias('addPromotionCode').
  promotionCode: String.isRequired(),
  reCaptchaToken: String.isRequired()
}).mutation().shape(cartInfo);

export const deletePromo = params({ // alias('deletePromotionCode')
  promotionCode: String.isRequired()
}).mutation().shape(cartInfo);

export const cartToList = params({
  cartRequest
}).mutation().shape(cartInfo);
export const listToCart = params({
  cartRequest
}).mutation().shape(cartInfo);

export const copySharedCart = params({
  fromUserId: String.isRequired(),
  fromCustomerId: String.isRequired(),
  mergeWithExistingCart: Boolean
}).mutation().shape(cartInfo);

const modelMap = {
  addToCart,
  updateCart,
  deleteItem,
  deleteCart,
  addPromo, // : addPromoAliased,
  deletePromo, // : deletePromoAliased,
  cartToList,
  listToCart,
  copySharedCart
};

export const getDataModel = (mutationName) => {
  return modelMap[mutationName];
};
// updatePO(purchaseOrderNumber: String): CartInfo
// deletePO(purchaseOrderNumber: String!): CartInfo