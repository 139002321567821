import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, bool, useDataModel,
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { getCustomUrlWithAnalytics } from '../../productDetailsUtils';
import styles from './ProductHeader.module.scss';

const CONFIGURABLE_BLINDS = 'CONFIGURABLE_BLINDS';
const BUILD_AND_BUY = 'Build and Buy';
const cx = classNames.bind(styles);
export const ProductHeader = ({
  itemId,
  brand,
  collectionBlock,
  disableShopThisCollection,
  large,
  target,
  brandMaxLine,
  brandTitleMaxLine,
  titleMaxLine,
  fullWidth,
  xSmall,
  onClick
}) => {
  const {
    channel,
    hosts
  } = useContext(ExperienceContext);

  const CustomHeaderTag = useConfigService('fs:specialBuyHeaderTag') ? 'div' : 'h3';

  const { additionalData = {} } = useImpression({ data: {
    id: itemId,
    component: 'ProductDetails',
    name: 'ProductDetails',
    position: 0
  } });

  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  // part of tnt code see note below
  const [newTitleName, setNewTitleName] = useState({});

  const [productState, setProductState] = useState({
    canonicalUrl: '',
    productType: '',
    productLabel: '',
    brandName: ''
  });

  const { data } = useDataModel('product', {
    variables: {
      itemId
    }
  });

  const { product } = data || {};

  const {
    details,
    identifiers,
    info
  } = product || {};

  const {
    collection: collectionObj
  } = details || {};

  useEffect(() => {
    if (identifiers?.productLabel) {
      setProductState({
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.productType,
        productLabel: identifiers?.productLabel,
        brandName: identifiers?.brandName
      });
    }
  }, [identifiers]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredMetadata]);

  // this is start of temporary code for tnt take out when tnt is done
  // tnt est start 4/24/23
  const output = useLifeCycleEventBus('product-name_PN120-testing', true);

  useEffect(() => {
    const incomingName = output[itemId];
    if (incomingName) {
      const sampleObj = ({});
      sampleObj[itemId] = incomingName;
      setNewTitleName({ ...newTitleName,
        ...sampleObj });
      setProductState({
        ...productState,
        productLabel: incomingName
      });
    }
  }, [output, itemId]);
  // end of temp tnt code
  const {
    name: collectionName,
    url: collectionUrl
  } = collectionObj || {};

  const canonicalUrl = identifiers?.canonicalUrl || productState.canonicalUrl;
  const productType = identifiers?.productType || productState.productType;
  const productLabel = newTitleName[itemId] || identifiers?.productLabel || productState.productLabel;
  const brandName = identifiers?.brandName || productState.brandName;

  if (!productLabel) return null;

  const handleClick = (event, action = null) => {
    const customExperience = !!info?.globalCustomConfigurator?.customExperience;
    if (!customExperience && onClick && action !== BUILD_AND_BUY) {
      onClick(event, product, action, sponsoredValues);
    }
    const productHeaderEventData = {
      podAction: action || 'product details',
      podAnchorSku: itemId,
      target,
      parent
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productHeaderEventData);
  };

  const shouldDisplayBrand = (location) => {
    return brandName && brandName.toLowerCase() !== 'unbranded' && brand === location;
  };

  const isBuildAndBuyProduct = productType === CONFIGURABLE_BLINDS;

  const isMobile = channel === 'mobile';
  const showShopThisCollectionLink = !disableShopThisCollection && collectionName && collectionUrl;
  const onBuildAndBuyImageClick = (event) => handleClick(event, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct ? onBuildAndBuyImageClick : handleClick;

  const productURL = getCustomUrlWithAnalytics({
    canonicalUrl,
    isBuildAndBuyProduct,
    blindsHost: hosts?.customBlinds,
    sponsoredValues: sponsoredValues?.isSponsored ? sponsoredValues : {
      isSponsored: info?.isSponsored,
      sponsoredMetadata: info?.sponsoredMetadata
    }
  });

  const headerTarget = isMobile ? '' : target;

  const titleClasses = cx('product-header__title', {
    'product-header__title-product': (brand === 'inline' || brand === 'above'),
    'product-header__title--large': large,
    'product-header__title--full-width': fullWidth
  });
  const labelClasses = cx('product-header__title-product', {
    'product-header__title-product--twoline': titleMaxLine === 2,
    'product-header__title-product--xsmall': xSmall && isMobile
  });
  const brandClasses = cx('product-header__title__brand', {
    'product-header__title__brand--oneline': brandMaxLine === 1,
    'product-header__title__brand--xsmall': xSmall && isMobile
  });
  const shopThisCollectionClasses = cx('product-header__title__collection', {
    'product-header__title__collection--block': brand === 'inline'
      && collectionBlock
  });
  const brandTitleClasses = cx({
    'product-header__title--control': !brandTitleMaxLine,
    'product-header__title--clamp': !!brandTitleMaxLine,
    'product-header__title--twoline': brandTitleMaxLine === 2,
    'product-header__title--threeline': brandTitleMaxLine === 3,
    'product-header__title--fourline': brandTitleMaxLine === 4
  });

  return (
    <>
      <div data-testid="product-header" data-component="ProductHeader">
        <a
          href={productURL}
          onClick={checkOnClick}
          target={headerTarget}
        >
          <div className={brandTitleClasses}>
            <CustomHeaderTag className={titleClasses}>
              {shouldDisplayBrand('above')
                && (
                  <p className={cx('product-header__title__brand--bold')}> {brandName} </p>
                )}
              {shouldDisplayBrand('inline')
                && (
                  <span className={cx('product-header__title__brand--bold')}>
                    {brandName}
                  </span>
                )}
              <span className={labelClasses}>
                {productLabel}
              </span>
            </CustomHeaderTag>
          </div>
        </a>
        {shouldDisplayBrand('below')
          && (
            <p className={brandClasses}>by
              <span className={cx('product-header__title__brand--bold')}> {brandName}</span>
            </p>
          )}
      </div>
      {showShopThisCollectionLink && (
        <a className={shopThisCollectionClasses} href={collectionUrl} target={target}>
          Shop this Collection
        </a>
      )}
    </>
  );
};

ProductHeader.propTypes = {
  itemId: PropTypes.string.isRequired,
  /** Position the brand name */
  brand: PropTypes.oneOf(['above', 'below', 'hidden', 'inline']),
  collectionBlock: PropTypes.bool,
  disableShopThisCollection: PropTypes.bool,
  /** Larger text for title */
  large: PropTypes.bool,
  target: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleMaxLine: PropTypes.number,
  brandMaxLine: PropTypes.number,
  brandTitleMaxLine: PropTypes.number,
  xSmall: PropTypes.bool,
  onClick: PropTypes.func,
};

ProductHeader.defaultProps = {
  brand: 'below',
  collectionBlock: true,
  disableShopThisCollection: false,
  large: false,
  target: null,
  fullWidth: false,
  titleMaxLine: null,
  brandMaxLine: null,
  brandTitleMaxLine: null,
  xSmall: false,
  onClick: () => {},
};

ProductHeader.displayName = 'ProductHeader';

ProductHeader.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSource: string(),
    details: shape({
      collection: shape({
        name: string(),
        url: string()
      })
    }),
    identifiers: shape({
      canonicalUrl: string(),
      brandName: string(),
      productType: string(),
      productLabel: string()
    }),
    info: shape({
      isSponsored: bool(),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      })
    })
  })
};
