import React, { useState } from 'react';
import { number, shape, string } from 'prop-types';
import {
  Card,
  CardTitle,
  CardMedia,
  CardBody,
  CardActions,
  Popover,
  Typography,
  Link
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

const RelatedProductsCard = ({
  itemId, canonicalUrl, image, productLabel, description, index
}) => {
  const [anchorElForPopover, setAnchorElForPopover] = useState(null);

  const { ref, clickID } = useImpression({
    data: {
      id: itemId || '',
      name: 'RelatedProductsCard',
      component: 'RelatedProductsCard',
      position: index + 1,
      type: 'product'
    }
  });

  const handleClick = (event) => {
    setAnchorElForPopover(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElForPopover(null);
  };

  return (
    <div
      className="sui-flex"
      ref={ref}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={clickID}
    >
      <Card>
        <a href={canonicalUrl} tabIndex={0}>
          <CardMedia
            src={`${image?.url}`.replace(/<SIZE>/g, '300')}
            alt={`${productLabel}`.replace(/"/g, '\'\'')}
            aspect="square"
          />
        </a>

        <CardTitle header={(
          <Typography
            component="h3"
            variant="body-base"
            weight="bold"
            lineClamp="3"
            height="tight"
          >
            {productLabel}
          </Typography>
        )}
        />

        <CardBody>
          <div className="sui-cursor-pointer">
            <Typography
              variant="body-xs"
              lineClamp="2"
              height="tight"
              onClick={handleClick}
            >
              {description}
            </Typography>
          </div>
          <Popover
            id="related-products-full-details-popover"
            open={!!anchorElForPopover}
            anchorEl={anchorElForPopover}
            onClose={handleClose}
            placement="top"
          >
            <Typography variant="body-xs">
              {description}
            </Typography>
          </Popover>
        </CardBody>

        <CardActions>
          <Link href={canonicalUrl} underline="always">
            View Product
          </Link>
        </CardActions>
      </Card>
    </div>
  );

};

RelatedProductsCard.defaultProps = {
  canonicalUrl: '',
  image: {},
  productLabel: '',
  description: '',
};

RelatedProductsCard.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  image: shape({}),
  productLabel: string,
  description: string,
  index: number.isRequired,
};

export { RelatedProductsCard };
