// Shared
export const HD_URL = 'https://www.homedepot.com';
export const APPENDED_BRAND_NAME = ' - The Home Depot';
export const OPEN_GRAPH_FACEBOOK_ADMIN = '100005416960152';
export const IMAGE_REGEX = /[/.](gif|jpg|jpeg|tiff|png)$/g;

// Article Metadata
export const ARTICLE_BUYING_GUIDE_REGEX = /ab/i;
export const ARTICLE_INSPIRATION_GUIDE_REGEX = /ai/i;
export const ARTICLE_PROJECT_GUIDE_REGEX = /ah/i;
export const ARTICLE_INFLUENCER_GUIDE_REGEX = /ap/i;

// Product Metadata
export const IMAGE_SIZE_REGEX = /_\d+./gm;
export const IMAGE_SIZE_URL_PARAM_REGEX = /_<SIZE>./g;

// Store Pages
export const SD_OG_IMAGE = 'https://assets.thdstatic.com/mcstore/images/hero-200x155.jpg';
export const HD_URL_L = 'https://www.homedepot.com/l/';
export const REVIEWS_PAGE_SIZE = 30;

export const PAGE_TYPE = {
  DETAILS: 'details',
  REVIEWS: 'reviews',
  HOME_SERVICES: 'services',
  RENTALS: 'rentals',
  GARDEN_CENTER: 'garden-center',
  PRO_DESK: 'pro-desk'
};

export const LAST_PATH = {
  DETAILS: '',
  HOME_SERVICES: '/services',
  RENTALS: '/rentals',
  GARDEN_CENTER: '/garden-center',
  REVIEWS: '/reviews',
  PRO_DESK: '/pro-desk'
};

export const EMT_PAGE_NAMES = {
  DETAILS: 'storeDetails',
  HOME_SERVICES: 'homeServices',
  RENTALS: 'rentals',
  GARDEN_CENTER: 'gardenCenter',
  REVIEWS: 'storeReviews',
  LOCAL_CITY: 'lcp',
  PRO_DESK: 'proDesk'
};

// Store Locator Metadata
export const STORE_LOCATOR_DESCRIPTION = 'Find The Home Depot location closest to you with our store locator.'
+ ' You\'ll save time and money at The Home Depot near you.';

// Product Reviews Metadata
export const DEFAULT_REVIEWS_SORT_BY = 'photoreview';