// NOTE: THIS IS NOT A STANDARD PATTERN AND IS ONLY BEING DONE FOR PRE-HOLIDAY
// THIS NEEDS TO BE DRIVEN BY AN IDM ATTRIBUTE PROVIDED VIA API
export const KEY_PRODUCT_FEATURE_POD_CATEGORIES = [
  '5yc1vZc2ec',
  '5yc1vZc91o',
  '5yc1vZcerm',
  '5yc1vZc22a',
  '5yc1vZc28p',
  '5yc1vZc8lk',
  '5yc1vZ2fkoml9',
  '5yc1vZ1z18gv1',
  '5yc1vZ1z18gvv',
  '5yc1vZ1z18gva',
  '5yc1vZ1z18gw7',
  '5yc1vZ1z18gw1',
  '5yc1vZ1z18gw6',
  '5yc1vZ1z18gwg',
  '5yc1vZ1z18guy',
  '5yc1vZ1z18gwj',
  '5yc1vZ1z18gwd',
  '5yc1vZ1z18gw9',
  '5yc1vZ1z18gvg',
  '5yc1vZ2fkoml9',
  '5yc1vZc5ax',
  '5yc1vZcarh',
  '5yc1vZcari',
  '5yc1vZc5ak',
  '5yc1vZc5c0',
  '5yc1vZcjob',
  '5yc1vZbrdj',
  '5yc1vZc2dc',
  '5yc1vZc2h7',
  '5yc1vZc2d7',
  '5yc1vZ2fkokir',
  '5yc1vZc3wt',
  '5yc1vZc29s',
  '5yc1vZc2f6',
  '5yc1vZc2br',
  '5yc1vZcc0i',
  '5yc1vZc26g',
  '5yc1vZ2fkool7',
  '5yc1vZc232',
  '5yc1vZc23t',
  '5yc1vZcc0k',
  '5yc1vZcc5t',
  '5yc1vZ2fkool0',
  '5yc1vZ2fkook4',
  '5yc1vZbmh8',
  '5yc1vZbmgk',
  '5yc1vZbmgj',
  '5yc1vZbmgp',
  '5yc1vZc6xq',
  '5yc1vZc4dq',
  '5yc1vZbtxa',
  '5yc1vZbtxc',
  '5yc1vZ2fkom5w',
  '5yc1vZ2fkom5o',
  '5yc1vZc5lx',
  '5yc1vZc3ph',
  '5yc1vZbtz2',
  '5yc1vZbu93',
  '5yc1vZbu8z',
  '5yc1vZbu95'
];

export const HYDRATION_STANDARD_SCROLL_BUFFER = 750;
export const CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER = 2500;
export const STICKY_HEADER_HEIGHT = 115;
export const CHECK_CAT_STYLE = 'catStyle=ShowProducts';

// For Hybrid
export const BRAND_PAGE = 'Brand Page';
export const HYBRID_LAYOUT = 'HybridPLPLayout';
export const NTT_PREFIX = '/Ntt-';

export default {
  KEY_PRODUCT_FEATURE_POD_CATEGORIES,
};

// Recommendation requestKey
export const RECS_REQUEST_KEY = 'aGAQFG4j6QtVTSWqujfFYWeIU6BR5Mee';

export const DEFAULT_LAYOUT_STYLE = 'Grid';

// TODO: Update if necessary with new values for Gen Merch
export const requestKey = 'aGAQFG4j6QtVTSWqujfFYWeIU6BR5Mee';
export const subscriptionType = ['STYLE_AND_DECOR'];
export const pepCampaignId = '1380';
