import React from 'react';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { Typography } from '@one-thd/sui-atomic-components';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { bool } from 'prop-types';

export const ProductOverviewAccordionTitleComponent = (props) => {
  const { disableHeight } = props;
  return (
    <>
      <div
        data-component="ProductOverviewAccordionTitle"
        className={`${!disableHeight ? 'sui-h-10' : ''} sui-flex sui-flex-col sui-justify-center`}
      >
        <Typography
          height="tight"
          weight="bold"
          variant="h5"
          component="h3"
        >
          Product Details
        </Typography>
      </div>
    </>
  );
};

ProductOverviewAccordionTitleComponent.displayName = 'ProductOverviewAccordionTitleComponent';

ProductOverviewAccordionTitleComponent.propTypes = {
  disableHeight: bool
};

ProductOverviewAccordionTitleComponent.defaultProps = {
  disableHeight: false
};

const DynamicComponent = withDynamicComponent(ProductOverviewAccordionTitleComponent);
const ProductOverviewAccordionTitle = withErrorBoundary(DynamicComponent);

ProductOverviewAccordionTitle.displayName = 'ProductOverviewAccordionTitle';

export { ProductOverviewAccordionTitle };
