import React, { useState, useEffect } from 'react';
import { oneOf } from 'prop-types';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { TileGroup, Tile } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { toggleProjectPriceAnalytics } from '../../analytics';

export const ProjectToggle = ({ pageType }) => {
  const [selectedTile, setSelectedTile] = useState('unitPrice');
  const { toggleOn } = useLifeCycleEventBus('flooringPicker.flooringPicker_toggle', true);

  const triggerAnalyticsAndEvents = (value) => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('flooringPicker.flooringPicker_toggle', { toggleOn: value });
    toggleProjectPriceAnalytics({ isToggledOn: value, pageType });
  };

  useEffect(() => {
    if (window && window.sessionStorage) {
      let storageValue = window.sessionStorage.getItem('flooringPicker.flooringPicker_selection');
      if (storageValue !== null) {
        setSelectedTile(storageValue);
      }
    }
  }, []);

  useEffect(() => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('flooringPicker.flooringPicker_selection', selectedTile);
    }

    triggerAnalyticsAndEvents(selectedTile === 'projectPrice');
  }, [selectedTile]);

  useEffect(() => {
    if (toggleOn && selectedTile !== 'projectPrice') {
      setSelectedTile('projectPrice');
    }
  }, [toggleOn]);

  const getTileStyles = (value) => {
    // Overriding the styles was necessary in order to meet UX comps (mostly responsive scaling of the tiles
    return classNames(
      'max-sm:sui-grow sui-rounded-md sui-lab-btn-base sui-bg-primary',
      'sui-border-solid sui-rounded-base sui-text-primary',
      'hover:sui-outline hover:sui-outline-2 hover:-sui-outline-offset-2',
      'focus-visible:sui-outline focus-visible:sui-outline-input-focus',
      'focus-visible:sui-outline-4 focus-visible:-sui-outline-offset-4',
      'sui-p-[7px] hover:sui-outline-button-hover-accent',
      {
        'sui-border-1 sui-border-input-primary': selectedTile !== value,
        'sui-border-2 sui-border-input-active': selectedTile === value
      });
  };

  return (
    <div className="sui-flex sui-items-center sui-justify-center max-sm:sui-mt-4 max-sm:sui-w-full">
      <TileGroup
        value={selectedTile}
        className="sui-flex sui-flex-row sui-gap-3 sui-w-full"
        onChange={(event, val) => {
          setSelectedTile(val);
        }}
      >
        <Tile
          value="unitPrice"
          className={getTileStyles('unitPrice')}
          data-testid="unit-price"
        >
          <p className="sui-py-2 sui-px-4 sui-text-lg max-sm:sui-mx-auto">
            Unit Price
          </p>
        </Tile>
        <Tile
          value="projectPrice"
          className={getTileStyles('projectPrice')}
          data-testid="project-price"
        >
          <p className="sui-py-2 sui-px-4 sui-text-lg max-sm:sui-mx-auto">
            Project Price
          </p>
        </Tile>
      </TileGroup>
    </div>
  );
};

ProjectToggle.propTypes = {
  pageType: oneOf(['PIP', 'PLP'])
};

ProjectToggle.defaultProps = {
  pageType: 'PIP'
};