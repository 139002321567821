import isDraftProduct from './isDraftProduct';

export const findOmsId = () => {
  const url = typeof window !== 'undefined' ? window?.location : '';
  const urlSearchParams = new URLSearchParams(url.search);

  if (isDraftProduct) {
    return urlSearchParams.get('draftProductId');
  }

  const urlOmsIdMatches = url?.href?.match(/\/([0-9]{9})/);
  const anchorSkuMatches = urlSearchParams.get('omsid');
  // eslint-disable-next-line no-nested-ternary
  return anchorSkuMatches ? anchorSkuMatches[1] : (urlOmsIdMatches ? urlOmsIdMatches[1] : null);
};

const omsId = findOmsId();
export default omsId;
