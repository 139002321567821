import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  arrayOf,
  number,
  string,
} from 'prop-types';

import {
  FormController,
  FormLabel,
  Radio, RadioGroup, SelectionControlLabel,
} from '@one-thd/sui-atomic-components';

import { CalculatorContext } from './subcomponents/CalculatorProvider';

export const CalculateByDimensions = ({
  dimensionNames,
  selectedArea
}) => {
  const {
    calculate: {
      areas,
    },
    calculatorType,
    setCalculateStateAndRef
  } = useContext(CalculatorContext);

  const formControllerWrapper = classNames({
    'sui-pr-5': selectedArea === null,
    'sui-w-full': selectedArea !== null
  });

  const handleToggleText = () => {
    const adjustedAreas = areas.map((area, index) => {
      return {
        ...area,
        calcByArea: selectedArea !== null && index !== selectedArea
          ? area.calcByArea
          : !area.calcByArea
      };
    });
    setCalculateStateAndRef((prevState) => {
      return {
        ...prevState,
        areas: adjustedAreas,
        changesAwaitingLocalStorage: true
      };
    });
  };

  const [dimension1, dimension2] = dimensionNames;
  const depthToggleLabel = calculatorType === 'Volume' ? ' x Depth' : '';
  const lwToggleLabel = `${dimension1} x ${dimension2}${depthToggleLabel}`;
  const sqFtToggleLabel = `Square Footage${depthToggleLabel}`;

  const filteredAreas = selectedArea !== null ? [areas[selectedArea]] : areas;

  return (
    <div className="sui-flex sui-w-full sui-mt-3 sui-mb-3">
      <div className={formControllerWrapper}>
        <FormController fullWidth>
          <span className="sui-pt-1"><FormLabel>Calculate by:</FormLabel></span>
          <div className="sui-flex">
            <RadioGroup>
              <SelectionControlLabel value={lwToggleLabel} label={lwToggleLabel}>
                <Radio
                  onChange={handleToggleText}
                  checked={!filteredAreas[0].calcByArea}
                />
              </SelectionControlLabel>
              <SelectionControlLabel value={sqFtToggleLabel} label={sqFtToggleLabel}>
                <Radio
                  onChange={handleToggleText}
                  checked={filteredAreas[0].calcByArea}
                />
              </SelectionControlLabel>
            </RadioGroup>
          </div>
        </FormController>
      </div>
    </div>
  );
};

CalculateByDimensions.displayName = 'CalculateByDimensions';

CalculateByDimensions.propTypes = {
  dimensionNames: arrayOf(string),
  selectedArea: number
};

CalculateByDimensions.defaultProps = {
  dimensionNames: ['Width', 'Height'],
  selectedArea: null
};