export const getVideoStructuredData = ({ videos, productLabel } = {}) => {
  return {
    '@context': 'https://schema.org',
    '@graph': videos && videos.map(({
      thumbnail, videoStill, shortDescription, url, uploadDate, dateModified
    }) => ({
      '@type': 'VideoObject',
      name: productLabel,
      description: shortDescription,
      thumbnailUrl: [thumbnail || videoStill],
      contentUrl: url,
      uploadDate,
      dateModified,
    }))
  };
};
