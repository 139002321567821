import { useEffect } from 'react';
import { useDebouncedState } from './useDebouncedState';

const TIMER_INTERVAL = 250;

export const useDebouncedQuantity = (quantity, enabled) => {
  const [debouncedQuantity, setDebouncedQuantity] = useDebouncedState(quantity, TIMER_INTERVAL);
  useEffect(() => {
    if (enabled) {
      setDebouncedQuantity(quantity);
    }
  }, [quantity]);
  return debouncedQuantity;
};