import React from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import './instant-checkout-loader.scss';

export const InstantCheckoutLoader = () => {
  return (
    <div className="loader_container" data-component="InstantCheckoutLoader">
      <Placeholder className="loader__rectangle_a" type="text" height="28px" />
      <Placeholder className="loader__rectangle_b" type="text" height="28px" />
    </div>
  );
};
