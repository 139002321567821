import React from 'react';
import PropTypes, { arrayOf, node } from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { EducationalInfo } from '../EducationalInfo/EducationalInfo';
import { AdditionalDetails } from '../AdditionalDetails/AdditionalDetails';
import { getSupplementVideos } from '../Helper';
import '../educational-summary.style.scss';

const EducationalSummaryContent = ({
  itemId, showDetails, children, maxVideos, product
}) => {

  const {
    video
  } = product?.media || [];

  const supplementalVideos = maxVideos ? getSupplementVideos(video).slice(0, maxVideos) : getSupplementVideos(video);

  if (!supplementalVideos.length) { return null; }

  const multipleVideos = supplementalVideos.length > 1;
  const detailsBreakpoint = showDetails ? '12' : '6';
  const detailsWrapperBreakpoint = showDetails ? '6' : '12';
  const videoAndDetailsBreakpoint = ((multipleVideos && showDetails) || showDetails) ? '6' : '12';
  const multVideoWrapperBreakpoint = multipleVideos ? detailsWrapperBreakpoint : '12';

  const videoWrapClasses = classNames({
    'educational-summary__desktop-view--container': !multipleVideos,
    'educational-summary__desktop-view--container--wrap-sooner': multipleVideos
  });

  const itemWrapperClasses = classNames('educational-summary__item-wrapper', {
    'educational-summary__item-wrapper--details': showDetails
  });

  return (
    <Row>
      <Col nopadding className="educational-summary__desktop-view">
        <Col
          xs={multVideoWrapperBreakpoint}
          sm={videoAndDetailsBreakpoint}
          className={videoWrapClasses}
          nopadding
        >
          {
            supplementalVideos.map((supVideo, i) => {
              return (
                <div className={itemWrapperClasses} key={i}>
                  <Col xs={detailsBreakpoint} className="educational-summary__video">
                    <VideoPlayer
                      videoStill={supVideo.videoStill}
                      videoId={supVideo.videoId}
                      width={300}
                      height={150}
                    />
                  </Col>
                  <Col xs={detailsBreakpoint} nopadding>
                    <EducationalInfo
                      title={supVideo.title}
                      shortDescription={supVideo.shortDescription}
                      link={supVideo.link}
                      multiView={multipleVideos || showDetails}
                    />
                  </Col>
                </div>
              );
            })
          }
        </Col>
        {
          showDetails && (
            <Col nopadding xs={multVideoWrapperBreakpoint} sm={videoAndDetailsBreakpoint}>
              <div className="educational-summary__details--container">
                <AdditionalDetails itemId={itemId}>
                  {children}
                </AdditionalDetails>
              </div>
            </Col>
          )
        }
      </Col>
    </Row>
  );
};

EducationalSummaryContent.displayName = 'EducationalSummaryContent';

EducationalSummaryContent.propTypes = {
  itemId: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
  children: PropTypes.oneOfType([node, arrayOf(node)]),
  maxVideos: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  product: PropTypes.shape({
    dataSources: PropTypes.string,
    identifiers: PropTypes.shape({
      productLabel: PropTypes.string
    }),
    media: PropTypes.shape({
      video: arrayOf(PropTypes.shape({
        link: PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string
        }),
        shortDescription: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        videoId: PropTypes.string,
        videoStill: PropTypes.string
      }))
    })
  }).isRequired
};

EducationalSummaryContent.defaultProps = {
  showDetails: false,
  children: null,
  maxVideos: null
};

export { EducationalSummaryContent };
