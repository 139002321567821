import React, { useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Typography, Link, IconButton, Popover
} from '@one-thd/sui-atomic-components';
import { ArrowForward, Calculator, Info } from '@one-thd/sui-icons';
import { useCalculatorLocalStorage } from '@thd-olt-component-react/calculator';
import { ProjectToggle } from '../project-toggle/ProjectToggle';
import { VINYL_SHEET_POPOVER_MESSAGE, ESTIMATE_YOUR_PROJECT } from '../../constants';

export const DrawerWidget = ({
  drawerOpen, label, onClick, pageType, isVinylSheet
}) => {
  const { coverage, calculatorData } = useCalculatorLocalStorage({ label });
  const { areas } = calculatorData || {};
  const areaLength = areas?.length || 0;
  const areasText = areaLength > 1 ? 'Areas' : 'Area';

  const anchorRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const id = tooltipOpen ? 'fixed-width-popover' : undefined;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="sui-w-full"
      >
        {!areaLength > 0 && (
          <>
            <div className="sui-w-full sui-flex sui-flex-row">
              <div className="sui-flex sui-flex-col sui-mr-auto">
                <div className="sui-flex sui-gap-2">
                  <Calculator size="regular" />
                  <Typography variant="body-lg" weight="bold">
                    {ESTIMATE_YOUR_PROJECT}
                  </Typography>
                </div>
                <div className="sui-ml-8 sui-flex sui-flex-row sui-items-center">
                  <Typography variant="body-xs">
                    Enter your rooms to estimate your cost
                  </Typography>
                  {isVinylSheet && (
                    <>
                      <IconButton
                        ref={anchorRef}
                        aria-describedby={id}
                        onClick={() => setTooltipOpen((value) => !value)}
                        icon={Info}
                      />
                      <Popover
                        id={id}
                        open={tooltipOpen}
                        anchorEl={anchorRef.current}
                        onClose={() => setTooltipOpen(false)}
                        placement="bottom"
                        title="Measuring for Vinyl Sheet"
                      >
                        <Typography variant="body-xs">
                          {VINYL_SHEET_POPOVER_MESSAGE}
                        </Typography>
                        <div className="sui-mt-2">
                          <Link
                            // eslint-disable-next-line max-len
                            href="https://images.thdstatic.com/catalog/pdfImages/d4/d4bbe90b-74b5-4ad2-b4e8-5a294fb14db7.pdf"
                            target="_blank"
                            variant="body-xs"
                          >
                            See our vinyl sheet measuring guide.
                          </Link>
                        </div>
                      </Popover>
                    </>
                  )}
                </div>
              </div>
              <button
                data-testid="open-drawer"
                type="button"
                onClick={() => onClick(!drawerOpen)}
              >
                <div className="max-sm:sui-hidden">
                  <Button variant="secondary">
                    Enter Rooms
                  </Button>
                </div>
                <div className="sui-flex sui-flex-col sui-justify-center md:sui-hidden">
                  <ArrowForward size="regular" />
                </div>
              </button>
            </div>
          </>
        )}
        {areaLength > 0 && (
          <>
            <div className="sui-w-full sui-flex sui-flex-row sui-justify-between max-sm:sui-flex-col">
              <div className="sui-flex sui-flex-col max-sm:sui-flex-row">
                <div className="sui-flex sui-gap-2">
                  <Calculator size="regular" />
                  <Typography variant="body-lg" weight="bold">
                    {areaLength} {areasText}, {coverage} sq. ft.
                  </Typography>
                </div>
                <div className="sui-ml-2 md:sui-ml-8">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    data-testid="open-drawer"
                    component="button"
                    onClick={() => onClick(!drawerOpen)}
                    underline="always"
                  >
                    Edit
                  </Link>
                </div>
              </div>
              <ProjectToggle pageType={pageType} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

DrawerWidget.propTypes = {
  drawerOpen: bool.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
  pageType: string.isRequired,
  isVinylSheet: bool.isRequired
};
