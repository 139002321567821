/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  Typography,
  Alert,
  SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { useAddToQuote } from '../hooks/useQuotes';
import { DeliveryZipCode } from './DeliveryZipCode';
import { DELIVERY_FULFILLMENT } from '../shared/Constants';

const PriceChangeWarningDrawer = ({
  itemId,
  quantity,
  price,
  fulfillmentLocation,
  fulfillmentMethod,
  localStoreId,
  customerInfo,
  setQuoteName,
  setError,
  selectedQuote,
  onClose,
  setPriceChangeWarningDrawer,
  setSuccess,
  setErrorDrawer,
  isQuoteV2Enabled = false,
  deliveryZip,
  setDeliveryZip,
  isDeliveryZipEnabled
}) => {
  const { svocId } = customerInfo;
  const [showLoader, setShowLoader] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const [addToQuote, quoteResponse] = useAddToQuote({
    itemId,
    quantity,
    fulfillmentLocation,
    fulfillmentMethod,
    svocId,
    userId: selectedQuote?.userId,
    quoteId: selectedQuote?.quoteId,
    localStoreId,
    deliveryZip,
    isQuoteV2Enabled
  });
  const { loading, data, error } = quoteResponse;

  const addItemToQuote = () => {
    setShowLoader(true);
    addToQuote();
  };

  const triggerAnalytics = () => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('add-to-quote.quoteAdd', {
      quoteType: 'online',
      quoteId: data?.addItemToQuote?.quoteId || data?.addToQuote?.cartId,
      item: {
        quoteLocation: 'pip',
        quantity,
        price: {
          basePrice: price
        },
        productInfo: {
          sku: itemId
        }
      }
    });
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setShowLoader(false);
      setQuoteName(selectedQuote.quoteName);
      setPriceChangeWarningDrawer(false);
      setSuccess(true);
      triggerAnalytics();
    } else if (error) {
      setShowLoader(false);
      setError(error);
      setErrorDrawer(true);
      setPriceChangeWarningDrawer(false);
    }
  }, [data, loading, error]);

  if (showLoader) {
    return (
      <DrawerBody>
        <SkeletonBlock />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody>
        <Alert status="warning">
          <Typography variant="body-lg" className="sui-pb-2">Updated Quoted Pricing</Typography>
          <div className="sui-pt-2">
            <Typography variant="body-base">
              Prices on your existing quote{' '}
              <u>{`${selectedQuote.quoteName}`}</u> will
              update when you add this item or change the delivery ZIP Code. Are you sure you want to proceed?
            </Typography>
          </div>
        </Alert>
        {isDeliveryZipEnabled && DELIVERY_FULFILLMENT.includes(fulfillmentMethod) && (
          <DeliveryZipCode
            deliveryZip={deliveryZip}
            setDeliveryZip={setDeliveryZip}
            setDisabled={setDisabled}
          />
        )}
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={addItemToQuote}
            data-testid="add-item-to-quote"
            disabled={isDisabled}
          >
            Add to Quote
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

PriceChangeWarningDrawer.propTypes = {};

PriceChangeWarningDrawer.displayName = 'PriceChangeWarningDrawer';

export { PriceChangeWarningDrawer };
