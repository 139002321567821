import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { Col } from '@thd-olt-component-react/core-ui';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { FREE_SHIPPING_THRESHOLD,
  APPLIANCE_CART_BTN_MESSAGE,
  CART_BTN_MESSAGE,
  APPLIANCE_BUNDLE_PRICE_MSG } from './appliances/constants';
import { DeliveryMessage } from './appliances/DeliveryMessage';
import './Buybox.style.scss';

const Buybox = ({
  cartOptions,
  cartReqParams,
  finalPriceMessage,
  itemId,
  message,
  onAddToCartClick,
  pricing,
  strikethrough,
  applianceBundle,
  checkedItems,
  storeId
}) => {

  const { channel } = useContext(ExperienceContext);
  const enableApplianceDeliveryCharge = useConfigService('enableApplianceDeliveryCharge') || false;
  const enableFreeDeliveryForExchange = useConfigService('enableFreeDeliveryForExchange') || false;
  const { isExchangeCustomer } = useThdCustomer() || {};

  const isApplianceDeliveryFree = (totalPrice) => {
    return totalPrice.pricing?.original >= FREE_SHIPPING_THRESHOLD;
  };

  const shouldShowFinalPriceMessage = finalPriceMessage && !applianceBundle;
  const shouldShowStrikethrough = strikethrough && !applianceBundle;

  return (
    <>
      <div className="fbt-buybox__message-price">
        {message
        && (
          <div className="fbt-buybox__message">
            {applianceBundle ? APPLIANCE_BUNDLE_PRICE_MSG : message.priceMessage}
          </div>
        )}
        <LoadingPlaceholder
          type="text"
          ready={checkedItems.length > 1}
          color="#E0E0E0"
          rows={3}
          style={{ height: 100 }}
        >
          <div className="fbt-buybox__price">
            {shouldShowStrikethrough && <span className="fbt-buybox__strikethrough" />}
            <Price
              basic={applianceBundle}
              channel={channel}
              itemId={itemId}
              large={applianceBundle}
              product={pricing}
              displayEachUom={!applianceBundle}
              storeId={storeId}
            />
            {applianceBundle
              && (
                <DeliveryMessage
                  freeDelivery={isApplianceDeliveryFree(pricing)}
                  enableApplianceDeliveryCharge={enableApplianceDeliveryCharge}
                  enableFreeDeliveryForExchange={enableFreeDeliveryForExchange}
                  isExchangeCustomer={isExchangeCustomer}
                />
              )}
          </div>
        </LoadingPlaceholder>
      </div>
      {shouldShowFinalPriceMessage && (
        <div className="fbt-buybox__final-price-message">See Final Price in Cart</div>
      )}
      <Col nopadding>
        <AddToCart
          cartOptions={cartOptions}
          cartReqParams={cartReqParams}
          onClick={onAddToCartClick}
          outline
          small={false}
        >
          { applianceBundle ? APPLIANCE_CART_BTN_MESSAGE : CART_BTN_MESSAGE }
        </AddToCart>
      </Col>
    </>
  );
};

Buybox.propTypes = {
  applianceBundle: PropTypes.bool,
  cartOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cartReqParams: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({})
  ]).isRequired,
  finalPriceMessage: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onAddToCartClick: PropTypes.func.isRequired,
  pricing: PropTypes.shape({}).isRequired,
  strikethrough: PropTypes.bool,
  checkedItems: PropTypes.arrayOf(PropTypes.string),
  storeId: PropTypes.string.isRequired
};

Buybox.defaultProps = {
  applianceBundle: false,
  finalPriceMessage: false,
  strikethrough: false,
  checkedItems: [],
};

Buybox.displayName = 'FBTBuybox';

export default Buybox;
