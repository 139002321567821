import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

export const BackgroundCircleIcon = ({ isThumbnail }) => {
  const classes = classNames(
    'sui-bg-inverse sui-absolute sui-inset-0 sui-opacity-50 sui-m-auto sui-pointer-events-none sui-rounded-full',
    {
      'sui-h-20 sui-w-20': !isThumbnail,
      'sui-h-12 sui-w-12': isThumbnail
    }
  );
  return <span className={classes} />;
};

BackgroundCircleIcon.propTypes = {
  isThumbnail: bool
};

BackgroundCircleIcon.defaultProps = {
  isThumbnail: false
};