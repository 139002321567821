import React, { useEffect } from 'react';
import { node } from 'prop-types';

export const ImageSwap = (({ children }) => {

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('media-gallery-rendered');
  }, []);

  return (
    <>
      { children }
    </>
  );
});

ImageSwap.propTypes = {
  children: node.isRequired
};