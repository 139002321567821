import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';

export const FBRPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  let numOfPlaceholders = isMobile ? 2 : 4;
  let placeholderHeight = isMobile ? 48 : 56;
  const placeholderGridClass = classNames('sui-grid', { 'sui-grid-cols-2': isMobile,
    'sui-grid-cols-4': !isMobile });
  const RenderPlaceholders = () => {
    let placeholder = [];
    for (let i = 0; i < numOfPlaceholders; i += 1) {
      placeholder.push(
        <Skeleton
          orientation="vertical"
          key={i}
        >
          <SkeletonBlock aspect="square" height={placeholderHeight} />
          <SkeletonLine variant="multi" fullWidth numberOfLines={isMobile ? 4 : 8} />
        </Skeleton>);
    }
    return placeholder;
  };
  return (
    <div data-component="FBRPlaceholder">
      <div className="sui-grid sui-w-full">
        <Skeleton
          orientation="vertical"
        >
          <SkeletonLine variant="single" fullWidth />
        </Skeleton>
      </div>
      <div className={placeholderGridClass}>
        <RenderPlaceholders />
      </div>
    </div>
  );
};
FBRPlaceholder.displayName = 'FBRPlaceholder';
