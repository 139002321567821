import React from 'react';
import { Link } from '@one-thd/sui-atomic-components';
import { arrayOf, shape, string } from 'prop-types';

export const Links = ({ links }) => {

  if (links.length === 0) {
    return null;
  }

  return (
    <ul className="sui-flex sui-flex-col sui-list-none sui-mt-2 sui-gap-2">
      {
        links.map((link, i) => {
          return (
            <li key={`setLink_${link.keyword}_${i}`}>
              <Link href={link.url} title={link.keyword}>
                {link.keyword}
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
};

Links.propTypes = {
  links: arrayOf(shape({
    url: string.isRequired,
    keyword: string.isRequired,
  })).isRequired
};
